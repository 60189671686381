import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { cancelSubscription } from "../../../../api-services/account-information/cancelSubscription";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { METADATA_QUERY_KEY } from "../metadata/useMetadataQuery";

export const useCancelSubscriptionMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const { notifySuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation(() => cancelSubscription(), {
    ...mutationOptions,
    onSuccess() {
      queryClient.invalidateQueries(METADATA_QUERY_KEY);
      notifySuccess(feedbackMessage("", FEEDBACK_TYPE.SUBSCRIPTION_CANCELED));
    },
  });
};
