import { useState } from "@hookstate/core";
import { state } from "./state";

export const useConfirmationModal = () => {
  return useState(state.confirmationModal);
};

export const useRequestPaymentModal = () => {
  return useState(state.requestPaymentModal);
};

export const useWithdrawFundsModal = () => {
  return useState(state.withdrawFundsModal);
};
