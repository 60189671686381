import { useMutation, UseMutationOptions } from "react-query";
import { addJobPackage } from "../../../../api-services/job-posts/addJobPackage";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";

export const useAddJobPackage = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const { notifyError } = useToast();

  return useMutation(
    async (body: { jobPostsCredits: number }) => {
      const { data } = await addJobPackage(body);

      return data;
    },
    {
      ...mutationOptions,
      onError() {
        notifyError(
          feedbackMessage("job package", FEEDBACK_TYPE.PURCHASE_ERROR)
        );
      },
    }
  );
};
