import { QueryOptions } from "@testing-library/react";
import { useQuery } from "react-query";
import { fetchUserBids } from "../../../../api-services/bids";
import { Bid } from "../../../../api-types/job-requests/bids";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../state/hooks";

export const ACCOUNT_BIDS = "account-bids";

export const useAccountBids = (options?: QueryOptions) => {
  const { id } = useScopedDowngradedStateValue(useUser());

  return useQuery<Bid[], unknown, Bid[]>(
    ACCOUNT_BIDS,
    async () => {
      const { data } = await fetchUserBids(id ?? "");

      return data;
    },

    {
      ...options,
      enabled: Boolean(id),
    }
  );
};
