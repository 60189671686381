import { useQuery } from "react-query";
import { fetchStripeAccount } from "../../../../api-services/stripe-account";
import { useMetadataQuery } from "../metadata/useMetadataQuery";

export const STRIPE_ACCOUNT_KEY = "stripe-account";

export const useStripeAccount = () => {
  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  return useQuery<any>(
    STRIPE_ACCOUNT_KEY,
    async () => {
      const { data } = await fetchStripeAccount();

      return data;
    },
    {
      enabled: !isLoadingMetadata && metadata?.stripeOnBoarding,
    }
  );
};
