import { Box, Divider, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";
import { SearchBarSkeleton } from "../../../common/professionals-search/browse-mainbar/SearchBarSkeleton";
import { SkeletonCollection } from "../../../common/professionals-search/browse-mainbar/SkeletonCollection";
import { SavedJobRequestHighlights } from "./SavedJobRequestsHighlights";
import { SearchForm } from "./SearchForm";
import { SearchJobPostFiltersDialog } from "./SearchJobPostFiltersDialog";

export const SearchMainbar: React.FC = () => {
  const boldStyles = useBoldStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const { isLoading } = useMetadataQuery();

  return (
    <Box display="flex" gridColumnGap={24}>
      <Box width={950} height={340}>
        <Box pl={1.5} py={10}>
          <Typography variant="h1" className={boldStyles.root}>
            Freelance Projects and Jobs
          </Typography>
        </Box>
        <SkeletonCollection
          isLoading={isLoading}
          skeleton={<SearchBarSkeleton />}
        >
          <SearchForm onOpenJobFiltersDialog={() => setOpenDialog(true)} />
        </SkeletonCollection>
      </Box>
      <Divider orientation="vertical" flexItem />
      <SavedJobRequestHighlights />

      <SearchJobPostFiltersDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Box>
  );
};
