import { useQuery } from "react-query";
import { fetchJobPosts } from "../../../../api-services/job-posts";
import { JobPostSummary } from "../../../../api-types/job-posts";

export const JOB_POSTS_QUERY_KEY = "job-posts";

export const useJobPosts = (isDraft: boolean = false) => {
  return useQuery<JobPostSummary[], unknown, JobPostSummary[]>(
    JOB_POSTS_QUERY_KEY,
    async () => {
      const { data: jobPosts } = await fetchJobPosts(isDraft);

      return jobPosts;
    }
  );
};
