import { CircularProgress, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { getMenuItemLabel } from "../../common/navbar/Navbar";

type MoreMenuProps = {
  anchorEl?: Element | null;
  handleClose?: () => void;
};

export const MoreMenu: React.FC<MoreMenuProps> = ({
  anchorEl,
  handleClose,
}) => {
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MenuItem
        onClick={() => {
          navigate(`/inquiries/`);
          handleClose?.();
        }}
      >
        {getMenuItemLabel(
          "Job Request Inquiries",
          isLoadingMetadata,
          metadata?.accountInquiriesCount
        )}
        {isLoadingMetadata && (
          <CircularProgress
            color="inherit"
            style={{
              marginLeft: 8,
              width: 16,
              height: 16,
            }}
          />
        )}
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/professional-referrals/");
          handleClose?.();
        }}
      >
        {getMenuItemLabel(
          "Referrals From Pros",
          isLoadingMetadata,
          metadata?.professionalReferralsCount
        )}
        {isLoadingMetadata && (
          <CircularProgress
            color="inherit"
            style={{
              marginLeft: 8,
              width: 16,
              height: 16,
            }}
          />
        )}
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/invitations/");
          handleClose?.();
        }}
      >
        {getMenuItemLabel(
          "Client Invitations",
          isLoadingMetadata,
          metadata?.clientInvitationsCount
        )}
        {isLoadingMetadata && (
          <CircularProgress
            color="inherit"
            style={{
              marginLeft: 8,
              width: 16,
              height: 16,
            }}
          />
        )}
      </MenuItem>
      {/*<MenuItem*/}
      {/*  onClick={() => {*/}
      {/*    navigate("/job-applications/");*/}
      {/*    handleClose?.();*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {getMenuItemLabel(*/}
      {/*    "Job Applications",*/}
      {/*    isLoadingMetadata,*/}
      {/*    metadata?.jobApplicationsCount*/}
      {/*  )}*/}
      {/*  {isLoadingMetadata && (*/}
      {/*    <CircularProgress*/}
      {/*      color="inherit"*/}
      {/*      style={{*/}
      {/*        marginLeft: 8,*/}
      {/*        width: 16,*/}
      {/*        height: 16,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</MenuItem>*/}
    </Menu>
  );
};
