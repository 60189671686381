import { FormikValues } from "formik";
import { JobRequest } from "../../../../../api-types/job-requests/job-request";

export const parseJobPostForm = (
  values: FormikValues,
  creating?: boolean
): any => {
  return {
    ...values,
    skills: values.skills.map((skillName: string | object) =>
      typeof skillName === "string" ? { name: skillName } : skillName
    ),
    createdAt: creating ? new Date().toISOString() : values.createdAt,
  };
};

export const parseJobRequestForm = (
  values: FormikValues,
  creating?: boolean
): JobRequest => {
  const serviceCategory = Object.keys(values?.services ?? {}).filter(
    (serviceKey) => values.services[serviceKey]
  )[0];

  return {
    _id: values._id,
    serviceCategory: creating ? serviceCategory : values.serviceCategory,
    projectType: values.project,
    skills: values.skills.map((skillName: string | object) =>
      typeof skillName === "string" ? { name: skillName } : skillName
    ),
    visibility: "public",
    description: values.description,
    title: values.title,
    timeFrame: values.timeFrame,
    images: values.images,
    isDraft: values.isDraft,
    budget: values.budget,
    fixedProjectFunding: values.fixedProjectFunding,
    hourlyRateProjectFunding: values.hourlyRateProjectFunding,
  };
};

export const jobRequestCanBePublished = (jobRequest: JobRequest): boolean => {
  const { title, description, visibility } = jobRequest;

  return title?.length > 0 && description.length > 0 && Boolean(visibility);
};
