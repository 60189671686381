import { Box, Divider, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { useJobRequests } from "../../../common/hooks/job-requests/useJobRequests";
import DraftJobRequestItem from "./DraftJobRequestItem";

export const DraftJobRequests: React.FC = () => {
  const boldStyles = useBoldStyles();
  const { data: jobRequests, isFetching: isLoadingJobRequests } =
    useJobRequests(true);

  const errorMessage = useMemo(() => {
    return !jobRequests?.length && !isLoadingJobRequests
      ? "Saved job request drafts will be shown here."
      : "";
  }, [isLoadingJobRequests, jobRequests?.length]);

  return (
    <PageWrapper>
      <Box height="100%" py={10}>
        <Section errorMessage={errorMessage} isLoading={isLoadingJobRequests}>
          <Typography variant="h1" className={boldStyles.root}>
            Drafts for Job Requests
          </Typography>
          <Box display="flex" flexDirection="column">
            {jobRequests?.map((jobRequest) => (
              <Box key={jobRequest._id} py={10}>
                <DraftJobRequestItem
                  jobRequest={jobRequest}
                  isLoading={isLoadingJobRequests}
                />
                <Box py={2.5}>
                  <Divider />
                </Box>
              </Box>
            ))}
          </Box>
        </Section>
      </Box>
    </PageWrapper>
  );
};
