import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { YELLOW } from "../../../../themes/components/utils";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const ProfessionalsSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <Box pt={12.5}>
      <Box display="flex" gridColumnGap={24} justifyContent="space-between">
        <Box
          width={180}
          pt={15}
          display="flex"
          flexDirection="column"
          gridRowGap={24}
          alignItems="center"
        >
          <Skeleton
            animation="wave"
            variant="circle"
            width={180}
            height={180}
          />
          <Skeleton animation="wave" variant="rect" width={100} height={40} />
        </Box>
        <Box
          width="calc(100% - 750px)"
          display="flex"
          flexDirection="column"
          gridRowGap={12}
        >
          <Box pb={5}>
            <Skeleton
              classes={{
                wave: styles.root,
              }}
              variant="rect"
              animation="wave"
              width="50%"
              height={30}
            />
          </Box>
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            height={30}
          />
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            height={30}
          />
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            height={30}
          />
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            height={30}
          />
          <Box pt={7.5} display="flex" gridColumnGap={8} alignItems="center">
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Box display="flex" gridColumnGap={4} alignItems="center">
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
            </Box>
          </Box>
        </Box>
        <Box width={450}>
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            width={450}
            height={350}
          />
        </Box>
      </Box>
    </Box>
  );
};
