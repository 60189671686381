import { FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { SignUpConfirmation } from "../common";
import { SignUpInfo } from "./SignUpInfoProps";

type CurrentClientStepProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: any;
  onBack?: () => void;
};

export const CurrentClientStep: React.FC<CurrentClientStepProps> = (props) => {
  const [searchParams] = useSearchParams();

  if (Number(searchParams.get("step")) === 2) {
    return <SignUpInfo {...props} />;
  }

  if (Number(searchParams.get("step")) === 3) {
    return <SignUpConfirmation {...props} />;
  }

  return null;
};
