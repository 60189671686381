import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../../../common/components";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../../../common/hooks/projects/project/useProjectQuery";
import { usePastProjectsOptions } from "../../../../../bids/hooks/usePastProjectOptions";

export const PortfolioProjectHeader: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: pastProjects, isLoading: isLoadingProjects } =
    usePastProjectsOptions();

  const isLoading = isLoadingProject || isLoadingProjects;

  const collectionConfig = {
    type: Collection.PORTFOLIO_PROJECT,
    items: pastProjects,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingProjects,
    projectId
  );

  const handleSelectCurrentPastProject = useCallback(
    (index: number) => {
      if (!pastProjects) return;

      navigate(`/projects/portfolio-project/${pastProjects[index]._id}`);
    },
    [navigate, pastProjects]
  );

  return (
    <Box pt={6}>
      <Box width="40%" pt={6}>
        <Box pb={2}>
          <LoadingTypography
            typographyProps={{
              variant: "subtitle2",
            }}
          >
            Portfolio Project ({index + 1} of {pastProjects?.length})
          </LoadingTypography>
        </Box>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={projectId}
          options={pastProjects}
          onSelect={handleSelectCurrentPastProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>
    </Box>
  );
};
