import { createState } from "@hookstate/core";
import { AppState } from "./types";

export const state = createState<AppState>({
  confirmationModal: {
    open: false,
  },
  subscribeModal: {
    open: false,
  },
  redirectUrl: null,
  authed: false,
  user: {
    name: "",
    id: null,
    accountType: null,
  },
  currentJobRequest: null,
});
