import { useQuery } from "react-query";
import { searchProjects } from "../../../../api-services/projects/searchProjects";
import { BrowseSearchFilters } from "../../../../api-types/job-requests/common";
import { Project } from "../../../../api-types/projects";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { useSearchFilters } from "../state/hooks";
import { defaultBrowseFilters } from "./useSearchProfessionals";

export const SEARCH_PROJECTS_QUERY_KEY = "search-projects";

const searchKey = (filters: BrowseSearchFilters) => {
  return `${SEARCH_PROJECTS_QUERY_KEY}-${filters.skills}-${filters.fee?.min}-${filters.fee?.max}`;
};

export const useSearchProjects = (useDefaultFilters?: boolean) => {
  const filters = useScopedDowngradedStateValue(useSearchFilters());

  return useQuery<Project[], unknown, Project[]>(
    searchKey(useDefaultFilters ? defaultBrowseFilters : filters),
    async () => {
      const { data } = await searchProjects(
        useDefaultFilters ? defaultBrowseFilters : filters
      );

      return data;
    }
  );
};
