import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const MainbarSkeleton: React.FC = () => {
  return (
    <Box pt={2}>
      <Box pb={2}>
        <Skeleton variant="rect" animation="wave" width="40%" height={20} />
      </Box>
      <Box
        pt={2}
        display="flex"
        gridColumnGap={8}
        justifyContent="space-between"
      >
        <Box display="flex" gridColumnGap={36} width="65%">
          <Skeleton variant="rect" animation="wave" width="78%" height={68} />
          <Skeleton variant="rect" animation="wave" width="15%" height={68} />
        </Box>
        <Skeleton variant="rect" animation="wave" width="20%" height={68} />
      </Box>{" "}
      <Box display="flex" gridColumnGap={8} justifyContent="space-between">
        <Box display="flex" gridColumnGap={12} width="80%" pt={10}>
          <Skeleton variant="rect" animation="wave" width="10%" height={30} />
          <Skeleton variant="rect" animation="wave" width="15%" height={30} />
          <Skeleton variant="rect" animation="wave" width="15%" height={30} />
          <Skeleton variant="rect" animation="wave" width="10%" height={30} />
          <Skeleton variant="rect" animation="wave" width="20%" height={30} />
          <Skeleton variant="rect" animation="wave" width="15%" height={30} />
        </Box>
      </Box>
    </Box>
  );
};
