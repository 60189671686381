import { Box, Divider } from "@material-ui/core";
import React from "react";
import { UnpublishedPastProjectHeaderContent } from "./UnpublishedPastProjectHeaderContent";
import { UnpublishedPastProjectHeaderSummary } from "./UnpublishedPastProjectHeaderSummary";

export const UnpublishedPastProjectHeader: React.FC = () => {
  return (
    <Box display="flex" gridColumnGap={40} height={250}>
      <UnpublishedPastProjectHeaderContent />
      <Divider orientation="vertical" flexItem />
      <UnpublishedPastProjectHeaderSummary />
    </Box>
  );
};
