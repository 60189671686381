import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchBid } from "../../../../api-services/bids/bid";
import { BidData } from "../../../../api-types/job-requests/bids";
import { NEW_ID } from "../../inquiries/hooks/useDefaultInquiryPage";
import { ACCOUNT_BIDS } from "./useAccountBids";

export const accountBidKey = (id: null | string) => {
  return `${ACCOUNT_BIDS}-${id}`;
};

export const useAccountBid = (id?: string) => {
  const { bidId } = useParams();

  return useQuery<BidData, unknown, BidData>(
    accountBidKey(bidId),
    async () => {
      const { data } = await fetchBid(id ?? bidId);

      return data;
    },
    {
      enabled: Boolean(id ?? bidId),
    }
  );
};
