import { Box, makeStyles, MenuItem } from "@material-ui/core";
import React, { ReactNode } from "react";
import { YELLOW } from "../../../themes/components/utils";

type NotificationWrapperProps = {
  icon?: ReactNode;
  children?: ReactNode;
};

const useStyles = makeStyles({
  menuItem: {
    border: `1px solid rgba(202, 227, 244, 0.2)`,
    "&:hover": {
      border: `1px solid ${YELLOW}`,
    },
  },
});

export const NotificationWrapper: React.FC<NotificationWrapperProps> = ({
  icon,
  children,
}: NotificationWrapperProps) => {
  const styles = useStyles();

  return (
    <MenuItem
      className={styles.menuItem}
      style={{
        whiteSpace: "normal",
        height: 120,
        width: "100%",
        borderRadius: 15,
      }}
    >
      <Box
        display="flex"
        gridColumnGap={16}
        width="100%"
        px={4}
        alignItems="center"
      >
        <Box display="flex" alignItems="center" width={80}>
          {icon}
        </Box>
        <Box
          width="90%"
          style={{
            color: YELLOW,
            fontSize: 21,
          }}
        >
          {children}
        </Box>
      </Box>
    </MenuItem>
  );
};
