import { Box, Divider } from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  MilestoneInvoice,
  milestoneInvoicesColumns,
} from "../../../../api-types/projects/milestone-invoices";
import { BROWSE_DEFAULT_URL } from "../../../../routes/utils";
import { DownloadableDataGrid } from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { CompleteProjectDialog } from "../../../common/components/modals/complete-project-dialog";
import { ProfessionalSummary } from "../../../common/components/ProfessionalSummary";
import { useMilestonesInvoices } from "../../../common/hooks/projects/project/milestone-invoices/useMilestonesInvoices";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { formatInvoiceId } from "../../../utils";
import { useCompleteProject } from "../hooks/useCompleteProject";
import { useCompleteProjectModal } from "../state/hooks";
import { MilestonesPaymentAndFunding } from "./MilestonesPaymentAndFunding";

export const generateLocalId = (title: string, index: number) => {
  return `${String(title.toLocaleUpperCase()).substring(0, 2)}-${1 + index}`;
};

export const Payments: React.FC = () => {
  const { data: milestoneInvoices, isLoading: isLoadingMilestoneInvoices } =
    useMilestonesInvoices();

  const navigate = useNavigate();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const completeProjectModal = useScopedDowngradedStateValue(
    useCompleteProjectModal()
  );
  const setCompleteProjectModal = useCompleteProjectModal().set;

  const rows = useMemo(
    () =>
      milestoneInvoices?.map((milestoneInvoice: MilestoneInvoice, index) => ({
        description: milestoneInvoice.description,
        amount: `$${milestoneInvoice.amount}`,
        professional: `${milestoneInvoice.professional?.accountInformation?.firstName} ${milestoneInvoice.professional?.accountInformation?.lastName}`,
        title: milestoneInvoice.project.title,
        createdAt: format(
          new Date(milestoneInvoice.createdAt),
          formatNominativeMonth
        ),
        id: generateLocalId(
          milestoneInvoice.description.trim() ?? "UNKNOWN",
          index
        ),
        invoice: formatInvoiceId(milestoneInvoice),
      })) ?? [],
    [milestoneInvoices]
  );

  const { mutate: completeProject } = useCompleteProject();

  return (
    <Box gridColumnGap={20}>
      {project?.professionals.map(({ professional }) => (
        <Box pb={10} key={professional._id}>
          <Box pb={5}>
            <ProfessionalSummary professional={professional} width={48} />
          </Box>

          <Divider />

          <MilestonesPaymentAndFunding />

          <DownloadableDataGrid
            columns={milestoneInvoicesColumns}
            data={rows}
            title="Milestone Invoices"
          />
        </Box>
      ))}
      <CompleteProjectDialog
        open={completeProjectModal.open}
        onClose={() => setCompleteProjectModal({ open: false })}
        onConfirm={(rating: number, review: string) => {
          completeProject(
            {
              rating,
              review,
              professional: project?.professionals[0].professional._id ?? "",
            },
            {
              onSuccess: () => {
                navigate(BROWSE_DEFAULT_URL);
              },
            }
          );
          setCompleteProjectModal({ open: false });
        }}
        title="Project is Complete"
        subtitle="Please be mindful of the impact that leaving a review has on the profile and portfolio of a Professional. Please be respectful."
        confirmLabel="Continue The Project"
      />
    </Box>
  );
};
