import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import { FC } from "react";
import { stringToTitleCase } from "../../../../utils";
import { BudgetType } from "../../../job-requests/create-job-request-wizard/freelance-job-request/BudgetStep";
import { useTotalEstimatedCostCore } from "../../../job-requests/create-job-request-wizard/hooks";
import { HourlyRateMilestone } from "../../../job-requests/create-job-request-wizard/validation-schema";
import { useHourlyMilestonesLabels } from "../../hooks";

type HourlyRateMilestonesTableProps = {
  budget?: BudgetType;
  milestones: HourlyRateMilestone[];
};

export const HourlyRateMilestonesTable: FC<HourlyRateMilestonesTableProps> = ({
  budget,
  milestones,
}) => {
  const estimatedCostPerWeek = useTotalEstimatedCostCore(milestones, budget);
  const { hoursLabel, hourlyRateLabel } = useHourlyMilestonesLabels(budget);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell variant="head" width="20%">
            Milestone Description
          </TableCell>
          <TableCell variant="head" width="25%">
            Due Date
          </TableCell>
          <TableCell variant="head" width="15%">
            Hours/Week
          </TableCell>
          <TableCell variant="head" width="15%">
            Dollars/Hour
          </TableCell>
          <TableCell variant="head" width="10%">
            Estimated Cost
          </TableCell>
          <TableCell variant="head" width="10%">
            Milestone Status
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {milestones.map(
          (
            { description, dueDate, status, actualWork, isSubmitted },
            index
          ) => (
            <TableRow key={index}>
              <TableCell>
                <Typography component="span" variant="body2">
                  {description}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {!actualWork || !isSubmitted
                    ? `Between ${dueDate.min} and ${dueDate.max} weeks`
                    : `${actualWork.dueDate} week(s)`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {!actualWork || !isSubmitted
                    ? hoursLabel
                    : `${actualWork.hoursPerWeek} hrs/wk`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {!actualWork || !isSubmitted
                    ? hourlyRateLabel
                    : `$${actualWork.costPerHour}/hr`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {!actualWork || !isSubmitted
                    ? `$${(dueDate?.min ?? 0) * estimatedCostPerWeek.min} - $${
                        (dueDate?.max ?? 0) * estimatedCostPerWeek.max
                      }`
                    : `$${
                        actualWork.costPerHour *
                        actualWork.dueDate *
                        actualWork.hoursPerWeek
                      }`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  {stringToTitleCase(status ?? "")}
                </Typography>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
