import { Box, Button, makeStyles } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { useRedButtonStyles } from "../../../../../themes/style-hooks";
import { Loader } from "../../../../common/elements/Loader";
import {
  useRemoveFromSavedJobPostMutation,
  useSavedJobPosts,
  useSaveJobPostMutation,
} from "../../../job-posts/hooks";

type JobPostItemActionsProps = {
  jobPostId: string;
  onOpenPreview: () => void;
  label: string;
  onWithdraw?: () => void;
  withdrawLabel?: string;
};

type JobPostItemActionsFC = React.FC<JobPostItemActionsProps>;

const useStyles = makeStyles({
  button: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: "normal",
    height: "44px",
  },
});

export const JobPostItemActions: JobPostItemActionsFC = ({
  jobPostId,
  onOpenPreview,
  label,
  onWithdraw,
  withdrawLabel,
}) => {
  const { mutate: saveJobPost, isLoading: isSavingJobPost } =
    useSaveJobPostMutation();

  const {
    mutate: removeFromSavedJobPost,
    isLoading: isRemovingFromSavedJobPost,
  } = useRemoveFromSavedJobPostMutation();

  const { data: savedJobPosts, isLoading: isLoadingJobPosts } =
    useSavedJobPosts();

  const onSaveJobPostHandler = useCallback(() => {
    saveJobPost(jobPostId);
  }, [jobPostId, saveJobPost]);

  const onRemoveJobPostFromSavedHandler = useCallback(() => {
    removeFromSavedJobPost(jobPostId);
  }, [jobPostId, removeFromSavedJobPost]);

  const saveButton = useMemo(() => {
    const isJobPostSaved = savedJobPosts?.find(
      (savedJobPost) => savedJobPost._id === jobPostId
    );

    return !isJobPostSaved ? (
      <Button
        size="medium"
        onClick={onSaveJobPostHandler}
        endIcon={isSavingJobPost ? <Loader size={12} /> : null}
        disabled={isLoadingJobPosts}
        data-cy-btn="save-job"
      >
        Save
      </Button>
    ) : (
      <Button
        size="medium"
        onClick={onRemoveJobPostFromSavedHandler}
        endIcon={isRemovingFromSavedJobPost ? <Loader size={12} /> : null}
        disabled={isLoadingJobPosts}
        data-cy-btn="remove-job"
      >
        Remove
      </Button>
    );
  }, [
    isLoadingJobPosts,
    isRemovingFromSavedJobPost,
    isSavingJobPost,
    jobPostId,
    onRemoveJobPostFromSavedHandler,
    onSaveJobPostHandler,
    savedJobPosts,
  ]);

  return (
    <Box display="flex" gridColumnGap={8}>
      {onWithdraw ? (
        <Button variant="outlined" size="medium" onClick={onWithdraw}>
          {withdrawLabel}
        </Button>
      ) : (
        saveButton
      )}
      <Button
        variant="contained"
        size="medium"
        onClick={onOpenPreview}
        data-cy-btn="details"
      >
        {label}
      </Button>
    </Box>
  );
};
