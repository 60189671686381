import {
  Box,
  CircularProgress,
  Divider,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import {
  MilestoneInvoice,
  milestoneInvoicesColumns,
} from "../../../../../api-types/projects/milestone-invoices";
import { DownloadableDataGrid, Section } from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { FixedPriceMilestonesSummary } from "../../../../common/components/milestones/FixedPriceMilestonesSummary";
import { HourlyRateMilestonesSummary } from "../../../../common/components/milestones/HourlyRateMilestonesSummary";
import { ProfessionalSummary } from "../../../../common/components/ProfessionalSummary";
import { useMilestonesInvoices } from "../../../../common/hooks/projects/project/milestone-invoices/useMilestonesInvoices";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { formatInvoiceId } from "../../../../utils";
import { generateLocalId } from "../../payments/Payments";
import { FixedPriceMilestonesTable } from "./FixedPriceMilestonesTable";
import { HourlyRateMilestonesTable } from "./HourlyRateMilestonesTable";

export const Payments: React.FC = () => {
  const { data: paymentRequests, isLoading: isLoadingPaymentReports } =
    useMilestonesInvoices();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const rows = useMemo(
    () =>
      paymentRequests?.map((paymentRequest: MilestoneInvoice, index) => ({
        amount: `$${paymentRequest.amount}`,
        professional: paymentRequest.professional.email,
        title: paymentRequest.project.title,
        description: paymentRequest.description,
        createdAt: format(
          new Date(paymentRequest.createdAt),
          formatNominativeMonth
        ),
        id: generateLocalId(paymentRequest.description, index),
        invoice: formatInvoiceId(paymentRequest),
      })) ?? [],
    [paymentRequests]
  );

  const milestonesTable = useMemo(
    () =>
      project?.jobRequest.budget.type === "fixed" ? (
        <FixedPriceMilestonesTable
          milestones={
            project?.jobRequest?.fixedProjectFunding?.milestones ?? []
          }
        />
      ) : (
        <HourlyRateMilestonesTable
          budget={project?.jobRequest.budget}
          milestones={
            project?.jobRequest.hourlyRateProjectFunding.milestones ?? []
          }
        />
      ),
    [
      project?.jobRequest.budget,
      project?.jobRequest?.fixedProjectFunding?.milestones,
      project?.jobRequest.hourlyRateProjectFunding.milestones,
    ]
  );

  return (
    <Section isLoading={isLoadingPaymentReports}>
      <Box gridColumnGap={24} py={4}>
        {project?.professionals.map(({ professional }) => (
          <Box pb={24} key={professional._id}>
            <Box pb={4}>
              <ProfessionalSummary professional={professional} width={35} />
            </Box>

            <Divider />

            <Box pt={9}>
              <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                Milestone Payments and Funding
              </Typography>
            </Box>

            <Box pb={4} pt={8}>
              {project?.jobRequest.budget.type === "fixed" ? (
                <FixedPriceMilestonesSummary />
              ) : (
                <HourlyRateMilestonesSummary
                  jobRequest={project.jobRequest}
                  milestonesCount={
                    project?.jobRequest.hourlyRateProjectFunding.milestones
                      .length ?? 0
                  }
                />
              )}
            </Box>

            <Divider />

            <TableContainer
              style={{
                paddingTop: 24,
                marginLeft: -16,
                width: "calc(100% + 16px)",
              }}
            >
              {isLoadingProject ? (
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                milestonesTable
              )}
            </TableContainer>

            <DownloadableDataGrid
              title="Milestone Invoices"
              columns={milestoneInvoicesColumns}
              data={rows}
            />
          </Box>
        ))}
      </Box>
    </Section>
  );
};
