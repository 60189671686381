import { useCallback, useEffect, useMemo } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { NEW_ID } from "../../inquiries/hooks/useDefaultInquiryPage";
import { useAccountBids } from "./useAccountBids";

export const useDefaultAccountBidPage = () => {
  const { bidId } = useParams();
  const navigate = useNavigate();
  const { data: accountBids, isLoading: isLoadingAccountBids } =
    useAccountBids();

  const firstBidId = useMemo(() => {
    if (!accountBids || isLoadingAccountBids) return "";

    return accountBids?.[0]?._id;
  }, [accountBids, isLoadingAccountBids]);

  const validateBidId = useCallback(() => {
    return (
      accountBids?.map((accountBid) => accountBid._id).includes(bidId) ||
      bidId === NEW_ID
    );
  }, [accountBids, bidId]);

  useEffect(() => {
    if (isLoadingAccountBids) return;

    if (bidId) return;

    if (!firstBidId) return;

    navigate({
      pathname: firstBidId,
      search: `?${createSearchParams({
        tab: "details",
      })}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidId, firstBidId, isLoadingAccountBids, validateBidId]);
};
