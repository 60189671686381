import { useMemo } from "react";
import { useJobRequests } from "../../../common/hooks/job-requests/useJobRequests";

export const useJobRequestsOptions = () => {
  const { data: jobRequests, isLoading: isLoadingJobRequests } =
    useJobRequests(false);

  const data = useMemo(() => {
    return (
      jobRequests?.map((jobRequest) => {
        return {
          ...jobRequest,
          title: jobRequest?.title,
          id: jobRequest?._id,
        };
      }) ?? []
    );
  }, [jobRequests]);

  return { data, isLoading: isLoadingJobRequests };
};
