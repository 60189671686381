import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  HeaderDropdown,
  LoadingTypography,
} from "../../../../common/components";
import { Card } from "../../../../common/elements/Card";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useReceivedProfessionalReferralsProposals } from "../../../../common/hooks/projects/project/collaborations";
import { useProfessionalReferralQuery } from "../../../../common/hooks/projects/project/collaborations/useProfessionalReferralQuery";
import { useNavigateOnCondition } from "../../../../common/hooks/utils";
import { useProfessionalReferralsOptions } from "./hooks/useProfessionalReferralsOptions";

export const ProfessionalReferralHeaderContent = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { professionalReferralId } = useParams();

  const {
    data: professionalReferrals,
    isLoading: isLoadingProfessionalReferrals,
  } = useReceivedProfessionalReferralsProposals();

  const {
    data: professionalReferral,
    isLoading: isLoadingProfessionalReferral,
  } = useProfessionalReferralQuery();

  const collectionConfig = {
    type: Collection.PROFESSIONAL_REFERRALS,
    items: professionalReferrals,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingProfessionalReferrals,
    professionalReferralId
  );

  useNavigateOnCondition(
    "/professional-referrals/",
    !isLoadingProfessionalReferrals &&
      !isLoadingProfessionalReferral &&
      !professionalReferral
  );

  const handleSelectProfessionalReferral = useCallback(
    (index: number) => {
      if (isLoadingProfessionalReferrals || !professionalReferrals) return;

      navigate({
        pathname: `/professional-referrals/${professionalReferrals[index]._id}/`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },

    [isLoadingProfessionalReferrals, navigate, professionalReferrals]
  );

  const professionalReferralOptions = useProfessionalReferralsOptions();

  const isLoading = isLoadingProfessionalReferral;

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box width="65%">
      <LoadingTypography
        isLoading={isLoading}
        typographyProps={{
          variant: "subtitle2",
        }}
      >
        Professional Referral ({1 + index} of {professionalReferrals?.length})
      </LoadingTypography>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={professionalReferralId}
          options={professionalReferralOptions ?? []}
          onSelect={handleSelectProfessionalReferral}
          placeholder={professionalReferral?.project?.jobRequest?.title}
        />
      </Box>
      <Box display="flex" gridColumnGap={20}>
        <Card
          label="Details"
          isSelected={searchParams.get("tab") === "details"}
          onClick={() => onTabNavigationHandler("details")}
        />
        <Card
          label="Bid and Client Inquiries"
          isSelected={searchParams.get("tab") === "replies"}
          onClick={() => onTabNavigationHandler("replies")}
        />
      </Box>
    </Box>
  );
};
