import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { addInquiryMessage } from "../../../../api-services/inquiries/inquiry";
import { AccountType } from "../../../../api-types/common";
import { Message } from "../../../../api-types/job-requests/common";
import { ACCOUNT_INQUIRIES } from "./useAccountInquiries";
import { accountInquiryKey } from "./useAccountInquiry";

export const useAddInquiryMessageMutations = (
  options?: UseMutationOptions<unknown, unknown, unknown>,
  id?: string
) => {
  const queryClient = useQueryClient();
  const { inquiryId } = useParams();

  return useMutation(
    async (request: {
      messages: Message[];
      jobRequestId?: string;
      accountType?: AccountType | null;
    }) => {
      const { data } = await addInquiryMessage(id ?? inquiryId, request);

      return data;
    },
    {
      ...options,
      onSettled(inquiry) {
        queryClient.invalidateQueries(ACCOUNT_INQUIRIES);
        queryClient.invalidateQueries(accountInquiryKey(id ?? inquiryId));
      },
    }
  );
};
