import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigateBack } from "../../hooks/navigation";
import { SignUpClient } from "../client";
import { SignUpProfessional } from "../professional";
import { SignUpAccountType } from "./SignUpAccountType";

export const SignUpView: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigateBack = useNavigateBack();

  if (searchParams.get("type") === "client") {
    return <SignUpClient onBack={navigateBack} />;
  }

  if (searchParams.get("type") === "professional") {
    return <SignUpProfessional onBack={navigateBack} />;
  }

  return <SignUpAccountType />;
};
