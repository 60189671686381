import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback, useState } from "react";
import { BLUE } from "../../../themes/components/utils";
import { LoadingTypography } from "./LoadingTypography";
import { OptionsDropdown } from "./options-dropdown";

const useStyles = makeStyles({
  mediumBold: {
    fontWeight: 450,
  },
});

export type Option = { _id?: string; id: string; title: string };

type HeaderDropdownProps = {
  fontWeight?: number;
  color?: string;
  disabled?: boolean;
  leftLabel?: string;
  selectedOptionId: string;
  options: Option[];
  isLoading?: boolean;
  onSelect: (index: number) => void;
  placeholder?: string;
  variant?: "h1" | "subtitle1" | "subtitle2" | "body1" | "body2";
  className?: string;
  boxContainerClassName?: string;
  dropdownIcon?: string;
  mainContainer?: string;
  mainBox?: string;
  minWidth?: string;
  titleClass?: string;
};

export const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  color,
  disabled,
  leftLabel,
  selectedOptionId,
  options,
  isLoading,
  onSelect,
  placeholder,
  variant = "h1",
  fontWeight = 600,
  boxContainerClassName,
  dropdownIcon,
  mainContainer,
  mainBox,
  minWidth,
  titleClass,
}) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onOptionsDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (disabled) return;
      setAnchorEl(event.currentTarget);
    },
    [disabled]
  );

  const onDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSelectHandler = useCallback(
    (index: number) => {
      onSelect(index);
      onDropdownClose();
    },
    [onDropdownClose, onSelect]
  );

  const hasOptions = options?.length > 1;

  const title =
    options.find((option) => option?.id === selectedOptionId)?.title ??
    placeholder;

  const isSelected = Boolean(leftLabel) && selectedOptionId !== "none";

  return (
    <Box className={mainBox}>
      <LoadingTypography
        lineHeight={1.175}
        isLoading={isLoading}
        typographyProps={{
          variant,
          className: styles.mediumBold,
        }}
        onClick={onOptionsDropdownClick}
      >
        <Box display="flex" alignItems="baseline" className={mainContainer}>
          {isSelected ? <Box pb={0.5}>{leftLabel} </Box> : null}
          <Box
            className={boxContainerClassName}
            component="span"
            flex={1}
            style={{
              textOverflow: "ellipsis",
              overflowX: "hidden",
              cursor: "pointer",
              opacity: disabled ? 0.5 : 1,
            }}
          >
            <Typography
              variant={variant}
              className={titleClass}
              style={{
                fontWeight,
                color,
              }}
              noWrap
            >
              {title}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {hasOptions && (
              <Box>
                {anchorEl ? (
                  <IconButton className={dropdownIcon} disabled={disabled}>
                    <ExpandLessIcon
                      fontSize="medium"
                      color={color === BLUE ? "secondary" : "primary"}
                    />
                  </IconButton>
                ) : (
                  <IconButton className={dropdownIcon} disabled={disabled}>
                    <ExpandMoreIcon
                      fontSize="medium"
                      color={color === BLUE ? "secondary" : "primary"}
                    />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </LoadingTypography>
      {hasOptions && (
        <OptionsDropdown
          anchorEl={anchorEl}
          onClose={onDropdownClose}
          selectedOptionId={selectedOptionId}
          options={options}
          onOptionSelect={onSelectHandler}
          minWidth={minWidth}
        />
      )}
    </Box>
  );
};
