import { useQuery } from "react-query";
import { fetchInquiries } from "../../../../api-services/inquiries";
import { InquiryData } from "../../../../api-types/job-requests/inquiries";

export const INQUIRIES_QUERY_KEY = "inquiries";

export const useInquiries = () => {
  return useQuery<InquiryData[], unknown, InquiryData[]>(
    INQUIRIES_QUERY_KEY,
    async () => {
      const { data: inquiries } = await fetchInquiries();

      return inquiries;
    }
  );
};
