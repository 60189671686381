import axios, { AxiosResponse } from "axios";
import {
  BidData,
  CreateBidRequest,
} from "../../../api-types/job-requests/bids";
import { BIDS_URL } from "../../utils";

export const createBid = (
  bidData: CreateBidRequest
): Promise<AxiosResponse<BidData>> => {
  return axios.post(BIDS_URL, bidData);
};
