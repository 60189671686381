import { Typography } from "@mui/material";
import { Fragment, ReactNode, useCallback } from "react";
import { YELLOW } from "../../../../themes/components/utils";

export const useRequiredFieldLabel = () => {
  return useCallback((label: ReactNode) => {
    return (
      <Fragment>
        {label}
        <Typography
          color={YELLOW}
          component="span"
          fontSize="inherit"
          fontWeight="inherit"
        >
          *
        </Typography>
      </Fragment>
    );
  }, []);
};
