import { TableCellClassKey, TableCellProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { COLOR } from "./utils";

export const MuiTableCell: MuiComponentThemeDefinition<
  TableCellClassKey,
  TableCellProps
> = {
  props: {},
  override: () => ({
    body: {
      border: "none",
      color: COLOR.WHITE,
    },
    head: {
      border: "none",
      color: COLOR.WHITE,
      fontSize: 16,
      fontWeight: 700,
    },
    footer: {
      border: "none",
      color: COLOR.WHITE,
    },
  }),
};
