import { isEqual } from "lodash";
import {
  HourlyRateMilestone,
  Milestone,
} from "../../../client/job-requests/create-job-request-wizard/validation-schema";

export const getPendingChanges = (
  milestones: (Milestone | HourlyRateMilestone)[],
  initialMilestones: (Milestone | HourlyRateMilestone)[],
  pendingChanges?: (Milestone | HourlyRateMilestone)[]
): (Milestone | HourlyRateMilestone)[] => {
  return milestones.map((milestone) => {
    const initialMilestone = initialMilestones.find(
      (m) => m._id === milestone._id
    );
    const pendingChangedMilestone = pendingChanges?.find(
      (m) => m._id === milestone._id
    );

    if (
      isEqual(initialMilestone, milestone) &&
      !isEqual(milestone, pendingChangedMilestone) &&
      pendingChangedMilestone
    ) {
      return pendingChangedMilestone;
    }

    return milestone;
  });
};
