import axios, { AxiosResponse } from "axios";
import {
  CreateInquiry,
  InquiryData,
} from "../../../api-types/job-requests/inquiries";
import { INQUIRIES_URL } from "../../utils";

export const addInquiry = async (
  inquiryData: CreateInquiry
): Promise<AxiosResponse<InquiryData>> => {
  return axios.post(INQUIRIES_URL, inquiryData);
};
