import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useJobRequestDeleteMutation } from "../../../../common/hooks/job-requests/job-request/useJobRequestDeleteMutation";

export const useOnConfirmWithdrawalHandler = (onSuccess?: () => void) => {
  const { jobRequestId } = useParams();
  const { mutate: deleteJobRequest } = useJobRequestDeleteMutation();

  return useCallback(() => {
    if (!jobRequestId) return;

    deleteJobRequest(jobRequestId, {
      onSuccess,
    });
  }, [deleteJobRequest, jobRequestId, onSuccess]);
};
