import { DialogTitleClassKey, DialogTitleProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiDialogTitle: MuiComponentThemeDefinition<
  DialogTitleClassKey,
  DialogTitleProps
> = {
  props: {},
  override: () => ({
    root: {
      paddingTop: 50,
    },
  }),
};
