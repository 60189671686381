import { AxiosResponse } from "axios";
import { JobRequest } from "../../../api-types/job-requests/job-request";
import axios from "../../index";
import { jobRequestsFundMilestonesUrl } from "../../utils";

export const fundJobRequestMilestones = async (
  jobRequestId: string,
  body: {
    chargeId: string;
    milestones: string[];
    isHourly?: boolean;
    professionalId?: string;
  }
): Promise<AxiosResponse<JobRequest>> => {
  return await axios.post(jobRequestsFundMilestonesUrl(jobRequestId), body);
};
