import { useQuery } from "react-query";
import { fetchSavedJobPosts } from "../../../../api-services/saved-job-posts";
import { JobPost } from "../../../../api-types/job-posts";

export const SAVE_JOB_POSTS_QUERY_KEY = "saved-job-posts";

export const useSavedJobPosts = () => {
  return useQuery<JobPost[], unknown, JobPost[]>(
    SAVE_JOB_POSTS_QUERY_KEY,
    async () => {
      const { data } = await fetchSavedJobPosts();

      return data;
    }
  );
};
