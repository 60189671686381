import { Box, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BLUE } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";

export type MobileNotSupportedDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const useDialogStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  dialogTitle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
    ["@media screen and (max-width: 450px), screen and (max-height: 450px)"]: {
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      paddingBottom: 16,
    },
  },
  fullWidth: {
    "& .MuiDialogActions-root": {
      paddingBottom: 64,
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          paddingBottom: 32,
        },
      ["@media screen and (max-height: 450px)"]: {
        paddingBottom: 12,
      },
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
    "& .MuiDialog-paper": {
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          height: "100%",
          maxHeight: "100%",
        },
      minHeight: 450,
      maxHeight: 450,
      overflowY: "hidden",
      borderRadius: 0,
      width: "100vw",
      maxWidth: 1440,
      padding: 0,
      margin: 0,
      boxShadow: "none",
      zIndex: theme.zIndex.modal,
    },
  },
  button: {
    height: 64,
    width: 100,
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
}));

export const MobileNotSupportedDialog = ({
  open,
  onClose,
}: MobileNotSupportedDialogProps) => {
  const styles = useDialogStyles();

  const handleCloseDialog = useHandleCloseDialog(onClose);

  return (
    <Dialog
      className={styles.fullWidth}
      onClose={handleCloseDialog}
      open={open}
    >
      <DialogTitle className={styles.dialogTitle}>
        <Typography className={styles.title} color="secondary">
          This viewport is not currently supported.
        </Typography>
        <Typography className={styles.subtitle} color="secondary">
          Please use a desktop to access the platform. We will be updating this
          very soon!
        </Typography>
      </DialogTitle>

      <DialogActions style={{ marginTop: "auto" }}>
        <Box display="grid" gridAutoFlow="column" gridColumnGap={20}>
          <Button
            className={styles.button}
            variant="outlined"
            style={{
              color: BLUE,
              borderColor: BLUE,
            }}
            disableFocusRipple
            onClick={onClose}
            autoFocus
          >
            Ok
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
