import styled from "@emotion/styled";
import { Box, List, Typography } from "@material-ui/core";
import { FileWithPath } from "file-selector";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { S3DataOrLoading } from "../../../../api-types/s3";
import { BLUE } from "../../../../themes/components/utils";
import { DropItemList } from "../../../common/components/drop-list/DropItemList";
import { ScrollbarStyles } from "../../../common/components/ScrollableSection";
import { ImagePlaceholder } from "./ImagePlaceholder";
import { ImagePreview } from "./ImagePreview";
import { DropArea } from "./index";

type ImageComponentProps = {
  images?: any[];
  height?: number;
  handleChangeFileUpload?: (files: FileWithPath[]) => void;
  handleFocus?: (field: string, value: string) => void;
  color?: string;
  maxFiles?: number;
};

const StyledList = styled(List)`
  ${ScrollbarStyles};
  max-height: 120px;
  overflow: auto;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 12px;
`;

export const ImageComponent: React.FC<ImageComponentProps> = ({
  handleChangeFileUpload,
  handleFocus,
  height,
  images,
  color = "primary",
  maxFiles,
}) => {
  const dropRef = useRef<{
    onFileRemoved: (file: FileWithPath) => void;
  }>();

  const [encodedFiles, setEncodedFiles] = useState<any[]>(images ?? []);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleImageDescriptionChange = (e: SyntheticEvent, index: number) => {
    const data = (e.target as any)?.value;
    const files = [...encodedFiles];
    files[index].description = data;
    setEncodedFiles(files);
  };

  useEffect(() => {
    handleChangeFileUpload?.(encodedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodedFiles]);

  const availableFiles = encodedFiles.filter((file) => !file.loading);

  return (
    <Box
      height="inherit"
      display="flex"
      gridColumnGap={64}
      pt={4}
      justifyContent="space-between"
    >
      <Box width="30%">
        <DropArea
          ref={dropRef}
          encodedFiles={encodedFiles}
          setEncodedFiles={setEncodedFiles}
          setCurrentIndex={setCurrentIndex}
          maxFiles={maxFiles}
        >
          <Box
            mt={2}
            mb={4}
            width={300}
            height={300}
            bgcolor="#DDDDDD"
            borderRadius="50%"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Box
              width={230}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                variant="h1"
                style={{
                  fontSize: 85,
                  color: "#001D60",
                }}
              >
                +
              </Typography>
              <Typography
                variant="body2"
                style={{
                  paddingLeft: 24,
                  color: BLUE,
                }}
              >
                Drag and drop new pictures here, or click on this area to upload
                them.
                <br />
                <br />
                Upload up to 5 pictures per project.
              </Typography>
            </Box>
          </Box>
        </DropArea>
        <Typography variant="body1">
          Pictures must be .jpeg, .png, or .gif files up to 5mb each.{" "}
        </Typography>
        <StyledList>
          {encodedFiles.map((file: S3DataOrLoading, index) => (
            <Box
              key={`${file.key ?? file.path}-${index}`}
              data-cy="images-list"
            >
              <DropItemList
                index={index}
                file={file}
                onRemoveFile={(index) => {
                  const newFiles = [...encodedFiles];
                  newFiles.splice(index, 1);
                  setEncodedFiles(newFiles);
                  if (currentIndex >= index) {
                    setCurrentIndex(currentIndex - 1);
                  }
                }}
              />
            </Box>
          ))}
        </StyledList>
      </Box>
      <Box width="60%" py={4}>
        <Box height={height} pt={2}>
          {availableFiles.length ? (
            <>
              <ImagePreview
                color={color}
                isLocalImages={false}
                selectedFiles={availableFiles}
                index={currentIndex}
                handleImageDescriptionChange={handleImageDescriptionChange}
                handleFocus={handleFocus}
              />
            </>
          ) : (
            <ImagePlaceholder />
          )}
        </Box>
      </Box>
    </Box>
  );
};
