import { Box, Divider, Link, makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { MakeIt } from "../../../icons/MakeIt";
import { BLUE } from "../../../themes/components/utils";
import { PageWrapper } from "../../app/container/PageWrapper";
import { formatNominativeMonth } from "../components/LoadingTypography";
import { useIsMobileView } from "../landing-page/hooks";
import { FreelancingInTech } from "./FreelancingInTech";
import { Graphs } from "./Graphs";
import { Reconversion } from "./Reconversion";

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: BLUE,
    opacity: 1,
  },
}));

export const OurBlog = () => {
  const style = useStyle();
  const isMobile = useIsMobileView();

  return (
    <PageWrapper customColor="#FFFADE">
      <Box mt={isMobile ? 25 : 40} pb={isMobile ? 7.5 : 15} height="100%">
        <Typography
          variant={isMobile ? "subtitle1" : "h1"}
          color="secondary"
          style={{
            fontWeight: 600,
          }}
        >
          TheTalented.co Blog
        </Typography>

        <Graphs />

        <Reconversion />

        {/*<FreelancingInTech />*/}

        <Box pt={isMobile ? 7.5 : 15}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              variant={isMobile ? "subtitle1" : "h2"}
              style={{
                width: "70%",
                fontWeight: 600,
              }}
              color="secondary"
            >
              How AI Is Helping Freelancers Work Better
            </Typography>
            <Typography color="secondary" variant="body2">
              {format(new Date("2023-04-10"), formatNominativeMonth)}
            </Typography>
          </Box>
          <Box py={2.5}>
            <Divider className={style.root} />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={24}>
            <Typography variant="body1" color="secondary">
              In 2023, there are new business trends for you to follow to
              achieve greater freelance success; the rapid evolution and
              increasing adoption of artificial intelligence (AI)-driven tools
              is a must-follow trend for freelancers who want to become more
              efficient and productive.
            </Typography>
            <Typography variant="body1" color="secondary">
              Tech solutions come and go, but all signs are pointing to the
              likelihood that AI is here to stay and make a{" "}
              <Link
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
                underline="hover"
                href={
                  "https://www.linkedin.com/pulse/41-big-ideas-change-our-world-2023-linkedin-news/?src=aff-ref&trk=aff-ir_progid.8005_partid.196318_sid._adid.449670&clickid=X1sx68RL9xyNR5RwPA1SXw1ZUkA1hA29jxfaTQ0&mcid=6851962469594763264&irgwc=1"
                }
              >
                big impact on business.
              </Link>{" "}
              Probably the (currently) most popular example of AI and natural
              language processing (NLP)-based technology that's helping business
              professionals and freelancers automate some of their tasks is
              ChatGPT.
            </Typography>
            <Typography variant="body1" color="secondary">
              To that end, let's cover some of the greatest advantages that come
              with leveraging tools that use AI like ChatGPT, Galileo AI, and
              Alexa Translations, and how freelancers can use them to work
              better, smarter, and more efficiently.
            </Typography>
          </Box>
        </Box>

        <Box pt={7.5}>
          <Typography
            variant={isMobile ? "subtitle2" : "subtitle1"}
            color="secondary"
            style={{
              fontWeight: 600,
            }}
          >
            AI + Freelancing = Time Saved
          </Typography>
          <Box py={2.5}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={24}>
            <Typography variant="body1" color="secondary">
              It's hard to deny that AI and tools such as ChatGPT can save
              freelancers lots of time by automating repetitive tasks. Whether
              you're tackling something mundane like data entry or are focusing
              a little harder on creating content, tools with AI algorithms can
              free up your time so that you can focus on other, more important
              tasks that can't be as simply automated.
            </Typography>
            <Typography variant="body1" color="secondary">
              Freelance designers and developers who design and deploy
              applications and websites are, arguably, going to benefit the most
              in the near term from AI; UX designers can, for example, leverage
              AI tools to analyze user behavior and arrive at designs for their
              apps or sites based on their tool's design suggestions. UI
              designers can quickly arrive at designs with the help of{" "}
              <Link
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
                underline="hover"
                href={
                  "https://www.linkedin.com/pulse/41-big-ideas-change-our-world-2023-linkedin-news/?src=aff-ref&trk=aff-ir_progid.8005_partid.196318_sid._adid.449670&clickid=X1sx68RL9xyNR5RwPA1SXw1ZUkA1hA29jxfaTQ0&mcid=6851962469594763264&irgwc=1"
                }
              >
                AI-driven UI design tools like Galileo AI{" "}
              </Link>
              that rely on NLP to generate suggestions for their users.
            </Typography>
            <Typography variant="body1" color="secondary">
              ChatGPT, while it's still in its relatively early stages, is also
              changing up the game for content writers and editors. Thanks to AI
              language models, content creators can save themselves untold hours
              of time by using ChatGPT to quickly generate pieces of quality
              content.
            </Typography>
          </Box>
        </Box>

        <Box pt={7.5}>
          <Typography
            variant={isMobile ? "subtitle2" : "subtitle1"}
            color="secondary"
            style={{
              fontWeight: 600,
            }}
          >
            Build a Better Reputation With Clients
          </Typography>
          <Box py={2.5}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={24}>
            <Typography variant="body1" color="secondary">
              Another benefit that's particularly useful for new freelancers is
              the ability to build a better reputation with clients. Freelancers
              using tools like ChatGPT can cut down on inconsistencies in their
              work and thus make better first impressions on new and repeat
              clients. Thanks to greater accuracy and consistency, content
              writers and editors, in particular, will be thrilled to know that
              <Link
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
                underline="hover"
                href={"https://its.unc.edu/2023/02/15/one-question-chatgpt/"}
              >
                AI can standardize styles and formats
              </Link>{" "}
              in their content; an end product that's consistently formatted and
              stylized is easier for clients to follow and understand.
            </Typography>
            <Typography variant="body1" color="secondary">
              ChatGPT, in particular, seems like a promising AI-driven tool for
              freelancing content creators: it provides suggestions and
              corrections to freelancers' writing, making it easier for writers
              and content creators to create high-quality work that appears
              perfectly polished by the time it's in the hands of their clients.
            </Typography>
          </Box>
        </Box>

        <Box pt={7.5}>
          <Typography
            variant={isMobile ? "subtitle2" : "subtitle1"}
            color="secondary"
            style={{
              fontWeight: 600,
            }}
          >
            AI Opens Freelancers Up To New Markets{" "}
          </Typography>
          <Box py={2.5}>
            <Divider />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={24}>
            <Typography variant="body1" color="secondary">
              Last but not least, AI lets freelancers open themselves up to new
              markets by allowing them to scale and expand their services. Tools
              like ChatGPT as well as other well-known services{" "}
              <Link
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
                underline="hover"
                href="https://www.unite.ai/best-ai-translation-software-tools/"
              >
                like Alexa Translations and Bing Microsoft Translator
              </Link>{" "}
              offer handy services such as language translation that can help
              freelancers overcome language barriers and connect with clients
              who may not natively speak English. Language translation tools can
              even help freelancers generate multilingual content, which can
              help them reach a broader pool of potential customers and,
              ultimately, widen their base of new and repeat clients.
            </Typography>
            <Typography color="secondary" variant="body1">
              In conclusion, freelancers can benefit from leveraging AI and
              tools such as ChatGPT in multiple creative ways. AI stands to save
              freelancers a whole lot of time and effort, and it’s incredibly
              useful for freelancers of all experience levels who want to
              deliver work to new and repeat clients that is accurate,
              consistent, and of high quality. Additionally, AI and tools such
              as ChatGPT can help freelancers expand and scale their services to
              connect with new and broader audiences. This ability to expand and
              scale services means freelancers can reliably grow their
              businesses - and their incomes - thanks to AI.
            </Typography>
            <Typography color="secondary" variant="body1">
              If you're looking to boost your productivity and efficiency as a
              freelancer, consider incorporating AI into your workflow and
              exploring new freelancing platforms like{" "}
              <Link
                color="secondary"
                style={{
                  fontWeight: 600,
                }}
                underline="hover"
                href="https://www.thetalented.co/"
              >
                The Talented
              </Link>{" "}
              that connect creative designers and developers to businesses and
              innovators.
            </Typography>
          </Box>
        </Box>

        <Box pt={7.5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography
              variant={isMobile ? "subtitle1" : "h2"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Make It Count
            </Typography>
            <Typography color="secondary" variant="body2">
              {format(new Date("2023-04-10"), formatNominativeMonth)}
            </Typography>
          </Box>
          <Box py={2.5}>
            <Divider className={style.root} />
          </Box>
          <Box pt={2.5} display="flex" justifyContent="center">
            <MakeIt />
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};
