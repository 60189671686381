import { ButtonBaseClassKey, ButtonBaseProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiButtonBase: MuiComponentThemeDefinition<
  ButtonBaseClassKey,
  ButtonBaseProps
> = {
  props: {
    color: "primary",
  },
  override: () => ({
    root: {
      fontWeight: "normal",
      width: "fit-content",
    },
    disabled: {
      opacity: 0.5,
    },
  }),
};
