import { Box, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormikValues } from "formik";
import React, { ChangeEvent, SetStateAction, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HeaderDropdown } from "../../../../common/components";
import {
  useJobPostQuery,
  useJobPosts,
} from "../../../../common/hooks/job-posts";
import { JobPostSetUpForm } from "./JobPostSetUpForm";
import { JobPreview } from "./JobPreview";

type JobPostSetUpProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  handleUpdateSkills: (newSkills: string[]) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  isValid?: boolean;
  onNext?: () => void;
  setValues: (fields: SetStateAction<any>) => void;
};

const useStyles = makeStyles({
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 36,
    lineHeight: "52px",
  },
  subtitle: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "35px",
  },
  subtitle2: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 26,
    lineHeight: "38px",
  },
  boxContainerReset: {
    flex: "0 1 auto",
  },
  dropdownIcon: {
    left: "50.56%",
    right: "76.1%",
    top: "80.65%",
    bottom: "12.4%",
    transform: "scale(2.2)",
  },
});

export const JobPostSetUp: React.FC<JobPostSetUpProps> = ({
  handleBlur,
  handleChange,
  handleUpdateSkills,
  setFieldValue,
  errors,
  values,
  isValid,
  onNext,
  setValues,
}) => {
  const styles = useStyles();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: jobPosts } = useJobPosts();
  const extendedJobPosts = useMemo(
    () => [
      {
        id: "",
        title: "None",
      },
      ...(jobPosts?.map(({ _id, jobTitle }) => ({
        id: _id,
        title: jobTitle,
      })) ?? []),
    ],
    [jobPosts]
  );

  const handleDropdownSelect = (index: number) => {
    setSelectedOptionId(extendedJobPosts[index].id);
  };

  useJobPostQuery(selectedOptionId, {
    onSuccess: (data) => {
      setValues(data);
    },
  });

  if (previewOpen) {
    return (
      <JobPreview
        values={values}
        onClose={() => {
          setPreviewOpen(false);
          searchParams.delete("isPreview");
          setSearchParams(searchParams);
        }}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box>
        <Typography className={styles.title}>Set Up Your Job Post</Typography>
        <Typography className={styles.subtitle}>
          Please enter job post details below
        </Typography>
      </Box>
      {(jobPosts?.length ?? 0) > 0 && (
        <Box pt={10} pb={10}>
          <Typography className={styles.subtitle}>
            Use a previous post as a starting point
          </Typography>
          <HeaderDropdown
            className={styles.subtitle2}
            selectedOptionId={selectedOptionId}
            options={extendedJobPosts}
            onSelect={handleDropdownSelect}
            placeholder="Select an option"
            variant="subtitle1"
            boxContainerClassName={styles.boxContainerReset}
            dropdownIcon={styles.dropdownIcon}
            minWidth="265px"
          />
        </Box>
      )}

      <Divider />

      <JobPostSetUpForm
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleUpdateSkills={handleUpdateSkills}
        onPreview={() => {
          setPreviewOpen(true);
          searchParams.set("isPreview", "true");
          setSearchParams(searchParams);
        }}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        onNext={onNext}
        isValid={isValid}
      />
    </Box>
  );
};
