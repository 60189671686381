import { filter, find } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useBids } from "../bids/useBids";
import { useInquiries } from "../inquiries/useInquiries";
import { useSentInvitations } from "./job-request/invites";
import { useJobRequests } from "./useJobRequests";

export const useInvitableJobRequests = (proId?: string) => {
  const { professionalId } = useParams();

  const { data: jobRequests, isLoading: isLoadingJobRequests } =
    useJobRequests();
  const { data: inquiries, isLoading: isLoadingInquiries } = useInquiries();
  const { data: bids, isLoading: isLoadingBids } = useBids();
  const { data: sentInvitations, isLoading: isLoadingSentInvitations } =
    useSentInvitations();

  const id = proId ?? professionalId;

  const invitableJobRequests = useMemo(
    () =>
      filter(jobRequests, ({ _id }) => {
        const inquiredJob = find(inquiries, ({ jobRequest, createdBy }) => {
          return createdBy?._id === id && jobRequest?._id === _id;
        });

        const bidedJob = find(
          bids,
          ({ jobRequest, createdBy }) =>
            createdBy?._id === id && jobRequest._id === _id
        );

        const invitedJob = find(
          sentInvitations,
          ({ jobRequestId, professional }) => {
            return jobRequestId === _id && professional._id === id;
          }
        );

        return !inquiredJob && !bidedJob && !invitedJob;
      }),
    [bids, inquiries, jobRequests, id, sentInvitations]
  );

  return {
    jobRequests: invitableJobRequests,
    isLoading: isLoadingJobRequests || isLoadingInquiries || isLoadingBids,
  };
};
