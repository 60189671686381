import { useCallback, useEffect, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

export enum Collection {
  PROJECTS = "projects",
  BIDS = "bids",
  INQUIRIES = "inquiries",
  INVITATIONS = "invitations",
  PROFESSIONAL_REFERRALS = "professional-referrals",
  JOB_REQUESTS = "job-requests",
  PAST_PROJECTS = "projects/history",
  UNPUBLISHED_PROJECTS = "projects/unpublished",
  PORTFOLIO_PROJECT = "projects/portfolio-project",
}

type CollectionConfig = {
  type: string;
  items?: any[];
};

export const useNavigationHandlers = (
  collection: CollectionConfig,
  isLoading: boolean,
  id: string
) => {
  const navigate = useNavigate();

  const items = useMemo(() => collection?.items ?? [], [collection?.items]);

  const index = useMemo(() => {
    if (isLoading || items.length === 0) {
      return 0;
    }

    return items.findIndex((item) => item._id === id);
  }, [id, isLoading, items]);

  useEffect(() => {
    if (isLoading || !id) return;

    if (!items.find((item) => item._id === id)) {
      navigate(`/${collection.type}/`, { replace: true });
    }
  }, [navigate, isLoading, items.length, collection.type, items, id]);

  const handleSelectPrevious = useCallback(() => {
    if (isLoading || items.length === 0 || index < 1) return;

    if (items[index - 1]?._id) {
      navigate({
        pathname: `/${collection.type}/${items[index - 1]._id}`,
        search: `?${createSearchParams({ tab: "details" })}`,
      });
    }
  }, [collection.type, index, isLoading, items, navigate]);

  const handleSelectNext = useCallback(() => {
    if (isLoading || items.length === 0 || index + 1 > items.length) return;

    if (items[index + 1]?._id) {
      navigate({
        pathname: `/${collection.type}/${items[index + 1]._id}`,
        search: `?${createSearchParams({ tab: "details" })}`,
      });
    }
  }, [collection.type, index, isLoading, items, navigate]);

  return {
    index,
    handleSelectNext,
    handleSelectPrevious,
  };
};
