import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const AccountInformationSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      gridColumnGap={24}
      gridRowGap={36}
      justifyContent="flex-end"
      flexDirection="column"
    >
      <Box display="flex" justifyContent="space-between">
        <Skeleton variant="rect" animation="wave" width="60%" height={40} />
        <Box display="flex" gridColumnGap={12}>
          <Skeleton variant="rect" animation="wave" width={140} height={40} />
          <Skeleton variant="rect" animation="wave" width={140} height={40} />
        </Box>
      </Box>
      <Box
        pt={10}
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        gridColumnGap={24}
      >
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        gridColumnGap={24}
      >
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        gridColumnGap={24}
      >
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        gridColumnGap={24}
      >
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
      </Box>
      <Box
        display="flex"
        alignSelf="flex-end"
        justifySelf="flex-end"
        gridColumnGap={24}
      >
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
        <Skeleton variant="rect" animation="wave" width={400} height={68} />
      </Box>
    </Box>
  );
};
