import { PaginationClassKey, PaginationProps } from "@material-ui/lab";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE } from "./utils";

export const MuiPagination: MuiComponentThemeDefinition<
  PaginationClassKey,
  PaginationProps
> = {
  props: {},
  override: () => ({
    root: {
      color: BLUE,
    },
  }),
};
