import { SvgIconClassKey, SvgIconProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE } from "./utils";

export const MuiSvgIcon: MuiComponentThemeDefinition<
  SvgIconClassKey,
  SvgIconProps
> = {
  props: {
    color: "primary",
  },
  override: () => ({
    root: {
      width: 24,
      height: 24,
    },
    fontSizeLarge: {
      fontSize: 48,
    },
    fontSizeSmall: {
      fontSize: 24,
    },
    colorSecondary: {
      color: BLUE,
    },
  }),
};
