import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchSentInvitations } from "../../../../../../api-services/job-requests/invitations";
import { Invitation } from "../../../../../../api-types/job-requests/invitations";
import { JOB_REQUESTS_QUERY_KEY } from "../../useJobRequests";

export const INVITATIONS_QUERY_KEY = `${JOB_REQUESTS_QUERY_KEY}-invitations`;

const invitationsQueryKey = (jobRequestId?: string) => {
  return `${INVITATIONS_QUERY_KEY}-${jobRequestId}`;
};

export const useSentInvitations = () => {
  const { jobRequestId } = useParams();

  return useQuery<Invitation[], unknown, Invitation[]>(
    invitationsQueryKey(jobRequestId),
    async () => {
      const { data } = await fetchSentInvitations();

      return data;
    }
  );
};
