import { useEffect, useMemo } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useJobRequests } from "../../../../common/hooks/job-requests/useJobRequests";

export const useDefaultJobRequestPage = () => {
  const { jobRequestId } = useParams();
  const navigate = useNavigate();
  const { data: jobRequests, isLoading: isLoadingJobRequests } =
    useJobRequests();

  const firstJobRequestId = useMemo(() => {
    if (!jobRequests || isLoadingJobRequests) return "";

    return jobRequests[0]?._id;
  }, [jobRequests, isLoadingJobRequests]);

  useEffect(() => {
    if (isLoadingJobRequests) return;

    if (jobRequestId) return;

    if (!firstJobRequestId) return;

    navigate(
      {
        pathname: firstJobRequestId,
        search: `?${createSearchParams({ tab: "details" })}`,
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobRequestId, firstJobRequestId, isLoadingJobRequests]);
};
