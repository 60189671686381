import { Box, Divider } from "@material-ui/core";
import React from "react";
import { InvitationHeaderContent } from "./InvitationHeaderContent";
import { InvitationHeaderSummary } from "./InvitationHeaderSummary";

export const InvitationHeader: React.FC = () => {
  return (
    <Box display="flex" gridColumnGap={40} py={10}>
      <InvitationHeaderContent />
      <Divider orientation="vertical" flexItem />
      <InvitationHeaderSummary />
    </Box>
  );
};
