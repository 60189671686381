import {
  Box,
  Button,
  Typography,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { File } from "../../../../api-types/job-requests/common";
import { FileIcon } from "../../../../icons/FileIcon";
import { BLUE, LIGHT_BLUE, YELLOW } from "../../../../themes/components/utils";
import { DropAreaProps } from "../../../client/job-requests/utils/DropArea";
import { CheckboxCard } from "../../elements/CheckboxCard";
import { formatMessageDate } from "../LoadingTypography";
import { ScrollableSection } from "../ScrollableSection";

type FilesProps = {
  isLoading?: boolean;
  files: File[];
  onNewFile: (files: any[]) => void;
  DropAreaProps?: DropAreaProps;
};

const useStyles = makeStyles({
  fileDate: {
    color: "#fff",
    fontSize: 14,
  },
  fileIcon: {
    "& svg": {
      transform: "scale(0.8)",
    },
  },
});

export const Files: React.FC<FilesProps> = ({
  files,
  onNewFile,
  isLoading,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const styles = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;

          onNewFile([{ content: binaryStr, name: file.name, path: file.path }]);
        };
        reader.readAsDataURL(file);
      });
    },
    [onNewFile]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    multiple: true,
    noClick: true,
    onDrop,
  });

  useEffect(() => {
    const uploadedFiles: File[] = acceptedFiles
      .map((file: FileWithPath) => {
        return {
          path: file.path ?? "",
          checked: false,
          name: file.name,
          type: file.type,
        };
      })
      .filter((file) => files.findIndex((fl) => fl.path === file?.path) < 0);

    setSelectedFiles(
      [...files, ...uploadedFiles].map((file) => ({ ...file, checked: false }))
    );
  }, [acceptedFiles, files]);

  const onCheckChanged = useCallback(
    (index: number) => {
      const filesCopy = [...selectedFiles];

      filesCopy[index] = {
        ...selectedFiles[index],
        checked: !selectedFiles[index]?.checked,
      };

      setSelectedFiles(filesCopy);
    },
    [selectedFiles]
  );

  const onSelectAll = useCallback(() => {
    const updatedFiles = selectedFiles.map((file) => {
      return {
        ...file,
        checked: true,
      };
    });

    setSelectedFiles(updatedFiles);
  }, [selectedFiles]);

  const onUnselectAll = useCallback(() => {
    const updatedFiles = selectedFiles.map((file) => {
      return {
        ...file,
        checked: false,
      };
    });

    setSelectedFiles(updatedFiles);
  }, [selectedFiles]);

  const onDownloadAllHandler = useCallback(() => {
    selectedFiles.map((file) => {
      if (file.checked && file?.content) {
        saveAs(file.content, file.name);
      }
    });
  }, [selectedFiles]);

  const isEmpty = selectedFiles?.length === 0;

  const isDownloadDisabled = !selectedFiles.some((file) => file?.checked);

  const allSelected = selectedFiles.every((file) => file?.checked);

  return (
    <React.Fragment>
      <Box
        height={80}
        pl={4}
        pr={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2">Files</Typography>
        <Box display="flex" gridColumnGap={10}>
          {!allSelected ? (
            <Button
              size="medium"
              onClick={onSelectAll}
              variant="outlined"
              disabled={isEmpty}
            >
              Select All
            </Button>
          ) : (
            <Button
              size="medium"
              onClick={onUnselectAll}
              variant="outlined"
              disabled={isEmpty}
            >
              Unselect All
            </Button>
          )}
          <Button
            size="medium"
            onClick={onDownloadAllHandler}
            variant="contained"
            disabled={isDownloadDisabled}
          >
            Download
          </Button>
        </Box>
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Box height={800} pr={9} {...getRootProps({ className: "dropzone" })}>
        <ScrollableSection scrollable loading={isLoading}>
          {isEmpty ? (
            <Box
              height="inherit"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {/*<CloudUploadOutlinedIcon*/}
              {/*  style={{*/}
              {/*    fontSize: "100",*/}
              {/*    opacity: 0.6,*/}
              {/*  }}*/}
              {/*/>*/}
              <Typography variant="body1">
                Drag & Drop your files here to share with others
              </Typography>
            </Box>
          ) : (
            <Box py={4}>
              <input {...getInputProps()} />
              {selectedFiles?.map((file: any, index: number) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  key={file.name}
                  mt={4}
                >
                  {Boolean(file?.createdAt?.length) && (
                    <Typography className={styles.fileDate}>
                      {format(new Date(file?.createdAt), formatMessageDate)}
                    </Typography>
                  )}
                  <Box
                    display="flex"
                    alignItems="center"
                    height={100}
                    width="100%"
                    border="2px solid"
                    borderColor={file.checked ? YELLOW : LIGHT_BLUE}
                    my={1}
                    color="red"
                    bgcolor={file.checked ? YELLOW : ""}
                  >
                    <CheckboxCard
                      width={48}
                      onChange={(_) => onCheckChanged(index)}
                      checked={file.checked}
                      label={`${file?.name}`}
                      checkedColor={BLUE}
                      leftIcon={
                        <Box className={styles.fileIcon}>
                          <FileIcon
                            fillColor={file.checked ? "#001D60" : "#d0e4f4"}
                          />
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </ScrollableSection>
      </Box>
    </React.Fragment>
  );
};
