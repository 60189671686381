import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Field, FormikErrors, FormikValues } from "formik";
import { chain } from "lodash";
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { uploadFiles } from "../../../../../api-services/upload-files/uploadFiles";
import { IMAGE_MAX_SIZE_MB } from "../../../../../api-services/utils";
import { AccountInformation } from "../../../../../api-types/account-information";
import { S3Data } from "../../../../../api-types/s3";
import {
  BLUE,
  YELLOW,
  LIGHT_BLUE,
} from "../../../../../themes/components/utils";
import {
  useBoldStyles,
  useRedButtonStyles,
} from "../../../../../themes/style-hooks";
import { TextBox } from "../../../../client/job-requests/utils/TextBox";
import { CustomInput, CustomTextArea } from "../../../../common/elements";
import { BackButton } from "../../../../common/elements/BackButton";
import { useRequiredFieldLabel } from "../../../../common/hooks/utils";
import { isImageSizeValid } from "../../../../utils";
import { useToast } from "../../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../job-requests/hooks/feedbackErrors";

type ProfileInformationProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  user?: AccountInformation;
  onBack?: () => void;
  submitForm?: () => void;
};

const useStyles = makeStyles({
  radio: {
    "& .MuiSvgIcon-root": {
      width: 48,
      height: 48,
      fill: YELLOW,
    },
  },
  label: {
    "& .MuiTypography-body1": {
      fontSize: "21px !important",
    },
  },
  nameTextArea: {
    width: 400,
    height: "80px !important",
    fontSize: 24,
    paddingTop: 24,
    paddingLeft: 12,
    resize: "none",
  },
  descriptionTextArea: {
    height: "inherit !important",
    resize: "none",
  },
});

export const ProfileInformation: React.FC<ProfileInformationProps> = ({
  handleChange,
  setFieldValue,
  handleBlur,
  values,
  errors,
  submitForm,
  onBack,
}) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const boldStyles = useBoldStyles();
  const [previewImage, setPreviewImage] = useState(values?.profileImage);
  const [loading, setLoading] = useState(false);
  const requiredFieldLabel = useRequiredFieldLabel();

  const { notifyError } = useToast();

  const [encodedFiles, setEncodedFiles] = useState<any[]>([]);

  useEffect(() => {
    setPreviewImage(values?.profileImage);
  }, [values?.profileImage]);

  const onUploadImageHandler = useCallback(
    async (event: BaseSyntheticEvent) => {
      if (event.target.value) {
        if (!isImageSizeValid(event.target.files[0]?.size)) {
          notifyError(
            `File is too big. Please upload file smaller than ${IMAGE_MAX_SIZE_MB}`
          );
          return;
        }
        setLoading(true);
        let image;
        try {
          image = await uploadFiles([event.target.files[0]], "profile");
        } catch (err) {
          notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
          return;
        } finally {
          submitForm?.();
        }
        setFieldValue("profileImage", image[0].Location);
        setLoading(false);
        setPreviewImage(image[0].Location);
      }
    },
    [notifyError, setFieldValue, submitForm]
  );

  const redButton = useRedButtonStyles();

  const imageContent = useMemo(
    () =>
      previewImage ? (
        <img
          src={previewImage}
          width={300}
          height={300}
          style={{ objectFit: "cover" }}
          alt=""
        />
      ) : (
        <Box
          width="inherit"
          color={BLUE}
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={5}
        >
          <Typography
            variant="h1"
            style={{
              fontSize: 85,
              color: "#001D60",
            }}
          >
            +
          </Typography>
          <Typography variant="body2" color="secondary">
            {requiredFieldLabel(
              "To add or change a Profile Picture drag and drop it here or click on this area to upload it."
            )}
          </Typography>
        </Box>
      ),
    [previewImage, requiredFieldLabel]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (encodedFiles.length >= 1) {
        return;
      }

      setLoading(true);

      const previewFiles = chain(encodedFiles)
        .concat(
          acceptedFiles.map((file: any) => ({
            ...file,
            loading: true,
            key: file.path,
          }))
        )
        .slice(0, 1)
        .value();
      setEncodedFiles(previewFiles);

      let fileUrls: S3Data[] = [];
      try {
        const uploadSliceEnd = 1;
        const [files, tooBigFiles] = acceptedFiles
          .slice(0, uploadSliceEnd)
          .reduce(
            ([files1, files2]: [any, any], elem: any) =>
              isImageSizeValid(elem.size)
                ? [[...files1, elem], files2]
                : [files1, [...files2, elem]],
            [[], []]
          );

        if (tooBigFiles.length) {
          notifyError(
            `${tooBigFiles
              .map((file: any) => file.path)
              .join(
                ", "
              )} are too big. Please upload files smaller than ${IMAGE_MAX_SIZE_MB}mb`
          );
        }

        fileUrls = (await uploadFiles(files, "images")).map((file) => ({
          key: file.Key,
          bucket: file.Bucket,
          location: file.Location,
        }));
      } catch (err) {
        notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
      }

      setFieldValue("profileImage", fileUrls[0].location);
      setPreviewImage(fileUrls[0].location);
      setLoading(false);
    },
    [encodedFiles, notifyError, setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/gif",
    onDrop,
    disabled: encodedFiles.length >= 1,
  });

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton
        onClick={() => {
          if (!onBack) {
            navigate(-1);
          } else {
            onBack();
          }
        }}
      />
      <Box
        display="flex"
        pt={8}
        width="85%"
        justifyContent="space-between"
        gridColumnGap={120}
      >
        <Box maxWidth="35%">
          <Typography variant="h1" className={boldStyles.root}>
            Profile Information
          </Typography>
          <TextBox
            variant="subtitle1"
            boxProps={{
              paddingTop: 6,
            }}
            text="Enter your profile information here."
          />
        </Box>
        <Box display="flex" flexDirection="column" gridRowGap={60} flexGrow={1}>
          <Box
            width={300}
            {...getRootProps({ className: "dropzone" })}
            style={{ cursor: "pointer" }}
            display="flex"
            flexDirection="column"
            gridRowGap={8}
            alignItems="center"
          >
            <input
              {...getInputProps()}
              type="file"
              onChange={onUploadImageHandler}
            />
            <Box
              display="flex"
              gridColumnGap={36}
              alignItems="center"
              justifyContent="flex-start"
            >
              <IconButton component="label">
                <Box
                  width={300}
                  height={300}
                  bgcolor="#DDDDDD"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  overflow="hidden"
                >
                  {loading ? (
                    <Skeleton
                      width="100%"
                      height="100%"
                      variant="circle"
                      style={{ background: LIGHT_BLUE }}
                    />
                  ) : (
                    imageContent
                  )}
                </Box>
              </IconButton>
            </Box>
            <Button
              className={redButton.delete}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setFieldValue("profileImage", "");
              }}
            >
              Delete Image
            </Button>
          </Box>
          <Field
            component={CustomInput}
            placeholder={"Position"}
            name="position"
            value={values.position}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.position}
          />
          <Box height={200}>
            <Field
              component={CustomTextArea}
              placeholder={"Description"}
              name="description"
              value={values.description}
              className={styles.descriptionTextArea}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.description}
              maxLength={1500}
            />
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body2">Maximum 1500 characters</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
