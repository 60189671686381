import { Box, Typography, makeStyles } from "@material-ui/core";
import { range } from "lodash";
import React, { useMemo } from "react";
import Carousel from "react-material-ui-carousel";
import { SkillV2 } from "../../../api-types/job-requests/common";
import { BLUE, LIGHT_BLUE, YELLOW } from "../../../themes/components/utils";
import { CustomTooltip } from "./CustomTooltip";

type SkillsOutputProps = {
  skills?: SkillV2[];
  color?: string;
  isHovering?: boolean;
};

const useStyles = makeStyles({
  root: {
    transitionDuration: "0s",
  },
});

export const SkillsOutput: React.FC<SkillsOutputProps> = ({
  skills = [],
  color = "white",
  isHovering = false,
}) => {
  const styles = useStyles();

  const items = useMemo(() => {
    const groupCount = Math.min(Math.ceil((skills?.length ?? 0) / 5), 3);

    return range(groupCount).map((stack) => (
      <Box display="flex" gridColumnGap={6} width={450} height={40} key={stack}>
        {range(stack * 5, stack * 5 + 5).map((index) => (
          <CustomTooltip
            placement="top"
            title={skills[index] ?? ""}
            key={index}
          >
            <Box
              bgcolor={skills[index] ? color : "transparent"}
              border={skills[index] ? "1px solid white" : "none"}
              px={2}
              width={86}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="body2"
                color="inherit"
                style={{
                  color: color === "white" ? BLUE : "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                noWrap
              >
                {skills[index]}
              </Typography>
            </Box>
          </CustomTooltip>
        ))}
      </Box>
    ));
  }, [color, skills]);

  return (
    <Carousel
      sx={{
        height: "45px",
        width: 510,
        display: "flex",
        alignItems: "center",
        gridColumnGap: 16,
      }}
      className={styles.root}
      animation="fade"
      autoPlay={false}
      navButtonsAlwaysInvisible
      // fullHeightHover={false}
      indicators={items?.length > 1}
      indicatorContainerProps={{
        style: {
          display: "flex",
          gridColumnGap: 2,
          width: "fit-content",
          padding: 0,
          margin: 0,
        },
      }}
      indicatorIconButtonProps={{
        style: {
          color: BLUE,
          backgroundColor: isHovering ? YELLOW : LIGHT_BLUE,
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: isHovering ? YELLOW : LIGHT_BLUE,
          backgroundColor: isHovering ? YELLOW : LIGHT_BLUE,
        },
      }}
    >
      {items}
    </Carousel>
  );
};
