import { Box, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useCallback, useState } from "react";
import { CreateBidRequest } from "../../../../../api-types/job-requests/bids";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { ConfirmationDialog, Section } from "../../../../common/components";
import { formatShortDate } from "../../../../common/components/LoadingTypography";
import { useInvitationQuery } from "../../../../common/hooks/job-requests/job-request/invites/useInvitationQuery";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useCurrentJobRequest } from "../../../../state/hooks";
import { useToast } from "../../../../utils/useToast";
import { EditingForm } from "../../../bids/communication/EditingForm";
import {
  useBidCreateMutation,
  useBidUpdateMutation,
  useOnConfirmWithdrawalHandler,
} from "../../../bids/communication/hooks";
import { ProposedBidActions } from "../../../bids/communication/ProposedBidActions";
import { useBidDataFunding } from "../../../bids/hooks";
import { useAccountBid } from "../../../bids/hooks/useAccountBid";
import { useConfirmationModal } from "../../../bids/state/hooks";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../job-requests/hooks/feedbackErrors";
import { useBidByJobRequestId } from "../../hooks/useBidByProjectId";

type BidSectionProps = {
  editing?: boolean;
  onCancel?: () => void;
};

export const BidSection: React.FC<BidSectionProps> = ({
  editing,
  onCancel,
}) => {
  const [isEditing, setIsEditing] = useState(editing);
  const setConfirmationModalState = useConfirmationModal().set;
  const [startDate] = useState(new Date());
  const { open } = useScopedDowngradedStateValue(useConfirmationModal());
  const boldStyles = useBoldStyles();

  const { notifySuccess } = useToast();

  const { data: invitation, isLoading: isLoadingInvitation } =
    useInvitationQuery();

  const { data: jobRequest, isLoading: isLoadingJobRequest } =
    useJobRequestQuery(invitation?.jobRequestId);

  const jobRequestId = invitation?.jobRequestId;

  const accountBidId = useBidByJobRequestId(jobRequestId);

  const { mutate: updateBid, isLoading: isUpdating } =
    useBidUpdateMutation(accountBidId);

  const { mutate: createBid, isLoading: isCreatingBid } =
    useBidCreateMutation();

  const setCurrentJobRequest = useCurrentJobRequest().set;

  const { data: bidData, isLoading: isLoadingBid } =
    useAccountBid(accountBidId);

  const bidDataFunding = useBidDataFunding(accountBidId);

  const onHandleUpdateBid = useCallback(
    (values: CreateBidRequest) => {
      if (!accountBidId) {
        createBid({
          jobRequest: jobRequestId,
          ...values,
          messages: [],
        });
        return;
      }

      updateBid({ ...values, id: accountBidId });
    },
    [accountBidId, createBid, jobRequestId, updateBid]
  );

  const onWithdrawHandler = useCallback(() => {
    setConfirmationModalState({ open: true });
  }, [setConfirmationModalState]);

  const onConfirmWithdrawHandler = useOnConfirmWithdrawalHandler(
    undefined,
    () => {
      notifySuccess(feedbackMessage("bid", FEEDBACK_TYPE.WITHDRAW));
      setCurrentJobRequest(null);
      setConfirmationModalState({ open: false });
    }
  );

  const onPlaceBidHandler = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setConfirmationModalState({ open: false });
  }, [setConfirmationModalState]);

  const isLoading =
    isLoadingBid ||
    isCreatingBid ||
    isUpdating ||
    isLoadingJobRequest ||
    isLoadingInvitation;

  return (
    <Section isLoading={isLoading}>
      <Box display="flex" pb={4} gridColumnGap={96} alignItems="center">
        <Typography variant="subtitle1" className={boldStyles.root}>
          Introductory Response
        </Typography>
        <Typography variant="subtitle2">
          {format(startDate, formatShortDate)}
        </Typography>
      </Box>
      <Divider />
      <Box pt={4}>
        {isEditing ? (
          <EditingForm
            onCancel={onCancel}
            setIsEditing={setIsEditing}
            onHandlePlaceBid={onHandleUpdateBid}
            initialValues={{
              introductoryMessage: bidData?.introductoryMessage ?? "",
              ...bidDataFunding,
            }}
            budgetType={jobRequest?.budget.type}
          />
        ) : (
          <React.Fragment>
            <Typography variant="subtitle2">
              {bidData?.introductoryMessage}
            </Typography>
            <ProposedBidActions
              bidData={bidData}
              isNewBid={!accountBidId}
              onPlaceBid={onPlaceBidHandler}
              onWithdraw={onWithdrawHandler}
            />
          </React.Fragment>
        )}
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <ConfirmationDialog
        open={open}
        onClose={onCloseModalHandler}
        onConfirm={onConfirmWithdrawHandler}
        title="Are you sure you want to withdraw this bid?"
        message="This bid will be permanently erased."
      />
    </Section>
  );
};
