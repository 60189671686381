import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useCallback } from "react";
import { BLUE, COLOR } from "../../../../themes/components/utils";
import { useDialogStyles } from "../../../common/components/modals/subscribe-dialog/SubscribeDialog";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { SearchJobFiltersFormData, searchJobFiltersSchema } from "../schemas";
import { useJobPostSearchFilters } from "../state/hooks";
import { JobLevelFilter } from "./JobLevelFilter";
import { JobLocationFilter } from "./JobLocationFilter";
import { JobSponsorshipFilter } from "./JobSponsorshipFilter";
import { JobTypeFilter } from "./JobTypeFilter";
import { RatePerHourFilter } from "./RatePerHourFilter";
import { SalaryPerYearFilter } from "./SalaryPerYearFilter";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: 16,
  },
  dialog: {
    height: "unset",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "none",
      border: `2px solid ${BLUE}`,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "none",
      border: `2px solid ${BLUE}`,
    },
    "&::-webkit-scrollbar-track-piece": {
      backgroundColor: "none",
    },
    "&::-webkit-scrollbar": {
      width: 5,
    },
  },
});

type SearchJobPostFiltersDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const SearchJobPostFiltersDialog = ({
  open,
  onClose,
}: SearchJobPostFiltersDialogProps) => {
  const dialogStyles = useDialogStyles();
  const { content, dialog } = useStyles();
  const styles = useSearchJobPostFiltersStyles();
  const jobPostSearchFilters = useScopedDowngradedStateValue(
    useJobPostSearchFilters()
  );
  const setJobPostSearchFilters = useJobPostSearchFilters().merge;

  const onSubmitFilters = useCallback(
    (values: SearchJobFiltersFormData) => {
      setJobPostSearchFilters(values);
      onClose();
    },
    [onClose, setJobPostSearchFilters]
  );

  return (
    <Dialog onClose={onClose} open={open} PaperProps={{ className: dialog }}>
      <Box onClick={onClose} className={dialogStyles.closeIcon}>
        <CloseIcon />
      </Box>
      <Formik
        initialValues={jobPostSearchFilters}
        onSubmit={onSubmitFilters}
        validationSchema={searchJobFiltersSchema}
      >
        {({ handleChange, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Typography
                color="secondary"
                variant="subtitle1"
                style={{
                  fontWeight: 700,
                }}
              >
                Select the Kind of Job You're Looking For
              </Typography>
            </DialogTitle>
            <DialogContent className={content}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.showAll}
                    className={styles.checkbox}
                    data-cy="show-all"
                    name="showAll"
                    onChange={handleChange}
                    size="small"
                    style={{
                      color: COLOR.BLUE,
                    }}
                  />
                }
                label={
                  <Typography
                    className={styles.label}
                    color="secondary"
                    variant="subtitle2"
                  >
                    Show all job posts (default)
                  </Typography>
                }
                name="all"
                style={{
                  color: COLOR.BLUE,
                  margin: 0,
                  marginLeft: -13,
                }}
              />
              <Divider style={{ backgroundColor: "#000", height: 1 }} />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <JobLevelFilter />

                <JobTypeFilter />

                <JobLocationFilter />

                <JobSponsorshipFilter />
              </Box>

              <SalaryPerYearFilter />

              <RatePerHourFilter />
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="secondary"
                type="submit"
                size="large"
                variant="contained"
              >
                Confirm
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                size="large"
                onClick={onClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
