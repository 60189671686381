import { useMutation, useQueryClient } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import { inviteProfessional } from "../../../../../../api-services/job-requests/invitations";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../../utils/useToast";
import { INVITATIONS_QUERY_KEY } from "./useSentInvitations";

export const useInviteProfessionalMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    (params: {
      jobRequestId: string;
      jobRequestTitle: string;
      professionalId: string;
      introductoryMessage: string;
    }) => inviteProfessional(params),
    {
      onSuccess() {
        notifySuccess(feedbackMessage("invitation", FEEDBACK_TYPE.SEND));
        queryClient.invalidateQueries(INVITATIONS_QUERY_KEY);
        navigate({
          pathname: `/browse`,
          search: `?${createSearchParams({
            type: "professionals",
          })}`,
        });
      },
      onError() {
        notifyError(
          "There was an error inviting this professional. Please try again"
        );
      },
    }
  );
};
