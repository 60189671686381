import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

type TextOverflowProps = {
  className?: any;
  onClick?: () => void;
  style?: any;
  to?: string;
  width: string;
  wrapperClassName?: any;
};

export const TextOverflow: React.FC<TextOverflowProps> = ({
  children,
  className,
  onClick,
  style,
  to,
  width,
  wrapperClassName,
}) => {
  return (
    <Box
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        width,
      }}
      onClick={onClick}
      className={wrapperClassName}
    >
      <Typography
        component={to ? Link : "p"}
        style={{ ...style, textDecoration: "none" }}
        className={className}
        noWrap
        to={to}
      >
        {children}
      </Typography>
    </Box>
  );
};
