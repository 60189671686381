import { Box, Button, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { ShowImages, TextOverflow } from "../../../../common/components";
import {
  formatNominativeMonth,
  LoadingTypography,
} from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";
import { usePublishPastProject } from "../unpublished-projects/hooks/usePublishPastProject";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.images?.length ?? 0 > 0);

  const { mutate: publishProject } = usePublishPastProject();

  const onPublishProjectHandler = useCallback(() => {
    publishProject();
  }, [publishProject]);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <LoadingTypography
          width={400}
          isLoading={isLoadingProject}
          typographyProps={{
            variant: "subtitle1",
            className: boldStyles.root,
          }}
        >
          {project?.title}
        </LoadingTypography>
        {project?.createdAt && (
          <Typography>
            {`Started ${format(
              new Date(project?.createdAt),
              formatNominativeMonth
            )}`}
          </Typography>
        )}
        {project?.client && (
          <Box display="flex" gridColumnGap={8} width="15%">
            <Typography display="inline">posted by</Typography>
            <TextOverflow width="40%">{`${project?.client.accountInformation?.firstName} ${project?.client.accountInformation?.firstName}`}</TextOverflow>
          </Box>
        )}

        <Box justifySelf="flex-end">
          <Button
            size="medium"
            variant="outlined"
            onClick={onPublishProjectHandler}
          >
            Publish this project
          </Button>
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={project?.description} />
      <SkillsOutput skills={skills} />
      {hasImages && (
        <>
          <ShowImages selectedFiles={project?.images} />
          <Box py={2}>
            <Divider />
          </Box>
        </>
      )}
    </Box>
  );
};
