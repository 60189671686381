import { Box } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { FC } from "react";
import { useStepAStyles } from "../../../../../../themes/style-hooks";
import { useSubscribeModal } from "../../../../../state/hooks";
import { useScopedDowngradedStateValue } from "../../../../hooks/utils/useScopedDowngradedStateValue";
import { CardsCollection } from "./CardsCollection";
import { Typewriter } from "./Typewriter";

export const LandingStepA: FC = () => {
  const subscribeModalState = useScopedDowngradedStateValue(
    useSubscribeModal()
  );
  const styles = useStepAStyles();
  const smallMobileHeight = useMediaQuery("(max-height: 450px)");

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="flex-start"
      style={{
        background: `linear-gradient(180deg, #F5895F 45%, #B1D9D9 85%)`,
      }}
    >
      <Box
        style={{
          visibility: subscribeModalState.open ? "hidden" : "visible",
        }}
        className={styles.container}
        maxWidth={1440}
        height="100%"
        margin="0 auto"
        display="flex"
        alignItems="flex-start"
        justifyContent={smallMobileHeight ? "space-evenly" : "space-between"}
        flexDirection="column"
      >
        <Box className={`${styles.clientHeadlineText} ${styles.mediumItalic}`}>
          <Typewriter />
        </Box>
        <Box width="90%">
          <Box>
            <Box className={styles.clientDescriptionText}>
              We connect creative designers and developers to businesses and
              innovators.
            </Box>
            <Box className={styles.clientDescriptionText}>
              It feels like home for the talented.
            </Box>
          </Box>
          <Box className={styles.descriptionWrapper}>
            <Box className={styles.clientDescriptionText}>
              Build your app, your .com, or your brand.
            </Box>
            <Box className={styles.clientDescriptionText}>
              Build with talent.
            </Box>
          </Box>
        </Box>
        <CardsCollection />
      </Box>
    </Box>
  );
};
