import { useMutation } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import { makeCollaborationProposal } from "../../../../../../api-services/projects/collaborations";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../../utils/useToast";

export const useReferProfessionalMutation = () => {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    (params: {
      projectId: string;
      professionalId: string;
      introductoryMessage: string;
    }) => makeCollaborationProposal(params),
    {
      onSuccess() {
        notifySuccess(
          feedbackMessage("professional referral", FEEDBACK_TYPE.SUBMIT)
        );
        navigate({
          pathname: `/browse`,
          search: `?${createSearchParams({
            type: "professionals",
          })}`,
        });
      },
      onSettled() {},
      onError() {
        notifyError(
          "There was an error referring this professional. Please try again"
        );
      },
    }
  );
};
