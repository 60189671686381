import axios from "axios";
import { CompleteProjectData } from "../../../api-types/projects/project";
import { completeProjectRequestUrl } from "../../utils";

export const completeProject = (
  id: string,
  completeProject?: CompleteProjectData
) => {
  return axios.post(completeProjectRequestUrl(id), completeProject);
};
