import { InputBaseClassKey, InputBaseProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiInputBase: MuiComponentThemeDefinition<
  InputBaseClassKey,
  InputBaseProps
> = {
  props: {
    autoComplete: "off",
  },
  override: () => ({
    root: {
      height: "inherit",
      fontSize: 21,
      backgroundColor: "white",
      borderRadius: 1,
    },
  }),
};
