import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { FilterDropdownCore } from "../../../common/components";
import { useDialogStyles } from "../../../common/components/modals/subscribe-dialog/SubscribeDialog";

interface SelectFilterProps {
  label?: string;
  options?: string[];
  onSave: (option: any) => any;
  defaultSelected: string;
}

export const SelectBids: React.FC<SelectFilterProps> = ({
  label,
  options,
  defaultSelected,
  onSave,
}) => {
  const [type, setType] = useState(defaultSelected);
  const dialogStyles = useDialogStyles();

  return (
    <Box display="flex" data-cy="bids-select">
      <FilterDropdownCore
        buttonLabel={
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {label}:{" "}
              </span>
            </Typography>
            <Box
              style={{
                color: "white",
                fontSize: 18,
              }}
              px={2}
            >
              {type}
            </Box>
          </Box>
        }
        onApply={() => onSave(type)}
      >
        <Box px={5}>
          <RadioGroup
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            defaultValue={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            {options?.map((option) => (
              <FormControlLabel
                key={option}
                data-cy={option}
                value={option}
                control={
                  <Radio className={dialogStyles.radio} disableTouchRipple />
                }
                label={<Typography color="secondary">{option}</Typography>}
              />
            ))}
          </RadioGroup>
        </Box>
      </FilterDropdownCore>
    </Box>
  );
};
