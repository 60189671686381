import { Box, Button } from "@material-ui/core";
import { Formik, Field } from "formik";
import React from "react";
import * as Yup from "yup";
import { string } from "yup";
import { PasswordResetData } from "../../../api-types/sign-in";
import { CustomInput } from "../elements";

const initialValues = {
  email: "",
};

type PasswordResetEmailFormProps = {
  formRef: any;
  onTriggerReset?: (values: PasswordResetData) => void;
};

export const PasswordResetEmailForm: React.FC<PasswordResetEmailFormProps> = ({
  formRef,
  onTriggerReset,
}) => {
  const validationSchema = Yup.object({
    email: string()
      .required("Email address is required")
      .email("Valid email required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onTriggerReset?.(values);
      }}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        isValid,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="nope">
          <Box display="flex" flexDirection="column" gridRowGap={50}>
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder="Enter email address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              id="email"
              error={errors.email}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gridRowGap={10}
            pt={5}
          >
            <Button
              size="medium"
              variant="contained"
              type="submit"
              disabled={!isValid}
              data-cy-btn="send"
            >
              Send
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
