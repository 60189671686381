import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BROWSE_DEFAULT_URL } from "../../../routes/utils";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { NavbarMenuItem } from "../../common/navbar/NavbarMenuItem";

export const ClientNavbar: React.FC = () => {
  const navigate = useNavigate();
  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const onNavbarItemClickHandler = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <Box display="flex" gridColumnGap={8}>
      <NavbarMenuItem
        label="Jobs Posted"
        labelCount={metadata?.jobPostsCount}
        isLoading={isLoadingMetadata}
        onClick={() => onNavbarItemClickHandler("/job-posts")}
        dataCyBtn="job-posts"
      />
      <NavbarMenuItem
        label="Freelancing Requests"
        labelCount={metadata?.jobRequestsCount ?? 0}
        isLoading={isLoadingMetadata}
        onClick={() => onNavbarItemClickHandler("/job-requests")}
      />
      <NavbarMenuItem
        label="Current Projects"
        labelCount={metadata?.projectsCount ?? 0}
        isLoading={isLoadingMetadata}
        onClick={() => onNavbarItemClickHandler("/projects")}
      />
      <NavbarMenuItem
        label="New Job or Project"
        onClick={() => onNavbarItemClickHandler("/job-request/new")}
        dataCyBtn="post-new-job-request"
      />
      <NavbarMenuItem
        label="Browse Professionals"
        onClick={() => onNavbarItemClickHandler(BROWSE_DEFAULT_URL)}
      />
    </Box>
  );
};
