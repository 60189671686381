import { FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { FileWithPath } from "react-dropzone";
import { useSearchParams } from "react-router-dom";
import {
  BudgetStep,
  Images,
  JobRequestDetails,
  ProjectFundingStep,
  Services,
  Skills,
} from "./index";

type CurrentJobRequestStepProps = {
  handleChange: (e: ChangeEvent) => void;
  handleUpdateSkills: (newSkills: string[]) => void;
  handleChangeFileUpload?: (files: FileWithPath[]) => void;
  handleBlur: (e: FocusEvent) => void;
  handleFocus: (field: string, value: string) => void;
  values: FormikValues;
  errors: any;
  onBack?: () => void;
};

export const CurrentJobRequestStep: React.FC<CurrentJobRequestStepProps> = (
  props
) => {
  const [searchParams] = useSearchParams();

  const currentStep = Number(searchParams.get("step"));

  if (currentStep === 1) {
    return <Services {...props} />;
  }

  if (currentStep === 2) {
    return <JobRequestDetails {...props} />;
  }

  if (currentStep === 3) {
    return <Images {...props} />;
  }

  if (currentStep === 4) {
    return <Skills {...props} />;
  }
  //
  // if (currentStep === 5) {
  //   return <TimeframeCost {...props} />;
  // }

  // if (currentStep === 5) {
  //   return <Visibility {...props} />;
  // }

  if (currentStep === 5) {
    return <BudgetStep {...props} />;
  }

  if (currentStep === 6) {
    return <ProjectFundingStep {...props} />;
  }

  return null;
};
