import axios from "axios";
import { File } from "../../../../api-types/job-requests/common";
import { PROFESSIONALS_INTERACTION_ADD_FILES_URL } from "../../../utils";

export const addInteractionFile = (professionalId: string, files: File[]) => {
  return axios.post(PROFESSIONALS_INTERACTION_ADD_FILES_URL, {
    files,
    professionalId,
  });
};
