import { Box, Divider } from "@material-ui/core";
import React from "react";
import { ProjectHeaderContent } from "./ProjectHeaderContent";
import { ProjectHeaderSummary } from "./ProjectHeaderSummary";

type ProjectHeaderProps = {
  variant?: "project" | "collaboration";
};

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({ variant }) => {
  return (
    <Box display="flex" gridColumnGap={40}>
      <ProjectHeaderContent variant={variant} />
      <Divider orientation="vertical" flexItem />
      <ProjectHeaderSummary variant={variant} />
    </Box>
  );
};
