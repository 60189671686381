import { FormikErrors, FormikValues } from "formik";
import { ChangeEvent, FC } from "react";
import { SkillV2 } from "../../../../api-types/job-requests/common";
import { Skills } from "../../../professional/account/edit-professional-account/steps";

type SignUpProfessionalOptionalSkillsProps = {
  onBack?: () => void;
  values: FormikValues;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export const SignUpProfessionalOptionalSkills: FC<
  SignUpProfessionalOptionalSkillsProps
> = ({ onBack, setFieldValue, values }) => {
  return (
    <Skills
      handleChange={(newSkills: SkillV2[]) => {
        setFieldValue(
          "skills",
          newSkills.map((skill) => ({
            name: skill,
          }))
        );
      }}
      onBack={onBack}
      values={values}
    />
  );
};
