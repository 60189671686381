import { useEffect, useMemo } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useReceivedClientInvitations } from "../../../common/hooks/job-requests/job-request/invites/useReceivedClientInvitations";
import { useAccountInquiries } from "./useAccountInquiries";

export const NEW_ID = "new";

export const useDefaultInquiryPage = () => {
  const { inquiryId } = useParams();
  const navigate = useNavigate();
  const { data: accountInquiries, isLoading: isLoadingInquiries } =
    useAccountInquiries();

  const { data: invitations, isLoading: isLoadingInvitations } =
    useReceivedClientInvitations();

  const filteredInquiries = useMemo(() => {
    const invitationsJobIds = invitations?.map(
      ({ jobRequestId }) => jobRequestId
    );

    return accountInquiries?.filter(
      ({ jobRequest }) => !invitationsJobIds?.includes(jobRequest._id)
    );
  }, [accountInquiries, invitations]);

  const firstInquiry = useMemo(() => {
    if (!filteredInquiries || isLoadingInquiries || isLoadingInvitations)
      return null;

    return filteredInquiries[0];
  }, [filteredInquiries, isLoadingInquiries, isLoadingInvitations]);

  useEffect(() => {
    if (isLoadingInquiries || isLoadingInvitations) return;

    if (!firstInquiry?._id) return;

    if (inquiryId) return;

    navigate(
      {
        pathname: firstInquiry?._id,
        search: `?${createSearchParams({ tab: "details" })}`,
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInquiries, inquiryId, firstInquiry]);
};
