import { Box, Typography, useMediaQuery } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { SubscribeDialog } from "../../../components/modals/subscribe-dialog";
import { useSubscribe } from "../../../hooks/subscribe";
import { BrowseMainbar } from "../../../professionals-search/browse-mainbar";
import {
  DESKTOP_NAVBAR_HEIGHT,
  MOBILE_NAVBAR_HEIGHT,
  SMALL_MOBILE_NAVBAR_HEIGHT,
} from "../../constants";
import { Topbar } from "../../Topbar";
import { useDialogStyles } from "../job-requests/JobRequestsPageSubscribe";

export const ProfessionalsPageSubscribe: React.FC = () => {
  const mobileWidth = useMediaQuery("(max-width: 1024px)");
  const hideMainbar = useMediaQuery("(max-height: 980px)");
  const shrinkLogo = useMediaQuery("(max-height: 750px)", { noSsr: true });
  const mobileHeight = useMediaQuery("(max-height: 650px)");
  const smallMobileHeight = useMediaQuery("(max-height: 400px)", {
    noSsr: true,
  });
  const mobileView = mobileWidth || mobileHeight;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const styles = useDialogStyles();

  const { mutate: subscribe, isLoading } = useSubscribe({
    onSuccess: () => {
      setIsSubscribed(true);
    },
  });

  const onCloseModalHandler = useCallback(() => {
    location.replace("/");
  }, []);

  const height = useMemo(() => {
    if (smallMobileHeight) return SMALL_MOBILE_NAVBAR_HEIGHT;

    if (shrinkLogo) return MOBILE_NAVBAR_HEIGHT;

    return DESKTOP_NAVBAR_HEIGHT;
  }, [shrinkLogo, smallMobileHeight]);

  return (
    <Box height="100%">
      <Topbar inverse={!mobileView} height={height} />
      <Box px={15} pt={20} height={260}>
        {hideMainbar || mobileView ? null : (
          <Box py={12}>
            <Typography
              variant="h1"
              style={{
                fontWeight: 700,
              }}
            >
              Professionals and Teams
            </Typography>
            <BrowseMainbar isMock />
          </Box>
        )}
      </Box>
      <SubscribeDialog
        classNames={styles.dialog}
        subscribed={isSubscribed}
        open
        isLoading={isLoading}
        onClose={onCloseModalHandler}
        title="Be part of The Talented community! Join us as an early adopter."
        subtitle="We’re launching very soon. Stay tuned for the product launch email
            invite. Looking forward to having you on board."
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        onConfirm={(values) => {
          subscribe(values);
        }}
      />
    </Box>
  );
};
