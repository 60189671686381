import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { Project } from "../../../../../api-types/projects";
import { YELLOW } from "../../../../../themes/components/utils";
import {
  useBoldStyles,
  useDividerStyles,
  usePointerStyles,
} from "../../../../../themes/style-hooks";
import { CustomTooltip } from "../../../../common/components";
import { ProfessionalSummary } from "../../../../common/components/ProfessionalSummary";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useWindowDimensions } from "../../../../common/window";
import { useUser } from "../../../../state/hooks";
import { useToggleFeaturedProject } from "../../../projects/past-projects/unpublished-projects/hooks/useToggleFeaturedProject";
import { PortfolioProject } from "./PortfolioProject";
import { PortfolioProjectAvailability } from "./PortfolioProjectAvailability";

type PortfolioRowProps = { project?: Project; isFeaturedDisabled?: boolean };

const useStyles = makeStyles(() => ({
  showMoreText: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const PortfolioRow: React.FC<PortfolioRowProps> = ({
  project,
  isFeaturedDisabled,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const pointerStyles = usePointerStyles();
  const boldStyles = useBoldStyles();
  const styles = useStyles();
  const navigate = useNavigate();

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const { id } = useScopedDowngradedStateValue(useUser());

  const { mutate: toggleFeaturedProject, isLoading } =
    useToggleFeaturedProject();

  const skills = useMemo(() => {
    if (!project?.external) {
      return project?.skills.map((skill) => skill.name);
    }

    return project?.skills.map((skill) => skill.name);
  }, [project?.external, project?.skills]);

  const dividerStyles = useDividerStyles();

  const collaborators = useMemo(() => {
    return project?.professionals?.filter(
      ({ professional }) => professional?._id != id
    );
  }, [id, project?.professionals]);

  const handleToggleFeatured = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (project?._id) {
        toggleFeaturedProject(project._id);
      }
    },
    [toggleFeaturedProject, project?._id]
  );

  const hasCollaborators = Boolean(collaborators?.length);

  const { width } = useWindowDimensions();

  const handleProjectClick = useCallback(() => {
    navigate(`/projects/portfolio-project/${project?._id}`);
  }, [navigate, project?._id]);

  const imageWidth = useMemo(() => {
    if (width > 1440) return 720;

    const approximateWidth = width / 2 - 40;

    return Math.max(approximateWidth, 400);
  }, [width]);

  return (
    <Box
      display="flex"
      gridColumnGap={20}
      className={pointerStyles.root}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={handleProjectClick}
    >
      {!project?.images?.length ? (
        <Box display="flex" flexDirection="column" flexGrow={1} gridRowGap={12}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography color="primary" variant="subtitle1">
              {project?.title}
            </Typography>
          </Box>
          <Divider />
          <Typography component="div" variant="body1">
            <ShowMoreText
              lines={5}
              more={
                <CustomTooltip
                  arrow
                  interactive
                  placement="right"
                  title={
                    <Box>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Description
                      </Typography>
                      <Typography
                        color="secondary"
                        variant="body1"
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {project?.description ?? ""}
                      </Typography>
                    </Box>
                  }
                >
                  <Typography component="span" variant="body2" color="primary">
                    More
                  </Typography>
                </CustomTooltip>
              }
              anchorClass={styles.showMoreText}
              expanded={false}
              // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
              expandByClick={false}
            >
              {project?.description}
            </ShowMoreText>
          </Typography>
          {!project?.external ? (
            <Box mr="auto">
              <PortfolioProjectAvailability timeFrame={project?.timeFrame} />
            </Box>
          ) : (
            <Box display="flex" gridColumnGap={4}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Timeframe:
              </Typography>
              <Typography variant="body2">
                {project?.timeFrame?.projectTimeFrame?.weeks} week(s)
              </Typography>
            </Box>
          )}
          {(skills?.length ?? 0) > 0 && (
            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SkillsOutput skills={skills} isHovering={isHovering} />
            </Box>
          )}
          {Boolean(project?.url) && (
            <Typography
              color="primary"
              component="a"
              href={project?.url}
              variant="body1"
              noWrap
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {project?.url}
            </Typography>
          )}
        </Box>
      ) : (
        <Fragment>
          <PortfolioProject
            project={project}
            height={520}
            width={imageWidth}
            hover={isHovering}
          />{" "}
          <Box
            display="flex"
            flexDirection="column"
            gridRowGap={20}
            flexGrow={1}
            width="35%"
          >
            <Box>
              {!project?.external && (
                <PortfolioProjectAvailability timeFrame={project?.timeFrame} />
              )}
              <Box
                pr={2}
                display="flex"
                gridColumnGap={8}
                justifyContent="space-between"
                alignItems="center"
              >
                {Boolean(project?.external) && (
                  <Box display="flex" gridColumnGap={10}>
                    <Typography
                      className={boldStyles.root}
                      component="div"
                      variant="body2"
                    >
                      Timeframe:
                    </Typography>
                    <Typography variant="body2">
                      {project?.timeFrame?.projectTimeFrame?.weeks} week(s)
                    </Typography>
                  </Box>
                )}
                {project?.isFeatured ? (
                  <Box pt={1}>
                    <Button
                      size="small"
                      onClick={handleToggleFeatured}
                      disabled={isLoading || isFeaturedDisabled}
                    >
                      Disable featured
                    </Button>
                  </Box>
                ) : (
                  <Box pt={1}>
                    <Button
                      size="small"
                      onClick={handleToggleFeatured}
                      disabled={isLoading}
                    >
                      Make featured
                    </Button>
                  </Box>
                )}
              </Box>
              <Box py={2.5}>
                <Divider className={dividerStyles.root} />
              </Box>
            </Box>
            <Box pr={5}>
              <Typography className={boldStyles.root} variant="subtitle1">
                Description
              </Typography>
              <Typography component="div" variant="body1">
                <ShowMoreText
                  lines={5}
                  more={
                    <CustomTooltip
                      arrow
                      interactive
                      placement="right"
                      title={
                        <Box>
                          <Typography
                            color="secondary"
                            variant="subtitle1"
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Description
                          </Typography>
                          <Typography
                            color="secondary"
                            variant="body1"
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {project?.description ?? ""}
                          </Typography>
                        </Box>
                      }
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                      >
                        More
                      </Typography>
                    </CustomTooltip>
                  }
                  anchorClass={styles.showMoreText}
                  expanded={false}
                  // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
                  expandByClick={false}
                >
                  {project?.description}
                </ShowMoreText>
              </Typography>
            </Box>
            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SkillsOutput skills={skills} isHovering={isHovering} />
            </Box>
            {hasCollaborators && (
              <Fragment>
                <Divider className={dividerStyles.root} />
                <Box display="flex" alignItems="center" gridColumnGap={48}>
                  <Typography variant="subtitle2">Collaborated with</Typography>
                  <Box display="flex" gridColumnGap={24}>
                    {collaborators?.map(({ professional }) => (
                      <ProfessionalSummary
                        key={professional._id}
                        professional={professional}
                        width={36}
                      />
                    ))}
                  </Box>
                </Box>
              </Fragment>
            )}
            {Boolean(project?.url) && (
              <Typography
                color="primary"
                component="a"
                href={project?.url}
                variant="body1"
                noWrap
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {project?.url}
              </Typography>
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
