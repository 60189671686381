import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { updateAccountInformation } from "../../../../api-services/account-information";
import { AccountInformation } from "../../../../api-types/account-information";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { ACCOUNT_INFORMATION_QUERY_KEY } from "./useAccountInformation";

export const useUpdateAccountInformationMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation(
    (accountInformation: Partial<AccountInformation>) =>
      updateAccountInformation(accountInformation),
    {
      ...mutationOptions,
      onSuccess() {
        queryClient.invalidateQueries(ACCOUNT_INFORMATION_QUERY_KEY);
        notifySuccess(
          feedbackMessage("account information", FEEDBACK_TYPE.UPDATE)
        );
      },
    }
  );
};
