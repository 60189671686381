import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePastProjectsQuery } from "../../../../common/hooks/projects/usePastProjectsQuery";
import { useCurrentProject } from "../state/hooks";

export const useDefaultPastProjectPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { data: pastProjects, isLoading: isLoadingProjects } =
    usePastProjectsQuery();
  const setCurrentProject = useCurrentProject().set;

  const firstProjectId = useMemo(() => {
    if (!pastProjects || isLoadingProjects) return "";

    return pastProjects[0]?._id;
  }, [pastProjects, isLoadingProjects]);

  useEffect(() => {
    if (isLoadingProjects) return;

    if (projectId) return;

    if (!pastProjects?.length) return;

    setCurrentProject({
      index: 0,
    });

    navigate(`${firstProjectId}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, firstProjectId, isLoadingProjects, pastProjects]);
};
