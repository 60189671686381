import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FieldArray, Formik, FormikValues } from "formik";
import { first, isEqual, keys, last, pick, uniqueId } from "lodash";
import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { object } from "yup";
import { useRedButtonStyles } from "../../../../themes/style-hooks";
import { FIXED_PRICE_DIFF_PROPS } from "../../../common/components/milestones/constants";
import { FixedPriceDiffTableRow } from "../../../common/components/milestones/FixedPriceDiffTableRow";
import { FixedPriceEditableTableRow } from "../../../common/components/milestones/FixedPriceEditableTableRow";
import { FixedPriceMilestonesSummary } from "../../../common/components/milestones/FixedPriceMilestonesSummary";
import { FixedPriceTableRow } from "../../../common/components/milestones/FixedPriceTableRow";
import { MilestoneChangesActions } from "../../../common/components/milestones/MilestoneChangesActions";
import { getPendingChanges } from "../../../common/components/milestones/utils";
import { NumberChip } from "../../../common/components/NumberChip";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";
import {
  Milestone,
  MilestonesSchemaDefinition,
  MilestoneStatus,
} from "../../job-requests/create-job-request-wizard/validation-schema";
import { useCompleteProjectModal } from "../state/hooks";
import { TABLE_HEIGHT } from "./constants";

type FixedPriceMilestonesTableProps = {
  canAcceptPendingChanges?: boolean;
  canEditMilestones?: boolean;
  closingRequest?: boolean;
  existingMilestonesIds: string[];
  isAcceptingWork?: boolean;
  isEditingMilestones: boolean;
  isFundingMilestones: boolean;
  isLoading?: boolean;
  isRequestingChanges?: boolean;
  milestones: Milestone[];
  onAcceptChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onAcceptWork?: (milestoneId?: string) => void;
  onDenyChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onFinishEdit: (values: FormikValues) => void;
  onRequestChanges?: (milestoneId?: string) => void;
  onStartEdit: () => void;
  pendingChanges?: Milestone[];
  setIsClosing: () => void;
  setIsEditingMilestones: (isEditing: boolean) => void;
  setIsFundingMilestones: (isFunding: boolean) => void;
  alertsMap?: { alertIndex: number; milestoneId: string }[];
};

export const FixedPriceMilestonesTable: FC<FixedPriceMilestonesTableProps> = ({
  canAcceptPendingChanges,
  canEditMilestones,
  closingRequest,
  existingMilestonesIds,
  isAcceptingWork,
  isEditingMilestones,
  isFundingMilestones,
  isLoading,
  isRequestingChanges,
  milestones,
  onAcceptChanges,
  onAcceptWork,
  onDenyChanges,
  onFinishEdit,
  onStartEdit,
  onRequestChanges,
  pendingChanges,
  setIsEditingMilestones,
  setIsFundingMilestones,
  setIsClosing,
  alertsMap,
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { data: project } = useProjectQuery();
  const requiredFieldLabel = useRequiredFieldLabel();

  const redButtonStyles = useRedButtonStyles();

  const [selectedMilestones, setSelectedMilestones] = useState<Milestone[]>([]);

  const handleSelectMilestone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, milestone: any) => {
      if (event.target.checked) {
        setSelectedMilestones([...selectedMilestones, milestone]);
      }

      if (!event.target.checked) {
        const updatedMilestones = [...selectedMilestones];

        const index = updatedMilestones.findIndex(
          (m) => m._id === milestone._id
        );

        updatedMilestones.splice(index, 1);
        setSelectedMilestones(updatedMilestones);
      }
    },
    [selectedMilestones]
  );

  const isMilestoneSelected = useCallback(
    (milestone: Milestone) => {
      return Boolean(
        selectedMilestones.find(({ _id }) => _id === milestone._id)
      );
    },
    [selectedMilestones]
  );

  const amount = useMemo(() => {
    return selectedMilestones.reduce(
      (prev, curr) => prev + (curr.isFunded ? 0 : curr.amount),
      0
    );
  }, [selectedMilestones]);

  // https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  const amountIncludingCommission = (amount + 0.3) / (1 - 0.029);
  // const amountIncludingCommission = amount + amount * 0.029 + 0.3;

  const roundedAmount = amount
    ? Math.round((amountIncludingCommission + Number.EPSILON) * 100) / 100
    : 0;

  const onFundMilestonesHandler = useCallback(() => {
    if (!project?.jobRequest._id) {
      return;
    }

    setIsFundingMilestones(false);
    setSelectedMilestones([]);

    const milestonesIds = selectedMilestones.map((milestone) => milestone._id);

    navigate(
      {
        pathname: "/payment/",
        search: `?${createSearchParams({
          amount: roundedAmount.toString(),
          jobRequestId: project.jobRequest._id,
          milestonesIds: JSON.stringify(milestonesIds),
        })}`,
      },
      {
        state: {
          backRoute: pathname,
          search,
        },
      }
    );
  }, [
    navigate,
    pathname,
    project?.jobRequest._id,
    search,
    selectedMilestones,
    setIsFundingMilestones,
    roundedAmount,
  ]);

  const isLastMilestoneCompleted =
    milestones[milestones.length - 1].status === MilestoneStatus.COMPLETED;

  const isEntireProjectFunded = milestones.every(
    (milestone) => milestone.isFunded
  );

  const setCompleteProjectModal = useCompleteProjectModal().set;

  return (
    <Fragment>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pb={4}
        pt={8}
      >
        <FixedPriceMilestonesSummary />
        <Box display="flex" gridColumnGap={8}>
          {isLastMilestoneCompleted ? (
            <Button
              onClick={() => {
                setCompleteProjectModal({ open: true });
              }}
              disabled={isFundingMilestones || isEditingMilestones}
              size="small"
            >
              Complete Project
            </Button>
          ) : (
            <Button
              disabled={
                isFundingMilestones || isEditingMilestones || closingRequest
              }
              onClick={setIsClosing}
              size="small"
              className={redButtonStyles.delete}
            >
              Cancel Project
            </Button>
          )}
          <Button
            disabled={
              isEditingMilestones || !canEditMilestones || isFundingMilestones
            }
            onClick={onStartEdit}
            size="small"
          >
            Edit Milestones
          </Button>
          {!isEntireProjectFunded && (
            <Button
              disabled={
                isFundingMilestones ||
                isEditingMilestones ||
                isRequestingChanges
              }
              onClick={() => setIsFundingMilestones(true)}
              size="small"
              variant="contained"
            >
              Fund Milestone
            </Button>
          )}
        </Box>
      </Box>

      <Divider />

      <Box
        pt={4}
        minHeight={240}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={{
              milestones,
            }}
            onSubmit={(values) => {
              const newChanges = getPendingChanges(
                values.milestones,
                milestones,
                pendingChanges
              );

              onFinishEdit({ milestones: newChanges });
            }}
            enableReinitialize
            validationSchema={object({
              milestones: MilestonesSchemaDefinition,
            })}
            validateOnChange
            validateOnMount
          >
            {({ errors, handleReset, submitForm, values }) => {
              return (
                <form>
                  <FieldArray name="milestones">
                    {({ push, remove }) => (
                      <Fragment>
                        <TableContainer
                          style={{
                            maxHeight: TABLE_HEIGHT,
                            marginLeft: -16,
                            width: "calc(100% + 16px)",
                          }}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head" width="5%">
                                  <Checkbox disabled />
                                </TableCell>
                                <TableCell variant="head" width="25%">
                                  {requiredFieldLabel("Milestone Description")}
                                </TableCell>
                                <TableCell variant="head" width="20%">
                                  {requiredFieldLabel("Milestone Amount")}
                                </TableCell>
                                <TableCell variant="head" width="25%">
                                  {requiredFieldLabel("Due Date")}
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Funded
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Status
                                </TableCell>
                                <TableCell variant="head" width="5%" />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.milestones.map((milestone, index) => {
                                const { _id, status, isFunded } = milestone;

                                const suggestedChanges = pendingChanges?.find(
                                  (m) => _id && m._id === _id
                                );

                                const hasSuggestedChanges =
                                  suggestedChanges &&
                                  !isEqual(
                                    pick(milestone, FIXED_PRICE_DIFF_PROPS),
                                    pick(
                                      suggestedChanges,
                                      FIXED_PRICE_DIFF_PROPS
                                    )
                                  );

                                const isNewMilestone =
                                  !_id || !existingMilestonesIds.includes(_id);

                                const isDeletedMilestone =
                                  (pendingChanges?.length ?? 0) > 0 &&
                                  milestone &&
                                  !suggestedChanges;

                                const editableMilestone =
                                  !status ||
                                  (status === MilestoneStatus.PENDING &&
                                    !isFunded);

                                const alerts = alertsMap?.filter(
                                  (m) => m.milestoneId === _id
                                );

                                const mainAlert = first(alerts);
                                const diffAlert = last(alerts);

                                return (
                                  <Fragment key={index}>
                                    {isEditingMilestones &&
                                    editableMilestone ? (
                                      <FixedPriceEditableTableRow
                                        hasBorder={hasSuggestedChanges}
                                        milestone={milestone}
                                        onSelect={(event) =>
                                          handleSelectMilestone(
                                            event,
                                            milestone
                                          )
                                        }
                                        remove={remove}
                                        rowIndex={index}
                                        selected={isMilestoneSelected(
                                          milestone
                                        )}
                                      />
                                    ) : (
                                      <FixedPriceTableRow
                                        actions={
                                          <Fragment>
                                            {(isDeletedMilestone ||
                                              isNewMilestone) &&
                                              canAcceptPendingChanges && (
                                                <MilestoneChangesActions
                                                  onAcceptChanges={() =>
                                                    onAcceptChanges?.(
                                                      _id,
                                                      isNewMilestone &&
                                                        !isDeletedMilestone
                                                    )
                                                  }
                                                  onDenyChanges={() =>
                                                    onDenyChanges?.(
                                                      _id,
                                                      isNewMilestone &&
                                                        !isDeletedMilestone
                                                    )
                                                  }
                                                />
                                              )}
                                            {status ===
                                              MilestoneStatus.IN_REVIEW && (
                                              <Fragment>
                                                <Button
                                                  disabled={isAcceptingWork}
                                                  onClick={() =>
                                                    onRequestChanges?.(_id)
                                                  }
                                                  size="small"
                                                >
                                                  Request Changes
                                                </Button>
                                                <Button
                                                  disabled={isRequestingChanges}
                                                  onClick={() =>
                                                    onAcceptWork?.(_id)
                                                  }
                                                  size="small"
                                                  variant="contained"
                                                >
                                                  Release Payment
                                                </Button>
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        }
                                        alertChip={
                                          mainAlert?.alertIndex && (
                                            <NumberChip
                                              color={
                                                isNewMilestone ||
                                                isDeletedMilestone
                                                  ? "secondary"
                                                  : "primary"
                                              }
                                              label={mainAlert.alertIndex}
                                            />
                                          )
                                        }
                                        firstRow={index === 0}
                                        hasBorder={hasSuggestedChanges}
                                        isSelectable={isFundingMilestones}
                                        milestone={milestone}
                                        onSelect={(event) =>
                                          handleSelectMilestone(
                                            event,
                                            milestone
                                          )
                                        }
                                        selected={isMilestoneSelected(
                                          milestone
                                        )}
                                        showAsDiff={
                                          isNewMilestone || isDeletedMilestone
                                        }
                                      />
                                    )}
                                    {hasSuggestedChanges && (
                                      <FixedPriceDiffTableRow
                                        actions={
                                          canAcceptPendingChanges &&
                                          !isEditingMilestones && (
                                            <MilestoneChangesActions
                                              onAcceptChanges={() =>
                                                onAcceptChanges?.(_id, false)
                                              }
                                              onDenyChanges={() =>
                                                onDenyChanges?.(_id, false)
                                              }
                                            />
                                          )
                                        }
                                        alertChip={
                                          diffAlert?.alertIndex && (
                                            <NumberChip
                                              color="secondary"
                                              label={diffAlert.alertIndex}
                                            />
                                          )
                                        }
                                        firstRow={index === 0}
                                        milestone={suggestedChanges}
                                      />
                                    )}
                                  </Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {isEditingMilestones ? (
                          <Fragment>
                            <Box py={4}>
                              <Divider />
                            </Box>
                            <Box display="flex" gridColumnGap={100}>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() => {
                                    handleReset();
                                    setIsEditingMilestones(false);
                                    setSelectedMilestones([]);
                                  }}
                                  size="small"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={keys(errors).length > 0}
                                  onClick={submitForm}
                                  size="small"
                                  variant="contained"
                                >
                                  Save Changes
                                </Button>
                              </Box>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() =>
                                    push({
                                      _id: uniqueId(),
                                      amount: 0,
                                      description: "",
                                      dueDate: {
                                        type: "weeks",
                                        weeks: 0,
                                        days: 0,
                                      },
                                      chargeId: null,
                                      isFunded: false,
                                      status: MilestoneStatus.PENDING,
                                    })
                                  }
                                  size="small"
                                  variant="contained"
                                >
                                  Add Milestone
                                </Button>
                              </Box>
                            </Box>
                          </Fragment>
                        ) : (
                          <Box height={69} />
                        )}
                        {isFundingMilestones && (
                          <Fragment>
                            <Box py={4}>
                              <Divider />
                            </Box>
                            <Box display="flex" gridColumnGap={100}>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() => {
                                    handleReset();
                                    setIsFundingMilestones(false);
                                    setSelectedMilestones([]);
                                  }}
                                  size="small"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={!roundedAmount}
                                  onClick={() => {
                                    handleReset();
                                    onFundMilestonesHandler();
                                  }}
                                  size="small"
                                  variant="contained"
                                >
                                  Pay ${roundedAmount ?? 0}
                                </Button>
                              </Box>
                            </Box>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        )}
      </Box>
    </Fragment>
  );
};
