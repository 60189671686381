import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { readNotification } from "../../../../api-services/notifications/readNotification";
import { useToast } from "../../../utils/useToast";
import { METADATA_QUERY_KEY } from "../metadata/useMetadataQuery";
import { NOTIFICATIONS_KEY } from "./useNotifications";

export const useReadNotificationMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const { notifySuccess, notifyError } = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    async (notificationId: string) => {
      const { data } = await readNotification(notificationId);

      return data;
    },
    {
      ...mutationOptions,
      onSuccess() {
        queryClient.invalidateQueries(NOTIFICATIONS_KEY);
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
      },
      onError(err: any) {
        notifyError(
          err?.response.data.message ?? "Unexpected error. Please try again."
        );
      },
    }
  );
};
