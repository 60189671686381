import { QueryOptions } from "@testing-library/react";
import { useQuery } from "react-query";
import { fetchUserInquiries } from "../../../../api-services/inquiries";
import { InquiryData } from "../../../../api-types/job-requests/inquiries";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../state/hooks";

export const ACCOUNT_INQUIRIES = "account-inquiries";

export const useAccountInquiries = (options?: QueryOptions) => {
  const { id } = useScopedDowngradedStateValue(useUser());

  return useQuery<InquiryData[], unknown, InquiryData[]>(
    ACCOUNT_INQUIRIES,
    async () => {
      const { data } = await fetchUserInquiries(id ?? "");

      return data;
    },
    {
      ...options,
      enabled: Boolean(id),
    }
  );
};
