import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const InquiriesCommunicationSkeleton: React.FC = () => {
  return (
    <Box>
      <Box pt={2.5} display="flex" justifyContent="space-between">
        <Skeleton variant="rect" animation="wave" width="30%" height={25} />
        <Skeleton variant="rect" animation="wave" width="27%" height={25} />
      </Box>
      <Box pt={5} display="flex" justifyContent="flex-start">
        <Skeleton variant="rect" animation="wave" width="50%" height={40} />
      </Box>
      <Box pt={5} display="flex" justifyContent="flex-end">
        <Skeleton variant="rect" animation="wave" width="50%" height={40} />
      </Box>
      <Box pt={5} display="flex" justifyContent="flex-start">
        <Skeleton variant="rect" animation="wave" width="50%" height={40} />
      </Box>
      <Box pt={10} display="flex">
        <Skeleton variant="rect" animation="wave" width="100%" height={50} />
      </Box>
    </Box>
  );
};
