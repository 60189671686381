import { Box, IconButton, ListItem, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { S3DataOrLoading } from "../../../../api-types/s3";
import { BLUE, COLOR } from "../../../../themes/components/utils";
import theme from "../../../../themes/themes";
import { TextOverflow } from "../TextOverflow";

type DropItemListProps = {
  index: number;
  file: S3DataOrLoading;
  onRemoveFile?: (index: number) => void;
};

const useStyles = makeStyles(() => ({
  listItem: {
    border: `1px solid ${theme.palette.common.white}`,
  },
  icon: {
    "& .MuiSvgIcon-colorPrimary": {
      color: COLOR.RED,
    },
  },
}));

export const DropItemList: React.FC<DropItemListProps> = ({
  index,
  file,
  onRemoveFile,
}) => {
  const styles = useStyles();

  return (
    <Box
      pt={index === 0 ? 0 : 3}
      style={{
        cursor: "pointer",
      }}
    >
      {file.loading ? (
        <Skeleton height="50px" width="100%" variant="rect" />
      ) : (
        <ListItem className={styles.listItem} key={file.key}>
          <TextOverflow width="100%">{file.key}</TextOverflow>
          <IconButton
            className={styles.icon}
            onClick={() => onRemoveFile && onRemoveFile(index)}
          >
            <DeleteIcon htmlColor={theme.palette.primary.contrastText} />
          </IconButton>
        </ListItem>
      )}
    </Box>
  );
};
