import { Box } from "@material-ui/core";
import React from "react";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { MilestoneInvoices } from "./MilestoneInvoices";

export const PaymentReports: React.FC = () => {
  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flexGrow={1}>
          <MilestoneInvoices />
        </Box>
      </Box>
    </PageWrapper>
  );
};
