import { IconButtonClassKey, IconButtonProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiIconButton: MuiComponentThemeDefinition<
  IconButtonClassKey,
  IconButtonProps
> = {
  props: {},
  override: () => ({
    root: { padding: 6 },
    disabled: {
      color: "inherit !important",
      opacity: 0.6,
      pointerEvents: "none",
    },
  }),
};
