import { Box } from "@material-ui/core";
import { FormikValues } from "formik";
import React, { useRef } from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { Section } from "../components";
import { useSignInMutation } from "../hooks/sign-in";
import { usePasswordResetConfirmMutation } from "../hooks/sign-in/usePasswordResetConfirmMutation";
import { usePasswordResetMutation } from "../hooks/sign-in/usePasswordResetMutation";
import { PasswordResetEmailForm } from "./PasswordResetEmailForm";
import { PasswordResetForm } from "./PasswordResetForm";
import { SignInForm } from "./SignInForm";
import { useAutoLogin } from "./useAutoLogin";

export const PasswordResetConfirmation: React.FC = () => {
  const formRef = useRef<FormikValues>();
  const {
    mutate: passwordResetConfirm,
    isLoading,
    isSuccess,
  } = usePasswordResetConfirmMutation();

  return (
    <PageWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box width={400}>
          <PasswordResetForm
            formRef={formRef}
            onSubmit={passwordResetConfirm}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};
