import { useQuery } from "react-query";
import { fetchUnpublishedProjects } from "../../../../api-services/projects/fetchUnpublishedProjects";
import { Project } from "../../../../api-types/projects";

export const UNPUBLISHED_PROJECTS_QUERY_KEY =
  "unpublished-projects-projects-key";

export const useUnpublishedProjectsQuery = () => {
  return useQuery<Project[], unknown, Project[]>(
    UNPUBLISHED_PROJECTS_QUERY_KEY,
    async () => {
      const { data } = await fetchUnpublishedProjects();

      return data;
    }
  );
};
