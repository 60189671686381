import { Box, Button, Divider, Typography } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { format } from "date-fns";
import React, { Fragment, useMemo } from "react";
import { BASE_URL } from "../../../../api-services/utils";
import { MilestoneInvoice } from "../../../../api-types/projects/milestone-invoices";
import { generateLocalId } from "../../../client/projects/payments/Payments";
import {
  CustomTooltip,
  DownloadableDataGrid,
} from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { WithdrawFundsDialog } from "../../../common/components/modals/withdraw-funds-dialog";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";
import { useMilestonesInvoices } from "../../../common/hooks/projects/project/milestone-invoices/useMilestonesInvoices";
import { useStripeAccount } from "../../../common/hooks/stripe-account/useStripeAccount";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { formatInvoiceId } from "../../../utils";
import { useToast } from "../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../job-requests/hooks/feedbackErrors";
import { useWithdrawFundsModal } from "../state/hooks";
import { MilestonesPaymentAndFunding } from "./MilestonesPaymentAndFunding";

export const Payments: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 250,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      sortable: false,
    },
    {
      field: "client",
      headerName: "Client",
      width: 250,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 250,
      sortable: false,
    },
    {
      field: "id",
      headerName: "Invoice ID",
      width: 250,
      sortable: false,
      hide: true,
    },
    { field: "invoice", headerName: "Invoice ID", width: 250, sortable: false },
  ];

  const withdrawFundsDialog = useScopedDowngradedStateValue(
    useWithdrawFundsModal()
  );

  const setWithdrawFundsDialog = useWithdrawFundsModal().set;

  const { notifySuccess, notifyError } = useToast();

  const { data: milestoneInvoices } = useMilestonesInvoices();

  const { data: metadata } = useMetadataQuery();

  const { data, isLoading: isLoadingStripeAccount } = useStripeAccount();

  const rows = useMemo(() => {
    return (
      milestoneInvoices?.map((milestoneInvoice: MilestoneInvoice, index) => ({
        amount: `$${milestoneInvoice.amount}`,
        client: `${milestoneInvoice.client?.accountInformation?.firstName} ${milestoneInvoice.client?.accountInformation?.lastName}`,
        createdAt: format(
          new Date(milestoneInvoice.createdAt),
          formatNominativeMonth
        ),
        description: milestoneInvoice.description,
        id: generateLocalId(
          milestoneInvoice?.description.trim() ?? "UNKNOWN",
          index
        ),
        invoice: formatInvoiceId(milestoneInvoice),
      })) ?? []
    );
  }, [milestoneInvoices]);

  const tooltipTitle = useMemo(() => {
    return (
      <Fragment>
        <Box display="flex" flexDirection="column" gridRowGap={14}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 500,
            }}
            variant="body2"
          >
            All payments are processed by Stripe. Set up your account with
            Stripe Connect in order to be able to withdraw funds to your bank
            account or debit card. You can set up your account from your Account
            Balance page, or by clicking <a href="/account-balance">here</a>.
          </Typography>
        </Box>
      </Fragment>
    );
  }, []);

  const isWithdrawDisabled = isLoadingStripeAccount || !data?.url;

  const handleWithdrawAmount = async (amount: number) => {
    const response = await axios.post(`${BASE_URL}/stripe/withdraw`, {
      amount,
    });

    if (response.status == 200 || response.status == 201) {
      notifySuccess(feedbackMessage("", FEEDBACK_TYPE.MONEY_WITHDRAW));
    } else {
      notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
    }

    setWithdrawFundsDialog({ open: false });
  };

  return (
    <Fragment>
      <Box flexGrow={1}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridColumnGap={12}>
            <Typography
              color="primary"
              component="span"
              style={{
                fontWeight: "bold",
              }}
              variant="subtitle2"
            >
              Available Account Balance
            </Typography>
            <Typography
              component="span"
              style={{
                fontWeight: "bold",
              }}
              variant="subtitle2"
            >
              ${metadata?.balance?.available ?? 0}
            </Typography>
          </Box>
          {isWithdrawDisabled ? (
            <CustomTooltip
              arrow
              interactive
              placement="right"
              title={tooltipTitle}
            >
              <Typography
                color="primary"
                style={{
                  cursor: "pointer",
                  opacity: 0.6,
                }}
              >
                Withdrawals disabled
              </Typography>
            </CustomTooltip>
          ) : (
            <Button
              variant="outlined"
              size="small"
              disabled={isWithdrawDisabled}
              onClick={() => {
                setWithdrawFundsDialog({ open: true });
              }}
            >
              Withdraw
            </Button>
          )}
        </Box>

        <Box py={2.5}>
          <Divider />
        </Box>

        <MilestonesPaymentAndFunding />

        <Box minHeight={400}>
          <DownloadableDataGrid
            columns={columns}
            data={rows}
            title="Milestone Invoices"
          />
        </Box>
      </Box>

      <WithdrawFundsDialog
        open={withdrawFundsDialog.open}
        onClose={() => setWithdrawFundsDialog({ open: false })}
        onConfirm={handleWithdrawAmount}
      />
    </Fragment>
  );
};
