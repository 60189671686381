import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import {
  useLocation,
  useParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import {
  useBoldStyles,
  useWhiteSelectStyles,
} from "../../../../themes/style-hooks";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { ProfessionalSummary } from "../../components/ProfessionalSummary";
import { CustomTextArea } from "../../elements";
import { BackButton } from "../../elements/BackButton";
import { useInviteProfessionalMutation } from "../../hooks/job-requests/job-request/invites";
import { useInvitableJobRequests } from "../../hooks/job-requests/useInvitableJobRequests";
import { useProfessional } from "../../hooks/professionals/professional/useProfessional";
import { menuProps } from "./select/select";

type JobRequestState = {
  jobRequestId: string;
  jobRequestTitle: string;
};

export const InviteProfessional: React.FC = () => {
  const navigate = useNavigate();
  const boldStyles = useBoldStyles();
  const { professionalId } = useParams();
  const { mutate: onInviteProfessional } = useInviteProfessionalMutation();
  const whiteSelectStyles = useWhiteSelectStyles();
  const { state } = useLocation();
  const {
    jobRequestId: initialJobRequestId,
    jobRequestTitle: initialJobRequestTitle,
  } = (state ?? {}) as JobRequestState;
  const [selectedJobRequest, setSelectedJobRequest] = useState({
    jobRequestId: initialJobRequestId ?? "jobRequestId",
    jobRequestTitle: initialJobRequestTitle ?? "jobRequestTitle",
  });
  const { jobRequests } = useInvitableJobRequests();

  const initialValues = {
    jobRequestId: initialJobRequestTitle ?? "Select job request",
    introductoryMessage: "",
  };

  const validationSchema = Yup.object({});

  const onInviteHandler = useCallback(
    (introductoryMessage: string) => {
      onInviteProfessional({
        jobRequestId: selectedJobRequest.jobRequestId,
        jobRequestTitle: selectedJobRequest.jobRequestTitle,
        professionalId,
        introductoryMessage,
      });
    },
    [selectedJobRequest, onInviteProfessional, professionalId]
  );

  const onCancelHandler = useCallback(() => {
    navigate({
      pathname: `/browse`,
      search: `?${createSearchParams({
        type: "professionals",
      })}`,
    });
  }, [navigate]);

  const { data: professional, isLoading: isLoadingProfessional } =
    useProfessional(professionalId);

  const onBackHandler = useCallback(() => {
    history.back();
  }, []);

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onInviteHandler(values?.introductoryMessage ?? "");
        }}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          values,
          isValid,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <Box display="flex" flexDirection="column" height="100%" pt={10}>
              <Box>
                <Box pb={10}>
                  <Typography variant="h1" className={boldStyles.root}>
                    Invitation To Work on a Project
                  </Typography>
                </Box>
                <Typography variant="subtitle2">
                  The Professional will see the Job Request you posted in
                  addition to the information you’ll provide below
                </Typography>
              </Box>

              <Box py={2}>
                <Divider />
              </Box>

              <Box pt={5}>
                <BackButton onClick={onBackHandler} backLabel="Back" />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box pt={5}>
                  <Box display="flex" flexDirection="column" gridRowGap={24}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className={boldStyles.root}
                      >
                        You are inviting
                      </Typography>
                      <ProfessionalSummary professional={professional} />
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className={boldStyles.root}
                      >
                        Select the project you want to invite to
                      </Typography>
                      <Box width={800} pt={4}>
                        <Select
                          IconComponent={() => (
                            <ExpandMoreIcon color="secondary" />
                          )}
                          MenuProps={menuProps}
                          fullWidth
                          value={selectedJobRequest.jobRequestId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={whiteSelectStyles.root}
                        >
                          <MenuItem value="jobRequestId">
                            Select job request
                          </MenuItem>
                          {jobRequests?.map((jobRequest) => (
                            <MenuItem
                              key={jobRequest._id}
                              value={jobRequest._id}
                              selected={
                                selectedJobRequest.jobRequestId ===
                                jobRequest._id
                              }
                              onClick={() =>
                                setSelectedJobRequest({
                                  jobRequestId: jobRequest._id,
                                  jobRequestTitle: jobRequest.title,
                                })
                              }
                            >
                              {jobRequest.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className={boldStyles.root}
                      >
                        Write a custom invitation message
                      </Typography>
                      <Box pt={10}>
                        <Field
                          component={CustomTextArea}
                          placeholder="Invitation message"
                          name="introductoryMessage"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          minRows={5}
                          maxRows={5}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                justifyContent="flex-end"
                alignItems="flex-end"
                flexGrow={1}
                display="flex"
                gridColumnGap={10}
                py={10}
              >
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onCancelHandler}
                >
                  Cancel
                </Button>
                <Button size="medium" variant="contained" type="submit">
                  Send Invitation
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </PageWrapper>
  );
};
