import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { milestoneAcceptChanges } from "../../../../../api-services/projects/milestones";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../utils/useToast";

export const useMilestoneAcceptChanges = (projectId?: string) => {
  const { projectId: paramsProjectId } = useParams();
  const { notifySuccess } = useToast();

  return useMutation(
    ({ milestoneId, isNew }: { milestoneId: string; isNew?: boolean }) =>
      milestoneAcceptChanges(projectId ?? paramsProjectId, milestoneId, isNew),
    {
      onSuccess() {
        notifySuccess(
          feedbackMessage("milestone changes", FEEDBACK_TYPE.ACCEPT)
        );
      },
    }
  );
};
