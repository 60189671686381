import { Box, Typography } from "@material-ui/core";
import { FormikValues, FormikErrors } from "formik";
import React, { ChangeEvent } from "react";
import { BackButton } from "../../elements/BackButton";
import { SignUpInfoForm } from "./SignUpInfoForm";

type SignUpInfoProps = {
  onBack?: () => void;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const SignUpInfo: React.FC<SignUpInfoProps> = ({
  onBack,
  handleBlur,
  handleChange,
  values,
  errors,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box display="flex" justifyContent="space-between" width="80%" pt={10}>
        <Box maxWidth="45%">
          <Typography variant="subtitle1">Welcome!</Typography>
          <br />
          <Typography variant="subtitle1">
            To get started, enter your email address and create a password.
            <br />
            You will receive a confirmation message.
          </Typography>
        </Box>
        <Box width="35%">
          <SignUpInfoForm
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            errors={errors}
          />
        </Box>
      </Box>
    </Box>
  );
};
