import { Box, Divider } from "@material-ui/core";
import React from "react";
import { AccountInformation } from "../../../../api-types/account-information";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { ProfessionalAccountSummaryContent } from "./ProfessionalAccountSummaryContent";
import { ProfessionalAccountSummaryPanel } from "./ProfessionalAccountSummaryPanel";

type ProfessionalAccountSummaryDescriptionProps = {
  accountInformation?: AccountInformation;
  profileInformation?: ProfileInformation;
  isLoading?: boolean;
  averageRating?: number;
  ratingsCount?: number;
};

export const ProfessionalAccountSummaryDescription: React.FC<
  ProfessionalAccountSummaryDescriptionProps
> = ({
  accountInformation,
  profileInformation,
  isLoading,
  averageRating,
  ratingsCount,
}) => {
  return (
    <Box display="flex" width="100%">
      <Box width={700} height="100%" display="flex">
        <ProfessionalAccountSummaryContent
          accountInformation={accountInformation}
          profileInformation={profileInformation}
          isLoading={isLoading}
        />
        <Box height="100%">
          <Divider orientation="vertical" />
        </Box>
      </Box>
      <Box pl={6}>
        <ProfessionalAccountSummaryPanel
          profileInformation={profileInformation}
          averageRating={averageRating}
          ratingsCount={ratingsCount}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
