import { Box, makeStyles, Table } from "@material-ui/core";
import { TableBody } from "@mui/material";
import { LIGHT_BLUE } from "../../../themes/components/utils";
import { PageWrapper } from "../../app/container/PageWrapper";
import { ErrorWrapper, LoadingTypography } from "../../common/components";
import { useJobPosts } from "../../common/hooks/job-posts";
import { SkeletonCollection } from "../../common/professionals-search/browse-mainbar/SkeletonCollection";
import { JobPostsCreditSummary } from "./JobPostsCreditSummary";
import { JobPostsDashboardSkeleton } from "./JobPostsDashboardSkeleton";
import { JobPostsListSummary } from "./JobPostsListSummary";
import { JobPostsTableHead } from "./JobPostsTableHead";
import { JobPostTableRow } from "./JobPostTableRow";

export const useStyles = makeStyles(() => ({
  border: {
    borderTop: `0.5px solid ${LIGHT_BLUE}`,
  },
}));

export const JobPostsDashboard = () => {
  const { data: jobPosts, isLoading } = useJobPosts();

  const hasJobs = (jobPosts?.length ?? 0) > 0;

  return (
    <PageWrapper>
      {/*<Section*/}
      {/*  isLoading={isLoading}*/}
      {/*  sectionProps={{*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "column",*/}
      {/*    gridRowGap: 40,*/}
      {/*    paddingBottom: 40,*/}
      {/*  }}*/}
      {/*>*/}
      <Box
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        flexDirection="row"
        py={10}
        width="100%"
      >
        <Box width="30%">
          <LoadingTypography
            isLoading={isLoading}
            style={{
              fontWeight: 600,
            }}
            typographyProps={{
              variant: "h2",
            }}
          >
            Job Posts
          </LoadingTypography>
        </Box>
        <Box width="60%">
          <JobPostsCreditSummary purchaseButton />
        </Box>
      </Box>

      <Box py={2.5}>
        <JobPostsListSummary />
      </Box>

      <SkeletonCollection
        isLoading={isLoading}
        skeleton={<JobPostsDashboardSkeleton />}
      >
        {hasJobs ? (
          <Table>
            <JobPostsTableHead />

            <TableBody>
              {jobPosts?.map((jobPost) => (
                <JobPostTableRow key={jobPost.jobTitle} jobPost={jobPost} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box py={10}>
            <ErrorWrapper errorMessage="Active job posts you publish will be shown here." />
          </Box>
        )}
      </SkeletonCollection>
      {/*</Section>*/}
    </PageWrapper>
  );
};
