import { useQuery, UseQueryOptions } from "react-query";
import { fetchNotifications } from "../../../../api-services/notifications/fetchNotifications";

export const NOTIFICATIONS_KEY = "notifications";

export enum NotificationStatus {
  PENDING = "pending",
  SEEN = "seen",
}

export enum NotificationType {
  BID_ACCEPTED = "bid_accepted",
  BID_MESSAGE = "bid_message",
  BID_PLACED = "bid_placed",
  BID_WITHDRAWN = "bid_withdrawn",
  CHANGES_ACCEPTED = "changes_accepted",
  WORK_SUBMITTED = "work_submitted",
  CHANGES_DENIED = "changes_denied",
  INQUIRY_PLACED = "inquiry_placed",
  PAYMENT_PLACED = "payment_placed",
  CLOSING_CONFIRMED = "closing_confirmed",
  CLIENT_INVITATION = "client_invitation",
  PROFESSIONAL_INVITATION = "professional_invitation",
  INQUIRY_MESSAGE = "inquiry_message",
  PROJECT_MESSAGE = "project_message",
  JOB_REQUEST_DELETED = "job_request_deleted",
  COMPANY_INVITATION = "company_invitation",
  COMPANY_INVITATION_CANCELED = "company_invitation_canceled",
  COMPANY_INVITATION_REMOVED = "company_invitation_removed",
  COMPANY_INVITATION_ACCEPTED = "company_invitation_accepted",
  COMPANY_INVITATION_REJECTED = "company_invitation_rejected",
}

export type Notification = {
  _id: string;

  entity: string;

  title: string;

  type: NotificationType;

  status: NotificationStatus;
};

export const useNotifications = (
  options?: UseQueryOptions<Notification[], unknown, Notification[]>
) => {
  return useQuery<Notification[], unknown, Notification[]>(
    NOTIFICATIONS_KEY,
    async () => {
      const { data } = await fetchNotifications();

      return data.reverse();
    },
    { ...options }
  );
};
