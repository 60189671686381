import { Box, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { Section, ShowImages, TextOverflow } from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../common/components/SkillsOutput";
import { Description } from "../../../common/professionals-search/projects/project/sections/Description";
import { BidDetailsSkeleton } from "../../bids/BidDetailsSkeleton";
import { AvailabilityCostBids } from "../../job-requests/search-job-requests-list/job-request-item/content";
import { useAccountInquiryQuery } from "../hooks/useAccountInquiry";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: inquiry, isLoading: isLoadingInquiry } =
    useAccountInquiryQuery();

  const skills = useMemo(() => {
    if (inquiry?.jobRequest?.skills && !isLoadingInquiry) {
      return inquiry?.jobRequest?.skills.map((skill) => skill.name);
    }

    return [];
  }, [inquiry?.jobRequest?.skills, isLoadingInquiry]);

  const hasImages = Boolean(inquiry?.jobRequest?.images?.length ?? 0 > 0);

  return (
    <Section
      isLoading={isLoadingInquiry}
      loadingSkeleton={<BidDetailsSkeleton />}
    >
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <TextOverflow
          width="30%"
          style={{
            fontSize: 24,
          }}
          className={boldStyles.root}
        >
          {inquiry?.jobRequest?.title}
        </TextOverflow>
        <Box display="flex" gridColumnGap={40}>
          {inquiry?.jobRequest?.createdAt && (
            <Typography
              variant="body2"
              style={{
                lineHeight: 1,
              }}
            >
              {`Posted ${format(
                new Date(inquiry?.jobRequest?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
          {inquiry?.createdAt && (
            <Typography
              variant="body2"
              style={{
                lineHeight: 1,
              }}
            >
              {`Placed bid ${format(
                new Date(inquiry?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={inquiry?.jobRequest?.description} label="" />
      <Box pt={2.5}>
        <AvailabilityCostBids
          budget={inquiry?.jobRequest?.budget}
          fixedProjectFunding={inquiry?.jobRequest?.fixedProjectFunding}
        />
      </Box>
      {skills.length > 0 && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      <Box py={2.5}>
        {hasImages && (
          <ShowImages selectedFiles={inquiry?.jobRequest?.images} />
        )}
      </Box>
    </Section>
  );
};
