import { Box, BoxProps } from "@material-ui/core";
import React from "react";

type GridWrapperProps = {
  GridContainerProps?: Partial<BoxProps>;
};

export const TWO_COLUMNS = "350px auto";

const defaultGridProps = {
  display: "grid",
  // height: "100%",
  flexGrow: 1,
  minHeight: 0,
  gridTemplateColumns: TWO_COLUMNS,
};

export const GridWrapper: React.FC<GridWrapperProps> = ({
  GridContainerProps = {},
  children,
}) => {
  return (
    <Box {...defaultGridProps} {...GridContainerProps}>
      {children}
    </Box>
  );
};
