import { Box, Typography } from "@material-ui/core";
import { format, add } from "date-fns";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../themes/style-hooks";
import { Estimate } from "../../client/job-requests/create-job-request-wizard/freelance-job-request/BudgetStep";
import { ProjectDueDate } from "../../client/job-requests/create-job-request-wizard/validation-schema";
import { formatNominativeMonth } from "./LoadingTypography";

type TimeframeProps = {
  startDate: Date;
  finishDate?: Date;
  weeksDuration?: ProjectDueDate | Estimate;
  label?: string;
};

export const Timeframe: React.FC<TimeframeProps> = ({
  startDate,
  finishDate,
  weeksDuration,
  label,
}) => {
  const boldStyles = useBoldStyles();

  const endDate = useMemo(() => {
    if (finishDate) {
      return format(finishDate, formatNominativeMonth);
    }

    if (weeksDuration) {
      if ("type" in weeksDuration) {
        if (weeksDuration.type !== "fixed" && weeksDuration.type !== "hourly") {
          return "N/A";
        }

        if (weeksDuration.type === "fixed") {
          const endDate = add(startDate, { weeks: weeksDuration.fixed });

          return format(endDate, formatNominativeMonth);
        }

        const minEndDate = add(startDate, {
          weeks: weeksDuration.estimate.min,
        });
        const maxEndDate = add(startDate, {
          weeks: weeksDuration.estimate.max,
        });

        return `${format(minEndDate, formatNominativeMonth)} - ${format(
          maxEndDate,
          formatNominativeMonth
        )}`;
      }

      const endDate = add(startDate, {
        weeks: weeksDuration.weeks,
        days: weeksDuration.days,
        hours: weeksDuration.hours,
      });

      return format(endDate, formatNominativeMonth);
    }

    return "N/A";
  }, [finishDate, startDate, weeksDuration]);

  return (
    <Box>
      {label && (
        <Typography variant="subtitle1" className={boldStyles.root}>
          {label}
        </Typography>
      )}
      <Box pt={2.5} display="flex" gridColumnGap={24}>
        <Box component="span">
          <Typography
            component="span"
            variant="body2"
            className={boldStyles.root}
          >
            Project Started
          </Typography>{" "}
          <Typography component="span" variant="body2">
            {format(startDate, formatNominativeMonth)}
          </Typography>
        </Box>

        <Box component="span">
          <Typography
            component="span"
            variant="body2"
            className={boldStyles.root}
          >
            Project Scheduled To Be Completed
          </Typography>{" "}
          <Typography component="span" variant="body2">
            {endDate}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
