import { createState } from "@hookstate/core";
import { AppState } from "./types";

export const state = createState<AppState>({
  confirmationModal: {
    open: false,
  },
  currentProjectIndex: 0,

  currentProfessionalIndex: 0,
  completeProjectModal: {
    open: false,
  },
});
