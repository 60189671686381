import { useMutation, useQueryClient } from "react-query";
import { addInteractionMessage } from "../../../../api-services/projects/collaborations/collaboration/addInteractionMessage";
import { InteractionMessageRequest } from "../../../../api-types/job-requests/common";

export const useAddInteractionMessageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ professionalId, messages }: InteractionMessageRequest) =>
      addInteractionMessage(professionalId, messages),
    {
      onSuccess() {},
      onSettled() {
        queryClient.invalidateQueries("interactions");
      },
    }
  );
};
