import { Box, DialogContent, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { noop } from "lodash";
import React, { forwardRef, ReactNode, useCallback, useState } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { ReviewInput } from "../../../../client/projects/payments/ReviewInput";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useDialogStyles } from "../subscribe-dialog/SubscribeDialog";

export type CompleteProjectDialogProps = {
  open: boolean;
  title?: string;
  subtitle?: string;
  confirmLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm: (rating: number, review: string) => void;
};

const useStyles = makeStyles({
  button: {
    height: 64,
    width: "fit-content",
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  title: {
    fontSize: 24,
    ["@media screen and (max-width: 1000px)"]: {
      fontSize: 18,
    },
  },
  subtitle: {
    fontSize: 21,
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: 19,
    },
    ["@media screen and (max-width: 860px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 720px)"]: {
      fontSize: 14,
    },
  },
  textArea: {
    background: "none",
    border: "1px solid",
    borderColor: BLUE,
    color: BLUE,
    fontFamily: "inherit",
    fontSize: 16,
    padding: 8,
    outline: "none",
    width: "100%",
  },
});

export const CompleteProjectDialog = forwardRef<
  HTMLDialogElement,
  CompleteProjectDialogProps
>((props, ref) => {
  const { title, subtitle, onClose, onConfirm, open } = props;
  const styles = useDialogStyles();
  const buttonStyles = useStyles();

  const [review, setReview] = useState("");
  const [rating, setRating] = useState<number>(0);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCloseDialog = useHandleCloseDialog(onClose);

  return (
    <Dialog
      ref={ref}
      open={open}
      className={`${styles.completeProjectDialog}`}
      onClose={handleCloseDialog}
    >
      <Formik initialValues={{ closingDetails: "" }} onSubmit={noop}>
        {({ handleBlur, handleChange, handleSubmit, submitForm, values }) => (
          <form onSubmit={handleSubmit}>
            <Box onClick={handleClose} className={styles.closeIcon}>
              <CloseIcon />
            </Box>
            {title && (
              <DialogTitle className={styles.completeDialogTitle}>
                <Typography
                  color="secondary"
                  style={{
                    fontSize: 24,
                    fontWeight: 550,
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  className={styles.subtitle}
                  color="secondary"
                  variant="body1"
                  style={{ fontWeight: 400 }}
                >
                  {subtitle}
                </Typography>
              </DialogTitle>
            )}
            <DialogContent>
              <ReviewInput
                onChangeReview={(review) => {
                  setReview(review);
                }}
                onChangeRating={(rating) => {
                  setRating(rating);
                }}
                review={review}
                rating={rating}
              />
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="flex-end" gridColumnGap={10}>
                <Button
                  variant="contained"
                  onClick={() => {
                    onConfirm(rating, review);
                  }}
                  style={{
                    backgroundColor: BLUE,
                    color: YELLOW,
                  }}
                  className={buttonStyles.button}
                >
                  Confirm
                </Button>
                <Button
                  style={{
                    color: BLUE,
                    borderColor: BLUE,
                  }}
                  variant="outlined"
                  autoFocus
                  onClick={onClose}
                  className={buttonStyles.button}
                >
                  Continue The Project
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
});
