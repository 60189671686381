import { Formik } from "formik";
import React from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { string } from "yup";
import { AccountType } from "../../../../api-types/common";
import { useToast } from "../../../utils/useToast";
import { useSignUpMutation } from "../../hooks/sign-up";
import { StepActions } from "../common";
import { CurrentClientStep } from "./CurrentClientStep";

const validationSchema = Yup.object().shape({
  email: string()
    .required("Email address is required")
    .email("Valid email required"),
  password: string()
    .required("Password is required")
    .min(6, "Password must contain at least 6 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{6,}[!@#$%^&]$/,
      "Password must contain at least 1 numeric and 1 alpha char, and may only end with special characters ( !@#$%^& )."
    ),
  confirmPassword: string()
    .required("Confirmation password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Password and confirmation password must match"
    ),
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
});

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
};

type SignUpClientProps = {
  onBack?: () => void;
};

export const SignUpClient: React.FC<SignUpClientProps> = ({ onBack }) => {
  const { notifySuccess } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: signUpMutation } = useSignUpMutation({
    onSuccess: () => {
      notifySuccess(
        "Account was successfully created. Please verify your mail"
      );
      const nextStep = Number(searchParams.get("step")) + 1;
      searchParams.set("step", String(nextStep));
      setSearchParams(searchParams);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        signUpMutation({ ...values, type: AccountType.CLIENT });
      }}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        isValid,
        errors,
        submitForm,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          <CurrentClientStep
            onBack={onBack}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
          />
          <StepActions
            submitStep={2}
            onSubmit={submitForm}
            isDisabled={!isValid}
          />
        </form>
      )}
    </Formik>
  );
};
