import { useMemo } from "react";
import { useReceivedClientInvitations } from "../../../common/hooks/job-requests/job-request/invites/useReceivedClientInvitations";
import { useAccountInquiries } from "../../inquiries/hooks/useAccountInquiries";

export const useAccountInquiriesOptions = () => {
  const { data: accountInquiries, isLoading: isLoadingAccountInquiries } =
    useAccountInquiries();

  const { data: invitations, isLoading: isLoadingInvitations } =
    useReceivedClientInvitations();

  const filteredInquiries = useMemo(() => {
    const invitationsJobIds = invitations?.map(
      ({ jobRequestId }) => jobRequestId
    );

    return accountInquiries?.filter(
      ({ jobRequest }) => !invitationsJobIds?.includes(jobRequest._id)
    );
  }, [accountInquiries, invitations]);

  const data = useMemo(() => {
    return filteredInquiries?.map((accountInquiry) => ({
      ...accountInquiry,

      title: accountInquiry?.jobRequest?.title,
      id: accountInquiry._id,
    }));
  }, [filteredInquiries]);

  return {
    data,
    isLoading: isLoadingAccountInquiries || isLoadingInvitations,
  };
};
