import { Box } from "@material-ui/core";
import { FormikValues } from "formik";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { BackButton } from "../elements/BackButton";
import { useNavigateBack } from "../hooks/navigation";
import { usePasswordResetMutation } from "../hooks/sign-in/usePasswordResetMutation";
import { PasswordResetEmailForm } from "./PasswordResetEmailForm";
import { useAutoLogin } from "./useAutoLogin";

export const PasswordReset: React.FC = () => {
  const formRef = useRef<FormikValues>();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  useAutoLogin();

  const { mutate: passwordReset } = usePasswordResetMutation();

  return (
    <PageWrapper>
      <Box pt={10}>
        <BackButton onClick={navigateBack} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box width={400}>
          <PasswordResetEmailForm
            formRef={formRef}
            onTriggerReset={passwordReset}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};
