import { useQuery, UseQueryOptions } from "react-query";
import { fetchMetadata } from "../../../../api-services/metadata";
import { Metadata } from "../../../../api-types/common";

export const METADATA_QUERY_KEY = "metadata-key";

export const useMetadataQuery = (
  options?: UseQueryOptions<Metadata, unknown, Metadata>
) => {
  return useQuery<Metadata, unknown, Metadata>(
    METADATA_QUERY_KEY,
    async () => {
      const { data } = await fetchMetadata();

      return data;
    },
    {
      ...options,
      refetchInterval: 1000 * 10, // this is for showing the notification count in the header (10 seconds)
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      staleTime: 0,
    }
  );
};
