import { Box, Button } from "@material-ui/core";
import React from "react";

type EditDetailsFormActionsProps = {
  setIsEditing: (value: boolean) => void;
  disabledSubmit?: boolean;
};

export const EditDetailsFormActions: React.FC<EditDetailsFormActionsProps> = ({
  setIsEditing,
  disabledSubmit = false,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" gridColumnGap={10} py={10}>
      <Button
        size="medium"
        variant="outlined"
        onClick={() => {
          setIsEditing(false);
        }}
      >
        Cancel
      </Button>
      <Button
        size="medium"
        variant="contained"
        type="submit"
        disabled={disabledSubmit}
      >
        Save Changes
      </Button>
    </Box>
  );
};
