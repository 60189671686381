import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { milestoneSubmitWork } from "../../../../../api-services/projects/milestones";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { ActualWork } from "../../../../professional/projects/payments/HourlyRateMilestonesTable";
import { useToast } from "../../../../utils/useToast";

export type SubmitMilestoneConfig = {
  milestoneId: string;
  actualWork?: ActualWork;
};

export const useMilestoneSubmitWork = (projectId?: string) => {
  const { projectId: paramsProjectId } = useParams();
  const { notifySuccess } = useToast();

  return useMutation(
    (submitMilestoneConfig: SubmitMilestoneConfig) =>
      milestoneSubmitWork(projectId ?? paramsProjectId, submitMilestoneConfig),
    {
      onSuccess() {
        notifySuccess(feedbackMessage("milestone work", FEEDBACK_TYPE.SUBMIT));
      },
    }
  );
};
