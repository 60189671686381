import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const JobPostsDashboardSkeleton: React.FC = () => {
  return (
    <Box pt={15} display="flex" flexDirection="column" gridRowGap={24}>
      <Box display="flex" justifyContent="space-between">
        <Skeleton animation="wave" variant="rect" width="20%" height={50} />
        <Skeleton animation="wave" variant="rect" width="20%" height={50} />
        <Skeleton animation="wave" variant="rect" width="20%" height={50} />
        <Skeleton animation="wave" variant="rect" width="15%" height={50} />
        <Skeleton animation="wave" variant="rect" width="20%" height={50} />
      </Box>
      <Skeleton animation="wave" variant="rect" height={60} />
      <Skeleton animation="wave" variant="rect" height={60} />
      <Skeleton animation="wave" variant="rect" height={60} />
      <Skeleton animation="wave" variant="rect" height={60} />
      <Skeleton animation="wave" variant="rect" height={60} />
    </Box>
  );
};
