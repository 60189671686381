import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteBid } from "../../../../../api-services/bids/bid/deleteBid";
import { ACCOUNT_BIDS } from "../../hooks/useAccountBids";

export const useBidDeleteMutation = (
  options?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteBid(id), {
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries(ACCOUNT_BIDS);
    },
  });
};
