import { useQuery } from "react-query";
import { fetchProfileInformation } from "../../../../api-services/profile-information";
import { ProfileInformation } from "../../../../api-types/profile-information";

export const PROFILE_INFORMATION_QUERY_KEY = "profile-information";

export const useProfileInformation = () => {
  return useQuery<ProfileInformation>(
    PROFILE_INFORMATION_QUERY_KEY,
    async () => {
      const { data } = await fetchProfileInformation();

      return data;
    }
  );
};
