import { SchemaOf, boolean, number, object, string } from "yup";

export type RatePerHourFilterFormData = {
  checked: boolean;
  estimate: {
    min: number;
    max: number;
  };
  currency: string;
};

export type RatePerHourFilterSchema = SchemaOf<RatePerHourFilterFormData>;

export const ratePerHourFilterSchema: RatePerHourFilterSchema = object({
  checked: boolean().default(false).required(),
  estimate: object({
    min: number().default(0).min(0).max(999).required(),
    max: number().default(999).min(0).max(999).required(),
  }),
  currency: string().default("USD").required(),
});
