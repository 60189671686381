import {
  Box,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import React, { forwardRef, ReactNode, useCallback, useState } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useDialogStyles } from "../subscribe-dialog/SubscribeDialog";

export type PaymentRequestDialogProps = {
  open: boolean;
  title?: string;
  confirmLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm?: (
    dueDate: number,
    hoursPerWeek: number,
    costPerHour: number
  ) => void;
};

const useStyles = makeStyles({
  button: {
    height: 64,
    width: "fit-content",
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
});

export const PaymentRequestDialog = forwardRef<
  HTMLDialogElement,
  PaymentRequestDialogProps
>((props, ref) => {
  const styles = useDialogStyles();
  const buttonStyles = useStyles();
  const { title, onClose, onConfirm, confirmLabel, open } = props;

  const [dueDate, setDueDate] = useState(0);
  const [costPerHour, setCostPerHour] = useState(0);
  const [hoursPerWeek, setHoursPerWeek] = useState(0);

  const handleConfirm = () => {
    onConfirm?.(dueDate, hoursPerWeek, costPerHour);
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCloseDialog = useHandleCloseDialog(onClose);

  return (
    <Dialog
      ref={ref}
      open={open}
      className={`${styles.completeProjectDialog} `}
      onClose={handleCloseDialog}
    >
      <Box onClick={handleClose} className={styles.closeIcon}>
        <CloseIcon />
      </Box>
      {title && (
        <DialogTitle>
          <Typography
            color="secondary"
            style={{
              fontSize: 24,
              fontWeight: 550,
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gridRowGap={4}
        >
          <Box width={500} display="flex" alignItems="center" gridColumnGap={8}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 200 }}
              color="secondary"
              component="span"
              noWrap
            >
              Actual Due Date
            </Typography>
            <Box width={150}>
              <TextField
                size="small"
                fullWidth
                value={dueDate}
                onChange={(e) => setDueDate(Number(e?.target?.value) ?? 0)}
              />
            </Box>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 100 }}
              color="secondary"
              component="span"
            >
              weeks
            </Typography>
          </Box>
          <Box width={500} display="flex" alignItems="center" gridColumnGap={8}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 200 }}
              color="secondary"
              component="span"
            >
              Actual Hours/Week
            </Typography>
            <Box width={150}>
              <TextField
                size="small"
                fullWidth
                value={hoursPerWeek}
                onChange={(e) => setHoursPerWeek(Number(e?.target?.value) ?? 0)}
              />
            </Box>
            <Typography
              variant="subtitle2"
              color="secondary"
              style={{ fontWeight: 500, width: 100 }}
              component="span"
            >
              hours
            </Typography>
          </Box>
          <Box width={500} display="flex" alignItems="center" gridColumnGap={8}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 500, width: 200 }}
              color="secondary"
              component="span"
            >
              Actual Cost/Hour
            </Typography>
            <Box width={150}>
              <TextField
                fullWidth
                size="small"
                value={costPerHour}
                onChange={(e) => setCostPerHour(Number(e?.target?.value) ?? 0)}
              />
            </Box>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 500, width: 100 }}
              color="secondary"
              component="span"
            >
              $
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gridColumnGap={10}>
          <Button
            variant="contained"
            onClick={onClose}
            className={buttonStyles.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: BLUE,
              color: YELLOW,
            }}
            className={buttonStyles.button}
            onClick={handleConfirm}
            autoFocus
          >
            {confirmLabel}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
