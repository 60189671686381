import { Box, Button, CircularProgress } from "@material-ui/core";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../../../api-services/utils";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useJobRequestFundMilestonesMutation } from "../../common/hooks/job-requests/job-request/useJobRequestFundMilestonesMutation";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../utils/useToast";

type PaymentFormProps = {
  amount: number;
};

type LocationState = {
  backRoute: string;
  search: string;
};

export const PaymentForm: React.FC<PaymentFormProps> = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { notifyError } = useToast();

  const { mutate: fundJobRequestMilestones } =
    useJobRequestFundMilestonesMutation({
      onSuccess: () => {
        if (state) {
          navigate(
            {
              pathname: (state as LocationState).backRoute,
              search: `${(state as LocationState).search}&isFunded=true`,
            },
            {
              state: undefined,
            }
          );
        }
      },
    });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (cardNumberElement) {
      // use stripe.createToken to get a unique token for the card
      const { error, token } = await stripe.createToken(cardNumberElement);

      if (!error && token) {
        // Backend is not implemented yet, but once there isn’t any errors,
        // you can pass the token and payment data to the backend to complete
        // the charge
        axios
          .post(`${BASE_URL}/stripe/pay`, {
            token: token.id,
            currency: "USD",
            price: amount * 100,
          })
          .then((charge: any) => {
            const milestonesIds = searchParams.get("milestonesIds");

            if (milestonesIds) {
              const milestones = JSON.parse(milestonesIds);
              fundJobRequestMilestones(
                {
                  milestones,
                  isHourly: Boolean(searchParams.get("isHourly")),
                  chargeId: charge.data.id,
                  professionalId:
                    searchParams.get("professionalId") ?? undefined,
                },
                {
                  onSettled: () => {
                    setIsLoading(false);
                  },
                }
              );
            }
          })
          .catch(() => {
            notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        notifyError(error?.message ?? "");
      }
    }
  };

  const cardStyle = {
    style: {
      base: {
        paddingTop: 10,
        paddingBottom: 10,
        height: "40px !important",
        iconColor: "#fff",
        color: "#fff",
        fontWeight: 400,
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "#BFAEF6",
        },
        ":-webkit-autofill": {
          color: "#fce883",
        },
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE",
      },
    },
  };

  return (
    <Box width={300}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <Box
          style={{
            padding: 10,
            borderRadius: 10,
            border: "1px solid white",
          }}
        >
          <CardNumberElement options={cardStyle} />
        </Box>
        <Box display="flex" width="100%" justifyContent="space-between" pt={5}>
          <Box
            width="50%"
            style={{
              padding: 10,
              borderRadius: 10,
              border: "1px solid white",
            }}
          >
            <CardExpiryElement options={cardStyle} />
          </Box>
          <Box
            width="45%"
            style={{
              padding: 10,
              borderRadius: 10,
              border: "1px solid white",
            }}
          >
            <CardCvcElement options={cardStyle} />
          </Box>
        </Box>
        <Box pt={5}>
          <Button
            type="submit"
            style={{
              cursor: "pointer",
              width: "100%",
              height: 45,
              backgroundColor: BLUE,
              borderRadius: 12,
              border: `1px solid ${YELLOW}`,
              color: YELLOW,
            }}
            endIcon={isLoading ? <CircularProgress size={16} /> : null}
          >
            Pay ${amount}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
