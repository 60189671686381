import { Box, DialogContent, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import React, { forwardRef, ReactNode, useCallback } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useDialogStyles } from "../subscribe-dialog/SubscribeDialog";

export type ConfirmationDialogProps = {
  open: boolean;
  title?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm?: () => void;
  hasMoreLabel?: boolean;
  customHeader?: boolean;
};

const useStyles = makeStyles({
  button: {
    height: 64,
    width: "fit-content",
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  title: {
    fontSize: 24,
    ["@media screen and (max-width: 1000px)"]: {
      fontSize: 18,
    },
  },
  subtitle: {
    fontSize: 21,
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: 19,
    },
    ["@media screen and (max-width: 860px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 720px)"]: {
      fontSize: 14,
    },
  },
});

export const ConfirmationDialog = forwardRef<
  HTMLDialogElement,
  ConfirmationDialogProps
>((props, ref) => {
  const {
    title,
    message,
    onClose,
    onConfirm,
    confirmLabel = "Confirm Withdrawal",
    cancelLabel = "Cancel",
    hasMoreLabel,
    customHeader,
    open,
  } = props;
  const styles = useDialogStyles(Boolean(message));
  const buttonStyles = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCloseDialog = useHandleCloseDialog(onClose);

  return (
    <Dialog
      ref={ref}
      open={open}
      className={hasMoreLabel ? styles.customModal : styles.fullWidth}
      onClose={handleCloseDialog}
    >
      <Box onClick={handleClose} className={styles.closeIcon}>
        <CloseIcon />
      </Box>
      {title && (
        <DialogTitle>
          <Typography
            color="secondary"
            style={{
              fontSize: 24,
              fontWeight: 550,
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
      )}
      {message && (
        <DialogContent className={styles.dialogContent}>
          {customHeader && (
            <Box height={100} border="2px solid black">
              <Typography
                color="secondary"
                style={{
                  paddingLeft: 10,
                  paddingTop: 10,
                  fontSize: 24,
                  fontWeight: 550,
                }}
              >
                Your money is currently deposited in your Stripe account. You
                cannot delete your account until your money is withdrawn. Please
                withdraw your money to your bank account to be able to delete
                your account.{" "}
              </Typography>
            </Box>
          )}
          {hasMoreLabel && (
            <Box pb={5} pt={5}>
              <Typography
                color="secondary"
                style={{
                  fontSize: 21,
                  fontWeight: 600,
                }}
              >
                More Information
              </Typography>
            </Box>
          )}
          <Typography
            color="secondary"
            style={{
              whiteSpace: "pre-line",
              marginTop: -15,
              fontSize: 18,
              fontWeight: "normal",
            }}
          >
            {message}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gridColumnGap={10}>
          <Button
            variant="outlined"
            style={{
              color: BLUE,
              borderColor: BLUE,
            }}
            onClick={onClose}
            className={buttonStyles.button}
            data-cy-btn="cancel"
          >
            {cancelLabel}
          </Button>
          {Boolean(onConfirm) && (
            <Button
              style={{
                backgroundColor: BLUE,
                color: YELLOW,
              }}
              variant="contained"
              onClick={onConfirm}
              autoFocus
              className={buttonStyles.button}
              data-cy-btn="confirm"
            >
              {confirmLabel}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
});
