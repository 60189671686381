import { Box } from "@material-ui/core";
import React from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { InfoBox } from "../../common/components/InfoBox";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { SearchMainbar } from "./search-form";
import { JobRequestsList } from "./search-job-requests-list";

export const SearchJobsPage: React.FC = () => {
  const { data: metadata } = useMetadataQuery();

  // const showJobs = window.sessionStorage.getItem("showJobRequests") === "1";

  // const showTabs = isFreelanceProjectsSelected && isJobPostsSelected;
  //
  // const isFreelanceTabSelected =
  //   (searchParams.get("tab") ?? "freelance") === "freelance";
  //
  // const itemsList = useMemo(() => {
  //   if (isFreelanceProjectsSelected && isJobPostsSelected) {
  //     return isFreelanceTabSelected ? <JobRequestsList /> : <JobPostsList />;
  //   }
  //
  //   if (isFreelanceProjectsSelected && !isJobPostsSelected) {
  //     return <JobRequestsList />;
  //   }
  //
  //   return <JobPostsList />;
  // }, [isFreelanceProjectsSelected, isFreelanceTabSelected, isJobPostsSelected]);

  return (
    <PageWrapper>
      {metadata?.restricted && (
        <Box py={2}>
          <InfoBox
            messages={
              " This account is restricted. Placing bids will require having position, and description. Please finish your profile to remove all restrictions."
            }
          />
        </Box>
      )}
      <Box height="100%" display="flex" flexDirection="column">
        <SearchMainbar />

        {/*{showTabs && (*/}
        {/*  <Box display="flex" gridColumnGap={16}>*/}
        {/*    <Card*/}
        {/*      isLoading={isLoading}*/}
        {/*      isSelected={isFreelanceTabSelected}*/}
        {/*      onClick={() => {*/}
        {/*        searchParams.set("tab", "freelance");*/}
        {/*        setSearchParams(searchParams);*/}
        {/*      }}*/}
        {/*      label="Freelance Projects"*/}
        {/*      width={190}*/}
        {/*    />*/}
        {/*    <Card*/}
        {/*      isLoading={isLoading}*/}
        {/*      isSelected={!isFreelanceTabSelected}*/}
        {/*      onClick={() => {*/}
        {/*        searchParams.set("tab", "jobs");*/}
        {/*        setSearchParams(searchParams);*/}
        {/*      }}*/}
        {/*      label={`Job Posts`}*/}
        {/*      width={130}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*)}*/}
        {/*{showJobs ? (*/}
        <Box flexGrow={1} pt={1}>
          <JobRequestsList />
        </Box>
        {/*) : (*/}
        {/*  <Box*/}
        {/*    bgcolor={YELLOW}*/}
        {/*    flexGrow={1}*/}
        {/*    minHeight={300}*/}
        {/*    maxHeight={300}*/}
        {/*    mt={20}*/}
        {/*    mx={-15}*/}
        {/*    px={15}*/}
        {/*    py={8}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      color="secondary"*/}
        {/*      variant="subtitle1"*/}
        {/*      style={{ fontWeight: 700 }}*/}
        {/*    >*/}
        {/*      Job Requests Notification*/}
        {/*    </Typography>*/}
        {/*    <Typography*/}
        {/*      color="secondary"*/}
        {/*      variant="body1"*/}
        {/*      style={{ fontWeight: 500 }}*/}
        {/*    >*/}
        {/*      The site is in an early access stage. For the time being we will*/}
        {/*      be automatically notifying you of job requests that match your*/}
        {/*      skills.*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*)}*/}
      </Box>
    </PageWrapper>
  );
};
