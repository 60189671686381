import axios from "axios";
import { inviteProUrl } from "../../utils";

export const inviteProfessional = async (params: {
  jobRequestId: string;
  jobRequestTitle: string;
  professionalId: string;
  introductoryMessage: string;
}) => {
  const { jobRequestId, jobRequestTitle, professionalId, introductoryMessage } =
    params;

  return axios.post(inviteProUrl(jobRequestId), {
    professional: professionalId,
    introductoryMessage,
    jobRequestTitle,
  });
};
