import { useQuery } from "react-query";
import { fetchSavedJobRequests } from "../../../../api-services/saved-job-requests";
import { JobRequest } from "../../../../api-types/job-requests/job-request";

export const SAVE_JOB_REQUESTS_QUERY_KEY = "saved-job-requests";

export const useSavedJobRequests = () => {
  return useQuery<JobRequest[], unknown, JobRequest[]>(
    SAVE_JOB_REQUESTS_QUERY_KEY,
    async () => {
      const { data } = await fetchSavedJobRequests();

      return data;
    }
  );
};
