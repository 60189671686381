import { ListItemClassKey, ListItemProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE, YELLOW, LIGHT_BLUE } from "./utils";

export const MuiListItem: MuiComponentThemeDefinition<
  ListItemClassKey,
  ListItemProps
> = {
  props: {},
  override: () => ({
    root: {
      height: 50,
      justifyContent: "center",
      fontSize: 18,
    },
    button: {
      "&:hover": {
        color: `${YELLOW} !important`,
        backgroundColor: `${BLUE} !important`,
      },
    },
    divider: {
      borderBottomColor: LIGHT_BLUE,
    },
  }),
};
