import { Box, Typography } from "@material-ui/core";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import React, { useCallback, useMemo, useState } from "react";

import { AccountType } from "../../../api-types/common";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useUser } from "../../state/hooks";
import { Section } from "../components";
import {
  Notification,
  NotificationType,
  useNotifications,
} from "../hooks/notifications/useNotifications";
import { useScopedDowngradedStateValue } from "../hooks/utils/useScopedDowngradedStateValue";
import { PaginationFooter } from "../professionals-search/PaginationFooter";
import { NotificationWrapper } from "./NotificationWrapper";

const defaultRowsPerPage = 8;
const itemsPerRow = 1;

export const NotificationsPage: React.FC = () => {
  const { data: notifications, isLoading } = useNotifications({
    refetchInterval: 1000 * 10, // refetch every 10 seconds
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
  const { accountType } = useScopedDowngradedStateValue(useUser());

  // const buildNotificationUrl = useCallback(
  //   (notification: Notification) => {
  //     switch (notification.type) {
  //       case NotificationType.WORK_SUBMITTED:
  //         return `/projects/${notification.entity}/?tab=payments`;
  //       case NotificationType.CHANGES_DENIED:
  //         return `/projects/${notification.entity}/?tab=payments`;
  //       case NotificationType.CHANGES_ACCEPTED:
  //         return `/projects/${notification.entity}/?tab=payments`;
  //       case NotificationType.BID_ACCEPTED:
  //         return `/bids/${notification.entity}/?tab=details`;
  //       case NotificationType.BID_PLACED:
  //         return `/job-requests/${notification.entity}/?tab=bids`;
  //       case NotificationType.PROJECT_MESSAGE:
  //         return `/projects/${notification.entity}/?tab=replies`;
  //       case NotificationType.BID_MESSAGE:
  //         const prefix =
  //           accountType == AccountType.CLIENT ? "job-requests" : "bids";
  //         const suffix =
  //           accountType == AccountType.CLIENT ? "?tab=bids" : "?tab=replies";
  //         return `/${prefix}/${notification.entity}/${suffix}`;
  //       case NotificationType.INQUIRY_MESSAGE:
  //         const prefixInquiry =
  //           accountType == AccountType.CLIENT ? "job-requests" : "inquiries";
  //         const suffixInquiry =
  //           accountType == AccountType.CLIENT
  //             ? "?tab=inquiries"
  //             : "?tab=replies";
  //         return `/${prefixInquiry}/${notification.entity}/${suffixInquiry}`;
  //       case NotificationType.INQUIRY_PLACED:
  //         return `/job-requests/${notification.entity}/?tab=inquiries`;
  //       case NotificationType.CLOSING_CONFIRMED:
  //         return `/projects/history/${notification.entity}/?tab=details`;
  //       case NotificationType.PAYMENT_PLACED:
  //         return `/projects/${notification.entity}/?tab=payments`;
  //       case NotificationType.CLIENT_INVITATION:
  //         return `/invitations/${notification.entity}/?tab=details`;
  //       case NotificationType.PROFESSIONAL_INVITATION:
  //         return `/professional-referrals/${notification.entity}/?tab=details`;
  //       default:
  //         return "";
  //     }
  //   },
  //   [accountType]
  // );

  const buildNotification = useCallback(
    (notification: Notification) => {
      const { type, title = "" } = notification;

      switch (type) {
        case NotificationType.CHANGES_ACCEPTED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              Changes were accepted for the milestones on <b>project</b> -{" "}
              <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.CHANGES_DENIED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              Changes were denied for the milestones on <b>project</b> -{" "}
              <b>{title}</b>{" "}
            </NotificationWrapper>
          );
        case NotificationType.WORK_SUBMITTED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              Work has been submitted for <b>project</b> - <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.BID_ACCEPTED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              A <b>bid</b> you placed for <b>job request</b>, <b>{title}</b>,
              was <b>accepted</b> by the client
            </NotificationWrapper>
          );
        case NotificationType.BID_MESSAGE:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              {accountType === AccountType.PROFESSIONAL ? (
                <Box>You received a new message on your bid. </Box>
              ) : (
                <Box>You received a new message on your Job Request's bid.</Box>
              )}
            </NotificationWrapper>
          );
        case NotificationType.INQUIRY_MESSAGE:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              {accountType === AccountType.PROFESSIONAL ? (
                <Box>You received a reply to your inquiry.</Box>
              ) : (
                <Box>You received a reply to your Job Request's inquiry.</Box>
              )}
            </NotificationWrapper>
          );
        case NotificationType.BID_PLACED:
          return (
            <NotificationWrapper
              icon={<GroupOutlinedIcon color="primary" fontSize="medium" />}
            >
              A <b>job request</b> you created, <b>{title}</b>, received a new{" "}
              <b>bid</b>
            </NotificationWrapper>
          );
        case NotificationType.INQUIRY_PLACED:
          return (
            <NotificationWrapper
              icon={<GroupOutlinedIcon color="primary" fontSize="medium" />}
            >
              A <b>job request</b> you created, <b>{title}</b>, received a new{" "}
              <b>inquiry</b>
            </NotificationWrapper>
          );
        case NotificationType.CLOSING_CONFIRMED:
          return (
            <NotificationWrapper
              icon={<GroupOutlinedIcon color="primary" fontSize="medium" />}
            >
              A <b>project</b> closing, <b>{title}</b>, was confirmed by the{" "}
              <b>professional</b>
            </NotificationWrapper>
          );

        case NotificationType.PAYMENT_PLACED:
          return (
            <NotificationWrapper
              icon={<GroupOutlinedIcon color="primary" fontSize="medium" />}
            >
              The <b>professional</b> working on your on-going <b>project</b>,{" "}
              <b>{title}</b>, requested a <b>payment</b>
            </NotificationWrapper>
          );
        case NotificationType.CLIENT_INVITATION:
          return (
            <NotificationWrapper
              icon={<GroupOutlinedIcon color="primary" fontSize="medium" />}
            >
              A <b>client</b> has invited you to his <b>job request</b>,{" "}
              <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.PROFESSIONAL_INVITATION:
          return (
            <NotificationWrapper
              icon={<GroupOutlinedIcon color="primary" fontSize="medium" />}
            >
              A <b>professional</b> has referred you to an on-going{" "}
              <b>project</b>, <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.PROJECT_MESSAGE:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              You received a new message on your on-going project,{" "}
              <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.COMPANY_INVITATION_CANCELED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              A <b>user</b> has canceled the invitation to join his{" "}
              <b>company</b>, <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.COMPANY_INVITATION_REMOVED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              A <b>user</b> has removed you from his <b>company</b>,{" "}
              <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.COMPANY_INVITATION_ACCEPTED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              A <b>user</b> has accepted the invitation to join your{" "}
              <b>company</b>, <b>{title}</b>
            </NotificationWrapper>
          );
        case NotificationType.COMPANY_INVITATION_REJECTED:
          return (
            <NotificationWrapper
              icon={
                <BusinessCenterOutlinedIcon color="primary" fontSize="medium" />
              }
            >
              A <b>user</b> has rejected the invitation to join your{" "}
              <b>company</b>, <b>{title}</b>
            </NotificationWrapper>
          );
        default:
          return "";
      }
    },
    [accountType]
  );

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return notifications
      ? notifications.slice(endIndex - itemsCount, endIndex)
      : [];
  }, [currentPage, notifications]);

  const totalPages = useMemo(
    () =>
      notifications
        ? Math.round(notifications.length / (defaultRowsPerPage * itemsPerRow))
        : 0,
    [notifications]
  );

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const errorMessage = useMemo(() => {
    return !isLoading && notifications?.length
      ? ""
      : "Notifications list is empty";
  }, [notifications?.length, isLoading]);

  return (
    <PageWrapper>
      <Section isLoading={isLoading} errorMessage={errorMessage}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box py={10}>
            <Typography variant="h1">Notifications</Typography>
          </Box>
          <Box
            flexGrow={1}
            gridRowGap={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {currentPageFilteredItems?.map((notification) =>
              buildNotification(notification)
            )}
          </Box>
          <Box py={10}>
            <PaginationFooter
              rowsPerPage={defaultRowsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handleChangePage}
              totalCount={notifications?.length}
              objectNamePlural="notifications"
              objectNameSingular="notification"
            />
          </Box>
        </Box>
      </Section>
    </PageWrapper>
  );
};
