import axios from "axios";
import { milestoneAcceptChangesUrl } from "../../utils";

export const milestoneAcceptChanges = (
  projectId: string,
  milestoneId: string,
  isNew?: boolean
) => {
  return axios.post(
    milestoneAcceptChangesUrl(projectId, milestoneId),
    {},
    { params: { isNew } }
  );
};
