import { useMemo } from "react";
import { usePastProjectsQuery } from "../../../common/hooks/projects/usePastProjectsQuery";

export const usePastProjectsOptions = (id?: string) => {
  const { data: projects, isLoading } = usePastProjectsQuery(id);

  const data = useMemo(() => {
    return (
      projects?.map((project) => {
        return {
          ...project,
          title: project?.title,
          id: project?._id,
        };
      }) ?? []
    );
  }, [projects]);

  return { data, isLoading };
};
