import { useQuery } from "react-query";
import { fetchWithdrawalHistory } from "../../../../../../api-services/withdrawal-history/fetchWithdrawalHistory";
import { WithdrawalHistoryItem } from "../../../../../../api-types/projects/withdrawal-history";

export const withdrawalHistoryKey = "withdrawal-history-key";

export const useWithdrawalHistory = () => {
  return useQuery<WithdrawalHistoryItem[], unknown, WithdrawalHistoryItem[]>(
    withdrawalHistoryKey,
    async () => {
      const { data } = await fetchWithdrawalHistory();

      return data;
    }
  );
};
