import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  usePointerStyles,
  useBoldStyles,
} from "../../../../themes/style-hooks";

export const Collaboration: React.FC = () => {
  const pointerStyles = usePointerStyles();
  const navigate = useNavigate();
  const boldStyles = useBoldStyles();
  const { projectId } = useParams();

  return (
    <Box display="flex" flexDirection="column" gridRowGap={20}>
      <Typography variant="subtitle1" className={boldStyles.root}>
        Do you have a professional you’d like to recommend and collaborate with?
      </Typography>
      <Box
        onClick={() => {
          navigate(`/professional-referral/?projectId=${projectId}`);
        }}
        className={pointerStyles.root}
      >
        <Typography variant="subtitle2" color="primary">
          Yes, I want to refer a professional
        </Typography>
      </Box>
    </Box>
  );
};
