import { Box, useMediaQuery } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BLUE,
  MAX_VIEWPORT_WIDTH,
  YELLOW,
} from "../../../../../themes/components/utils";
import { Step } from "../../../../common/elements/Step";
import { TheTalentedLogo } from "../../../../common/logo";
import { useJobPackages } from "../hooks/useJobPackages";

type StepType = {
  label: string;
  color?: string;
};

const stepNamesShortFlow: StepType[] = [
  { label: "Type of Job Post" },
  { label: "Set Up Job Post" },
];

const stepNames: StepType[] = [
  { label: "Type of Job Post" },
  { label: "Select Job Package" },
  { label: "Set Up Job Post" },
  { label: "Billing Details" },
];

export const JobPostWizardProgressBar: React.FC = () => {
  const [searchParams] = useSearchParams();

  const smallWidth = useMediaQuery("(max-width: 1440px)");

  const currentStepIndex = Number(searchParams.get("step"));

  const { jobPostsCredits } = useJobPackages();

  const steps = useMemo(() => {
    return jobPostsCredits > 0 ? stepNamesShortFlow : stepNames;
  }, [jobPostsCredits]);

  return (
    <Box
      height={100}
      px={5}
      bgcolor={YELLOW}
      display="flex"
      alignItems="center"
      justifyContent={smallWidth ? "center" : "space-between"}
    >
      {!smallWidth && <TheTalentedLogo inverse />}
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="center"
        width={MAX_VIEWPORT_WIDTH - 420}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          {steps.map(({ label, color }, index) => (
            <Step
              key={label}
              label={label}
              isSelected={currentStepIndex === index + 1}
              color={color ?? BLUE}
            />
          ))}
        </Box>
        <Box width="100%" height="12px" bgcolor="white">
          <Box
            width={`${(currentStepIndex / steps.length) * 100}%`}
            height="inherit"
            bgcolor={BLUE}
          />
        </Box>
      </Box>
      {!smallWidth && <Box width={180} />}
    </Box>
  );
};
