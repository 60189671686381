import { Box, Typography } from "@material-ui/core";
import React, { Fragment, useMemo } from "react";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { LoadingTypography } from "../../components";

type ProfessionalAccountSummaryPanelProps = {
  profileInformation?: ProfileInformation;
  averageRating?: number;
  ratingsCount?: number;
  isLoading?: boolean;
};

export const ProfessionalAccountSummaryPanel: React.FC<
  ProfessionalAccountSummaryPanelProps
> = ({ profileInformation, averageRating, ratingsCount, isLoading }) => {
  const availability = useMemo(() => {
    if (profileInformation?.pricing?.availabilityPerWeek.type === "fixed") {
      if (profileInformation?.pricing?.availabilityPerWeek.fixed) {
        return `${profileInformation?.pricing?.availabilityPerWeek.fixed} hr(s)/week`;
      }

      return "N/A";
    }

    if (
      profileInformation?.pricing?.availabilityPerWeek?.estimate?.min &&
      profileInformation?.pricing?.availabilityPerWeek?.estimate?.max
    ) {
      return `${profileInformation?.pricing?.availabilityPerWeek.estimate.min} - ${profileInformation?.pricing?.availabilityPerWeek.estimate.max} hrs/week`;
    }

    return "N/A";
  }, [
    profileInformation?.pricing?.availabilityPerWeek?.estimate?.max,
    profileInformation?.pricing?.availabilityPerWeek?.estimate?.min,
    profileInformation?.pricing?.availabilityPerWeek.fixed,
    profileInformation?.pricing?.availabilityPerWeek.type,
  ]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      gridRowGap={40}
      height="100%"
      width={300}
      py={10}
    >
      <Box>
        <LoadingTypography isLoading={isLoading} lineHeight={2}>
          <Typography
            component="span"
            variant="body1"
            style={{
              fontWeight: "bold",
              display: "inline",
            }}
          >
            Availability:
          </Typography>{" "}
          {profileInformation?.pricing?.availabilityPerWeek?.type ? (
            <Fragment>{availability}</Fragment>
          ) : (
            "N/A"
          )}
        </LoadingTypography>
        <Box pt={2}>
          <LoadingTypography isLoading={isLoading} lineHeight={2}>
            <Typography
              component="span"
              variant="body1"
              style={{
                fontWeight: "bold",
                display: "inline",
              }}
            >
              Fee:
            </Typography>{" "}
            {profileInformation?.pricing?.hourlyRate
              ? `$${profileInformation?.pricing?.hourlyRate}/hr`
              : "N/A"}
          </LoadingTypography>
        </Box>
      </Box>

      <Box>
        <LoadingTypography isLoading={isLoading} lineHeight={2}>
          <Typography
            component="span"
            variant="body1"
            style={{
              fontWeight: "bold",
              display: "inline",
            }}
          >
            {ratingsCount ?? 0}
          </Typography>{" "}
          project(s) completed on this platform
        </LoadingTypography>
        <Box pt={2}>
          <LoadingTypography isLoading={isLoading} lineHeight={2}>
            <Typography
              component="span"
              variant="body1"
              style={{
                fontWeight: "bold",
                display: "inline",
              }}
            >
              Avg. rating:
            </Typography>{" "}
            {averageRating && !isNaN(averageRating)
              ? `${averageRating}/10`
              : "N/A"}
          </LoadingTypography>
        </Box>
      </Box>
    </Box>
  );
};
