import { useMutation, UseMutationOptions } from "react-query";
import { updateJobPost } from "../../../../../api-services/job-posts";
import { JobPost } from "../../../../../api-types/job-posts";

type UseJobRequestUpdateMutationCoreOptions = UseMutationOptions<
  unknown,
  unknown,
  unknown
>;

export const useJobPostUpdateMutationCore = (
  options?: UseJobRequestUpdateMutationCoreOptions
) => {
  return useMutation(
    async (jobPost: Partial<JobPost>) => {
      const { data } = await updateJobPost(jobPost);
      return data;
    },
    {
      ...options,
    }
  );
};
