import { Box, FormControlLabel, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { BLUE } from "../../../../themes/components/utils";
import { dummyCurrencies } from "../../../client/job-requests/create-job-request-wizard/job-post/JobPostSetUpForm";
import { CheckboxWithInput } from "../../../client/job-requests/utils/CheckboxWithInput";
import { HeaderDropdown } from "../../../common/components";
import { SearchJobFiltersFormData } from "../schemas";
import { currencies } from "./RatePerHourFilter";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

export const SalaryPerYearFilter = () => {
  const { handleBlur, handleChange, setFieldValue, values } =
    useFormikContext<SearchJobFiltersFormData>();
  const styles = useSearchJobPostFiltersStyles();

  const handleCurrencySelect = (index: number) => {
    setFieldValue("salaryPerYear.currency", currencies[index]);
  };

  const disabledCurrency =
    values.salaryPerYear?.checked === false || values.showAll;

  return (
    <Box>
      <Typography
        className={`${styles.label} ${values.showAll && styles.disabledLabel}`}
        color="secondary"
        variant="subtitle1"
      >
        Salary Per Year
      </Typography>

      <Box display="flex">
        <FormControlLabel
          control={
            <CheckboxWithInput
              betweenInputsLabel="and"
              checked={values?.salaryPerYear?.checked}
              checkboxValue="estimate"
              colorCheckbox
              colorInput
              disabled={values.showAll}
              isMinMaxInput
              leftLabel="Between"
              minValue={values.salaryPerYear.estimate?.min}
              maxValue={values.salaryPerYear.estimate?.max}
              name="salaryPerYear"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          }
          label=""
        />
        {values.salaryPerYear?.checked && (
          <Box alignItems="center" display="flex">
            <Typography
              color="secondary"
              className={`${disabledCurrency && styles.disabledLabel}`}
            >
              Select Currency
            </Typography>
            <HeaderDropdown
              boxContainerClassName={`${styles.boxContainerReset} ${
                disabledCurrency && styles.disabledLabel
              }`}
              color={BLUE}
              disabled={disabledCurrency}
              dropdownIcon={styles.dropdownIcon}
              fontWeight={400}
              mainBox={`${styles.mainBoxContainer}  ${
                disabledCurrency && styles.disabledLabel
              }`}
              mainContainer={styles.boxContainer}
              minWidth="130px"
              onSelect={handleCurrencySelect}
              options={dummyCurrencies}
              selectedOptionId={values.salaryPerYear?.currency ?? "USD"}
              titleClass={styles.selectLabel}
              variant="body1"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
