import { useQuery } from "react-query";
import { searchProfessionals } from "../../../../api-services/professionals";
import { BrowseSearchFilters } from "../../../../api-types/job-requests/common";
import { Professional } from "../../../../api-types/professionals/professional";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { useSearchFilters } from "../state/hooks";

export const SEARCH_PROFESSIONALS_QUERY_KEY = "professionals-search-form-key";

const searchProfessionalsKey = (filters: BrowseSearchFilters) => {
  return `${SEARCH_PROFESSIONALS_QUERY_KEY}-${filters.skills}-${
    filters.fee?.min ?? 0
  }-${filters.fee?.max ?? 999}-${filters.serviceCategory}`;
};

export const defaultBrowseFilters: BrowseSearchFilters = {
  skills: [],
  fee: {
    min: 0,
    max: 999,
  },
  serviceCategory: "",
};

export const useSearchProfessionals = (useDefaultFilters?: boolean) => {
  const filters = useScopedDowngradedStateValue(useSearchFilters());

  return useQuery<Professional[], unknown, Professional[]>(
    searchProfessionalsKey(useDefaultFilters ? defaultBrowseFilters : filters),
    async () => {
      const { data } = await searchProfessionals(
        useDefaultFilters ? defaultBrowseFilters : filters
      );

      return data;
    }
  );
};
