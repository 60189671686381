import { useState } from "@hookstate/core";
import { state } from "./state";

export const useConfirmationModal = () => {
  return useState(state.confirmationModal);
};

export const useCurrentProjectIndex = () => {
  return useState(state.currentProjectIndex);
};

export const useCurrentProfessionalIndex = () => {
  return useState(state.currentProfessionalIndex);
};

export const useCompleteProjectModal = () => {
  return useState(state.completeProjectModal);
};
