import { useFormikContext } from "formik";
import { useMemo } from "react";
import { parsePositiveNumber } from "../../../../utils";
import { BudgetType } from "../freelance-job-request/BudgetStep";
import { BudgetSchema, HourlyRateFundingSchema } from "../validation-schema";

export const useEstimatedCostPerWeekCore = (budget?: BudgetType) => {
  return useMemo(() => {
    if (!budget) {
      return { min: 0, max: 0 };
    }

    const hoursType = budget.hours.type;
    const hourlyRateType = budget.hourlyRate.type;

    const hours = parsePositiveNumber(budget.hours.fixed);
    const minHours = parsePositiveNumber(budget.hours.estimate.min);
    const maxHours = parsePositiveNumber(budget.hours.estimate.max);

    const hourlyRate = parsePositiveNumber(budget.hourlyRate.fixed);
    const minHourlyRate = parsePositiveNumber(budget.hourlyRate.estimate.min);
    const maxHourlyRate = parsePositiveNumber(budget.hourlyRate.estimate.max);

    if (hoursType === "estimate" && hourlyRateType === "estimate") {
      return { min: minHourlyRate * minHours, max: maxHourlyRate * maxHours };
    }

    if (hoursType === "estimate") {
      return { min: hourlyRate * minHours, max: hourlyRate * maxHours };
    }

    if (hourlyRateType === "estimate") {
      return { min: minHourlyRate * hours, max: maxHourlyRate * hours };
    }

    return { min: hourlyRate * hours, max: hourlyRate * hours };
  }, [budget]);
};

export const useEstimatedCostPerWeek = () => {
  const { values } = useFormikContext<HourlyRateFundingSchema & BudgetSchema>();

  return useEstimatedCostPerWeekCore(values.budget as BudgetType);
};
