import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React, { Fragment, useMemo } from "react";
import ShowMoreText from "react-show-more-text";
import { AccountInformation } from "../../../../api-types/account-information";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { YELLOW } from "../../../../themes/components/utils";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { CustomTooltip } from "../../components";
import { SkillsOutput } from "../../components/SkillsOutput";

type ProfileSummaryDescriptionProps = {
  accountInformation?: AccountInformation;
  profileInformation?: ProfileInformation;
  isHovering?: boolean;
  onClick: () => void;
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
  divider: {
    backgroundColor: palette.secondary.main,
  },
}));

export const ProfileSummaryDescription: React.FC<
  ProfileSummaryDescriptionProps
> = ({ profileInformation, accountInformation, isHovering, onClick }) => {
  const boldStyles = useBoldStyles();
  const anchorStyles = useStyles();

  const availability = useMemo(() => {
    if (profileInformation?.pricing?.availabilityPerWeek?.type === "fixed") {
      if (profileInformation?.pricing?.availabilityPerWeek?.fixed) {
        return `${profileInformation?.pricing?.availabilityPerWeek.fixed} hrs/week`;
      }
    }

    if (
      profileInformation?.pricing?.availabilityPerWeek?.estimate?.min &&
      profileInformation?.pricing?.availabilityPerWeek?.estimate?.max
    ) {
      return `${profileInformation?.pricing.availabilityPerWeek.estimate.min} - ${profileInformation?.pricing.availabilityPerWeek.estimate.max} hrs/week`;
    }

    return "N/A";
  }, [
    profileInformation?.pricing?.availabilityPerWeek?.estimate?.max,
    profileInformation?.pricing?.availabilityPerWeek?.estimate?.min,
    profileInformation?.pricing?.availabilityPerWeek?.fixed,
    profileInformation?.pricing?.availabilityPerWeek?.type,
  ]);

  const tooltipTitle = useMemo(() => {
    return (
      <Fragment>
        <Box display="flex" flexDirection="column" gridRowGap={14}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 700,
            }}
            variant="subtitle2"
          >
            {accountInformation?.firstName} {accountInformation?.lastName}
          </Typography>
          <Divider className={anchorStyles.divider} />

          <Box display="flex" flexDirection="column" gridRowGap={28}>
            <Typography
              color="secondary"
              style={{
                fontWeight: 700,
              }}
              variant="subtitle2"
            >
              {profileInformation?.position}
            </Typography>
            <Typography
              color="secondary"
              variant="body1"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {profileInformation?.description}
            </Typography>
          </Box>
        </Box>
      </Fragment>
    );
  }, [
    accountInformation?.firstName,
    accountInformation?.lastName,
    anchorStyles.divider,
    profileInformation?.description,
    profileInformation?.position,
  ]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      style={{
        cursor: "pointer",
      }}
    >
      <Typography variant="subtitle2" className={boldStyles.root}>
        {profileInformation?.position || "No position"}
      </Typography>
      <Box
        onClick={onClick}
        height={200}
        maxWidth={510}
        style={{
          overflow: "auto",
          color: "white",
        }}
      >
        <Typography
          style={{
            fontSize: 16,
          }}
        >
          <ShowMoreText
            lines={3}
            more={
              <CustomTooltip
                arrow
                interactive
                placement="right"
                title={tooltipTitle}
              >
                <Typography component="span" variant="body2" color="primary">
                  More
                </Typography>
              </CustomTooltip>
            }
            anchorClass={anchorStyles.root}
            expanded={false}
            // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
            expandByClick={false}
          >
            {profileInformation?.description || "No description"}
          </ShowMoreText>
        </Typography>
      </Box>
      <SkillsOutput
        skills={profileInformation?.skills?.map((skill) => skill.name) ?? []}
        color="transparent"
        isHovering={isHovering}
      />
      <Box display="flex" gridColumnGap={20} pt={4}>
        <Box>
          <Typography
            component="span"
            variant="body2"
            style={{
              fontWeight: 500,
            }}
          >
            Fee{" "}
          </Typography>
          <Typography component="span" variant="body2">
            {profileInformation?.pricing?.hourlyRate
              ? `$${profileInformation?.pricing?.hourlyRate}/hr`
              : "N/A"}
          </Typography>
        </Box>
        <Box>
          <Typography
            component="span"
            variant="body2"
            style={{
              fontWeight: 500,
            }}
          >
            Availability{" "}
          </Typography>
          <Typography component="span" variant="body2">
            {availability}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
