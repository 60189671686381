import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FieldArray, Formik, FormikValues } from "formik";
import { isEqual, keys, pick, uniqueId } from "lodash";
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useMemo,
  useState,
} from "react";

import { object } from "yup";
import {
  useEstimatedCostPerWeekCore,
  useTotalEstimatedCostCore,
} from "../../../client/job-requests/create-job-request-wizard/hooks";
import {
  HourlyRateMilestone,
  HourlyRateMilestonesSchemaDefinition,
  MilestoneStatus,
} from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { HOURLY_RATE_DIFF_PROPS } from "../../../common/components/milestones/constants";
import { HourlyRateDiffTableRow } from "../../../common/components/milestones/HourlyRateDiffTableRow";
import { HourlyRateEditableTableRow } from "../../../common/components/milestones/HourlyRateEditableTableRow";
import { HourlyRateTableRow } from "../../../common/components/milestones/HourlyRateTableRow";
import { MilestoneChangesActions } from "../../../common/components/milestones/MilestoneChangesActions";
import { getPendingChanges } from "../../../common/components/milestones/utils";
import { PaymentRequestDialog } from "../../../common/components/modals/payment-request-dialog";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useRequestPaymentModal } from "../state/hooks";
import { TABLE_HEIGHT } from "./constants";

export type ActualWork = {
  dueDate: number;
  hoursPerWeek: number;
  costPerHour: number;
};

type HourlyRateMilestonesTableProps = {
  canAcceptPendingChanges?: boolean;
  canEditMilestones?: boolean;
  closingRequest?: boolean;
  existingMilestonesIds: string[];
  isEditingMilestones: boolean;
  isLoading?: boolean;
  isSubmittingWork?: boolean;
  milestones: HourlyRateMilestone[];
  onAcceptChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onDenyChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onFinishEdit: (values: FormikValues) => void;
  onStartEdit: () => void;
  onSubmitMilestone?: (milestoneId: string, actualWork: ActualWork) => void;
  pendingChanges?: HourlyRateMilestone[];
  setIsClosing: () => void;
  setIsEditingMilestones: (isEditing: boolean) => void;
};

export const HourlyRateMilestonesTable: FC<HourlyRateMilestonesTableProps> = ({
  canAcceptPendingChanges,
  canEditMilestones,
  closingRequest,
  existingMilestonesIds,
  isEditingMilestones,
  isLoading,
  isSubmittingWork,
  milestones,
  onAcceptChanges,
  onDenyChanges,
  onFinishEdit,
  onStartEdit,
  onSubmitMilestone,
  pendingChanges,
  setIsEditingMilestones,
  setIsClosing,
}) => {
  const requiredFieldLabel = useRequiredFieldLabel();
  const { data: project } = useProjectQuery();

  const paymentRequestDialog = useScopedDowngradedStateValue(
    useRequestPaymentModal()
  );

  const setPaymentRequestDialog = useRequestPaymentModal().set;

  const [selectedMilestones, setSelectedMilestones] = useState<
    HourlyRateMilestone[]
  >([]);

  const handleSelectMilestone = useCallback(
    (event: ChangeEvent<HTMLInputElement>, milestone: any) => {
      if (event.target.checked) {
        setSelectedMilestones([...selectedMilestones, milestone]);
      }

      if (!event.target.checked) {
        const updatedMilestones = [...selectedMilestones];

        const index = updatedMilestones.findIndex(
          (m) => m._id === milestone._id
        );

        updatedMilestones.splice(index, 1);
        setSelectedMilestones(updatedMilestones);
      }
    },
    [selectedMilestones]
  );

  const isMilestoneSelected = useCallback(
    (milestone: HourlyRateMilestone) => {
      return Boolean(
        selectedMilestones.find(({ _id }) => _id === milestone._id)
      );
    },
    [selectedMilestones]
  );

  const timeFrameLabel = useMemo(() => {
    const type = project?.jobRequest?.budget?.timeFrame?.type;

    if (type === "fixed") {
      return `${project?.jobRequest?.budget?.timeFrame?.fixed ?? 0} week(s)`;
    }

    const estimate = project?.jobRequest?.budget?.timeFrame?.estimate;

    return `${estimate?.min ?? 0} - ${estimate?.max ?? 0} week(s)`;
  }, [
    project?.jobRequest?.budget?.timeFrame?.estimate,
    project?.jobRequest?.budget?.timeFrame?.fixed,
    project?.jobRequest?.budget?.timeFrame?.type,
  ]);

  const hoursLabel = useMemo(() => {
    const budget = project?.jobRequest.budget;

    if (!budget) {
      return "";
    }

    return budget.hours.type === "fixed"
      ? `x ${budget.hours.fixed}hrs/wk`
      : `x ${budget.hours.estimate.min}- ${budget.hours.estimate.max}hrs/wk`;
  }, [project?.jobRequest.budget]);

  const hourlyRateLabel = useMemo(() => {
    const budget = project?.jobRequest.budget;

    if (!budget) {
      return "";
    }

    return budget.hourlyRate.type === "fixed"
      ? `x $${budget.hourlyRate.fixed}/hr`
      : `x $${budget.hourlyRate.estimate.min} - $${budget.hourlyRate.estimate.max}hr`;
  }, [project?.jobRequest.budget]);

  const estimatedCostPerWeek = useEstimatedCostPerWeekCore(
    project?.jobRequest.budget
  );

  const totalEstimatedCost = useTotalEstimatedCostCore(
    project?.jobRequest.hourlyRateProjectFunding.milestones ?? [],
    project?.jobRequest.budget
  );

  return (
    <Fragment>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pb={4}
        pt={8}
      >
        <Box display="flex" gridColumnGap={64}>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              Milestones for the Project
            </Typography>{" "}
            <Typography component="span" variant="body2">
              {milestones.length}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              Timeframe
            </Typography>{" "}
            <Typography component="span" variant="body2">
              {timeFrameLabel}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="span"
              variant="body2"
              style={{ fontWeight: 700 }}
            >
              Estimated Cost
            </Typography>{" "}
            <Typography component="span" variant="body2">
              ${totalEstimatedCost?.min ?? 0} - ${totalEstimatedCost?.max ?? 0}
            </Typography>
          </Box>
          {/*<Box>*/}
          {/*  <Typography*/}
          {/*    component="span"*/}
          {/*    variant="body2"*/}
          {/*    style={{ fontWeight: 700 }}*/}
          {/*  >*/}
          {/*    Payment Schedule*/}
          {/*  </Typography>{" "}*/}
          {/*  <Typography component="span" variant="body2">*/}
          {/*    {project?.jobRequest?.budget?.paymentSchedule.type}*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Box>
        <Box display="flex" gridColumnGap={8}>
          <Button
            disabled={isEditingMilestones || closingRequest}
            onClick={setIsClosing}
            size="small"
          >
            Cancel Project
          </Button>
          <Button
            disabled={isEditingMilestones || !canEditMilestones}
            onClick={onStartEdit}
            size="small"
          >
            Edit Milestones
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box pt={6} minHeight={240}>
        {isLoading ? (
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={{
              milestones,
            }}
            enableReinitialize
            onSubmit={(values) => {
              const newChanges = getPendingChanges(
                values.milestones,
                milestones,
                pendingChanges
              );

              onFinishEdit({ milestones: newChanges });
            }}
            validationSchema={object({
              milestones: HourlyRateMilestonesSchemaDefinition,
            })}
            validateOnChange
            validateOnMount
          >
            {({ errors, handleReset, submitForm, values }) => {
              return (
                <form>
                  <FieldArray name="milestones">
                    {({ push, remove }) => (
                      <Fragment>
                        <TableContainer
                          style={{
                            maxHeight: TABLE_HEIGHT,
                            marginLeft: -16,
                            width: "calc(100% + 16px)",
                          }}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  <Checkbox disabled />
                                </TableCell>
                                <TableCell variant="head" width="20%">
                                  {requiredFieldLabel("Milestone Description")}
                                </TableCell>
                                <TableCell variant="head" width="25%">
                                  {requiredFieldLabel("Due Date")}
                                </TableCell>
                                <TableCell variant="head" width="15%">
                                  Hours/Week
                                </TableCell>
                                <TableCell variant="head" width="15%">
                                  Dollars/Hour
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Estimated Cost
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Milestone Status
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.milestones.map((milestone, index) => {
                                const { _id, status, isSubmitted } = milestone;

                                const editableMilestone =
                                  !status || status === MilestoneStatus.PENDING;

                                const suggestedChanges = pendingChanges?.find(
                                  (m) => _id && m._id === _id
                                );

                                const showDiff =
                                  (suggestedChanges &&
                                    !isEqual(
                                      pick(milestone, HOURLY_RATE_DIFF_PROPS),
                                      pick(
                                        suggestedChanges,
                                        HOURLY_RATE_DIFF_PROPS
                                      )
                                    )) ||
                                  (isSubmitted &&
                                    status !== MilestoneStatus.COMPLETED);

                                const isNewMilestone =
                                  !_id || !existingMilestonesIds.includes(_id);

                                const isDeletedMilestone =
                                  (pendingChanges?.length ?? 0) > 0 &&
                                  milestone &&
                                  !suggestedChanges;

                                const hasActualWork =
                                  milestone?.actualWork?.dueDate ||
                                  milestone?.actualWork?.costPerHour ||
                                  milestone?.actualWork?.hoursPerWeek;

                                return (
                                  <Fragment key={index}>
                                    {isEditingMilestones &&
                                    editableMilestone ? (
                                      <HourlyRateEditableTableRow
                                        estimatedCostPerWeek={
                                          estimatedCostPerWeek
                                        }
                                        remove={remove}
                                        hasBorder={showDiff}
                                        hoursLabel={hoursLabel}
                                        hourlyRateLabel={hourlyRateLabel}
                                        milestone={milestone}
                                        onSelect={(event) =>
                                          handleSelectMilestone(
                                            event,
                                            milestone
                                          )
                                        }
                                        rowIndex={index}
                                        selected={isMilestoneSelected(
                                          milestone
                                        )}
                                      />
                                    ) : (
                                      <HourlyRateTableRow
                                        actions={
                                          <Fragment>
                                            {(isDeletedMilestone ||
                                              isNewMilestone) &&
                                              canAcceptPendingChanges && (
                                                <MilestoneChangesActions
                                                  onAcceptChanges={() =>
                                                    onAcceptChanges?.(
                                                      _id,
                                                      isNewMilestone &&
                                                        !isDeletedMilestone
                                                    )
                                                  }
                                                  onDenyChanges={() =>
                                                    onDenyChanges?.(
                                                      _id,
                                                      isNewMilestone &&
                                                        !isDeletedMilestone
                                                    )
                                                  }
                                                />
                                              )}
                                            {status ===
                                              MilestoneStatus.IN_PROGRESS && (
                                              <Button
                                                disabled={
                                                  isEditingMilestones ||
                                                  isSubmittingWork
                                                }
                                                onClick={() =>
                                                  setPaymentRequestDialog({
                                                    open: true,
                                                    milestoneId: _id,
                                                  })
                                                }
                                                endIcon={
                                                  isSubmittingWork ? (
                                                    <CircularProgress
                                                      size={16}
                                                    />
                                                  ) : null
                                                }
                                                size="small"
                                              >
                                                Submit Work
                                              </Button>
                                            )}
                                          </Fragment>
                                        }
                                        estimatedCostPerWeek={
                                          estimatedCostPerWeek
                                        }
                                        hasBorder={showDiff}
                                        hoursLabel={hoursLabel}
                                        hourlyRateLabel={hourlyRateLabel}
                                        milestone={milestone}
                                        showAsDiff={
                                          isNewMilestone || isDeletedMilestone
                                        }
                                      />
                                    )}
                                    {showDiff && (
                                      <HourlyRateDiffTableRow
                                        actions={
                                          !hasActualWork &&
                                          canAcceptPendingChanges &&
                                          !isEditingMilestones && (
                                            <MilestoneChangesActions
                                              onAcceptChanges={() =>
                                                onAcceptChanges?.(_id, false)
                                              }
                                              onDenyChanges={() =>
                                                onDenyChanges?.(_id, false)
                                              }
                                            />
                                          )
                                        }
                                        actualWork={milestone?.actualWork}
                                        estimatedCostPerWeek={
                                          estimatedCostPerWeek
                                        }
                                        hoursLabel={hoursLabel}
                                        hourlyRateLabel={hourlyRateLabel}
                                        milestone={suggestedChanges}
                                      />
                                    )}
                                  </Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {isEditingMilestones ? (
                          <Fragment>
                            <Box py={4}>
                              <Divider />
                            </Box>
                            <Box display="flex" gridColumnGap={100}>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() => {
                                    handleReset();
                                    setIsEditingMilestones(false);
                                  }}
                                  size="small"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={keys(errors).length > 0}
                                  onClick={submitForm}
                                  size="small"
                                  variant="contained"
                                >
                                  Save Changes
                                </Button>
                              </Box>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() =>
                                    push({
                                      _id: uniqueId(),
                                      amount: 0,
                                      description: "",
                                      dueDate: {
                                        type: "weeks",
                                        min: 0,
                                        max: 0,
                                      },
                                      status:
                                        milestones?.[milestones.length - 1]
                                          ?.status === MilestoneStatus.COMPLETED
                                          ? MilestoneStatus.IN_PROGRESS
                                          : MilestoneStatus.PENDING,
                                    })
                                  }
                                  size="small"
                                  variant="contained"
                                >
                                  Add Milestone
                                </Button>
                              </Box>
                            </Box>
                          </Fragment>
                        ) : (
                          <Box height={69} />
                        )}
                      </Fragment>
                    )}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        )}
      </Box>
      <PaymentRequestDialog
        title="Submit Milestone"
        confirmLabel="Submit"
        open={paymentRequestDialog.open}
        onClose={() => setPaymentRequestDialog({ open: false })}
        onConfirm={(dueDate, hoursPerWeek, costPerHour) => {
          if (!paymentRequestDialog?.milestoneId) return;

          onSubmitMilestone?.(paymentRequestDialog.milestoneId, {
            dueDate,
            hoursPerWeek,
            costPerHour,
          });
          setPaymentRequestDialog({ open: false });
        }}
      />
    </Fragment>
  );
};
