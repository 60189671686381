import { Box } from "@material-ui/core";
import { noop } from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useMobileStyles,
} from "../../../../../../themes/style-hooks";
import { PackagesCards } from "../../../../../client/job-posts/jobs-packages/components/PackagesCards";
import { useRedirectUrl } from "../../../../../state/hooks";

export const LandingStepDContent: FC = () => {
  const { mobileContentWrapper, mobileDescriptionWrapper } = useMobileStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: "#EC6048",
    color: COLOR.WHITE,
    hover: "#EC6048",
  });
  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });
  const navigate = useNavigate();
  const setRedirectUrl = useRedirectUrl().set;

  return (
    <Box className={mobileContentWrapper}>
      <Box className={mobileDescriptionWrapper}>
        <Box textAlign="left">
          <Box className={descriptionText.root}>
            Select any job post package and start a 10-day free trial.
          </Box>
        </Box>
      </Box>
      <Box width={"100%"} height={500} py={2}>
        <PackagesCards
          inverse
          onSelect={noop}
          selectLabel="Start 10-day free trial"
          buttonStyles={`${common} ${root}`}
          radioColor={"#EC6048"}
          onClick={() => {
            setRedirectUrl("/jobs-packages/");
            navigate("/jobs-packages/");
          }}
        />
      </Box>{" "}
    </Box>
  );
};
