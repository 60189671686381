import React from "react";
import { Helmet } from "react-helmet";

type MetadataProps = {
  title?: string;
  description?: string;
};

export const Metadata: React.FC<MetadataProps> = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="title" property="og:title" content={title} />
    <meta name="title" property="twitter:title" content={title} />
    <meta name="description" property="og:description" content={description} />
    <meta
      name="description"
      property="twitter:description"
      content={description}
    />
    <meta
      name="image"
      property="og:image"
      content="http://thetalented-media-dev.s3.amazonaws.com/images/thetalentedPreview.jpg"
    />
    <meta
      name="image"
      property="twitter:image"
      content="http://thetalented-media-dev.s3.amazonaws.com/images/thetalentedPreview.jpg"
    />
  </Helmet>
);
