import axios from "axios";
import { PasswordResetConfirmData } from "../../api-types/sign-in";
import { PASSWORD_RESET_CONFIRM_URL } from "../utils";

export const passwordResetConfirm = async (
  token: string,
  passwordResetData: PasswordResetConfirmData
) => {
  return await axios.post(PASSWORD_RESET_CONFIRM_URL, passwordResetData, {
    params: {
      token,
    },
  });
};
