import { Box, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import {
  TextOverflow,
  ShowImages,
  LoadingTypography,
} from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.jobRequest.images?.length);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <LoadingTypography
          width={300}
          isLoading={isLoadingProject}
          typographyProps={{
            variant: "subtitle1",
            className: boldStyles.root,
          }}
        >
          {project?.jobRequest?.title}
        </LoadingTypography>
        <Box display="flex" gridColumnGap={40}>
          {project?.createdAt && (
            <Typography>
              {`Started ${format(
                new Date(project?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
          {project?.client && (
            <Box display="flex" gridColumnGap={10}>
              <Typography display="inline">Posted by</Typography>
              <TextOverflow width="40%">
                {`${project?.client?.accountInformation?.firstName} ${project?.client?.accountInformation?.lastName}`}
              </TextOverflow>
            </Box>
          )}
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={project?.description} />
      {Boolean(skills.length) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={project?.jobRequest.images} />{" "}
        </Box>
      )}
      <Box py={2.5}>
        <Divider />
      </Box>
    </Box>
  );
};
