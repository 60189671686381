import { Box, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { SearchJobFiltersFormData } from "../schemas/searchJobFiltersSchema";
import { useSearchJobPostFiltersStyles } from "./useSearchJobPostFiltersStyles";

const jobLevel = [
  {
    label: "Intern",
    value: "intern",
  },
  {
    label: "Junior",
    value: "junior",
  },
  {
    label: "Mid-Level",
    value: "mid",
  },
  {
    label: "Senior",
    value: "senior",
  },
  {
    label: "Executive",
    value: "executive",
  },
];

export const JobLevelFilter = () => {
  const styles = useSearchJobPostFiltersStyles();
  const { handleChange, values } = useFormikContext<SearchJobFiltersFormData>();

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        className={`${styles.label} ${values.showAll && styles.disabledLabel}`}
        color="secondary"
        variant="subtitle1"
      >
        Job Level
      </Typography>
      {jobLevel.map((item) => (
        <FormControlLabel
          control={
            <Radio
              className={styles.radio}
              data-cy={item.value}
              disabled={values.showAll}
              name="jobLevel"
              onChange={handleChange}
              checked={values.jobLevel === item.value}
              value={item.value}
            />
          }
          key={item.value}
          label={
            <Typography color="secondary" variant="subtitle2">
              {item.label}
            </Typography>
          }
        />
      ))}
    </Box>
  );
};
