import { useMutation, UseMutationOptions } from "react-query";
import { validateToken } from "../../../../api-services/sign-up";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { useLocalState } from "../utils";

export const useValidateMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const [_, setToken] = useLocalState("access_token", "");
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    async (token: string) => {
      const { data } = await validateToken(token);

      return data;
    },
    {
      ...mutationOptions,
      onSuccess(response) {
        if (response.accessToken) {
          setToken(response.accessToken);
        }

        notifySuccess(feedbackMessage("", FEEDBACK_TYPE.ACCOUNT_VALIDATED));
      },
      onError(err: any) {
        notifyError(
          err?.response.data.message ?? "Unexpected error. Please try again."
        );
      },
    }
  );
};
