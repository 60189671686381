import { InferType, number, object, ref, string } from "yup";

export const BudgetSchemaDefinition = object().shape({
  type: string().required(),
  // added the schema to object() to avoid having the field as never type
  // @todo refactor to avoid code duplication
  hourlyRate: object({
    type: string(),
    fixed: number(),
    estimate: object({ min: number(), max: number() }),
  }).when("type", {
    is: "hourly",
    then: object().shape({
      type: string().required(),
      fixed: number().when("type", {
        is: "fixed",
        then: number()
          .min(1, "Hourly rate must be greater than zero")
          .required("Hourly rate is required"),
      }),
      estimate: object().when("type", {
        is: "estimate",
        then: object().shape({
          min: number()
            .min(1, "Minimum hourly rate must be greater than zero")
            .lessThan(
              ref("max"),
              "Minimum hourly rate must be less than maximum hourly rate"
            )
            .required("Minimum hourly rate is required"),
          max: number()
            .min(1, "Maximum hourly rate must be greater than zero")
            .required("Maximum hourly rate is required"),
        }),
      }),
    }),
  }),
  hours: object({
    type: string(),
    fixed: number(),
    estimate: object({ min: number(), max: number() }),
  }).when("type", {
    is: "hourly",
    then: object().shape({
      type: string().required(),
      fixed: number().when("type", {
        is: "fixed",
        then: number()
          .min(1, "Hours must be greater than zero")
          .required("Hours is required"),
      }),
      estimate: object().when("type", {
        is: "estimate",
        then: object().shape({
          min: number()
            .min(1, "Minimum hours must be greater than zero")
            .lessThan(
              ref("max"),
              "Minimum hours must be less than maximum hours"
            )
            .required("Minimum hours is required"),
          max: number()
            .min(1, "Maximum hours must be greater than zero")
            .required("Maximum hours is required"),
        }),
      }),
    }),
  }),
  timeFrame: object({
    type: string(),
    fixed: number(),
    estimate: object({ min: number(), max: number() }),
  }).when("type", {
    is: "hourly",
    then: object().shape({
      type: string().required(),
      fixed: number().when("type", {
        is: "fixed",
        then: number().required(),
      }),
      estimate: object().when("type", {
        is: "estimate",
        then: object().shape({
          min: number().required(),
          max: number().required(),
        }),
      }),
    }),
  }),
  // paymentSchedule: object({
  //   type: string(),
  // }).when("type", {
  //   is: "hourly",
  //   then: object().shape({
  //     type: string().required(),
  //   }),
  // }),
});

export type BudgetSchema = { budget: InferType<typeof BudgetSchemaDefinition> };
