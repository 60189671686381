import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  divider: {
    backgroundColor: palette.secondary.main,
  },
}));

export const HourlyRateMoreInfo = () => {
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="column" gridRowGap={14}>
      <Typography
        color="secondary"
        variant="subtitle2"
        style={{ fontWeight: 700 }}
      >
        Hourly Rate Projects
      </Typography>
      <Divider className={styles.divider} />
      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Submitting Work and Receiving Payment
      </Typography>
      <Typography color="secondary" variant="body2">
        By default, all hourly rate projects have one milestone.
      </Typography>
      <Typography color="secondary" variant="body2">
        Once all the work on a milestone is sent and reviewed, you can submit
        the actual number of hours completed in the time span of that milestone.{" "}
      </Typography>
      <Typography color="secondary" variant="body2">
        After you receive the payment, work can begin on the next milestone.{" "}
      </Typography>
      <Divider className={styles.divider} />
      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Your Account Balance and Withdrawing Funds
      </Typography>
      <Typography color="secondary" variant="body2">
        All funds from projects are deposited into your Account Balance until
        you withdraw them.
      </Typography>
      <Typography color="secondary" variant="body2">
        Upon withdrawal, the money will be deposited to your bank account or
        card on file. You can view your account from the Payments Dashboard. The
        transfer is facilitated by Stripe.
      </Typography>
      <Typography color="secondary" variant="body2">
        Invoices of withdrawals are available in the Withdrawal Invoices section
        on the Account Balance page, and on the Payments Dashboard.
      </Typography>
      <Typography color="secondary" variant="body2">
        Invoices deposited to your Account Balance are available in the
        Milestone Deposits section on that project’s page and on the Account
        Balance page.
      </Typography>
    </Box>
  );
};
