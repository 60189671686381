import axios from "axios";
import { SEARCH_JOB_REQUESTS_URL } from "../utils";

//TODO use proper type
export const searchJobRequests = async (filter: any) => {
  return axios.post(SEARCH_JOB_REQUESTS_URL, {
    ...filter,
    searchKeywords: [filter.search],
  });
};
