import { ReactElement } from "react";
import { Navigate, Route } from "react-router-dom";
import { useScopedDowngradedStateValue } from "../modules/common/hooks/utils/useScopedDowngradedStateValue";
import { useIsAuthed } from "../modules/state/hooks";

type PrivateRouteProps = {
  element: ReactElement;
  path: string;
};

export const PrivateRoute = ({ element, path }: PrivateRouteProps) => {
  const authed = useScopedDowngradedStateValue(useIsAuthed());

  const render = authed ? (
    element
  ) : (
    <Navigate to="/sign-in" replace state={{ path }} />
  );

  return <Route path={path} element={render} />;
};
