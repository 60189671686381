import axios from "axios";
import { Message } from "../../../../api-types/job-requests/common";
import { PROFESSIONALS_INTERACTION_ADD_MESSAGE_URL } from "../../../utils";

export const addInteractionMessage = (
  professionalId: string,
  messages: Message[]
) => {
  return axios.post(PROFESSIONALS_INTERACTION_ADD_MESSAGE_URL, {
    messages,
    professionalId,
  });
};
