import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { HeaderDropdown } from "../components";
import { useDialogStyles } from "../components/modals/subscribe-dialog/SubscribeDialog";

type FeatureFlagsDialogProps = {
  open?: boolean;
  handleClose: () => void;
};

const backendAPIOptions = ["dev", "prod", "local"];

export const defaultFeatureFlags = {
  platform: "dev",
  showProfessionals: "0",
  showJobRequests: "0",
};

export const FeatureFlagsDialog: FC<FeatureFlagsDialogProps> = ({
  open = false,
  handleClose,
}) => {
  const dialogStyles = useDialogStyles(true);

  const [platform, setPlatform] = useState(
    window.sessionStorage.getItem("platform") ?? "dev"
  );

  const [showProfessionals, setShowProfessionals] = useState(
    window.sessionStorage.getItem("showProfessionals") ?? "0"
  );

  const [showJobRequests, setShowJobRequests] = useState(
    window.sessionStorage.getItem("showJobRequests") ?? "0"
  );

  const handleUpdatePlatform = useCallback(() => {
    window.sessionStorage.setItem("platform", platform);
    window.sessionStorage.setItem("showProfessionals", showProfessionals);
    window.sessionStorage.setItem("showJobRequests", showJobRequests);

    handleClose();

    window.location.reload();
  }, [platform, showProfessionals, showJobRequests, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={`${dialogStyles.fullWidth} `}
    >
      <DialogTitle>
        <Typography
          style={{
            fontSize: 24,
            color: BLUE,
            fontWeight: 600,
          }}
        >
          Feature Flags
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" gridColumnGap={10}>
          <Typography
            color="secondary"
            style={{
              fontSize: 21,
              fontWeight: 550,
              width: 250,
              fontStyle: "italic",
            }}
          >
            API Platform:&nbsp;
          </Typography>
          <Box width={100}>
            <HeaderDropdown
              variant="subtitle2"
              selectedOptionId={platform}
              options={backendAPIOptions.map((apiOption) => ({
                id: apiOption,
                title: apiOption,
              }))}
              onSelect={(option) => {
                setPlatform(backendAPIOptions[option]);
              }}
              color={BLUE}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gridColumnGap={10}>
          <Typography
            color="secondary"
            style={{
              fontSize: 21,
              fontWeight: 550,
              width: 240,
              fontStyle: "italic",
            }}
          >
            Show Professionals:
          </Typography>
          <Box width={200}>
            <Switch
              defaultChecked={showProfessionals === "1"}
              onChange={(event) => {
                setShowProfessionals(event.target.checked ? "1" : "0");
              }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gridColumnGap={10}>
          <Typography
            color="secondary"
            style={{
              fontSize: 21,
              fontWeight: 550,
              width: 240,
              fontStyle: "italic",
            }}
          >
            Show Job Requests:
          </Typography>
          <Box width={200}>
            <Switch
              defaultChecked={showJobRequests === "1"}
              onChange={(event) => {
                setShowJobRequests(event.target.checked ? "1" : "0");
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{
            color: BLUE,
            borderColor: BLUE,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: BLUE,
            color: YELLOW,
          }}
          onClick={handleUpdatePlatform}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
