import { Box } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Project } from "../../../../../../api-types/projects";
import { BLUE } from "../../../../../../themes/components/utils";
import { Section } from "../../../../../common/components";
import { useProjectsQuery } from "../../../../../common/hooks/projects/useProjectsQuery";
import { PaginationFooter } from "../../../../../common/professionals-search/PaginationFooter";
import { PortfolioSkeleton } from "../../../sections/portfolio/PortfolioSkeleton";
import { ProjectItem } from "./project-item";

const defaultRowsPerPage = 2;
const itemsPerRow = 1;

export const CurrentAccountProjects: React.FC = () => {
  const { professionalId } = useParams();

  const { data: projects, isLoading } = useProjectsQuery(professionalId);

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () =>
      projects
        ? Math.round(projects.length / (defaultRowsPerPage * itemsPerRow))
        : 0,
    [projects]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return projects ? projects.slice(endIndex - itemsCount, endIndex) : [];
  }, [currentPage, projects]);

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const errorMessage = useMemo(() => {
    return !isLoading && projects?.length ? "" : "No current projects";
  }, [projects?.length, isLoading]);

  const navigate = useNavigate();

  return (
    <Box height="100%">
      <Section
        isLoading={isLoading}
        errorMessage={errorMessage}
        loadingSkeleton={
          <Box display="flex" flexDirection="column" gridRowGap={24} pb={10}>
            <PortfolioSkeleton />
            <PortfolioSkeleton />
          </Box>
        }
      >
        <Box py={6}>
          <Box display="flex" flexDirection="column" height="100%">
            {currentPageFilteredItems.map((project: Project) => {
              return (
                <Box
                  key={project._id}
                  width="100%"
                  onClick={() => {
                    navigate(`projects/${project._id}`);
                  }}
                >
                  <Box key={project._id} bgcolor={BLUE} py={4}>
                    <ProjectItem project={project} />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <PaginationFooter
            boxProps={{ py: 6 }}
            rowsPerPage={defaultRowsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handleChangePage}
            totalCount={projects?.length}
            objectNamePlural="projects"
            objectNameSingular="project"
          />
        </Box>
      </Section>
    </Box>
  );
};
