import { AxiosResponse } from "axios";
import { JobPost } from "../../../api-types/job-posts";
import axios from "../../index";
import { JOB_POSTS_URL } from "../../utils";

export const addJobPost = async (
  jobPostData: JobPost
): Promise<AxiosResponse<JobPost>> => {
  return await axios.post(JOB_POSTS_URL, jobPostData);
};
