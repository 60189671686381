import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback } from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { LoadingTypography } from "../../common/components";
import { MoreMenu } from "./MoreMenu";

type InProcessMenuProps = {
  width?: number;
  label: string;
  classes?: any;
  labelCount: number;
  isLoading?: boolean;
};

const useStyles = makeStyles({
  root: {
    letterSpacing: 0.25,
    // "& :hover": {
    //   textStrokeWidth: 1,
    // },
  },
});

export const More: React.FC<InProcessMenuProps> = ({
  label,
  labelCount,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const styles = useStyles();

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box className={styles.root}>
      <Button
        variant="contained"
        disableTouchRipple
        style={{
          backgroundColor: BLUE,
          color: YELLOW,
          width: "fit-content",
          height: 58,
          paddingLeft: 16,
          paddingRight: 16,
        }}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography
          component="div"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 18,
            columnGap: 4,
          }}
          color="primary"
        >
          {label}
          {isLoading !== undefined && (
            <Box display="flex" justifyContent="flex-end">
              <LoadingTypography
                width={32}
                lineHeight={2}
                isLoading={isLoading}
                typographyProps={{
                  color: "inherit",
                }}
              >
                <b>{`(${labelCount})`}</b>
              </LoadingTypography>
            </Box>
          )}
        </Typography>
      </Button>
      <MoreMenu anchorEl={anchorEl} handleClose={handleClose} />
    </Box>
  );
};
