import { Box } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import {
  ProfessionalAccountSummaryDescription,
  ProfessionalAccountSummaryInfo,
} from "../../../../common/account/profile";
import { useProfessional } from "../../../../common/hooks/professionals/professional/useProfessional";

export const ProfessionalAccountSummary: React.FC = () => {
  const { professionalId } = useParams();

  const { data: professional, isLoading } = useProfessional(professionalId);

  return (
    <Box display="flex" gridColumnGap={48} height={300} width="100%">
      <ProfessionalAccountSummaryInfo
        profileInformation={professional?.profileInformation}
        isLoading={isLoading}
      />
      <ProfessionalAccountSummaryDescription
        profileInformation={professional?.profileInformation}
        accountInformation={professional?.accountInformation}
        isLoading={isLoading}
        averageRating={professional?.averageRating}
        ratingsCount={professional?.ratingsCount}
      />
    </Box>
  );
};
