import { useMutation } from "react-query";
import { addMessage } from "../../../../api-services/contact";
import { ContactMessage } from "../../../../api-types/contact";

export const useAddMessage = () => {
  return useMutation(async (contactMessage: ContactMessage) => {
    const { data } = await addMessage(contactMessage);

    return data;
  });
};
