/* eslint-disable no-undef */
import AWS from "aws-sdk";
import { AWSFile } from "../../modules/common/types";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3Bucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
  region: process.env.REACT_APP_S3_REGION,
});

export const uploadFiles = async (files: any[], folderPath?: string) => {
  const promises = files.map((file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME ?? "",
      Key: `${folderPath ? `${folderPath}/` : ""}${file.name}`,
    };

    return s3Bucket.upload(params).promise();
  });

  return Promise.all(promises);
};

export const uploadResume = async (file: File, jobPostId: string) => {
  const [uploadedResume] = (
    await uploadFiles([file], `resumes/${jobPostId}`)
  ).map((file) => {
    return {
      key: file.Key,
      location: file.Location,
    };
  });

  return uploadedResume;
};

export const getAWSFiles = async (keys: string[]): Promise<AWSFile[]> => {
  const files: AWSFile[] = [];
  for (const key of keys) {
    const response = await s3Bucket
      .getObject({
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME ?? "",
        Key: key,
      })
      .promise();
    if (response.Body) {
      files.push({ key, buffer: Buffer.from(response.Body) });
    }
  }

  return files;
};
