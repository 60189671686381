import { Box, FormControlLabel, Typography } from "@material-ui/core";
import { Field, FormikErrors, FormikValues } from "formik";
import { ChangeEvent, FC, Fragment } from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { Radio } from "../../../../common/components";
import { BackButton } from "../../../../common/elements/BackButton";
import { useRequiredFieldLabel } from "../../../../common/hooks/utils";
import { RadioWithInput, RadioGroupContainer } from "../../utils";
import { BudgetSchema } from "../validation-schema";

type BudgetStepProps = {
  handleBlur?: (e: FocusEvent) => void;
  handleChange?: (e: ChangeEvent) => void;
  handleFocus?: (field: string, value: string) => void;
  onBack?: () => void;
  values: FormikValues;
  errors: FormikErrors<BudgetSchema>;
};

export type Estimate = {
  type: string;
  estimate: {
    type?: string;
    min?: number;
    max?: number;
  };
  fixed?: number;
};

export type BudgetType = {
  type: string;
  hourlyRate: Estimate;
  hours: Estimate;
  timeFrame: Estimate;
  // paymentSchedule: {
  //   type: string;
  // };
};

export const initialBudgetValues: { budget: BudgetType } = {
  budget: {
    type: "fixed",
    hourlyRate: {
      type: "estimate",
      estimate: {
        type: "weeks",
      },
    },
    hours: {
      type: "estimate",
      estimate: { type: "weeks" },
    },
    timeFrame: {
      type: "ongoing",
      estimate: {
        type: "weeks",
        min: 0,
        max: 0,
      },
      fixed: 0,
    },
    // paymentSchedule: {
    //   type: "",
    // },
  },
};

export const BudgetStep: FC<BudgetStepProps> = ({
  handleBlur,
  handleChange,
  handleFocus,
  onBack,
  values,
  errors,
}) => {
  const boldStyles = useBoldStyles();
  const requiredFieldLabel = useRequiredFieldLabel();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box
        display="flex"
        justifyContent="space-between"
        gridColumnGap={20}
        pt={10}
        width="100%"
      >
        <Box maxWidth="30%">
          <Typography variant="subtitle1" className={boldStyles.root}>
            Project Budget
          </Typography>
        </Box>

        <Box width="60%">
          <RadioGroupContainer
            subTitle="Is this an hourly rate or a fixed price budget?"
            title={requiredFieldLabel("Budget Type")}
          >
            <Box>
              <Field
                as={FormControlLabel}
                control={
                  <Radio
                    checked={values.budget.type === "hourly"}
                    data-cy="hourly-budget"
                  />
                }
                label="Hourly rate"
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                name="budget.type"
                value="hourly"
              />
            </Box>
            <Box>
              <Field
                as={FormControlLabel}
                control={
                  <Radio
                    checked={values.budget.type === "fixed"}
                    data-cy="fixed-budget"
                  />
                }
                label="Fixed price"
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                name="budget.type"
                value="fixed"
              />
            </Box>
          </RadioGroupContainer>

          {values.budget.type === "hourly" && (
            <Fragment>
              <RadioGroupContainer
                title={requiredFieldLabel("Set Hourly Rate")}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        colorRadio
                        colorInput
                        name={`budget.hourlyRate`}
                        minValue={values?.budget.hourlyRate.estimate.min ?? ""}
                        maxValue={values?.budget.hourlyRate.estimate.max ?? ""}
                        checked={values?.budget.hourlyRate.type === "estimate"}
                        radioValue="estimate"
                        isMinMaxInput
                        leftLabel="Between $"
                        betweenInputsLabel="/hr and $"
                        minError={errors.budget?.hourlyRate?.estimate?.min}
                        maxError={errors.budget?.hourlyRate?.estimate?.max}
                      />
                    }
                    label="/hr"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        leftLabel="Exactly $"
                        colorRadio
                        colorInput
                        name={`budget.hourlyRate`}
                        value={values?.budget.hourlyRate.fixed ?? ""}
                        checked={values?.budget.hourlyRate.type === "fixed"}
                        radioValue="fixed"
                        error={errors.budget?.hourlyRate?.fixed}
                      />
                    }
                    label="/hr"
                  />
                </Box>
              </RadioGroupContainer>

              <RadioGroupContainer
                subTitle="Estimate the hours you’d like dedicated to this project per week"
                title={requiredFieldLabel("Hours Dedicated to This Project")}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        colorRadio
                        colorInput
                        name={`budget.hours`}
                        minValue={values?.budget.hours.estimate.min ?? ""}
                        maxValue={values?.budget.hours.estimate.max ?? ""}
                        checked={values?.budget.hours.type === "estimate"}
                        radioValue="estimate"
                        isMinMaxInput
                        leftLabel="Between"
                        betweenInputsLabel="and"
                        minError={errors.budget?.hours?.estimate?.min}
                        maxError={errors.budget?.hours?.estimate?.max}
                      />
                    }
                    label="hours per week"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        leftLabel="Exactly"
                        colorRadio
                        colorInput
                        name={`budget.hours`}
                        value={values?.budget.hours.fixed ?? ""}
                        checked={values?.budget.hours.type === "fixed"}
                        radioValue="fixed"
                        error={errors.budget?.hours?.fixed}
                      />
                    }
                    label="hours per week"
                  />
                </Box>
              </RadioGroupContainer>

              {/* <RadioGroupContainer
                subTitle="Estimate how long you think the project will last"
                title={requiredFieldLabel("Estimate Timeframe")}
              >
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        colorRadio
                        colorInput
                        width="small"
                        name={`budget.timeFrame`}
                        minValue={values?.budget.timeFrame.estimate.min}
                        maxValue={values?.budget.timeFrame.estimate.max}
                        checked={values?.budget.timeFrame.type === "estimate"}
                        radioValue="estimate"
                        isMinMaxInput
                        leftLabel="Between"
                        betweenInputsLabel="and"
                      />
                    }
                    label="week(s)"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <RadioWithInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        leftLabel="Exactly"
                        colorRadio
                        colorInput
                        width="small"
                        name={`budget.timeFrame`}
                        value={values?.budget.timeFrame.fixed}
                        checked={values?.budget.timeFrame.type === "fixed"}
                        radioValue="fixed"
                      />
                    }
                    label="week(s)"
                  />
                </Box>
                <Box>
                  <Field
                    as={FormControlLabel}
                    name="budget.timeFrame.type"
                    value="ongoing"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    control={
                      <Radio
                        checked={values?.budget.timeFrame.type === "ongoing"}
                      />
                    }
                    label="On-going"
                  />
                </Box>
                <Box>
                  <Field
                    as={FormControlLabel}
                    name="budget.timeFrame.type"
                    value="notSure"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    control={
                      <Radio
                        checked={values?.budget.timeFrame.type === "notSure"}
                      />
                    }
                    label="I’m not sure"
                  />
                </Box>
              </RadioGroupContainer> */}

              {/*<RadioGroupContainer*/}
              {/*  subTitle="How often would you like to schedule payments?"*/}
              {/*  title="Payment Schedule"*/}
              {/*>*/}
              {/*  <Field*/}
              {/*    as={FormControlLabel}*/}
              {/*    name="budget.paymentSchedule.type"*/}
              {/*    value="weekly"*/}
              {/*    onChange={handleChange}*/}
              {/*    onBlur={handleBlur}*/}
              {/*    onFocus={handleFocus}*/}
              {/*    control={*/}
              {/*      <Radio*/}
              {/*        className={radioStyles.root}*/}
              {/*        checked={values?.budget.paymentSchedule.type === "weekly"}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label="Weekly"*/}
              {/*  />*/}
              {/*  <Field*/}
              {/*    as={FormControlLabel}*/}
              {/*    name="budget.paymentSchedule.type"*/}
              {/*    value="biWeekly"*/}
              {/*    onChange={handleChange}*/}
              {/*    onBlur={handleBlur}*/}
              {/*    onFocus={handleFocus}*/}
              {/*    control={*/}
              {/*      <Radio*/}
              {/*        className={radioStyles.root}*/}
              {/*        checked={*/}
              {/*          values?.budget.paymentSchedule.type === "biWeekly"*/}
              {/*        }*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label="Every two weeks"*/}
              {/*  />*/}
              {/*</RadioGroupContainer>*/}
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};
