import {
  Box,
  Button,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useBoldStyles,
  useRedButtonStyles,
} from "../../../../../themes/style-hooks";
import {
  ShowImages,
  Section,
  TextOverflow,
} from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../../../professional/job-requests/search-job-requests-list/job-request-item/content";
import { useBidConfirmationModal } from "../../state/hooks";
import { EditDetails } from "../edit-details";

type DetailsProps = {
  editing?: boolean;
  setIsEditing: (value: boolean) => void;
};

const useStyles = makeStyles({
  root: {
    fontSize: 15,
    paddingBottom: 2,
    whiteSpace: "nowrap",
  },
});

export const Details: React.FC<DetailsProps> = ({
  editing = false,
  setIsEditing,
}) => {
  const redButtonStyles = useRedButtonStyles();

  const boldStyles = useBoldStyles();

  const setConfirmationModalState = useBidConfirmationModal().set;

  const { jobRequestId } = useParams();

  const jobHeaderDetailsStyles = useStyles();

  const { data: jobRequest, isLoading } = useJobRequestQuery();

  const skills = useMemo(() => {
    if (jobRequest?.skills && !isLoading) {
      return jobRequest?.skills.map((skill) => skill.name);
    }

    return [];
  }, [jobRequest, isLoading]);

  if (editing) {
    return <EditDetails isEditing setIsEditing={setIsEditing} />;
  }

  const hasImages = (jobRequest?.images ?? []).length > 0;

  return (
    <Box>
      <Section isLoading={isLoading}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <TextOverflow
            width="30%"
            style={{
              fontSize: 24,
            }}
            className={boldStyles.root}
          >
            {jobRequest?.title}
          </TextOverflow>
          {jobRequest?.createdAt && (
            <Typography className={jobHeaderDetailsStyles.root} variant="body2">
              {`Posted ${format(
                new Date(jobRequest?.createdAt),
                formatNominativeMonth
              )}`}
            </Typography>
          )}
          <Box display="flex" marginLeft="auto" gridColumnGap={10}>
            <Button
              onClick={() => {
                setConfirmationModalState({ withdraw: true });
              }}
              size="small"
              className={redButtonStyles.delete}
            >
              Withdraw
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box py={2.5}>
          <Divider />
        </Box>
        <Description description={jobRequest?.description} />
        <Box pt={2.5}>
          <AvailabilityCostBids
            budget={jobRequest?.budget}
            fixedProjectFunding={jobRequest?.fixedProjectFunding}
            jobRequestId={jobRequestId}
          />
        </Box>

        {Boolean(skills.length > 0) && (
          <Box pt={2.5}>
            <SkillsOutput skills={skills} isHovering={true} />
          </Box>
        )}
        {hasImages && (
          <Box pt={2.5}>
            <ShowImages selectedFiles={jobRequest?.images} />
          </Box>
        )}
      </Section>
    </Box>
  );
};
