import { Box } from "@material-ui/core";
import {
  HourlyRateFundingDetails,
  JobRequestHourlyRateMilestoneTable,
} from "../../utils";
import { useUpdateBudgetTimeFrame } from "../hooks";

export const HourlyRateFundingContent = () => {
  useUpdateBudgetTimeFrame();

  return (
    <Box display="flex" flexDirection="column" gridColumnGap={20} width="100%">
      <HourlyRateFundingDetails />

      <JobRequestHourlyRateMilestoneTable />
    </Box>
  );
};
