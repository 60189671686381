import {
  Box,
  useTheme,
  Badge,
  IconButton,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../../api-types/common";
import { BLUE, COLOR, YELLOW } from "../../../themes/components/utils";
import { NotificationsMenu } from "../../professional/navbar/NotificationsMenu";
import { useMetadataQuery } from "../hooks/metadata/useMetadataQuery";
import { useReadNotificationMutation } from "../hooks/notifications/useReadNotificationMutation";
import { TheTalentedLogo } from "../logo";
import { UserMenu } from "./UserMenu";
import { UserNavbar } from "./UserNavbar";

type NavbarProps = {
  accountType?: AccountType | null;
  inverse?: boolean;
};

export const getMenuItemLabel = (
  baseLabel: string,
  isLoading: boolean,
  count?: number
) => {
  if (isLoading) return baseLabel;

  return `${baseLabel} (${count ?? 0})`;
};

export const Navbar: React.FC<NavbarProps> = ({
  accountType,
  inverse = true,
}) => {
  const smallWidth = useMediaQuery("(max-width: 1320px)", { noSsr: true });
  const theme = useTheme();

  const { data: metadata } = useMetadataQuery({
    enabled: !!accountType,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { mutate: readNotification } = useReadNotificationMutation();

  const onOptionsDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      readNotification("all");
    },
    [readNotification]
  );

  const onDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const notificationsCount = metadata?.notificationsCount ?? 0;
  const navigate = useNavigate();

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={5}
      px={5}
    >
      <TheTalentedLogo inverse={inverse} />
      {accountType && <UserNavbar accountType={accountType} />}
      {!accountType && (
        <Box display="flex" gridColumnGap={12}>
          <Button
            variant="text"
            color={inverse ? "secondary" : "primary"}
            size="medium"
            onClick={() => navigate("/sign-up")}
            data-cy-sign-up="top-bar"
            style={{
              fontWeight: "medium",
            }}
          >
            Sign Up
          </Button>
          <Button
            size="medium"
            variant="contained"
            onClick={() => navigate("/sign-in")}
            data-cy-sign-in="top-bar"
            style={{
              backgroundColor: BLUE,
              color: YELLOW,
              fontWeight: "medium",
            }}
          >
            Sign In
          </Button>
        </Box>
      )}
      {accountType && (
        <Box display="flex" alignItems="center" gridColumnGap={8}>
          {!smallWidth && (
            <Box height={40} pt={2}>
              <Badge badgeContent={notificationsCount} color="primary" max={9}>
                <IconButton size="small" onClick={onOptionsDropdownClick}>
                  <NotificationsOutlinedIcon color="secondary" />
                </IconButton>
              </Badge>
              <NotificationsMenu
                anchorEl={anchorEl}
                onClose={onDropdownClose}
              />
            </Box>
          )}
          <Box pt={2}>
            <UserMenu accountType={accountType} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
