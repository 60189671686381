import { useEffect, useState } from "react";
import { useWindowDimensions } from "../../window";
import { SCROLL_OFFSET } from "../constants";

export const useScrollEffects = ({
  pagesCount,
  backgroundColors,
}: {
  pagesCount: number;
  backgroundColors: string[];
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [currentVisibleView, setCurrentVisibleView] = useState(0);
  const [lastVisitedView, setLastVisitedView] = useState(0);
  const { height } = useWindowDimensions();

  useEffect(() => {
    const container: HTMLElement | null = document.querySelector(
      ".parallax-container"
    );

    let lastView = 0;
    let currentView = 0;

    const updateBackgroundTransition = (
      container: HTMLElement,
      transitionTime: number
    ) => {
      container.style.backgroundColor = backgroundColors[currentView];
      container.style.transition = `background-color ${transitionTime}ms`;
      container.style.willChange = "background-color";
    };

    const handleScroll = () => {
      if (!container) {
        return;
      }

      currentView = Math.floor((container.scrollTop ?? 0) / height);

      if (container.scrollTop >= height * (pagesCount - 1) - SCROLL_OFFSET) {
        container.scrollTo(0, 0);

        currentView = 0;
        return;
      }

      setIsScrolling(container.scrollTop > height / 2);

      if (lastView !== currentView) {
        const transitionTime =
          currentView > 1 && currentView < pagesCount - 1 ? 500 : 0;

        updateBackgroundTransition(container, transitionTime);
        setCurrentVisibleView(currentView);
        setLastVisitedView(lastView);

        lastView = currentView;
      }
    };

    container?.addEventListener("scroll", handleScroll);

    return () => container?.removeEventListener("scroll", handleScroll);
  }, [backgroundColors, height, pagesCount]);

  return { isScrolling, currentVisibleView, lastVisitedView };
};
