import React from "react";

export const SendMessageIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.113"
      height="27.114"
      viewBox="0 0 27.113 27.114"
    >
      <path
        id="Path_150"
        data-name="Path 150"
        d="M6197.95,3887.953a1.4,1.4,0,0,0-1.925,0l-4.067,4.067a1.361,1.361,0,0,0,1.925,1.925h0l1.749-1.763v4.867a1.355,1.355,0,1,0,2.711,0v-4.867l1.749,1.763a1.356,1.356,0,0,0,1.917.008l.008-.008a1.356,1.356,0,0,0,.008-1.917l-.008-.008Zm-.962-8.528a13.55,13.55,0,1,0,6.692,1.764A13.21,13.21,0,0,0,6196.988,3879.425Zm0,24.4a10.845,10.845,0,1,1,10.845-10.846A10.846,10.846,0,0,1,6196.988,3903.828Z"
        transform="translate(-6183.431 -3879.425)"
        fill="#D0E4F4"
      />
    </svg>
  );
};
