import { Box, Button, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InquiryData } from "../../../../../api-types/job-requests/inquiries";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import {
  MessagesProvider,
  MessagesInput,
} from "../../../../common/components/messages";
import { ProfessionalSummary } from "../../../../common/components/ProfessionalSummary";

type InquiryProps = {
  inquiry: InquiryData;
  onNewMessage: (newMessage: string) => void;
  isLoading?: boolean;
};

const initialValues = {
  message: "",
};

export const Inquiry: React.FC<InquiryProps> = ({
  inquiry,
  onNewMessage,
  isLoading,
}) => {
  const navigate = useNavigate();

  const [isReplying, setIsReplying] = useState(false);
  const { messages, createdBy, createdAt } = inquiry;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        onNewMessage(values.message);
        resetForm();
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        isValid,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={5}
          >
            <Box
              onClick={() => {
                navigate(`/professionals/${createdBy._id}`);
              }}
              style={{ cursor: "pointer" }}
            >
              <ProfessionalSummary professional={createdBy} />
            </Box>
            <Typography variant="body1">
              Placed on {format(new Date(createdAt), formatNominativeMonth)}
            </Typography>
          </Box>
          <MessagesProvider
            messages={messages}
            height={400}
            boxProps={{ borderColor: "transparent", padding: "0px" }}
          >
            {isReplying ? (
              <MessagesInput
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                errors={errors}
                isLoading={isLoading}
              />
            ) : null}
          </MessagesProvider>
          {!isReplying && (
            <Box
              display="flex"
              justifyContent="flex-end"
              pb={4}
              mt={9}
              alignItems="center"
            >
              <Button
                size="medium"
                variant="contained"
                onClick={() => setIsReplying(true)}
              >
                Send message
              </Button>
            </Box>
          )}
          <Box py={2}>
            <Divider />
          </Box>
        </form>
      )}
    </Formik>
  );
};
