import { Box, makeStyles, Typography } from "@material-ui/core";
import { FormikHelpers, FormikValues } from "formik";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { BLUE, YELLOW, YELLOW_HOVER } from "../../../themes/components/utils";

type CustomCheckboxProps = {
  label?: string;
  multiple?: boolean;
  labels?: string[];
  leftIcon?: ReactNode;
  value?: boolean;
  width?: number | string;
  height?: number;
  typographyVariant?: "subtitle1" | "body1" | "body2";
  fontSize?: number;
  field?: { name: string };
  form?: FormikHelpers<FormikValues>;
  onClick?: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
};

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      width: 60,
      height: 60,
    },
  },
  checked: {
    "& .MuiSvgIcon-root": {
      fill: BLUE,
    },
  },
  unchecked: {
    "& .MuiSvgIcon-root": {
      fill: YELLOW,
    },
  },
  box: {
    cursor: "pointer",
  },
  hoverBox: {
    backgroundColor: YELLOW_HOVER,
    cursor: "pointer",
  },
});

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  height = 85,
  typographyVariant = "subtitle1",
  label,
  leftIcon,
  value,
  width = 500,
  field,
  form,
  onClick,
  multiple,
  labels,
  isDisabled,
  isSelected,
}) => {
  const styles = useStyles();
  const [checked, setChecked] = useState(isSelected);
  const [isHovering, setIsHovering] = useState(false);

  const onChangeHandler = useCallback(() => {
    setChecked(!checked);
    form?.setFieldValue(field?.name ?? "", !checked);
    onClick?.();
  }, [checked, field?.name, form, onClick]);

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  useEffect(() => {
    if (value === undefined) return;
    setChecked(value);
  }, [value]);

  return (
    <Box
      color={checked ? BLUE : YELLOW}
      border="2px solid"
      borderColor={YELLOW}
      bgcolor={checked ? YELLOW : BLUE}
      // height={multiple ? (labels?.length ?? 1) * 86 : 86}
      width={width}
      height={height}
      onClick={onChangeHandler}
      className={isHovering ? styles.hoverBox : styles.box}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      style={
        isDisabled
          ? {
              pointerEvents: "none",
              opacity: 0.6,
            }
          : {}
      }
      data-cy-btn={field?.name}
    >
      <Box
        display="flex"
        flexDirection={multiple ? "column" : "row"}
        justifyContent="space-evenly"
        height="100%"
        alignItems="center"
        px={5}
      >
        {multiple ? (
          labels?.map((l) => (
            <Box display="flex" width="100%" justifyContent="start">
              {l && (
                <Typography
                  variant={typographyVariant}
                  color={checked || isHovering ? "secondary" : "primary"}
                  style={{ fontWeight: 450 }}
                >
                  {l}
                </Typography>
              )}
            </Box>
          ))
        ) : (
          <Box display="flex" alignItems="center" width="100%">
            {leftIcon}
            {label && (
              <Typography
                variant={typographyVariant}
                color={checked || isHovering ? "secondary" : "primary"}
                style={{ fontWeight: 450 }}
              >
                {label}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
