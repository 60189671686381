import { Box, makeStyles, Tooltip, TooltipProps } from "@material-ui/core";
import { FC, useRef } from "react";
import { BLUE, LIGHT_BLUE, RED } from "../../../themes/components/utils";
import { useTooltipPlacement } from "../hooks/utils";

const useStyles = makeStyles(({ palette, spacing }) => ({
  arrow: {
    "&:before": {
      border: "1px solid",
      borderColor: (props: { type: string }) =>
        props.type === "error" ? RED : palette.primary.main,
      backgroundColor: (props: { type: string }) =>
        props.type === "error" ? RED : palette.primary.main,
    },
  },
  tooltip: {
    backgroundColor: (props: { type: string }) =>
      props.type === "error" ? RED : palette.primary.main,
    maxWidth: "60vw",
    maxHeight: "70vh",
    overflow: "auto",
    fontFamily: "Jost",
    fontSize: 16,
    fontWeight: 600,
    padding: spacing(2, 4),
    color: (props: { type?: string }) =>
      props.type === "error" ? LIGHT_BLUE : BLUE,
  },
}));

export const CustomTooltip: FC<TooltipProps & { type?: string }> = ({
  children,
  type = "info",
  ...props
}) => {
  const styles = useStyles({ type });
  const tooltipRef = useRef(null);

  const placement = useTooltipPlacement(tooltipRef);

  return (
    <Tooltip
      {...props}
      ref={tooltipRef}
      interactive={true}
      placement={props.placement ?? placement}
      classes={{ arrow: styles.arrow, tooltip: styles.tooltip }}
      style={
        {
          // height: "400px",
          // overflow: "auto",
        }
      }
    >
      {children}
    </Tooltip>
  );
};
