import { OutlinedInputClassKey, OutlinedInputProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiOutlinedInput: MuiComponentThemeDefinition<
  OutlinedInputClassKey,
  OutlinedInputProps
> = {
  props: {},
  override: () => ({
    root: {
      borderRadius: 1,
    },
    adornedEnd: {
      paddingRight: 10,
    },
  }),
};
