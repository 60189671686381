import { useMutation, UseMutationOptions } from "react-query";
import { useParams } from "react-router-dom";
import { updateProject } from "../../../../../api-services/projects/project";
import { Project } from "../../../../../api-types/projects";

type UseProjectUpdateMutationOptions = UseMutationOptions<
  Project,
  unknown,
  Partial<Project>
>;

export const useUpdateProject = (options?: UseProjectUpdateMutationOptions) => {
  const { projectId } = useParams();

  return useMutation(
    async (updatedProject: Partial<Project>) => {
      const { data } = await updateProject(projectId, updatedProject);
      return data;
    },
    {
      ...options,
    }
  );
};
