import { Box, Typography } from "@material-ui/core";
import React from "react";

type TextBoxProps = {
  text: string;
  variant: "body1" | "h1" | "h2" | "h3" | "h4" | "subtitle1" | "subtitle2";
  color?: "primary" | "secondary" | "initial";
  classes?: string;
  boxProps?: any;
};

export const TextBox: React.FC<TextBoxProps> = ({
  text,
  variant,
  color = "initial",
  classes,
  boxProps,
}) => {
  return (
    <Box {...boxProps}>
      <Typography variant={variant} color={color} className={classes}>
        {text}
      </Typography>
    </Box>
  );
};
