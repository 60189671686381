import { Box, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { TextBox } from "../../../../client/job-requests/utils/TextBox";
import { Section, ShowImages } from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { ProfessionalSummary } from "../../../../common/components/ProfessionalSummary";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useProfessionalReferralQuery } from "../../../../common/hooks/projects/project/collaborations/useProfessionalReferralQuery";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../../job-requests/search-job-requests-list/job-request-item/content";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: professionalReferral, isLoading } =
    useProfessionalReferralQuery();

  const skills = useMemo(() => {
    if (!professionalReferral?.project?.jobRequest?.skills || isLoading) {
      return [];
    }

    return professionalReferral?.project?.jobRequest?.skills.map(
      (skill) => skill.name
    );
  }, [isLoading, professionalReferral?.project?.jobRequest?.skills]);

  const hasImages = Boolean(
    professionalReferral?.project.images?.length ?? 0 > 0
  );

  return (
    <Section isLoading={isLoading}>
      <TextBox
        text="The Professional Below Has Invited You To Consider a Project"
        variant="subtitle2"
        classes={boldStyles.root}
      />
      <Box py={2.5}>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={20} pt={10} pb={5}>
        <ProfessionalSummary
          width={64}
          professional={professionalReferral?.initiator}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={20}
        pb={10}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: 600,
          }}
        >
          {professionalReferral?.professional?.accountInformation?.firstName}{" "}
          {professionalReferral?.professional?.accountInformation?.lastName}`s
          Invitation Message
        </Typography>
        <Typography variant="body1">
          {professionalReferral?.introductoryMessage || "Empty message"}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="subtitle2" className={boldStyles.root}>
          {professionalReferral?.project?.jobRequest?.title}
        </Typography>
        <Box display="flex" gridColumnGap={40}>
          <Typography variant="body2">
            Posted {format(new Date(), formatNominativeMonth)}
          </Typography>
          <Typography variant="body2">
            Placed bid {format(new Date(), formatNominativeMonth)}
          </Typography>
        </Box>
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description
        description={professionalReferral?.project?.jobRequest?.description}
        label=""
      />
      <Box pt={2.5}>
        <AvailabilityCostBids
          budget={professionalReferral?.project?.jobRequest?.budget}
          fixedProjectFunding={
            professionalReferral?.project.jobRequest.fixedProjectFunding
          }
        />
      </Box>
      {Boolean(skills.length) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={professionalReferral?.project.images} />
        </Box>
      )}
    </Section>
  );
};
