import { InputLabelClassKey, InputLabelProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiInputLabel: MuiComponentThemeDefinition<
  InputLabelClassKey,
  InputLabelProps
> = {
  props: {},

  override: () => ({
    outlined: {
      fontSize: 21,
      color: "#0F1E4A",
      "&.MuiInputLabel-marginDense": {
        transform: "translate(16px, 29px) scale(1)",
      },
      "&.MuiInputLabel-shrink": {
        fontSize: 14,
        transform: "translate(2px, -16px) scale(1)",
      },
    },
  }),
};
