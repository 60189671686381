import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FieldArray, Formik, FormikValues } from "formik";
import { isEqual, keys, pick, uniqueId } from "lodash";
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { object } from "yup";
import { BLUE } from "../../../../themes/components/utils";
import { useRedButtonStyles } from "../../../../themes/style-hooks";
import { HOURLY_RATE_DIFF_PROPS } from "../../../common/components/milestones/constants";
import { HourlyRateDiffTableRow } from "../../../common/components/milestones/HourlyRateDiffTableRow";
import { HourlyRateEditableTableRow } from "../../../common/components/milestones/HourlyRateEditableTableRow";
import { HourlyRateMilestonesSummary } from "../../../common/components/milestones/HourlyRateMilestonesSummary";
import { HourlyRateTableRow } from "../../../common/components/milestones/HourlyRateTableRow";
import { MilestoneChangesActions } from "../../../common/components/milestones/MilestoneChangesActions";
import { getPendingChanges } from "../../../common/components/milestones/utils";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useRequiredFieldLabel } from "../../../common/hooks/utils";
import { useEstimatedCostPerWeekCore } from "../../job-requests/create-job-request-wizard/hooks";
import {
  HourlyRateMilestone,
  HourlyRateMilestonesSchemaDefinition,
  MilestoneStatus,
} from "../../job-requests/create-job-request-wizard/validation-schema";
import { useHourlyMilestonesLabels } from "../hooks";
import { TABLE_HEIGHT } from "./constants";

type HourlyRateMilestonesTableProps = {
  canAcceptPendingChanges?: boolean;
  canEditMilestones?: boolean;
  closingRequest?: boolean;
  existingMilestonesIds: string[];
  isAcceptingWork?: boolean;
  isEditingMilestones: boolean;
  isLoading?: boolean;
  isRequestingChanges?: boolean;
  milestones: HourlyRateMilestone[];
  onAcceptChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onAcceptWork?: (milestoneId?: string) => void;
  onDenyChanges?: (milestoneId?: string, isNew?: boolean) => void;
  onFinishEdit: (values: FormikValues) => void;
  onRequestChanges?: (milestoneId?: string) => void;
  onStartEdit: () => void;
  pendingChanges?: HourlyRateMilestone[];
  setIsClosing: () => void;
  setIsEditingMilestones: (isEditing: boolean) => void;
};

export const HourlyRateMilestonesTable: FC<HourlyRateMilestonesTableProps> = ({
  canAcceptPendingChanges,
  canEditMilestones,
  closingRequest,
  existingMilestonesIds,
  isAcceptingWork,
  isEditingMilestones,
  isLoading,
  isRequestingChanges,
  milestones,
  onAcceptChanges,
  onAcceptWork,
  onDenyChanges,
  onFinishEdit,
  onStartEdit,
  onRequestChanges,
  pendingChanges,
  setIsEditingMilestones,
  setIsClosing,
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const requiredFieldLabel = useRequiredFieldLabel();

  const { data: project } = useProjectQuery();

  const redButtonStyles = useRedButtonStyles();

  const [selectedMilestones, setSelectedMilestones] = useState<
    HourlyRateMilestone[]
  >([]);

  const handleSelectMilestone = useCallback(
    (event: ChangeEvent<HTMLInputElement>, milestone: any) => {
      if (event.target.checked) {
        setSelectedMilestones([...selectedMilestones, milestone]);
      }

      if (!event.target.checked) {
        const updatedMilestones = [...selectedMilestones];

        const index = updatedMilestones.findIndex(
          (m) => m._id === milestone._id
        );

        updatedMilestones.splice(index, 1);
        setSelectedMilestones(updatedMilestones);
      }
    },
    [selectedMilestones]
  );

  const isMilestoneSelected = useCallback(
    (milestone: HourlyRateMilestone) => {
      return Boolean(
        selectedMilestones.find(({ _id }) => _id === milestone._id)
      );
    },
    [selectedMilestones]
  );

  const { hoursLabel, hourlyRateLabel } = useHourlyMilestonesLabels(
    project?.jobRequest.budget
  );

  const estimatedCostPerWeek = useEstimatedCostPerWeekCore(
    project?.jobRequest?.budget
  );

  const onAcceptWorkHandler = useCallback(
    (amount: number, milestoneId?: string) => {
      // https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
      const amountIncludingCommission = (amount + 0.3) / (1 - 0.029);
      // const amountIncludingCommission = amount + amount * 0.029 + 0.3;

      const roundedAmount = amount
        ? Math.round((amountIncludingCommission + Number.EPSILON) * 100) / 100
        : 0;

      if (!project?.jobRequest._id || !milestoneId) {
        return;
      }

      navigate(
        {
          pathname: "/payment/",
          search: `?${createSearchParams({
            amount: roundedAmount.toString(),
            jobRequestId: project.jobRequest._id,
            milestonesIds: JSON.stringify([milestoneId]),
            isHourly: String(true),
            professionalId: project.professionals[0].professional._id,
          })}`,
        },
        {
          state: {
            backRoute: pathname,
            search: `${search}&milestoneId=${milestoneId}`,
          },
        }
      );
    },
    [
      navigate,
      pathname,
      project?.jobRequest._id,
      project?.professionals,
      search,
    ]
  );

  useEffect(() => {
    const isFunded = Boolean(searchParams.get("isFunded"));
    const milestoneId = searchParams.get("milestoneId");

    if (isFunded && milestoneId) {
      setSearchParams({ tab: "payments" }, { replace: true });
      onAcceptWork?.(milestoneId);
    }
  }, [onAcceptWork, searchParams, setSearchParams]);

  return (
    <Fragment>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pb={4}
        pt={8}
      >
        <HourlyRateMilestonesSummary
          jobRequest={project?.jobRequest}
          milestonesCount={milestones.length ?? 0}
        />
        <Box display="flex" gridColumnGap={8}>
          <Button
            disabled={isEditingMilestones || closingRequest}
            onClick={setIsClosing}
            size="small"
            className={redButtonStyles.delete}
          >
            Cancel Project
          </Button>
          <Button
            disabled={isEditingMilestones || !canEditMilestones}
            onClick={onStartEdit}
            size="small"
          >
            Edit Milestones
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box pt={6} minHeight={240}>
        {isLoading ? (
          <Box
            alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            initialValues={{
              milestones,
            }}
            onSubmit={(values) => {
              const newChanges = getPendingChanges(
                values.milestones,
                milestones,
                pendingChanges
              );

              onFinishEdit({ milestones: newChanges });
            }}
            enableReinitialize
            validationSchema={object({
              milestones: HourlyRateMilestonesSchemaDefinition,
            })}
            validateOnChange
            validateOnMount
          >
            {({ errors, handleReset, setFieldValue, submitForm, values }) => {
              return (
                <form>
                  <FieldArray name="milestones">
                    {({ push, remove }) => (
                      <Fragment>
                        <TableContainer
                          style={{
                            maxHeight: TABLE_HEIGHT,
                            marginLeft: -16,
                            width: "calc(100% + 16px)",
                          }}
                        >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  <Checkbox disabled />
                                </TableCell>
                                <TableCell variant="head" width="20%">
                                  {requiredFieldLabel("Milestone Description")}
                                </TableCell>
                                <TableCell variant="head" width="25%">
                                  {requiredFieldLabel("Due Date")}
                                </TableCell>
                                <TableCell variant="head" width="15%">
                                  Hours/Week
                                </TableCell>
                                <TableCell variant="head" width="15%">
                                  Dollars/Hour
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Estimated Cost
                                </TableCell>
                                <TableCell variant="head" width="10%">
                                  Milestone Status
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.milestones.map((milestone, index) => {
                                const { _id, status, actualWork, isSubmitted } =
                                  milestone;

                                const editableMilestone =
                                  !status || status === MilestoneStatus.PENDING;

                                const suggestedChanges = pendingChanges?.find(
                                  (m) => _id && m._id === _id
                                );

                                const showDiff =
                                  (suggestedChanges &&
                                    !isEqual(
                                      pick(milestone, HOURLY_RATE_DIFF_PROPS),
                                      pick(
                                        suggestedChanges,
                                        HOURLY_RATE_DIFF_PROPS
                                      )
                                    )) ||
                                  (isSubmitted &&
                                    status !== MilestoneStatus.COMPLETED);

                                const isNewMilestone =
                                  !_id || !existingMilestonesIds.includes(_id);

                                const isDeletedMilestone =
                                  (pendingChanges?.length ?? 0) > 0 &&
                                  milestone &&
                                  !suggestedChanges;

                                const hasActualWork =
                                  milestone?.actualWork?.dueDate ||
                                  milestone?.actualWork?.costPerHour ||
                                  milestone?.actualWork?.hoursPerWeek;

                                const actualCost =
                                  (actualWork?.dueDate ?? 0) *
                                  (actualWork?.hoursPerWeek ?? 0) *
                                  (actualWork?.costPerHour ?? 0);

                                return (
                                  <Fragment key={index}>
                                    {isEditingMilestones &&
                                    editableMilestone ? (
                                      <HourlyRateEditableTableRow
                                        estimatedCostPerWeek={
                                          estimatedCostPerWeek
                                        }
                                        remove={remove}
                                        hasBorder={showDiff}
                                        hoursLabel={hoursLabel}
                                        hourlyRateLabel={hourlyRateLabel}
                                        milestone={milestone}
                                        onSelect={(event) =>
                                          handleSelectMilestone(
                                            event,
                                            milestone
                                          )
                                        }
                                        rowIndex={index}
                                        selected={isMilestoneSelected(
                                          milestone
                                        )}
                                      />
                                    ) : (
                                      <HourlyRateTableRow
                                        actions={
                                          (isDeletedMilestone ||
                                            isNewMilestone) &&
                                          canAcceptPendingChanges && (
                                            <MilestoneChangesActions
                                              onAcceptChanges={() =>
                                                onAcceptChanges?.(
                                                  _id,
                                                  isNewMilestone &&
                                                    !isDeletedMilestone
                                                )
                                              }
                                              onDenyChanges={() =>
                                                onDenyChanges?.(
                                                  _id,
                                                  isNewMilestone &&
                                                    !isDeletedMilestone
                                                )
                                              }
                                            />
                                          )
                                        }
                                        estimatedCostPerWeek={
                                          estimatedCostPerWeek
                                        }
                                        hasBorder={showDiff}
                                        hoursLabel={hoursLabel}
                                        hourlyRateLabel={hourlyRateLabel}
                                        milestone={milestone}
                                        showAsDiff={
                                          isNewMilestone || isDeletedMilestone
                                        }
                                      />
                                    )}
                                    {showDiff && (
                                      <HourlyRateDiffTableRow
                                        actions={
                                          <Fragment>
                                            {!hasActualWork &&
                                              canAcceptPendingChanges && (
                                                <MilestoneChangesActions
                                                  onAcceptChanges={() =>
                                                    onAcceptChanges?.(
                                                      _id,
                                                      false
                                                    )
                                                  }
                                                  onDenyChanges={() =>
                                                    onDenyChanges?.(_id, false)
                                                  }
                                                />
                                              )}
                                            {Boolean(hasActualWork) &&
                                              status ===
                                                MilestoneStatus.IN_REVIEW && (
                                                <Fragment>
                                                  <Button
                                                    disabled={
                                                      isEditingMilestones ||
                                                      isAcceptingWork
                                                    }
                                                    onClick={() =>
                                                      onRequestChanges?.(_id)
                                                    }
                                                    size="small"
                                                    style={{
                                                      backgroundColor: BLUE,
                                                    }}
                                                  >
                                                    Request Changes
                                                  </Button>
                                                  <Button
                                                    disabled={
                                                      isEditingMilestones ||
                                                      isRequestingChanges
                                                    }
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      onAcceptWorkHandler(
                                                        actualCost,
                                                        _id
                                                      );
                                                    }}
                                                    size="small"
                                                    style={{
                                                      backgroundColor: BLUE,
                                                    }}
                                                  >
                                                    Release Payment
                                                  </Button>
                                                </Fragment>
                                              )}
                                          </Fragment>
                                        }
                                        actualWork={milestone?.actualWork}
                                        estimatedCostPerWeek={
                                          estimatedCostPerWeek
                                        }
                                        hoursLabel={hoursLabel}
                                        hourlyRateLabel={hourlyRateLabel}
                                        milestone={suggestedChanges}
                                      />
                                    )}
                                  </Fragment>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {isEditingMilestones ? (
                          <Fragment>
                            <Box py={4}>
                              <Divider />
                            </Box>
                            <Box display="flex" gridColumnGap={100}>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() => {
                                    handleReset();
                                    setIsEditingMilestones(false);
                                  }}
                                  size="small"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={keys(errors).length > 0}
                                  onClick={submitForm}
                                  size="small"
                                  variant="contained"
                                >
                                  Save Changes
                                </Button>
                              </Box>
                              <Box display="flex" gridColumnGap={16}>
                                <Button
                                  onClick={() =>
                                    push({
                                      _id: uniqueId(),
                                      amount: 0,
                                      description: "",
                                      dueDate: {
                                        type: "weeks",
                                        min: 0,
                                        max: 0,
                                      },
                                      status:
                                        milestones?.[milestones.length - 1]
                                          ?.status === MilestoneStatus.COMPLETED
                                          ? MilestoneStatus.IN_PROGRESS
                                          : MilestoneStatus.IN_PROGRESS,
                                    })
                                  }
                                  size="small"
                                  variant="contained"
                                >
                                  Add Milestone
                                </Button>
                              </Box>
                            </Box>
                          </Fragment>
                        ) : (
                          <Box height={69} />
                        )}
                      </Fragment>
                    )}
                  </FieldArray>
                </form>
              );
            }}
          </Formik>
        )}
      </Box>
    </Fragment>
  );
};
