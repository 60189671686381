export const BACKGROUND_BLUE_PATHS = [
  "account",
  "/job-requests/saved",
  "account/projects",
  "account/portfolio",
  "job-requests/search",
  "browse",
  "/professionals",
];

export const BROWSE_DEFAULT_URL = "/browse?type=professionals";

export const BROWSE_CLIENT_DEFAULT_URL = "/job-request/new?step=1";
