import { Box, makeStyles } from "@material-ui/core";

type ImagePlaceholderProps = {
  width?: number;
  height?: number;
};

const useStyles = makeStyles({
  lines: {
    background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100' style='stroke-width: 0px; background-color: white;'><path  d='M0 0 L0 1 L100 100 L100 99' fill='%23C3C3C3' /><path d='M0 100 L100 0 L100 1 L1 100' fill='%23C3C3C3' /></svg>")`,
    "background-repeat": "no-repeat",
    "background-position": "center center",
    "background-size": "100% 100%, auto",
  },
});

export const ImagePlaceholder = ({
  width,
  height = 320,
}: ImagePlaceholderProps) => {
  const styles = useStyles();
  return <Box className={styles.lines} width={width} height={height} />;
};
