import axios from "axios";
import { JobPost } from "../../api-types/job-posts";
import { START_FREE_TRIAL } from "../utils";

export const startFreeTrial = async (freeTrial: {
  freeTrialJobPosts: number;
  jobPost?: JobPost;
}) => {
  return await axios.post(START_FREE_TRIAL, freeTrial);
};
