import { Box } from "@material-ui/core";
import { noop } from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useHeadlineText,
  useMediumItalic,
  useStepDStyles,
} from "../../../../../../themes/style-hooks";
import { PackagesCards } from "../../../../../client/job-posts/jobs-packages/components/PackagesCards";
import { useRedirectUrl } from "../../../../../state/hooks";

export const LandingStepE: FC = () => {
  const styles = useStepDStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: "#EF7B7E",
    color: COLOR.WHITE,
    hover: "#EF7B7E",
  });
  const mediumItalic = useMediumItalic();
  const { skewedText, transparent } = useHeadlineText({
    primary: "#C8E3CE",
    secondary: "#EF7B7E",
    background: "#EF7B7E",
  });
  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });

  const navigate = useNavigate();
  const setRedirectUrl = useRedirectUrl().set;

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box
          className={mediumItalic.root}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;Jobs are posted&nbsp;</span>
          </Box>
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;for&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;90 days.&nbsp;</span>
          </Box>
        </Box>
      </Box>
      <Box width="100%" pt={2} pl={4}>
        <Box className={descriptionText.root}>
          A 90-day posting window gives you plenty of time to fill your job
          requirement.{" "}
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Box alignSelf="center" height={360}>
          <PackagesCards
            inverse
            onSelect={noop}
            selectLabel="Start 10-day free trial"
            buttonStyles={`${common} ${root}`}
            radioColor={"#EF7B7E"}
            onClick={() => {
              setRedirectUrl("/jobs-packages/");
              navigate("/jobs-packages/");
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
