import { Box } from "@material-ui/core";
import React from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useAutoLogin } from "../sign-in/useAutoLogin";
import { SignUpProgressBar, SignUpView } from "./common";

export const SignUp: React.FC = () => {
  useAutoLogin();

  return (
    <Box height="100%">
      <SignUpProgressBar />
      <PageWrapper hideNavbar>
        <Box height="calc(100% - 100px)" display="flex" flexDirection="column">
          <SignUpView />
        </Box>
      </PageWrapper>
    </Box>
  );
};
