import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { passwordReset } from "../../../../api-services/password-reset";
import { PasswordResetData } from "../../../../api-types/sign-in";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";

export const usePasswordResetMutation = () => {
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    (passwordResetData: PasswordResetData) => passwordReset(passwordResetData),
    {
      onSuccess(response) {
        notifySuccess(feedbackMessage("", FEEDBACK_TYPE.CHECK_EMAIL));
        navigate("/");
      },
      onError(error: { request: XMLHttpRequest }) {
        switch (error.request.status) {
          case 401:
            notifyError(feedbackMessage("", FEEDBACK_TYPE.WRONG_CREDENTIALS));
            return;
          case 403:
            notifyError(
              "User is not validated, please activate the user by following instructions received on email."
            );
            return;
          case 404:
            notifyError(feedbackMessage("", FEEDBACK_TYPE.ACCOUNT_NOT_FOUND));
            return;
          default:
            notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
        }
      },
    }
  );
};
