import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { fetchJobRequest } from "../../../../../api-services/job-requests/job-request";
import { JobRequest } from "../../../../../api-types/job-requests/job-request";
import { JOB_REQUESTS_QUERY_KEY } from "../useJobRequests";

export const jobRequestKey = (id: string | null) => {
  return `${JOB_REQUESTS_QUERY_KEY}-${id}`;
};

export const useJobRequestQuery = (id?: string) => {
  const { jobRequestId, bidId, isFunded } = useParams();

  return useQuery<JobRequest, unknown, JobRequest>(
    jobRequestKey(id ?? jobRequestId + bidId + isFunded),
    async () => {
      const { data } = await fetchJobRequest(id ?? jobRequestId);

      return data;
    },
    {
      enabled: Boolean(id || jobRequestId),
    }
  );
};

export const useInvalidateJobRequestQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (id: string) => {
      queryClient.invalidateQueries(jobRequestKey(id));
    },
    [queryClient]
  );
};
