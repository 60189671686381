import React from "react";

export const SearchIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <g
        id="Group_504"
        data-name="Group 504"
        transform="translate(-4890.745 498.506)"
      >
        <rect
          id="Rectangle_226"
          data-name="Rectangle 226"
          width="50"
          height="50"
          rx="4"
          transform="translate(4890.745 -498.506)"
          fill="#001d60"
        />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M4912.976-467.216a9.155,9.155,0,0,1-9.145-9.145,9.155,9.155,0,0,1,9.145-9.145,9.156,9.156,0,0,1,9.145,9.145A9.155,9.155,0,0,1,4912.976-467.216Zm0-15.167a6.029,6.029,0,0,0-6.022,6.022,6.029,6.029,0,0,0,6.022,6.022,6.03,6.03,0,0,0,6.023-6.022A6.03,6.03,0,0,0,4912.976-482.383Zm9.263,12.443a11.361,11.361,0,0,1-3.046,2.981l5.452,5.453,3.014-3.013Z"
          fill="#fff5f2"
        />
      </g>
    </svg>
  );
};
