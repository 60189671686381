import { useQuery } from "react-query";
import { fetchJobRequests } from "../../../../api-services/job-requests";
import { JobRequest } from "../../../../api-types/job-requests/job-request";

export const JOB_REQUESTS_QUERY_KEY = "job-requests";

const jobRequestsQuery = (isDraft: boolean) => {
  return `${JOB_REQUESTS_QUERY_KEY}${isDraft ? "?isDraft=true" : ""}`;
};

export const useJobRequests = (isDraft: boolean = false) => {
  return useQuery<JobRequest[], unknown, JobRequest[]>(
    jobRequestsQuery(isDraft),
    async () => {
      const { data: jobRequests } = await fetchJobRequests(isDraft);

      return jobRequests;
    },
    {
      staleTime: 1,
    }
  );
};
