import axios from "axios";
import { SEARCH_JOB_POSTS_URL } from "../utils";

//TODO use proper type
export const searchJobPosts = async (filter: any) => {
  let searchKeywords = [];

  if (filter.search) {
    searchKeywords.push(filter.search);
  }

  return axios.post(SEARCH_JOB_POSTS_URL, {
    ...filter,
    searchKeywords,
  });
};
