import { Box } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { MilestoneInvoice } from "../../../../api-types/projects/milestone-invoices";
import { formatInvoiceId } from "../../../utils";
import { DownloadableDataGrid } from "../../components";
import { formatNominativeMonth } from "../../components/LoadingTypography";
import { useMilestonesInvoices } from "../../hooks/projects/project/milestone-invoices/useMilestonesInvoices";

export const DepositInvoices: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      sortable: false,
    },
    {
      field: "client",
      headerName: "Client",
      width: 250,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      sortable: false,
    },
    {
      field: "id",
      headerName: "Invoice ID",
      width: 200,
      sortable: false,
      hide: true,
    },
    { field: "invoice", headerName: "Invoice ID", width: 200, sortable: false },
    { field: "type", headerName: "Type", width: 200, sortable: false },
  ];

  const { data: milestoneInvoices, isLoading } = useMilestonesInvoices();

  const rows = useMemo(() => {
    return (
      milestoneInvoices
        ?.map((milestoneInvoice: MilestoneInvoice) => ({
          amount: `$${milestoneInvoice.amount}`,
          client: milestoneInvoice.client.email,
          createdAt: format(
            new Date(milestoneInvoice.createdAt),
            formatNominativeMonth
          ),
          description: milestoneInvoice.description,
          id: milestoneInvoice._id,
          invoice: formatInvoiceId(milestoneInvoice),
          type: milestoneInvoice?.type?.toUpperCase(),
        }))
        .filter((milestoneInvoice) => milestoneInvoice.type === "FUND") ?? []
    );
  }, [milestoneInvoices]);

  return (
    <Box gridColumnGap={24}>
      <DownloadableDataGrid
        isLoading={isLoading}
        columns={columns}
        data={rows}
        title="Deposit Invoices"
      />
    </Box>
  );
};
