import { DialogActionsClassKey, DialogActionsProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiDialogActions: MuiComponentThemeDefinition<
  DialogActionsClassKey,
  DialogActionsProps
> = {
  props: {},
  override: () => ({
    root: {
      justifyContent: "center",
      paddingBottom: 50,
    },
  }),
};
