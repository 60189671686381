import { createState } from "@hookstate/core";
import { AppState } from "./types";

export const state = createState<AppState>({
  freeTrialModal: {
    open: false,
  },
  packagesModal: {
    open: false,
  },
});
