import { AxiosResponse } from "axios";
import { JobRequest } from "../../../api-types/job-requests/job-request";
import axios from "../../index";
import { JOB_REQUESTS_URL } from "../../utils";

export const addJobRequest = async (
  jobRequestData: JobRequest
): Promise<AxiosResponse<JobRequest>> => {
  return await axios.post(JOB_REQUESTS_URL, jobRequestData);
};
