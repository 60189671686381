import { Box, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { Project } from "../../../../../../api-types/projects";
import { YELLOW } from "../../../../../../themes/components/utils";
import { usePointerStyles } from "../../../../../../themes/style-hooks";
import { TextOverflow } from "../../../../../common/components";
import { formatNominativeMonth } from "../../../../../common/components/LoadingTypography";
import { ProfessionalSummary } from "../../../../../common/components/ProfessionalSummary";
import { SkillsOutput } from "../../../../../common/components/SkillsOutput";
import { useScopedDowngradedStateValue } from "../../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { Description } from "../../../../../common/professionals-search/projects/project/sections/Description";
// import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../../../state/hooks";

type ProjectItemProps = {
  project: Project;
};

export const ProjectItem: React.FC<ProjectItemProps> = ({ project }) => {
  const pointerStyles = usePointerStyles();
  // const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const { id } = useScopedDowngradedStateValue(useUser());

  // const { professionalId } = useParams();
  const { description, title, createdAt, professionals } = project;

  const skills = useMemo(() => {
    if (project?.skills) {
      return project?.skills.map((skill) => skill.name);
    }
    return [];
  }, [project]);

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);
  //
  // const onNavigateToCurrentProject = useCallback(() => {
  //   if (professionalId) {
  //     return;
  //   }
  //
  //   navigate(`/account/projects/${project._id}`);
  // }, [navigate, professionalId, project._id]);

  const collaborators = useMemo(() => {
    return professionals.filter(({ professional }) => professional?._id != id);
  }, [id, professionals]);

  const hasCollaborators = Boolean(collaborators.length ?? 0 > 0);

  return (
    <Box
      height="100%"
      // onClick={onNavigateToCurrentProject}
      className={pointerStyles.root}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <Box
        display="flex"
        alignItems="flex-end"
        pt={5}
        pb={3}
        gridColumnGap={40}
      >
        <TextOverflow
          width="30%"
          style={{
            fontSize: 24,
            fontWeight: 600,
            color: isHovering ? YELLOW : "white",
          }}
        >
          {title}
        </TextOverflow>
        {createdAt && (
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: 600,
            }}
          >
            Started{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={{
                display: "inline",
              }}
            >
              {format(new Date(createdAt), formatNominativeMonth)}
            </Typography>
          </Typography>
        )}
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Description description={description} />
      <SkillsOutput skills={skills} isHovering={isHovering} />
      {hasCollaborators && (
        <React.Fragment>
          <Box py={4}>
            <Divider />
          </Box>
          <Box display="flex" alignItems="center" gridColumnGap={48}>
            <Typography variant="subtitle2">Collaborating with</Typography>
            <Box display="flex" gridColumnGap={24}>
              {collaborators.map(({ professional }) => (
                <ProfessionalSummary
                  key={professional._id}
                  professional={professional}
                  width={64}
                />
              ))}
            </Box>
          </Box>
        </React.Fragment>
      )}
      <Box py={4}>
        <Divider
          style={{
            backgroundColor: "white",
            height: "2px",
          }}
        />
      </Box>
    </Box>
  );
};
