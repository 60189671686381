import axios, { AxiosResponse } from "axios";
import { shareJobPostUrl } from "../../utils";

export type ShareJobPostImageProps = {
  jobPostId: string;
  imageHeight: 1080 | 1920;
};

export const shareJobPostImage = async ({
  jobPostId,
  imageHeight,
}: ShareJobPostImageProps): Promise<AxiosResponse<string>> => {
  return await axios.post(shareJobPostUrl(jobPostId), { imageHeight });
};
