import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { addBidMessage } from "../../../../../api-services/bids/bid";
import { AddClientMessageVariables } from "../../../../../api-types/job-requests/common";
import { jobRequestsBidsKey } from "../../../../common/hooks/job-requests/job-request/bids/useJobRequestBids";
import { ACCOUNT_BIDS } from "../../../../professional/bids/hooks/useAccountBids";

export const useAddJobBidMessageMutation = () => {
  const queryClient = useQueryClient();
  const { jobRequestId } = useParams();

  return useMutation(
    async (variables: AddClientMessageVariables) => {
      const { id, messages, accountType } = variables;

      const { data } = await addBidMessage(id, {
        messages,
        accountType,
      });

      return data;
    },
    {
      onSettled() {
        queryClient.invalidateQueries(ACCOUNT_BIDS);
        queryClient.invalidateQueries(jobRequestsBidsKey(jobRequestId));
      },
    }
  );
};
