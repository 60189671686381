import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { addInquiry } from "../../../../api-services/inquiries/inquiry";
import { CreateInquiryVariables } from "../../../../api-types/job-requests/common";
import { ACCOUNT_INQUIRIES } from "./useAccountInquiries";
import { accountInquiryKey } from "./useAccountInquiry";

export const useCreateInquiryMutation = (
  options?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: CreateInquiryVariables) => {
      const { jobRequestId, messages } = variables;

      const { data } = await addInquiry({
        messages,
        jobRequest: jobRequestId,
      });

      return data;
    },
    {
      ...options,
      onSettled(inquiry) {
        queryClient.invalidateQueries(ACCOUNT_INQUIRIES);
        if (inquiry?._id) {
          queryClient.invalidateQueries(accountInquiryKey(inquiry._id));
        }
      },
    }
  );
};
