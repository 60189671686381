import { useMemo } from "react";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { MilestoneStatus } from "../../job-requests/create-job-request-wizard/validation-schema";

type FixedFundingBudgetSummary = {
  total: number;
  paid: number;
  escrow: number;
  remaining: number;
};

export const useFixedFundingBudgetSummary = () => {
  const { data: project } = useProjectQuery();

  return useMemo(() => {
    return project?.jobRequest?.fixedProjectFunding?.milestones?.reduce<FixedFundingBudgetSummary>(
      (total, milestone) => {
        if (
          milestone.isFunded &&
          milestone.status === MilestoneStatus.COMPLETED
        ) {
          total.paid += milestone.amount;
        }

        if (
          milestone.isFunded &&
          milestone.status !== MilestoneStatus.COMPLETED
        ) {
          total.escrow += milestone.amount;
        }

        if (!milestone.isFunded) {
          total.remaining += milestone.amount;
        }

        return total;
      },
      {
        total: project?.jobRequest?.fixedProjectFunding?.totalPrice ?? 0,
        paid: 0,
        escrow: 0,
        remaining: 0,
      }
    );
  }, [
    project?.jobRequest?.fixedProjectFunding?.milestones,
    project?.jobRequest?.fixedProjectFunding?.totalPrice,
  ]);
};
