import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateBid } from "../../../../../api-services/bids/bid";
import { CreateBidRequest } from "../../../../../api-types/job-requests/bids";
import { useToast } from "../../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../job-requests/hooks/feedbackErrors";
import { accountBidKey } from "../../hooks/useAccountBid";

export const useBidUpdateMutation = (id?: string) => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();
  const { bidId } = useParams();

  return useMutation(
    async (bid: CreateBidRequest) => {
      const { data } = await updateBid(bid);

      return data;
    },
    {
      onSuccess() {
        notifySuccess(feedbackMessage("bid", FEEDBACK_TYPE.UPDATE));
      },
      onSettled() {
        queryClient.invalidateQueries(accountBidKey(id ?? bidId));
      },
    }
  );
};
