import { Box } from "@material-ui/core";
import {
  FixedPriceFundingDetails,
  JobRequestFixedPriceMilestoneTable,
  MilestonesDetails,
} from "../../utils";
import { useUpdateFixedProjectFunding } from "../hooks";

export const FixedPriceFundingContent = () => {
  useUpdateFixedProjectFunding();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gridColumnGap={20}
      width="100%"
    >
      <MilestonesDetails />

      <Box width="60%">
        <Box display="flex" flexDirection="column" gridGap={20}>
          <FixedPriceFundingDetails />

          <JobRequestFixedPriceMilestoneTable />
        </Box>
      </Box>
    </Box>
  );
};
