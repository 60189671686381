import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../../themes/style-hooks";

type SavedJobRequestHighlightsProps = {};

export const SavedJobRequestHighlights: React.FC<
  SavedJobRequestHighlightsProps
> = () => {
  const boldStyles = useBoldStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      pt={10}
      gridRowGap={12}
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      width="100%"
    >
      <Typography variant="subtitle1" className={boldStyles.root} noWrap>
        Saved Job Requests
      </Typography>
      <Typography noWrap>You have saved {0} Job Request(s)</Typography>
      <Typography noWrap>
        <Link href="/job-requests/saved">Go to Saved Job Request(s) Page</Link>
      </Typography>
    </Box>
  );
};
