import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { LoadingTypography } from "../components";

type NavbarMenuItemProps = {
  label: string;
  isLoading?: boolean;
  onClick?: () => void;
  labelCount?: number;
  dataCyBtn?: string;
};

const useStyles = makeStyles({
  root: {
    letterSpacing: 0.25,
    // "& :hover": {
    //   textStrokeWidth: 1,
    // },
  },
});

export const NavbarMenuItem: React.FC<NavbarMenuItemProps> = ({
  label,
  isLoading,
  onClick,
  labelCount,
  dataCyBtn,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Button
        variant="contained"
        disableTouchRipple
        style={{
          width: "fit-content",
          height: 58,
          backgroundColor: BLUE,
          color: YELLOW,
        }}
        onClick={onClick}
        data-cy-btn={dataCyBtn}
      >
        <Typography
          component="div"
          color="primary"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 18,
            columnGap: 4,
          }}
        >
          <Box>{label}</Box>
          {isLoading !== undefined && (
            <Box display="flex" justifyContent="flex-end">
              <LoadingTypography
                width={32}
                lineHeight={2}
                isLoading={isLoading}
                typographyProps={{
                  color: "inherit",
                }}
              >
                <b>{`(${labelCount})`}</b>
              </LoadingTypography>
            </Box>
          )}
        </Typography>
      </Button>
    </Box>
  );
};
