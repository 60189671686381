import { array, InferType, number, object, ref, string } from "yup";

export enum MilestoneStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  IN_PROGRESS = "in progress",
  IN_REVIEW = "in review",
}

export type DueDate = {
  type: "weeks" | "days" | "hours";
  count: number;
};

export interface Milestone {
  _id?: string;
  amount: number;
  description: string;
  dueDate: DueDate;
  chargeId: string | null;
  isFunded?: boolean;
  isSubmitted?: boolean;
  status?: MilestoneStatus;
}

export interface ProjectDueDate {
  weeks: number;
  days: number;
  hours: number;
}

export interface FixedProjectFundingType {
  dueDate: ProjectDueDate;
  milestones: Milestone[];
  totalPrice: number;
}

export const MilestonesSchemaDefinition = array().of(
  object({
    amount: number()
      .min(1, "Amount must be greater than zero")
      .required("Amount is required"),
    description: string().required("Description is required"),
    dueDate: object({
      type: string(),
      count: number()
        .min(1, "Amount must be greater than zero")
        .required("Due date is required"),
    }).test((dueDate) => (dueDate.count ?? 0) > 0),
  })
);

export type MilestonesSchema = InferType<typeof MilestonesSchemaDefinition>;

export const FixedProjectFundingSchemaDefinition = object().shape({
  totalPrice: number().defined().min(1).required(),
  dueDate: object({
    weeks: number(),
    days: number(),
    hours: number(),
  }).test(
    (obj) =>
      (obj?.weeks ?? 0) > 0 || (obj?.days ?? 0) > 0 || (obj?.hours ?? 0) > 0
  ),
  milestones: MilestonesSchemaDefinition,
});

export type FixedProjectFundingSchema = {
  fixedProjectFunding: InferType<typeof FixedProjectFundingSchemaDefinition>;
};

export interface HourlyRateMilestone {
  _id?: string;
  description: string;
  dueDate: {
    type: string;
    min: number;
    max: number;
  };
  status?: MilestoneStatus;
  actualWork?: {
    dueDate: number;
    hoursPerWeek: number;
    costPerHour: number;
  };
  isSubmitted?: boolean;
}

export interface HourlyRateFundingType {
  milestones: HourlyRateMilestone[];
  timeframe?: {
    min: number;
    max: number;
  };
}

export const HourlyRateMilestonesSchemaDefinition = array().of(
  object({
    description: string().required("Description is required"),
    dueDate: object()
      .shape({
        type: string(),
        min: number()
          .min(1, "Minimum due date must be greater than zero")
          .lessThan(
            ref("max"),
            "Minimum due date must be less than maximum due date"
          )
          .required("Minimum due date is required")
          .test((value) => (value ?? 0) > 0),
        max: number()
          .min(1, "Maximum due date must be greater than zero")
          .required("Maximum due date is required")
          .test((value) => (value ?? 0) > 0),
      })
      .test((dueDate) => (dueDate?.min ?? 0) <= (dueDate?.max ?? 0)),
  })
);

export type HourlyRateMilestonesSchema = InferType<
  typeof HourlyRateMilestonesSchemaDefinition
>;

export const HourlyRateFundingSchemaDefinition = object().shape({
  milestones: HourlyRateMilestonesSchemaDefinition,
  timeframe: object({
    type: string(),
    min: number(),
    max: number(),
  }),
});

export type HourlyRateFundingSchema = {
  hourlyRateProjectFunding: InferType<typeof HourlyRateFundingSchemaDefinition>;
};
