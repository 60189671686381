import { useMutation, useQueryClient } from "react-query";
import { updateJobApplication } from "../../../../api-services/job-applications/updateJobApplication";
import { JobApplicationData } from "../../../../api-types/job-applications";
import { jobApplicationKey } from "../../../common/hooks/job-applications/useJobApplicationQuery";
import { JOB_APPLICATIONS_QUERY_KEY } from "../../../common/hooks/job-applications/useJobApplicationsQuery";
import { JOB_POSTS_QUERY_KEY } from "../../../common/hooks/job-posts/useJobPosts";
import { METADATA_QUERY_KEY } from "../../../common/hooks/metadata/useMetadataQuery";

export const useUpdateJobApplication = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (application: JobApplicationData) => {
      const { data } = await updateJobApplication(application);

      return data;
    },
    {
      onSettled(jobApplicationData: any) {
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
        queryClient.invalidateQueries(JOB_POSTS_QUERY_KEY);
        queryClient.invalidateQueries(JOB_APPLICATIONS_QUERY_KEY);
        queryClient.invalidateQueries(
          jobApplicationKey(jobApplicationData.jobPost)
        );
      },
    }
  );
};
