import {
  Box,
  DialogContent,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import { forwardRef, ReactNode, useCallback, useMemo, useState } from "react";
import { SubscribeData } from "../../../../../api-services/subscribe/subscribe";
import { BLUE } from "../../../../../themes/components/utils";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { Section } from "../../Section";
import { PostSubscribeDialog } from "./PostSubscribeDialog";

export type SubscribeDialogProps = {
  open: boolean;
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  subscribed?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm?: (values: SubscribeData) => void;
  classNames?: string;
};

export const useDialogStyles = (hasContent?: boolean) =>
  makeStyles((theme) => ({
    radioButtons: {
      gridColumnGap: 78,
      ["@media screen and (max-width: 1024px)"]: {
        gridColumnGap: 52,
      },
      ["@media screen and (max-width: 850px)"]: {
        gridColumnGap: 32,
      },
    },
    closeIcon: {
      right: 0,
      position: "absolute",
      paddingTop: 12,
      paddingRight: 12,
      cursor: "pointer",
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          display: "none",
        },
    },
    title: {
      fontSize: 24,
      ["@media screen and (max-width: 1000px)"]: {
        fontSize: 18,
      },
    },
    subtitle: {
      fontSize: 21,
      ["@media screen and (max-width: 1024px)"]: {
        fontSize: 19,
      },
      ["@media screen and (max-width: 860px)"]: {
        fontSize: 16,
      },
      ["@media screen and (max-width: 720px)"]: {
        fontSize: 14,
      },
    },
    dialogTitle: {
      paddingTop: 32,
      paddingLeft: 56,
      paddingRight: 40,
      paddingBottom: 36,
      ["@media screen and (max-height: 1024px), screen and (max-width: 1024px)"]:
        {
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
          paddingBottom: 24,
        },
      ["@media screen and (max-width: 450px), screen and (max-height: 450px)"]:
        {
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 12,
          paddingBottom: 16,
        },
    },
    completeDialogTitle: {
      paddingTop: 32,
      paddingLeft: 28,
      paddingRight: 28,
      paddingBottom: 36,
    },
    dialogContent: {
      height: 300,
      padding: "12px 24px",
      ["@media screen and (max-width: 1024px), screen and (max-height: 1024px)"]:
        {
          width: "100%",
          paddingLeft: 24,
          paddingRight: 24,
        },
      ["@media screen and (max-width: 450px), screen and (max-height: 450px)"]:
        {
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 0,
          paddingBottom: 0,
        },
    },
    radioGroup: {
      paddingTop: 12,
      ["@media screen and (max-height: 450px)"]: {
        paddingTop: 0,
      },
    },
    input: {
      color: BLUE,
      "&::placeholder": {
        fontSize: 21,
        ["@media screen and (max-width: 1024px)"]: {
          fontSize: 19,
        },
        ["@media screen and (max-width: 860px)"]: {
          fontSize: 16,
        },
        ["@media screen and (max-width: 720px)"]: {
          fontSize: 14,
        },
      },
    },
    textField: {
      "& .MuiInputBase-root": {
        height: 68,
        fontSize: 21,
        ["@media screen and (max-width: 1024px)"]: {
          height: 50,
          fontSize: 19,
        },
        ["@media screen and (max-width: 860px)"]: {
          height: 40,
          fontSize: 16,
        },
        ["@media screen and (max-width: 720px)"]: {
          fontSize: 14,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: BLUE,
      },
    },
    customModal: {
      "& .MuiDialogActions-root": {
        paddingBottom: 64,
        ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
          {
            paddingBottom: 32,
          },
        ["@media screen and (max-height: 450px)"]: {
          paddingBottom: 12,
        },
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "transparent",
      },
      "& .MuiDialog-paper": {
        ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
          {
            height: "100%",
            maxHeight: "100%",
          },
        minHeight: 600,
        maxHeight: 600,
        overflowY: "hidden",
        borderRadius: 0,
        width: "100vw",
        maxWidth: 1440,
        padding: 0,
        margin: 0,
        boxShadow: "none",
        zIndex: theme.zIndex.modal,
      },
    },
    fullWidth: {
      "& .MuiDialogActions-root": {
        paddingBottom: 64,
        ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
          {
            paddingBottom: 32,
          },
        ["@media screen and (max-height: 450px)"]: {
          paddingBottom: 12,
        },
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "transparent",
      },
      "& .MuiDialog-paper": {
        justifyContent: "space-between",
        ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
          {
            height: "100%",
            maxHeight: "100%",
          },
        minHeight: hasContent ? 450 : 200,
        maxHeight: hasContent ? 450 : 200,
        overflowY: "hidden",
        borderRadius: 0,
        width: "100vw",
        maxWidth: 1440,
        padding: 0,
        margin: 0,
        boxShadow: "none",
        zIndex: theme.zIndex.modal,
      },
    },
    completeProjectDialog: {
      "& .MuiDialogActions-root": {
        paddingBottom: 32,
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "transparent",
      },
      "& .MuiDialog-paper": {
        height: 550,
        maxHeight: 550,
        overflowY: "hidden",
        borderRadius: 0,
        width: "100vw",
        maxWidth: 1440,
        padding: 0,
        margin: 0,
        boxShadow: "none",
        zIndex: theme.zIndex.modal,
      },
    },
    radio: {
      "& .MuiSvgIcon-root": {
        fill: BLUE,
        width: 32,
        height: 32,
        ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
          {
            width: 26,
            height: 26,
          },
      },
    },
    button: {
      height: 64,
      width: 100,
      "& .MuiButton-label": {
        fontSize: 19,
        fontWeight: 550,
      },
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          height: 48,
          width: 75,
          "& .MuiButton-label": {
            fontSize: 16,
            fontWeight: 550,
          },
        },
      ["@media screen and (max-height: 300px)"]: {
        height: 30,
        width: 60,
        "& .MuiButton-label": {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
  }))();

export const SubscribeDialog = forwardRef<
  HTMLDialogElement,
  SubscribeDialogProps
>((props, ref) => {
  const styles = useDialogStyles(true);
  const mobileView = useMediaQuery("(max-width: 600px)");

  const {
    title,
    subtitle,
    onClose,
    onConfirm,
    confirmLabel,
    cancelLabel,
    isLoading,
    subscribed,
    open,
  } = props;

  const [values, setValues] = useState<SubscribeData>({
    email: "",
    accountType: "client",
  });

  const handleConfirm = useCallback(() => {
    if (!values) return;

    onConfirm?.(values);
  }, [onConfirm, values]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const isDisabled = useMemo(
    () =>
      !String(values.email) ||
      !values.email?.includes("@") ||
      !values.email?.includes("."),
    [values.email]
  );

  const handleCloseDialog = useHandleCloseDialog(onClose);

  if (subscribed) {
    return <PostSubscribeDialog {...props} />;
  }

  return (
    <Dialog
      ref={ref}
      open={open}
      className={`${styles.fullWidth} ${props.classNames}`}
      onClose={handleCloseDialog}
    >
      <Box onClick={handleClose} className={styles.closeIcon}>
        <CloseIcon />
      </Box>
      {title && (
        <DialogTitle className={styles.dialogTitle}>
          <Typography
            className={styles.title}
            color="secondary"
            style={{
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
          <Typography
            className={styles.subtitle}
            color="secondary"
            style={{ fontWeight: 500 }}
          >
            {subtitle}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent className={styles.dialogContent}>
        <Section
          isLoading={isLoading}
          color="secondary"
          sectionProps={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box alignSelf="center" width={mobileView ? "100%" : "80%"}>
            <Box width="100%">
              <TextField
                className={styles.textField}
                inputProps={{ className: styles.input }}
                placeholder="Enter your email here"
                fullWidth
                onChange={(event) =>
                  setValues({ ...values, email: event.target.value })
                }
              />
            </Box>
            <RadioGroup
              className={styles.radioGroup}
              defaultValue="client"
              onChange={(event) => {
                setValues({ ...values, accountType: event.target.value });
              }}
            >
              <Box
                display="flex"
                flexWrap="wrap"
                className={styles.radioButtons}
              >
                <FormControlLabel
                  value="client"
                  control={<Radio className={styles.radio} />}
                  label={
                    <Typography className={styles.subtitle} color="secondary">
                      I am interested in hiring a Professional
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="professional"
                  control={<Radio className={styles.radio} />}
                  label={
                    <Typography className={styles.subtitle} color="secondary">
                      I am a Professional
                    </Typography>
                  }
                />
              </Box>
            </RadioGroup>
          </Box>
        </Section>
      </DialogContent>
      <DialogActions>
        <Box display="grid" gridAutoFlow="column" gridColumnGap={20}>
          <Button
            className={styles.button}
            variant="contained"
            style={{
              backgroundColor: BLUE,
            }}
            disableFocusRipple
            disabled={isDisabled}
            onClick={handleConfirm}
            autoFocus
          >
            {confirmLabel}
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            style={{
              color: BLUE,
              borderColor: BLUE,
            }}
            disableFocusRipple
            onClick={handleClose}
            autoFocus
          >
            {cancelLabel}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
