import { Box, Typography } from "@material-ui/core";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { CustomInput } from "../../elements";
import { BackButton } from "../../elements/BackButton";
import { useRequiredFieldLabel } from "../../hooks/utils";

type SignUpProfessionalInfoProps = {
  onBack?: () => void;
  values: FormikValues;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  errors: FormikErrors<FormikValues>;
};

export const initialProfessionalInfoValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const SignUpProfessionalInfo: React.FC<SignUpProfessionalInfoProps> = ({
  onBack,
  values,
  handleBlur,
  handleChange,
  errors,
}) => {
  const requiredFieldLabel = useRequiredFieldLabel();

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      height="100%"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box
        pt={10}
        display="flex"
        width="80%"
        height="100%"
        justifyContent="space-between"
        gridColumnGap={12}
      >
        <Box maxWidth="45%">
          <Typography variant="subtitle1">
            Sign up with your email and create a password.
            <br />
            You will receive a confirmation message.
          </Typography>
        </Box>
        <Box
          width="35%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gridRowGap={48}>
            <Field
              component={CustomInput}
              value={values.firstName}
              error={errors.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              name="firstName"
              id="firstName"
              placeholder={requiredFieldLabel("First name")}
            />
            <Field
              component={CustomInput}
              value={values.lastName}
              error={errors.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              name="lastName"
              id="lastName"
              placeholder={requiredFieldLabel("Last name")}
            />
            <Field
              component={CustomInput}
              value={values.email}
              error={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              id="email"
              placeholder={requiredFieldLabel("Enter email address")}
            />
            <Field
              component={CustomInput}
              value={values.password}
              error={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              id="password"
              placeholder={requiredFieldLabel("Create a password")}
              type="password"
            />
            <Field
              component={CustomInput}
              value={values.confirmPassword}
              error={errors.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              name="confirmPassword"
              id="confirmPassword"
              placeholder={requiredFieldLabel("Confirm password")}
              type="password"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
