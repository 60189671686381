import { useMemo } from "react";
import { useJobApplicationsQuery } from "../../../common/hooks/job-applications";
import { useJobPostQuery } from "../../../common/hooks/job-posts";

export const useCanApplyToJobPost = () => {
  const { data: jobPost, isLoading: isLoadingJobPost } = useJobPostQuery();
  const { data: jobApplications, isLoading: isLoadingJobApplication } =
    useJobApplicationsQuery();

  return useMemo(() => {
    if (!jobPost || isLoadingJobPost || isLoadingJobApplication) {
      return false;
    }

    const jobApplicationsIds = jobApplications?.map(
      (jobApplication) => jobApplication.jobPost?._id
    );

    return !jobApplicationsIds?.includes(jobPost?._id);
  }, [isLoadingJobApplication, isLoadingJobPost, jobApplications, jobPost]);
};
