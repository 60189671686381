import { Box } from "@material-ui/core";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { WithdrawalHistoryItem } from "../../../../api-types/projects/withdrawal-history";
import { DownloadableDataGrid } from "../../components";
import { formatNominativeMonth } from "../../components/LoadingTypography";
import { useWithdrawalHistory } from "../../hooks/projects/project/withdrawal-history/useWithdrawalHistory";

export const WithdrawalHistory: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 250,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 250,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      sortable: false,
    },
    { field: "id", headerName: "Invoice ID", width: 250, sortable: false },
  ];

  const { data: withdrawalHistory, isLoading } = useWithdrawalHistory();

  const rows = useMemo(() => {
    return (
      withdrawalHistory?.map(
        (withdrawalHistoryItem: WithdrawalHistoryItem) => ({
          amount: withdrawalHistoryItem.amount,
          createdAt: format(
            new Date(withdrawalHistoryItem.createdAt),
            formatNominativeMonth
          ),
          id: withdrawalHistoryItem._id,
          status: withdrawalHistoryItem.status,
        })
      ) ?? []
    );
  }, [withdrawalHistory]);

  return (
    <Box gridColumnGap={24}>
      <DownloadableDataGrid
        isLoading={isLoading}
        columns={columns}
        data={rows}
        title="Withdrawal History"
      />
    </Box>
  );
};
