import { Box } from "@material-ui/core";
import React from "react";
import {
  ProfessionalAccountSummaryDescription,
  ProfessionalAccountSummaryInfo,
} from "../../../common/account/profile";
import { useAccountInformation } from "../../../common/hooks/account-information/useAccountInformation";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";
import { useProfileInformation } from "../../../common/hooks/profile-information/useProfileInformation";

export const ProfessionalAccountSummary: React.FC = () => {
  const { data: profileInformation, isLoading: isLoadingProfileInformation } =
    useProfileInformation();

  const { data: accountInformation, isLoading: isLoadingAccountInformation } =
    useAccountInformation();

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const isLoading =
    isLoadingAccountInformation ||
    isLoadingProfileInformation ||
    isLoadingMetadata;

  return (
    <Box display="flex" gridColumnGap={48} height={300} width="100%">
      <ProfessionalAccountSummaryInfo
        profileInformation={profileInformation}
        isLoading={isLoading}
      />
      <ProfessionalAccountSummaryDescription
        profileInformation={profileInformation}
        accountInformation={accountInformation}
        isLoading={isLoading}
        averageRating={metadata?.averageRating}
        ratingsCount={metadata?.ratingsCount}
      />
    </Box>
  );
};
