import { useMutation, UseMutationOptions } from "react-query";
import { updateJobRequest } from "../../../../../api-services/job-requests/job-request";
import { JobRequest } from "../../../../../api-types/job-requests/job-request";

type UseJobRequestUpdateMutationCoreOptions = UseMutationOptions<
  JobRequest,
  unknown,
  JobRequest
>;

export const useJobRequestUpdateMutationCore = (
  options?: UseJobRequestUpdateMutationCoreOptions
) => {
  return useMutation(
    async (jobRequest: JobRequest) => {
      const { data } = await updateJobRequest(jobRequest);
      return data;
    },
    {
      ...options,
    }
  );
};
