import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, {
  ChangeEventHandler,
  Fragment,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { uploadResume } from "../../../../../api-services/upload-files/uploadFiles";
import { useInvalidateJobApplicationsQuery } from "../../../../common/hooks/job-applications";
import { useJobPostQuery } from "../../../../common/hooks/job-posts";
import { ApplyByEmailDialog } from "../../../../professional/job-posts";
import { useCreateJobApplication } from "../../../../professional/job-posts/hooks";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../utils/useToast";

type JobPostApplicationPageProps = {
  id: string;
  onApplySuccessful?: () => void;
  onCancel?: () => void;
};

export const JobPostApplicationPage: React.FC<JobPostApplicationPageProps> = ({
  id,
  onCancel,
  onApplySuccessful,
}) => {
  const navigate = useNavigate();
  const [openApplyByEmailDialog, setOpenApplyByEmailDialog] = useState(false);
  const { data: jobPost, isLoading } = useJobPostQuery(id);
  const [isUploading, setIsUploading] = useState(false);

  const { notifyError } = useToast();

  const [uploadedResume, setUploadedResume] =
    useState<{ key: string; location: string }>();

  const onUploadResume = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      setIsUploading(true);
      const file = event.target.files?.item(0);

      if (!file) {
        setIsUploading(false);
        return;
      }

      try {
        const resume = await uploadResume(file, jobPost?._id ?? "");
        setUploadedResume(resume);
      } catch (error) {
        notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
      } finally {
        setIsUploading(false);
      }
    },
    [jobPost?._id, notifyError]
  );

  const { mutate: createJobApplication, isLoading: isCreatingJobApplication } =
    useCreateJobApplication();
  const invalidateJobApplicationsQuery = useInvalidateJobApplicationsQuery();

  const onApply = useCallback(() => {
    if (!jobPost?._id || !uploadedResume?.location) return;

    createJobApplication(
      {
        jobPost: jobPost?._id,
        resume: uploadedResume?.location,
      },
      {
        onSuccess: () => {
          navigate("/job-requests/search");
          onApplySuccessful?.();
          invalidateJobApplicationsQuery();
        },
        onError: () => {
          notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
        },
      }
    );
  }, [
    createJobApplication,
    invalidateJobApplicationsQuery,
    jobPost?._id,
    navigate,
    notifyError,
    onApplySuccessful,
    uploadedResume?.location,
  ]);

  if (isLoading) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (jobPost?.contact === "online")
    return (
      <>
        <Box pt={4} flexGrow={1}>
          <Box alignItems="center" display="flex" gridColumnGap={12} py={8}>
            {isUploading && <Skeleton variant="rect" width={400} height={20} />}
            {uploadedResume && (
              <Fragment>
                <Typography>
                  {uploadedResume?.key.replace(`resumes/${jobPost._id}/`, "")}
                </Typography>
                <Button
                  onClick={() => setUploadedResume(undefined)} // @todo delete file from s3
                  size="small"
                >
                  Delete
                </Button>
              </Fragment>
            )}

            {/*<Box ml="auto">
                            <input
                                accept="application/pdf"
                                id="upload-resume"
                                type="file"
                                hidden
                                onChange={onUploadResume}
                                disabled={Boolean(uploadedResume?.key)}
                                data-cy="upload-resume"
                            />
                            <label htmlFor="upload-resume">
                                <Button
                                    component="span"
                                    disabled={Boolean(uploadedResume?.key) || isUploading}
                                    variant="contained"
                                    size="medium"
                                    data-cy-btn="upload-resume"
                                >
                                    Upload Resume
                                </Button>
                            </label>
                        </Box>*/}
          </Box>
        </Box>
      </>
    );

  if (jobPost?.contact === "email")
    return (
      <>
        <Box display="flex" gridColumnGap={12} py={8} justifyContent="flex-end">
          <Button
            onClick={() => setOpenApplyByEmailDialog(true)}
            size="medium"
            variant="contained"
          >
            Send Email
          </Button>
        </Box>

        <ApplyByEmailDialog
          jobPostId={jobPost?._id}
          open={openApplyByEmailDialog}
          onClose={() => setOpenApplyByEmailDialog(false)}
        />
      </>
    );

  return (
    <>
      <Box>
        <Button
          variant="contained"
          size="medium"
          disabled={
            isCreatingJobApplication || jobPost?.externalWebsite.length === 0
          }
          onClick={() => {
            window.open(jobPost?.externalWebsite ?? "", "_blank");
          }}
        >
          Apply
        </Button>
      </Box>
    </>
  );
};
