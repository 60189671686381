import { format } from "date-fns";
import { MilestoneInvoice } from "../../api-types/projects/milestone-invoices";

export const formatInvoiceId = (invoice: MilestoneInvoice) => {
  const professionalInitials = `${invoice.professional.accountInformation?.firstName[0]}${invoice.professional.accountInformation?.lastName[0]}`;
  const formattedDate = format(new Date(invoice.createdAt), "MMMdd");
  const lastSixIdDigits = String(invoice._id).slice(-6);

  return `${professionalInitials}_${formattedDate}_${lastSixIdDigits}`;
};
