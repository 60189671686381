import { Box, Typography, useMediaQuery } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { AccountType } from "../../../api-types/common";
import {
  BLUE,
  LIGHT_BLUE,
  MAX_VIEWPORT_WIDTH,
} from "../../../themes/components/utils";
import { Topbar } from "../../common/components";
import { CustomInfoBox } from "../../common/components/CustomInfoBox";
import { useScopedDowngradedStateValue } from "../../common/hooks/utils/useScopedDowngradedStateValue";
import { useWindowDimensions } from "../../common/window";
import { useUser } from "../../state/hooks";

type PageWrapperProps = {
  hideNavbar?: boolean;
  customColor?: string;
};

export const PageWrapper: FC<PageWrapperProps> = ({
  children,
  hideNavbar,
  customColor = BLUE,
}) => {
  const { width } = useWindowDimensions();
  const mobileWidth = useMediaQuery("(max-width: 800px)", { noSsr: true });

  const { accountType } = useScopedDowngradedStateValue(useUser());

  const showNotification = useMemo(() => {
    if (
      location.pathname.includes("account") ||
      location.pathname.includes("search") ||
      location.pathname.includes("browse")
    ) {
      return true;
    }
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      {!hideNavbar && <Topbar />}
      <Box
        style={{
          backgroundColor: LIGHT_BLUE,
          display: "flex",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Box
          bgcolor={customColor}
          width={width}
          maxWidth={MAX_VIEWPORT_WIDTH}
          display="flex"
          flexDirection="column"
        >
          <Box height="100%" px={mobileWidth ? 5 : 15}>
            {children}
          </Box>

          {accountType === AccountType.PROFESSIONAL && showNotification && (
            <Box position="sticky" bottom={0} margin="auto" zIndex={99}>
              <CustomInfoBox
                messages={
                  <>
                    <Typography variant="body2" color="inherit">
                      <b>
                        Confidence in your ability to steer a project is
                        important, particularly as a freelancer.
                      </b>{" "}
                      We encourage design professionals to become educated and
                      proficient in their craft, and thus gain the confidence
                      needed to navigate the freelancing journey.
                    </Typography>
                    <br />
                    <Typography variant="body2" color="inherit">
                      We are proud to be educational partners of the{" "}
                      <b>Interaction Design Foundation.</b> With 156,925
                      graduates and counting, the Interaction Design Foundation
                      is the
                      <b> biggest online design school globally</b>. Check out
                      the courses they are offering from{" "}
                      <b>
                        some of the biggest and most recognizable names in
                        design today.
                      </b>
                    </Typography>
                    <br />
                    <Typography
                      variant="body2"
                      component={"a"}
                      target="_blank"
                      href="https://www.interaction-design.org/learn-ux-design?ep=thetalentedco"
                      color="primary"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Use the link offered through <b>TheTalented</b> to get{" "}
                      <b>1 month off of your yearly membership</b>.
                    </Typography>
                    <br />
                  </>
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
