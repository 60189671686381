import { SkeletonClassKey, SkeletonProps } from "@material-ui/lab";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiSkeleton: MuiComponentThemeDefinition<
  SkeletonClassKey,
  SkeletonProps
> = {
  props: {},
  override: () => ({
    root: {
      backgroundColor: "rgba(211, 211, 211, 0.21)",
    },
  }),
};
