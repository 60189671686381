import { createState } from "@hookstate/core";
import { BrowseSearchFilters } from "../../../../api-types/job-requests/common";

export const defaultFilters = {
  skills: [],
  fee: {
    min: 0,
    max: 999,
  },
  serviceCategory: "",
};

export const state = createState<BrowseSearchFilters>(defaultFilters);
