import { Box } from "@material-ui/core";
import { noop } from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useDescriptionText,
  useHeadlineText,
  useMediumItalic,
  useStepDStyles,
} from "../../../../../../themes/style-hooks";
import { PackagesCards } from "../../../../../client/job-posts/jobs-packages/components/PackagesCards";
import { useRedirectUrl } from "../../../../../state/hooks";

export const LandingStepD: FC = () => {
  const styles = useStepDStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: "#EC6048",
    color: COLOR.WHITE,
    hover: "#EC6048",
  });
  const mediumItalic = useMediumItalic();
  const { skewedText, transparent } = useHeadlineText({
    primary: COLOR.PALE,
    secondary: "#EC6048",
    background: "#EC6048",
  });
  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });
  const navigate = useNavigate();
  const setRedirectUrl = useRedirectUrl().set;

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box
          className={mediumItalic.root}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Box className={`${transparent} ${styles.headline}`}>
            <span>&nbsp;All job packages start with a&nbsp;</span>
          </Box>
          <br />
          <Box pl={1} className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;10-day free trial.&nbsp;</span>
          </Box>
        </Box>
      </Box>
      <Box width="100%" pl={8}>
        <Box className={descriptionText.root}>
          Select any job post package and start a 10-day free trial.
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box alignSelf="center" height={360}>
          <PackagesCards
            inverse
            onSelect={noop}
            selectLabel="Start 10-day free trial"
            buttonStyles={`${common} ${root}`}
            radioColor={"#EC6048"}
            onClick={() => {
              setRedirectUrl("/jobs-packages/");
              navigate("/jobs-packages/");
            }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
