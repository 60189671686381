import { Box, Button } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { Loader } from "../../../../common/elements/Loader";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";
import {
  useRemoveFromSavedJobRequestMutation,
  useSavedJobRequests,
  useSaveJobRequestMutation,
} from "../../hooks";

type JobRequestItemActionsProps = {
  placedInquiry?: boolean;
  placedBid?: boolean;
  jobRequestId: string;
  onToggleInquiry?: () => void;
  onToggleBid?: () => void;
  isCurrentBidOpen?: boolean;
  isCurrentInquiryOpen?: boolean;
};

export const JobRequestItemActions: React.FC<JobRequestItemActionsProps> = ({
  jobRequestId,
  onToggleBid,
  onToggleInquiry,
  isCurrentInquiryOpen,
  isCurrentBidOpen,
}) => {
  const { mutate: saveJobRequest, isLoading: isSavingJobRequest } =
    useSaveJobRequestMutation();

  const {
    mutate: removeFromSavedJobRequest,
    isLoading: isRemovingFromSavedJobRequest,
  } = useRemoveFromSavedJobRequestMutation();

  const { data: savedJobRequests, isLoading: isLoadingJobRequests } =
    useSavedJobRequests();

  const { data: metadata, isFetching: isLoadingMetadata } = useMetadataQuery();

  const onSaveJobRequestHandler = useCallback(() => {
    saveJobRequest(jobRequestId);
  }, [jobRequestId, saveJobRequest]);

  const onRemoveJobRequestFromSavedHandler = useCallback(() => {
    removeFromSavedJobRequest(jobRequestId);
  }, [jobRequestId, removeFromSavedJobRequest]);

  const saveButton = useMemo(() => {
    const isJobRequestSaved = savedJobRequests?.find(
      (savedJobRequest) => savedJobRequest._id === jobRequestId
    );

    return !isJobRequestSaved ? (
      <Button
        variant="outlined"
        size="medium"
        onClick={onSaveJobRequestHandler}
        endIcon={isSavingJobRequest ? <Loader size={12} /> : null}
        disabled={isCurrentBidOpen || isCurrentInquiryOpen}
      >
        Save
      </Button>
    ) : (
      <Button
        variant="outlined"
        size="medium"
        onClick={onRemoveJobRequestFromSavedHandler}
        endIcon={isRemovingFromSavedJobRequest ? <Loader size={12} /> : null}
        disabled={isCurrentBidOpen || isCurrentInquiryOpen}
      >
        Remove
      </Button>
    );
  }, [
    isCurrentBidOpen,
    isCurrentInquiryOpen,
    isRemovingFromSavedJobRequest,
    isSavingJobRequest,
    jobRequestId,
    onRemoveJobRequestFromSavedHandler,
    onSaveJobRequestHandler,
    savedJobRequests,
  ]);

  return (
    <Box display="flex" gridColumnGap={10}>
      {saveButton}

      <Button
        onClick={onToggleInquiry}
        size="medium"
        variant="outlined"
        disabled={isCurrentBidOpen}
      >
        {isCurrentInquiryOpen ? "Hide Inquiry" : "Place Inquiry"}
      </Button>

      <Button
        disabled={
          isLoadingMetadata || metadata?.restricted || isCurrentInquiryOpen
        }
        variant="contained"
        size="medium"
        onClick={onToggleBid}
      >
        {isCurrentBidOpen ? "Hide Bid" : "Place Bid"}
      </Button>
    </Box>
  );
};
