import { useMutation, useQueryClient } from "react-query";
import { saveJobRequest } from "../../../../api-services/saved-job-requests";
import { useToast } from "../../../utils/useToast";
import { FEEDBACK_TYPE, feedbackMessage } from "./feedbackErrors";
import { SAVE_JOB_REQUESTS_QUERY_KEY } from "./useSavedJobRequests";

export const useSaveJobRequestMutation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation((id: string) => saveJobRequest(id), {
    onSuccess() {
      queryClient.invalidateQueries(SAVE_JOB_REQUESTS_QUERY_KEY);
      notifySuccess(feedbackMessage("job request", FEEDBACK_TYPE.SAVE));
    },
  });
};
