import { TypographyClassKey, TypographyProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE, RED, YELLOW } from "./utils";

export const MuiTypography: MuiComponentThemeDefinition<
  TypographyClassKey,
  TypographyProps
> = {
  props: {},
  override: () => ({
    root: {
      color: "white",
    },
    colorPrimary: {
      color: YELLOW,
    },
    colorSecondary: {
      color: BLUE,
    },
    colorError: {
      color: RED,
    },
    colorTextPrimary: {
      color: BLUE,
    },
    colorTextSecondary: {
      color: RED,
    },
  }),
};
