import { InputClassKey, InputProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiInput: MuiComponentThemeDefinition<InputClassKey, InputProps> =
  {
    props: {},
    override: () => ({
      root: {
        height: 68,
        borderRadius: 1,
      },
    }),
  };
