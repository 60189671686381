import { useFormikContext } from "formik";
import { useMemo } from "react";
import { parsePositiveNumber } from "../../../../utils";
import { BudgetType } from "../freelance-job-request/BudgetStep";
import {
  BudgetSchema,
  HourlyRateFundingSchema,
  HourlyRateMilestone,
} from "../validation-schema";
import { useEstimatedCostPerWeekCore } from "./useEstimatedCostPerWeek";

export const useTotalEstimatedCostCore = (
  milestones: HourlyRateMilestone[],
  budget?: BudgetType
) => {
  const estimatedCostPerWeek = useEstimatedCostPerWeekCore(budget);

  return useMemo(() => {
    if (budget?.timeFrame.type === "fixed") {
      return {
        min:
          parsePositiveNumber(budget?.timeFrame.fixed) *
          estimatedCostPerWeek.min,
        max:
          parsePositiveNumber(budget?.timeFrame.fixed) *
          estimatedCostPerWeek.max,
      };
    }

    if (budget?.timeFrame.type === "estimate") {
      return {
        min:
          parsePositiveNumber(budget?.timeFrame.estimate.min) *
          estimatedCostPerWeek.min,
        max:
          parsePositiveNumber(budget?.timeFrame.estimate.max) *
          estimatedCostPerWeek.max,
      };
    }

    return milestones?.reduce<{ min: number; max: number }>(
      (totalCost, milestone) => {
        return {
          min:
            totalCost.min +
            parsePositiveNumber(milestone.dueDate.min) *
              estimatedCostPerWeek.min,
          max:
            totalCost.max +
            parsePositiveNumber(milestone.dueDate.max) *
              estimatedCostPerWeek.max,
        };
      },
      { min: 0, max: 0 }
    );
  }, [
    budget?.timeFrame.estimate.max,
    budget?.timeFrame.estimate.min,
    budget?.timeFrame.fixed,
    budget?.timeFrame.type,
    estimatedCostPerWeek.max,
    estimatedCostPerWeek.min,
    milestones,
  ]);
};

export const useTotalEstimatedCost = () => {
  const { values } = useFormikContext<HourlyRateFundingSchema & BudgetSchema>();

  return useTotalEstimatedCostCore(
    (values.hourlyRateProjectFunding.milestones as HourlyRateMilestone[]) ?? [],
    values.budget as BudgetType
  );
};
