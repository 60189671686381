import { useState } from "@hookstate/core";
import { state } from "./state";

export const useConfirmAccountDeletion = () => {
  return useState(state.confirmAccountDeletion);
};

export const useConfirmCancelSubscription = () => {
  return useState(state.confirmCancelSubscription);
};

export const useSubscriptionModal = () => {
  return useState(state.subscriptionModal);
};
