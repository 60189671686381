/* eslint-disable */
export const RED = "#EB5055";
export const BLUE = "#0F1E4A";
export const YELLOW = "#FFE462";
export const YELLOW_HOVER = "#FFF2B1";

export const LIGHT_BLUE = "#CAE3F4";

export const BLACK = "#000000";

export enum COLOR {
  WHITE = "#FFFFFF",
  WHITE_PINK = "#FFF0F0",
  WHITE_BROWN = "#FDF4FF",
  WHITE_GREEN = "#D7FEFB",
  WHITE_BLUE = "#D8ECFC",
  YELLOW = "#FFE462",
  BLUE = "#0F1E4A",
  GREEN = "#B2FFD3",
  BROWN = "#544758",
  DARK_BROWN = "#473132",
  PINK = "#F96262",
  RED = "#FF545C",
  CYAN = "#1AD4E8",
  BORDO = "#8F2043",
  ORANGE = "#FFC200",
  PALE = "#F9ECC2",
  REDDISH = "#FE4B51",
}

export const MIN_VIEWPORT_WIDTH = 1240;
export const MIN_TOPBAR_WIDTH = 1024;
export const MAX_VIEWPORT_WIDTH = 1440;
