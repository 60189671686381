import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { Fragment, useMemo } from "react";
import { COLOR } from "../../../../themes/components/utils";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { JobRequestFormValues } from "../create-job-request-wizard/freelance-job-request/FreelanceJobRequestWizard";
import { ProjectDueDate } from "../create-job-request-wizard/validation-schema";

const useStyles = makeStyles({
  input: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderRadius: 2,
    color: COLOR.WHITE,
    fontSize: 16,
    width: 82,
    height: 28,
    "& input": {
      padding: "0px 12px",
    },

    /* Chrome, Safari, Edge, Opera */
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    /* Firefox */
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  boldLabel: {
    fontWeight: "bold",
  },
});

export const FixedPriceFundingDetails = () => {
  const boldStyles = useBoldStyles();
  const styles = useStyles();

  const { values } = useFormikContext<JobRequestFormValues>();

  const { weeks, days, hours } = useMemo(
    () => ({
      weeks: (values?.fixedProjectFunding.dueDate as ProjectDueDate).weeks,
      days: (values?.fixedProjectFunding.dueDate as ProjectDueDate).days,
      hours: (values?.fixedProjectFunding.dueDate as ProjectDueDate).hours,
    }),
    [values?.fixedProjectFunding.dueDate]
  );

  const hasWeeks = weeks > 0;

  const hasDays = days > 0;

  const hasHours = hours > 0;

  return (
    <Fragment>
      <Typography variant="subtitle2" className={boldStyles.root}>
        Funding Your Project
      </Typography>
      <Typography variant="body2">
        By default, all fixed price projects have one milestone.
      </Typography>
      <Typography variant="body2">
        In order to begin a fixed price project, at least the first project
        milestone has to be funded.
      </Typography>
      <Typography variant="body2">
        If multiple milestones are set up, at least the upcoming milestone has
        to be funded for work to continue.
      </Typography>
      <Typography variant="body2">
        Once a Professional is hired, you will be prompted to add the amount you
        specify here into an escrow account, to be released to the Professional
        on completion of the milestone.
      </Typography>
      <Typography variant="body2">
        All payments are enabled by Stripe. There is a 3.9% + $0.30 processing
        fee for each transaction.
      </Typography>

      <Box py={10}>
        <Divider />

        <Box display="flex" flexWrap="wrap" gridColumnGap={40} pt={10}>
          <Box
            display="flex"
            alignItems="center"
            gridColumnGap={12}
            width="50%"
          >
            <Typography className={styles.boldLabel} variant="body2">
              Fixed Price for Complete Project
            </Typography>

            <Box width={120}>
              <Typography
                variant="body2"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                $
                {Number(
                  values?.fixedProjectFunding.totalPrice
                ).toLocaleString()}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography className={styles.boldLabel} variant="body2">
              Due Date
            </Typography>
            {hasWeeks && (
              <Box display="flex" pl={2.5}>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {weeks}&nbsp;
                </Typography>
                <Typography variant="body2">week(s)</Typography>
              </Box>
            )}
            {hasDays && (
              <Fragment>
                {hasWeeks && <Typography variant="body2">,&nbsp;</Typography>}
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {days}&nbsp;
                </Typography>
                <Typography variant="body2">day(s)</Typography>
              </Fragment>
            )}
            {hasHours && (
              <Fragment>
                {(hasDays || hasWeeks) && (
                  <Typography variant="body2">,&nbsp;</Typography>
                )}
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {hours}&nbsp;
                </Typography>
                <Typography variant="body2">hour(s)</Typography>
              </Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
