import { SchemaOf, array, object, string } from "yup";

export type JobSponsorshipFilterFormData = {
  options: string[];
  other: string;
};

export type JobSponsorshipFilterSchema = SchemaOf<JobSponsorshipFilterFormData>;

export const jobSponsorshipFilterSchema: JobSponsorshipFilterSchema = object({
  options: array().of(string().default("")),
  other: string().default(""),
});
