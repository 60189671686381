import { useMutation, useQueryClient } from "react-query";
import { toggleFeaturedProject } from "../../../../../../api-services/projects/project/toggleFeaturedProject";
import { PAST_PROJECTS_QUERY_KEY } from "../../../../../common/hooks/projects/usePastProjectsQuery";
import { useToast } from "../../../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../job-requests/hooks/feedbackErrors";

export const useToggleFeaturedProject = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation(
    async (projectId: string) => {
      const { data } = await toggleFeaturedProject(projectId);

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PAST_PROJECTS_QUERY_KEY);
        notifySuccess(feedbackMessage("project", FEEDBACK_TYPE.UPDATE));
      },
    }
  );
};
