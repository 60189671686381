import jwt from "jwt-decode";
import { useCallback, useMemo } from "react";
import { DecodedJWT } from "../../../../api-types/sign-up";
import { useIsAuthed, useUser } from "../../../state/hooks";
import { useLocalState } from "../utils";

export const useCheckAccessExpiry = () => {
  const setIsAuthed = useIsAuthed().set;
  const [token] = useLocalState("access_token", "");
  const setUser = useUser().set;

  const { search, pathname } = window.location;

  if (pathname !== "sign-in" && pathname !== "client") {
    window.sessionStorage.setItem("navigateTo", `${pathname}${search}`);
  }

  const currentTick = useMemo(() => (new Date().getTime() + 1) / 1000, []);

  const tokenHasExpired = useCallback(
    (decodedToken: DecodedJWT) => {
      return decodedToken.exp < currentTick;
    },
    [currentTick]
  );

  const clearUser = useCallback(() => {
    setIsAuthed(false);
    setUser({
      name: "",
      id: null,
      accountType: null,
    });
  }, [setIsAuthed, setUser]);

  if (!token) {
    clearUser();
    return;
  }

  const decodedToken: DecodedJWT = jwt(token);

  if (tokenHasExpired(decodedToken)) {
    clearUser();
  } else {
    setIsAuthed(true);
    setUser({
      name: decodedToken.username,
      id: decodedToken.userId,
      accountType: decodedToken.type,
    });
  }
};
