import {
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Formik } from "formik";
import { noop } from "lodash";
import React from "react";
import * as Yup from "yup";
import { JobRequestsSearchFilters } from "../../../../../../api-types/job-requests/common";
import { SearchIcon } from "../../../../../../icons/SearchIcon";
import { BLUE } from "../../../../../../themes/components/utils";
import {
  useBlueTextColorStyles,
  useWhiteSelectStyles,
} from "../../../../../../themes/style-hooks";
import { selectMenuProps } from "./utils";

const useStyles = makeStyles({
  input: {
    "& .MuiInputBase-root": {
      height: 68,
    },
    width: 450,
    "& ::placeholder": {
      color: BLUE,
    },
    ["@media screen and (max-width: 1340px)"]: {
      width: 400,
    },
    ["@media screen and (max-width: 1280px)"]: {
      width: 350,
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: 300,
    },
    ["@media screen and (max-width: 1140px)"]: {
      width: 250,
    },
  },
});

export const SearchForm: React.FC = () => {
  const whiteSelectStyles = useWhiteSelectStyles();
  const blueTextColorStyles = useBlueTextColorStyles();
  const styles = useStyles();

  const initialValues: JobRequestsSearchFilters = {
    search: "",
    date: "date",
    budget: { min: 0, max: 9999 },
    bids: { min: 0, max: 999 },
  };

  const validationSchema = Yup.object({});

  return (
    <Box width="100%">
      <Formik
        initialValues={initialValues}
        onSubmit={noop}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, handleChange, handleBlur, errors, values }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" width="100%" gridColumnGap={16}>
              <Box className={styles.input}>
                <TextField
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.search}
                  name="search"
                  placeholder={"Search job requests"}
                  InputProps={{
                    endAdornment: (
                      <IconButton disableTouchRipple type="submit">
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              <Box width={112} mt={-14.75}>
                <Typography
                  variant="subtitle2"
                  style={{
                    paddingBottom: 26,
                    whiteSpace: "nowrap",
                  }}
                >
                  Filter by:
                </Typography>
                <Select
                  classes={{
                    root: whiteSelectStyles.root,
                  }}
                  className={blueTextColorStyles.root}
                  MenuProps={selectMenuProps}
                  fullWidth
                  value={values.date}
                  name="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value="date">Date</MenuItem>
                </Select>
              </Box>
              <Box width={134}>
                <Select
                  MenuProps={selectMenuProps}
                  fullWidth
                  name="budget"
                  value={values.budget}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  classes={{
                    root: whiteSelectStyles.root,
                  }}
                  className={blueTextColorStyles.root}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value="budget">Budget</MenuItem>
                  <MenuItem value="<1000">{"<1000"}</MenuItem>
                  <MenuItem value="1000-5000">1000-5000</MenuItem>
                  <MenuItem value="5000+">{">5000"}</MenuItem>
                </Select>
              </Box>
              <Box width={174}>
                <Select
                  MenuProps={selectMenuProps}
                  fullWidth
                  name="bids"
                  value={values.bids}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  classes={{
                    root: whiteSelectStyles.root,
                  }}
                  className={blueTextColorStyles.root}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value="bids">Nr. of bids</MenuItem>
                  <MenuItem value="0">0</MenuItem>
                  <MenuItem value="1-10">1-10</MenuItem>
                  <MenuItem value="10+">10+</MenuItem>
                </Select>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};
