import { Box, Typography } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useMemo } from "react";
import { JobPostPaymentForm } from "./JobPostPaymentForm";

type JobPostPaymentProps = {
  amount: number;
  selectedPackage: string;
  noLabel?: boolean;
  buttonLabel?: string;
  onSuccess?: () => void;
};

export const JobPostPayment: React.FC<JobPostPaymentProps> = ({
  amount,
  selectedPackage,
  noLabel,
  buttonLabel,
  onSuccess,
}) => {
  // this will load stripe.js only when PaymentDashboard is rendered
  // eslint-disable-next-line no-undef
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? "");

  const selectedPackageLabel = useMemo(() => {
    return selectedPackage.replace("-", " ");
  }, [selectedPackage]);

  const selectedPackageCount = useMemo(() => {
    const num = selectedPackageLabel.replace(/[^0-9]/g, "");

    return parseInt(num, 10);
  }, [selectedPackageLabel]);

  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      {!noLabel && (
        <Typography variant="subtitle2">
          You have selected package with <strong>{selectedPackageLabel}</strong>{" "}
          posts
        </Typography>
      )}
      <Elements stripe={stripePromise}>
        <JobPostPaymentForm
          amount={amount}
          selectedPackageCount={selectedPackageCount}
          buttonLabel={buttonLabel}
          onSuccess={onSuccess}
        />
      </Elements>
    </Box>
  );
};
