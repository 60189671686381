import { useMutation, useQueryClient } from "react-query";
import { deleteJobPost } from "../../../../../api-services/job-posts";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../utils/useToast";
import { JOB_POSTS_QUERY_KEY } from "../useJobPosts";

export const useJobPostDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation((id: string) => deleteJobPost(id), {
    onSuccess() {
      notifySuccess(feedbackMessage("job post", FEEDBACK_TYPE.DELETE));
      queryClient.invalidateQueries(JOB_POSTS_QUERY_KEY);
    },
    onSettled() {},
  });
};
