import { SchemaOf, boolean, number, object, string } from "yup";

export type SalaryPerYearFilterFormData = {
  checked: boolean;
  estimate: {
    min: number;
    max: number;
  };
  currency: string;
};

export type SalaryPerYearFilterSchema = SchemaOf<SalaryPerYearFilterFormData>;

export const salaryPerYearFilterSchema: SalaryPerYearFilterSchema = object({
  checked: boolean().default(false).required(),
  estimate: object({
    min: number().default(0).min(0).max(999999).required(),
    max: number().default(999999).min(0).max(999999).required(),
  }),
  currency: string().default("USD").required(),
});
