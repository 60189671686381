import { useState } from "@hookstate/core";
import { state } from "./state";

export const useConfirmationModal = () => {
  return useState(state.confirmationModal);
};

export const useIsAuthed = () => {
  return useState(state.authed);
};

export const useUser = () => {
  return useState(state.user);
};

export const useSubscribeModal = () => {
  return useState(state.subscribeModal);
};

export const useCurrentJobRequest = () => {
  return useState(state.currentJobRequest);
};

export const useRedirectUrl = () => {
  return useState(state.redirectUrl);
};
