import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
} from "@material-ui/core";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { SendMessageIcon } from "../../../../icons/SendMessageIcon";
import { useMessagesInputStyles } from "../../../../themes/style-hooks";

type MessagesInputProps = {
  handleChange?: (e: ChangeEvent) => void;
  handleBlur?: (e: FocusEvent) => void;
  values?: FormikValues;
  errors?: FormikErrors<FormikValues>;
  isLoading?: boolean;
  onSend?: () => void;
};

export const MessagesInput: React.FC<MessagesInputProps> = ({
  handleChange,
  handleBlur,
  values,
  onSend,
  errors,
  isLoading = false,
}) => {
  const messagesInputStyles = useMessagesInputStyles();

  const isDisabled = Boolean(errors?.message || !values?.message);

  return (
    <Box py={2} id="message-input">
      <Field
        autoComplete="off"
        as={InputBase}
        className={messagesInputStyles.root}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values?.message}
        placeholder="Type your message here"
        name="message"
        endAdornment={
          isLoading ? (
            <CircularProgress
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            />
          ) : (
            <IconButton onClick={onSend} type="submit" disabled={isDisabled}>
              <SendMessageIcon />
            </IconButton>
          )
        }
      />
    </Box>
  );
};
