import { useMemo } from "react";
import { BudgetType } from "../../job-requests/create-job-request-wizard/freelance-job-request/BudgetStep";

export const useHourlyMilestonesLabels = (budget?: BudgetType) => {
  const hoursLabel = useMemo(() => {
    if (!budget) {
      return "";
    }

    return budget.hours.type === "fixed"
      ? `x ${budget.hours.fixed}hrs/wk`
      : `x ${budget.hours.estimate.min}- ${budget.hours.estimate.max}hrs/wk`;
  }, [budget]);

  const hourlyRateLabel = useMemo(() => {
    if (!budget) {
      return "";
    }

    return budget.hourlyRate.type === "fixed"
      ? `x $${budget.hourlyRate.fixed}/hr`
      : `x $${budget.hourlyRate.estimate.min} - $${budget.hourlyRate.estimate.max}hr`;
  }, [budget]);

  return { hoursLabel, hourlyRateLabel };
};
