import { Box, Divider } from "@material-ui/core";
import React from "react";
import { InquiryHeaderContent } from "./InquiryHeaderContent";
import { InquiryHeaderSummary } from "./InquiryHeaderSummary";

export const InquiryHeader: React.FC = () => {
  return (
    <Box display="flex" gridColumnGap={40}>
      <InquiryHeaderContent />
      <Divider orientation="vertical" flexItem />
      <InquiryHeaderSummary />
    </Box>
  );
};
