import { Box, Button } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useRedButtonStyles } from "../../../themes/style-hooks";
import { ConfirmationDialog } from "../../common/components";
import { useJobPostDeleteMutation } from "../../common/hooks/job-posts";
import { useJobPostRepostMutation } from "../../common/hooks/job-posts/job-post/useJobPostRepostMutation";
import { JOB_POSTS_QUERY_KEY } from "../../common/hooks/job-posts/useJobPosts";
import { METADATA_QUERY_KEY } from "../../common/hooks/metadata/useMetadataQuery";
import { useToast } from "../../utils/useToast";
import { useJobPackages } from "../job-requests/create-job-request-wizard/hooks/useJobPackages";

type ExpiredJobPostActionsProps = {
  jobPostId: string;
};

export const ExpiredJobPostActions = ({
  jobPostId,
}: ExpiredJobPostActionsProps) => {
  const { notifySuccess } = useToast();
  const queryClient = useQueryClient();
  const redButtonStyles = useRedButtonStyles();
  const { mutate: deleteJobPost, isLoading: isDeletingJobPost } =
    useJobPostDeleteMutation();

  const { mutate: repostJobPost, isLoading: isRepostingJobPost } =
    useJobPostRepostMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(JOB_POSTS_QUERY_KEY);
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
        notifySuccess("Job post has been re-posted successfully.");
      },
    });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const onDeleteJobPost = useCallback(() => {
    deleteJobPost(jobPostId);
    setOpenDeleteDialog(false);
  }, [deleteJobPost, jobPostId]);

  const { jobPostsCredits } = useJobPackages();

  const handleRepost = useCallback(() => {
    repostJobPost(jobPostId);
  }, [jobPostId, repostJobPost]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      gridColumnGap={8}
      justifyContent="flex-end"
    >
      <Button
        className={redButtonStyles.delete}
        disabled={isDeletingJobPost}
        onClick={() => setOpenDeleteDialog(true)}
        size="medium"
        variant="outlined"
        data-cy-btn="delete-job-post"
      >
        Delete
      </Button>
      <Button
        disabled={jobPostsCredits === 0 || isRepostingJobPost}
        size="medium"
        variant="contained"
        onClick={handleRepost}
        data-cy-btn="repost-job-post"
      >
        Re-post
      </Button>

      <ConfirmationDialog
        confirmLabel="Delete Job Post"
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={onDeleteJobPost}
        title="Are you sure you want to delete this job post?"
      />
    </Box>
  );
};
