import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { FC, ReactNode, useCallback } from "react";
import { YELLOW } from "../../../../themes/components/utils";
import {
  DueDate,
  Milestone,
} from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { stringToTitleCase } from "../../../utils";

type FixedPriceDiffTableRowProps = {
  actions: ReactNode;
  alertChip?: ReactNode;
  firstRow?: boolean;
  milestone: Milestone;
};

export const FixedPriceDiffTableRow: FC<FixedPriceDiffTableRowProps> = ({
  actions,
  alertChip,
  firstRow,
  milestone,
}) => {
  const { description, amount, dueDate, isFunded, status } = milestone;

  const dueDateType = useCallback((dueDate: DueDate) => {
    return dueDate.type.substring(0, dueDate.type.length - 1);
  }, []);

  return (
    <TableRow
      style={{
        backgroundColor: YELLOW,
        height: 64,
      }}
    >
      <TableCell />
      <TableCell>
        <Box display="flex" gridGap={12}>
          <Typography color="secondary" component="span" variant="body2">
            {description}
          </Typography>
          {alertChip}
        </Box>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          ${amount}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {`${dueDate.count} ${dueDateType(dueDate)}(s) from start of ${
            firstRow ? "project" : "milestone"
          }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {isFunded ? "Yes" : "No"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color="secondary" component="span" variant="body2">
          {stringToTitleCase(status ?? "")}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="flex-end" gridColumnGap={8}>
          {actions}
        </Box>
      </TableCell>
    </TableRow>
  );
};
