import { useMemo } from "react";
import { useProjectsQuery } from "../../../common/hooks/projects/useProjectsQuery";

export const useProjectsOptions = () => {
  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();

  return useMemo(() => {
    if (isLoadingProjects || !projects) return [];

    return projects.map((project, index) => {
      return {
        title: project?.title,
        id: project?._id,
        index,
      };
    });
  }, [projects, isLoadingProjects]);
};
