import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const SearchBarSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={24}
      pb={4}
      width="100%"
    >
      <Skeleton variant="rect" animation="wave" width="95%" height={68} />
      <Box display="flex" justifyContent="space-between" width="95%">
        <Skeleton variant="rect" animation="wave" width="20%" height={32} />
        <Skeleton variant="rect" animation="wave" width="30%" height={32} />
        <Skeleton variant="rect" animation="wave" width="25%" height={32} />
      </Box>
      <Box display="flex" justifyContent="space-between" width="95%">
        <Skeleton variant="rect" animation="wave" width="20%" height={32} />
        <Skeleton variant="rect" animation="wave" width="30%" height={32} />
        <Skeleton variant="rect" animation="wave" width="25%" height={32} />
      </Box>
      <Box display="flex" justifyContent="space-between" width="95%">
        <Skeleton variant="rect" animation="wave" width="15%" height={32} />
        <Skeleton variant="rect" animation="wave" width="15%" height={32} />
        <Skeleton variant="rect" animation="wave" width="20%" height={32} />
        <Skeleton variant="rect" animation="wave" width="15%" height={32} />
        <Skeleton variant="rect" animation="wave" width="15%" height={32} />
      </Box>
    </Box>
  );
};
