import { Box, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { Card } from "../../../elements/Card";

type SwitchProps = {
  isLoading?: boolean;
};

export const Switch: React.FC<SwitchProps> = ({ isLoading }) => {
  const boldStyles = useBoldStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box>
      <Box pt={10}>
        <Typography variant="h1" className={boldStyles.root}>
          Account and Subscription Information
        </Typography>
      </Box>
      <Box
        display="flex"
        py={10}
        gridColumnGap={20}
        className={boldStyles.root}
      >
        <Card
          width={216}
          label="Account Information"
          isLoading={isLoading}
          isSelected={
            searchParams.get("tab") === "account" || !searchParams.get("tab")
          }
          onClick={() => onTabNavigationHandler("account")}
        />
        <Card
          width={252}
          isLoading={isLoading}
          label="Subscription Information"
          isSelected={searchParams.get("tab") === "subscription"}
          onClick={() => onTabNavigationHandler("subscription")}
        />
      </Box>
    </Box>
  );
};
