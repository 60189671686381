import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { upperFirst } from "lodash";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { JobApplication } from "../../../../../api-types/job-applications";
import { JobPost } from "../../../../../api-types/job-posts";
import { YELLOW } from "../../../../../themes/components/utils";
import {
  useBoldStyles,
  usePointerStyles,
} from "../../../../../themes/style-hooks";
import {
  ConfirmationDialog,
  CustomTooltip,
  ShowImages,
  TextOverflow,
} from "../../../../common/components";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useDeleteJobApplication } from "../../../job-posts/hooks/useDeleteJobApplication";
import { useWithdrawApplication } from "../../state/hooks";
import { JobPostItemActions } from "./JobPostItemActions";
import { JobPostItemChips } from "./JobPostItemChips";

type JobPostItemProps = {
  jobPost: JobPost;
  jobApplication?: JobApplication;
};

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    fontSize: 16,
    whiteSpace: "nowrap",
  },
  tooltip: {
    textDecoration: "none",
    color: YELLOW,
  },
  divider: {
    backgroundColor: palette.secondary.main,
  },
}));

export const JobPostItem: React.FC<JobPostItemProps> = ({
  jobPost,
  jobApplication,
}) => {
  const boldStyles = useBoldStyles();
  const [isHovering, setIsHovering] = useState(false);
  const pointerStyles = usePointerStyles();
  const styles = useStyles();
  const navigate = useNavigate();

  const { mutate: deleteJobApplication } = useDeleteJobApplication();

  const { jobTitle, responsibilities, description, images } = jobPost;

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const skills = useMemo(() => {
    if (jobPost?.skills) {
      return jobPost?.skills.map((skill: any) => skill.name);
    }
    return [];
  }, [jobPost]);

  const ref = useRef(null);

  const hasImages = Boolean(images?.length ?? 0 > 0);

  const withdrawApplication = useScopedDowngradedStateValue(
    useWithdrawApplication()
  );

  const setWithdrawApplication = useWithdrawApplication().set;

  const handleWithdrawApplication = () => {
    if (jobApplication?._id) {
      deleteJobApplication(jobApplication?._id);
    }
    setWithdrawApplication({ open: false });
  };

  const tooltipTitle = useMemo(() => {
    return (
      <Fragment>
        <Box display="flex" flexDirection="column" gridRowGap={14}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 700,
            }}
            variant="subtitle2"
          >
            Description
          </Typography>
          <Divider className={styles.divider} />

          <Box display="flex" flexDirection="column" gridRowGap={28}>
            <Typography
              color="secondary"
              style={{
                fontWeight: 700,
              }}
              variant="subtitle2"
            >
              {/*{profileInformation?.position}*/}
            </Typography>
            <Typography
              color="secondary"
              variant="body1"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Fragment>
    );
  }, [description, styles.divider]);

  return (
    <Box
      // @ts-ignore
      ref={ref}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      className={pointerStyles.root}
      data-cy="job-request-item"
    >
      <Box display="flex" alignItems="flex-end" gridColumnGap={40}>
        <Box display="flex" alignItems="baseline" mr="auto">
          <TextOverflow
            width="100%"
            style={{
              fontSize: 24,
              color: isHovering ? YELLOW : "white",
              marginRight: "12px",
            }}
            className={boldStyles.root}
            to={`/job-requests/search/${jobPost._id}`}
          >
            {upperFirst(jobTitle)}
          </TextOverflow>
          {/*{createdAt && (*/}
          {/*  <Typography className={jobHeaderDetailsStyles.root}>*/}
          {/*    Posted {format(new Date(createdAt), formatShortDate)}*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </Box>
        {/*{jobApplication && jobApplication?.createdAt && (*/}
        {/*  <Typography>*/}
        {/*    Resume sent{" "}*/}
        {/*    {format(new Date(jobApplication.createdAt), formatShortDate)}*/}
        {/*  </Typography>*/}
        {/*)}*/}
        <JobPostItemActions
          jobPostId={jobPost._id}
          onOpenPreview={() => {
            navigate(`/job-requests/search/${jobPost._id}`);
          }}
          onWithdraw={
            jobApplication
              ? () => setWithdrawApplication({ open: true })
              : undefined
          }
          withdrawLabel="Withdraw Application"
          label="Details"
        />
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>

      <JobPostItemChips jobPost={jobPost} />

      <Box py={2.5}>
        {responsibilities ? (
          <Typography component="div" variant="body1">
            <ShowMoreText
              lines={3}
              more={
                <CustomTooltip
                  arrow
                  interactive
                  placement="top-end"
                  title={tooltipTitle}
                >
                  <Typography component="span" variant="body2" color="primary">
                    More
                  </Typography>
                </CustomTooltip>
              }
              anchorClass={styles.tooltip}
              expanded={false}
              // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
              expandByClick={false}
            >
              {responsibilities}
            </ShowMoreText>
          </Typography>
        ) : (
          // <Description
          //   label="Responsibilities and Duties"
          //   description={responsibilities}
          // />
          description && (
            <Typography component="div" variant="body1">
              <ShowMoreText
                lines={3}
                more={
                  <CustomTooltip
                    arrow
                    interactive
                    placement="left"
                    title={tooltipTitle}
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      color="primary"
                    >
                      More
                    </Typography>
                  </CustomTooltip>
                }
                anchorClass={styles.tooltip}
                expanded={false}
                // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
                expandByClick={false}
              >
                {description}
              </ShowMoreText>
            </Typography>
            // <Description label="Description" description={description} />
          )
        )}
      </Box>

      {skills.length > 0 && (
        <Box py={2.5}>
          <SkillsOutput skills={skills} isHovering={isHovering} />
        </Box>
      )}
      {hasImages && (
        <Box py={2.5}>
          <ShowImages selectedFiles={images} />{" "}
        </Box>
      )}
      <ConfirmationDialog
        confirmLabel="Withdraw Job Application"
        open={withdrawApplication.open}
        onClose={() =>
          setWithdrawApplication({
            open: false,
          })
        }
        onConfirm={handleWithdrawApplication}
        title="Are you sure you want to withdraw this job application?"
      />
    </Box>
  );
};
