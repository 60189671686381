import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useHeadlineText,
  useMediumItalic,
  useMobileStyles,
  useStepDStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepDHeadline: FC = () => {
  const styles = useStepDStyles();
  const mediumItalic = useMediumItalic();
  const { transparent, skewedText } = useHeadlineText({
    primary: COLOR.PALE,
    secondary: "#EC6048",
    background: "#EC6048",
  });

  const { mobileHeadlineWrapper } = useMobileStyles();

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box
        className={mediumItalic.root}
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Box className={`${transparent} ${styles.headline}`}>
          <span>All job packages&nbsp;</span>
        </Box>
        <br />
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;start with a&nbsp;</span>
        </Box>
        <br />
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;10-day free trial.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
