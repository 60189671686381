import { JobPost } from "../job-posts";

export enum Status {
  PENDING = "pending",
  REJECTED = "rejected",
  SHORTLISTED = "shortlisted",
}

export type JobApplication = {
  _id: string;
  message?: string;
  resume: string;
  status: Status;
  createdBy: string;
  createdAt: string;
  jobPost: JobPost;
};
