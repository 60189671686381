import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Field } from "formik";
import React, { ChangeEvent, useCallback, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { useSearchParams } from "react-router-dom";
import { SkillV2 } from "../../../../../api-types/job-requests/common";
import { ProjectTimeframeType } from "../../../../../api-types/job-requests/job-request";
import { FeaturedProject } from "../../../../../api-types/profile-information";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { ImageComponent } from "../../../../client/job-requests/utils";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { CustomInput, CustomTextArea } from "../../../../common/elements";
import { useRequiredFieldLabel } from "../../../../common/hooks/utils";

type AddNewProjectProps = {
  onAdd: (newProject: FeaturedProject) => void;
  onCancel: () => void;
  onUpdate: (updatedProject: FeaturedProject) => void;
  projectValues?: FeaturedProject;
};

const useStyles = makeStyles({
  editButton: {
    fontWeight: "normal",
    fontSize: 24,
    width: 80,
    height: 48,
    border: "1px solid",
    borderColor: YELLOW,
  },
  button: {
    fontWeight: "normal",
    width: 260,
  },
  nameTextArea: {
    width: 400,
    marginTop: 24,
  },
  projectsList: {
    color: "white",
    backgroundColor: BLUE,
    "&:hover": {
      cursor: "pointer",
      color: BLUE,
      backgroundColor: YELLOW,
    },
  },
});

const makeProjectUrl = (url?: string) => {
  if (!url) {
    return "";
  }

  return url.startsWith("https://") ? url : `https://${url}`;
};

export const AddNewProject: React.FC<AddNewProjectProps> = ({
  onAdd,
  onCancel,
  onUpdate,
  projectValues,
}) => {
  const styles = useStyles();
  const boldStyles = useBoldStyles();
  const [skills, setSkills] = useState<SkillV2[]>(
    projectValues?.skills.map(({ name }) => name) ?? []
  );
  const requiredFieldLabel = useRequiredFieldLabel();

  const [searchParams, setSearchParams] = useSearchParams();

  const [project, setProject] = useState<FeaturedProject>({
    name: projectValues?.name ?? "",
    url: projectValues?.url ?? "",
    images: projectValues?.images ?? [],
    skills: projectValues?.skills ?? [],
    description: projectValues?.description ?? "",
    timeframe: projectValues?.timeframe ?? {
      type: ProjectTimeframeType.WEEKS,
    },
  });

  const handleChange = useCallback(
    (value: any, field: string) => {
      setProject({ ...project, [field]: value });
    },
    [project]
  );

  const handleChangeFileUpload = useCallback(
    (uploadedFiles: FileWithPath[]) => {
      setProject({ ...project, images: uploadedFiles ?? [] });
    },
    [project]
  );

  const handleSaveProject = useCallback(() => {
    if (!projectValues) {
      onAdd({
        ...project,
        skills: skills.map((skill) => ({ name: skill })),
        url: makeProjectUrl(project.url),
      });
    } else {
      onUpdate({
        ...project,
        skills: skills.map((skill) => ({ name: skill })),
        url: makeProjectUrl(project.url),
      });
    }
  }, [projectValues, onAdd, project, skills, onUpdate]);

  const canSaveProject =
    Boolean(project.name) &&
    Boolean(project.description) &&
    skills.length > 0 &&
    (project?.timeframe?.weeks ?? 0) > 0;

  const blockInvalidChar = (e: any) => {
    if (
      [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
      ].includes(e.key)
    ) {
      return;
    }

    e.preventDefault();
  };

  return (
    <Box>
      <Box pt={6}>
        <Typography variant="h1" className={boldStyles.root}>
          {!projectValues ? "Add New Project" : "Edit Project"}
        </Typography>
        <br />
        <Box className={styles.nameTextArea}>
          <Field
            component={CustomInput}
            placeholder={requiredFieldLabel("Project Name")}
            // name="name"
            value={project.name}
            handleChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value, "name")
            }
            error={!project.name ? "Project name is required" : ""}
          />
        </Box>
      </Box>
      <ImageComponent
        images={project.images}
        handleChangeFileUpload={(files) => {
          handleChangeFileUpload(files);
        }}
        maxFiles={5}
      />
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={50}
        pl={130}
        pb={10}
      >
        <Box>
          <Typography variant="subtitle1" className={boldStyles.root}>
            {requiredFieldLabel("Skills Used In This Project")}
          </Typography>
          <Box display="flex" flexDirection="column" width="80%">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <SkillsInput
                width={800}
                initialSkills={project.skills.map((skill) => skill.name)}
                handleChange={(newSkills) => setSkills(newSkills)}
                error={!skills.length ? "At least one skill is required" : ""}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gridRowGap={50}>
          <Field
            component={CustomTextArea}
            placeholder={requiredFieldLabel("Description")}
            value={project.description}
            handleChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleChange(e.target.value, "description")
            }
            error={!project.description ? "Description is required" : ""}
          />

          <Box display="flex" gridColumnGap={20}>
            <Field
              component={CustomInput}
              placeholder="Website URL"
              value={project.url}
              handleChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e.target.value, "url")
              }
            />
            <Field
              onKeyDown={blockInvalidChar}
              type="number"
              component={CustomInput}
              placeholder={requiredFieldLabel("Timeframe (weeks)")}
              name="timeframe.weeks"
              value={project?.timeframe?.weeks?.toString() ?? ""}
              handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange(
                  { ...project.timeframe, weeks: e.target.value },
                  "timeframe"
                );
              }}
              error={!project.timeframe.weeks ? "Timeframe is required" : ""}
            />
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="flex-end"
        justifyContent="flex-end"
        display="flex"
        gridColumnGap={12}
        py={10}
      >
        <Button
          variant="outlined"
          size="medium"
          onClick={() => {
            searchParams.delete("isEditing");
            setSearchParams(searchParams);
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!canSaveProject}
          variant="contained"
          onClick={() => {
            searchParams.delete("isEditing");
            setSearchParams(searchParams);
            handleSaveProject();
          }}
          size="medium"
        >
          {!projectValues ? "Add project" : "Update Project"}
        </Button>
      </Box>
    </Box>
  );
};
