import { useMemo } from "react";
import { useAccountBids } from "./useAccountBids";

export const useBidsOptions = () => {
  const { data: accountBids, isLoading: isLoadingAccountBids } =
    useAccountBids();

  return useMemo(() => {
    if (isLoadingAccountBids || !accountBids) return [];

    return accountBids.map((accountBid, index) => {
      return {
        title: accountBid?.jobRequest?.title,
        id: accountBid?._id,
        index,
      };
    });
  }, [accountBids, isLoadingAccountBids]);
};
