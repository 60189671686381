import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { FC, ReactNode, useEffect, useRef } from "react";
import { YELLOW } from "../../../themes/components/utils";
import { useBoldStyles } from "../../../themes/style-hooks";

export const ScrollbarStyles = css`
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background-color: none;
    border: 2px solid ${YELLOW};
  }

  &::-webkit-scrollbar-track-piece {
    background-color: none;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const ScrollableContent = styled(Box)`
  ${ScrollbarStyles}
`;

export type SectionProps = {
  title?: string;
  children?: ReactNode;
  scrollable?: boolean;
  loading?: boolean;
  error?: string | null | ReactNode;
  boxProps?: any;
  scrollToBottom?: boolean;
  maxScrollHeight?: number;
};

const defaultContainerStyles = {
  minHeight: 0,
  height: "100%",
};

export const ScrollableSection: FC<SectionProps> = ({
  title,
  children,
  scrollable = true,
  loading,
  error,
  boxProps,
  scrollToBottom,
  maxScrollHeight,
}) => {
  const boldStyles = useBoldStyles();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref?.current || !scrollToBottom) return;

    const element = ref?.current as HTMLDivElement;

    element.scrollTop = element.scrollHeight;
  });

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={defaultContainerStyles}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={defaultContainerStyles}
      >
        {/*<ErrorIcon />*/}
        <Box mt={1}>{error}</Box>
      </Box>
    );
  }

  return (
    <Box
      style={defaultContainerStyles}
      position="relative"
      px={1}
      py={1}
      borderColor="white"
      {...boxProps}
    >
      <Box minHeight={0} height="100%" overflow="auto" flexGrow={1}>
        {title && (
          <Box
            style={{
              position: "absolute",
              top: "-12px",
              left: "8px",
              maxWidth: "50%",
              borderRadius: "8px",
              zIndex: 5,
              height: "20px",
            }}
            px={2}
          >
            <Typography
              variant="caption"
              style={{
                fontSize: 14,
                lineHeight: "20px",
              }}
              className={boldStyles.root}
              noWrap
              title={title}
            >
              {title}
            </Typography>
          </Box>
        )}

        {scrollable ? (
          <ScrollableContent
            // @ts-ignore
            ref={ref}
            height="100%"
            maxHeight={maxScrollHeight}
            style={{
              overflow: "auto",
            }}
          >
            {children}
          </ScrollableContent>
        ) : (
          <Box py={2} height="100%">
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};
