import { DataGridProps } from "@mui/x-data-grid";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiDataGrid: MuiComponentThemeDefinition<string, DataGridProps> = {
  props: {},
  override: () => ({
    root: {
      color: "white",
      border: `none`,
      "& div.MuiDataGrid-cell": {
        borderColor: "rgba(202, 227, 244, 0.5)",
        fontSize: 16,
      },
      "& div.MuiDataGrid-columnsContainer": {
        borderColor: "rgba(202, 227, 244, 0.5)",
      },
      "& div.MuiDataGrid-columnSeparator": {
        display: "none",
      },
      "& div.MuiTablePagination-root": {
        color: "white",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: 16,
        fontWeight: 700,
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-cell:focus-within": {
        outline: "none !important",
      },
    },
  }),
};
