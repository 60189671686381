import { Box, Link, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../common/hooks/sign-in";
import { useScopedDowngradedStateValue } from "../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../state/hooks";

export const ProfessionalMenu: React.FC = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { name } = useScopedDowngradedStateValue(useUser());

  const logout = useLogout();

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      <Link
        color="secondary"
        underline="hover"
        component="button"
        variant="body1"
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center">
          Hi,
          <Box
            component="span"
            style={{
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            &nbsp;<b>{name}</b>&nbsp;
          </Box>
          <ExpandMoreIcon color="secondary" />
        </Box>
      </Link>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/account/");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/account-balance");
          }}
        >
          Account Balance
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/account-information");
          }}
        >
          Account and Subscription
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/projects/unpublished");
          }}
        >
          Unpublished Projects
        </MenuItem>
        <MenuItem onClick={() => navigate("/contact")}>Contact Us</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
