import axios from "axios";
import { collaborationUrl } from "../../utils";

export const makeCollaborationProposal = async (params: {
  projectId: string;
  professionalId: string;
  introductoryMessage: string;
}) => {
  const { projectId, professionalId, introductoryMessage } = params;

  return axios.post(collaborationUrl(projectId), {
    professional: professionalId,
    introductoryMessage,
  });
};
