import { Box, Divider } from "@material-ui/core";
import React from "react";
import { ProfessionalReferralHeaderContent } from "./ProfessionalReferralHeaderContent";
import { ProfessionalReferralHeaderSummary } from "./ProfessionalReferralHeaderSummary";

export const ProfessionalReferralHeader = () => {
  return (
    <Box display="flex" gridColumnGap={40} py={10}>
      <ProfessionalReferralHeaderContent />
      <Divider orientation="vertical" flexItem />
      <ProfessionalReferralHeaderSummary />
    </Box>
  );
};
