import { useMemo } from "react";
import { useAccountBid } from "./useAccountBid";

export const useBidDataFunding = (bidId?: string) => {
  const { data: bidData, isLoading } = useAccountBid(bidId);

  return useMemo(() => {
    if (isLoading) return {};

    return bidData?.jobRequest.budget.type === "fixed"
      ? {
          fixedProjectFunding: {
            // dueDate: bidData?.fixedProjectFunding.dueDate ?? 0,
            // milestones: bidData?.fixedProjectFunding.milestones,
            totalPrice: bidData?.fixedProjectFunding?.totalPrice ?? 0,
          },
        }
      : {
          hourlyProjectFunding: {
            hourlyRate: bidData?.hourlyProjectFunding?.hourlyRate,
            // hours: bidData?.hourlyProjectFunding?.hours,
            // timeframe: bidData?.hourlyProjectFunding?.timeFrame,
          },
        };
  }, [
    bidData?.fixedProjectFunding?.totalPrice,
    bidData?.hourlyProjectFunding?.hourlyRate,
    bidData?.jobRequest.budget.type,
    isLoading,
  ]);
};
