import { Box, Button, Divider, Typography } from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridRowData,
  GridSelectionModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { chain } from "lodash";
import { FC, useMemo, useState } from "react";
import { generateInvoicesPDF } from "../../utils/generatePDF";
import { Section } from "./Section";

const DATE_FIELDS = ["createdAt", "date"];
const PAGE_SIZE = 5;
const HEADER_HEIGHT = 60;
const FOOTER_HEIGHT = 5;
const ROW_HEIGHT = 70;
const TABLE_TITLE_HEIGHT = 50;
const TABLE_TOP_PADDING = 18;
const TABLE_TOP_HEIGHT =
  TABLE_TOP_PADDING * 4 + TABLE_TITLE_HEIGHT + HEADER_HEIGHT;
const MIN_TABLE_HEIGHT = TABLE_TOP_HEIGHT + ROW_HEIGHT;
const MAX_TABLE_HEIGHT =
  TABLE_TOP_HEIGHT + ROW_HEIGHT * PAGE_SIZE + FOOTER_HEIGHT;

const dateValueFormatter = ({ value }: GridValueFormatterParams) => {
  if (value && typeof value === "string") {
    const date = new Date(value);
    return format(date, "MMM dd, yyyy");
  }

  return value;
};

const extendColumns = (column: GridColDef): GridColDef => {
  return {
    ...column,
    valueFormatter: DATE_FIELDS.includes(column.field)
      ? dateValueFormatter
      : undefined,
  };
};

type DownloadableDataGridProps = {
  columns: GridColDef[];
  data: GridRowData[];
  title?: string;
  isLoading?: boolean;
};

export const DownloadableDataGrid: FC<DownloadableDataGridProps> = ({
  columns,
  data,
  title,
  isLoading,
}) => {
  const [selectedItems, setSelectedItems] = useState<GridSelectionModel>();

  const onDownloadHandler = () => {
    const [fields, headers] = columns.reduce<string[][]>(
      (acc, { field, headerName }) => {
        return [
          [...acc[0], field],
          [...acc[1], headerName ?? field],
        ];
      },
      [[], []]
    );
    const tableColumns = [headers];
    const tableRows = chain(data)
      .filter(({ id }) => selectedItems?.includes(id))
      .map((row): string[] => fields.map((field) => row[field]))
      .value();

    generateInvoicesPDF(tableColumns, tableRows);
  };

  const extendedColumns = useMemo(() => columns.map(extendColumns), [columns]);

  const height = useMemo(() => {
    return data.length <= 5
      ? TABLE_TOP_HEIGHT + data.length * ROW_HEIGHT
      : MAX_TABLE_HEIGHT;
  }, [data.length]);

  return (
    <Box
      pt={TABLE_TOP_PADDING}
      minHeight={MIN_TABLE_HEIGHT}
      height={height}
      style={{
        color: "white",
      }}
    >
      <Box
        pb={4}
        height={TABLE_TITLE_HEIGHT}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          disabled={!selectedItems?.length}
          onClick={onDownloadHandler}
        >
          Download as .pdf
        </Button>
      </Box>
      <Divider />
      <Section isLoading={isLoading} size="small">
        <DataGrid
          checkboxSelection={data.length > 0}
          columns={extendedColumns}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          headerHeight={HEADER_HEIGHT}
          hideFooter={data.length <= PAGE_SIZE}
          onSelectionModelChange={setSelectedItems}
          pageSize={PAGE_SIZE}
          pagination={undefined}
          rows={data}
          rowsPerPageOptions={[PAGE_SIZE]}
          rowHeight={ROW_HEIGHT}
          selectionModel={selectedItems}
        />
      </Section>
    </Box>
  );
};
