import { useMutation } from "react-query";
import { useParams } from "react-router";
import { closeProjectRequest } from "../../../../api-services/projects/project/closeProjectRequest";
import { ClosedProjectData } from "../../../../api-types/projects/project";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";

export const useCloseProjectRequest = () => {
  const { notifySuccess } = useToast();
  const { projectId } = useParams();

  return useMutation(
    async (closedProjectData?: ClosedProjectData) => {
      const { data } = await closeProjectRequest(projectId, closedProjectData);

      return data;
    },
    {
      onSuccess: () => {
        notifySuccess(feedbackMessage("closing request", FEEDBACK_TYPE.SUBMIT));
      },
    }
  );
};
