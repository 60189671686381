import { TableContainerClassKey, TableContainerProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { YELLOW } from "./utils";

export const MuiTableContainer: MuiComponentThemeDefinition<
  TableContainerClassKey,
  TableContainerProps
> = {
  props: {},
  override: () => ({
    root: {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "none",
        border: `2px solid ${YELLOW}`,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "none",
        border: `2px solid ${YELLOW}`,
      },
      "&::-webkit-scrollbar-track-piece": {
        backgroundColor: "none",
      },
      "&::-webkit-scrollbar": {
        width: 5,
      },
    },
  }),
};
