import { Box, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { PageWrapper } from "../modules/app/container/PageWrapper";
import { LIGHT_BLUE } from "../themes/components/utils";

export const NotFound = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>The Talented | Not found</title>
        <meta
          name="title"
          property="og:title"
          content="The Talented | Not found"
        />
        <meta
          name="description"
          property="og:description"
          content="Page not found"
        />
        <meta property="og:url" content="https://www.thetalented.co/" />
      </Helmet>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          width={200}
          height={200}
          bgcolor={LIGHT_BLUE}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h1" color="secondary">
            404
          </Typography>
        </Box>
        <Box pt={14}>
          <Typography variant="h1">
            The Page You Were Looking For Doesn’t Exist
          </Typography>
        </Box>
        <Box pt={7}>
          <Typography variant="body1">
            You may have mistyped the address or the page may have moved.{" "}
          </Typography>
        </Box>
      </Box>
    </PageWrapper>
  );
};
