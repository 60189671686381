import { Box, Card, makeStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BLUE, LIGHT_BLUE, YELLOW } from "../../../../themes/components/utils";
import { FeaturedProject } from "./FeaturedProject";

type ProjectsListItemProps = {
  project: any;
};

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,

    "& .MuiTypography-root": {
      color: (isHovering: boolean) => (isHovering ? BLUE : "white"),
    },
  },
}));

export const ProjectsListItem: React.FC<ProjectsListItemProps> = ({
  project,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const styles = useStyles(isHovering);
  const navigate = useNavigate();

  const onMouseEnterHandler = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeaveHandler = useCallback(() => {
    setIsHovering(false);
  }, []);

  const handleNavigateToProfessionalAccount = useCallback(() => {
    navigate(`/professionals/${project?.professionals?.[0]?.professional}`);
  }, [navigate, project?.professionals]);

  // if (project?.images?.length === 0) return null;

  return (
    <Box
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={handleNavigateToProfessionalAccount}
    >
      <Card
        className={styles.root}
        style={{
          backgroundColor: isHovering ? YELLOW : LIGHT_BLUE,
          borderRadius: 0,
          padding: 12,
          cursor: "pointer",
        }}
      >
        <FeaturedProject project={project} showEmpty />
      </Card>
    </Box>
  );
};
