import { useMutation, useQueryClient } from "react-query";
import { unsaveJobPost } from "../../../../api-services/saved-job-posts";
import { useToast } from "../../../utils/useToast";
import { SAVE_JOB_POSTS_QUERY_KEY } from "./useSavedJobPosts";

export const useRemoveFromSavedJobPostMutation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation((id: string) => unsaveJobPost(id), {
    onSuccess() {
      queryClient.invalidateQueries(SAVE_JOB_POSTS_QUERY_KEY);
      notifySuccess(
        "The job post has been successfully removed from your saved list."
      );
    },
  });
};
