import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { BLUE } from "../../../themes/components/utils";
import { formatNominativeMonth } from "../components/LoadingTypography";
import { useIsMobileView } from "../landing-page/hooks";

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: BLUE,
    opacity: 1,
  },
}));

export const Graphs = () => {
  const isMobile = useIsMobileView();
  const style = useStyle();

  return (
    <Box>
      <Box pt={isMobile ? 7.5 : 15}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            variant={isMobile ? "subtitle1" : "h2"}
            style={{
              width: "70%",
              fontWeight: 600,
            }}
            color="secondary"
          >
            The Power of Graph Traversals: Real-World Applications Explored
          </Typography>
          <Typography color="secondary" variant="body2">
            {format(new Date("2023-05-24"), formatNominativeMonth)}
          </Typography>
        </Box>
        <Box py={2.5}>
          <Divider className={style.root} />
        </Box>
        <Box display="flex" flexDirection="column" gridRowGap={24}>
          <Typography variant="body1" color="secondary">
            Graphs are mathematical structures composed of nodes (vertices)
            connected by edges. They are used to represent and analyze
            relationships or connections between objects or entities. Graph
            traversals, such as depth-first search (DFS) and breadth-first
            search (BFS), are methods for systematically exploring the elements
            of a graph. DFS explores a graph by traversing as far as possible
            along each branch before backtracking, while BFS explores all the
            vertices of a given level before moving to the next level. These
            graph traversal algorithms are crucial for various applications,
            including network analysis, pathfinding, social network analysis,
            and recommendation systems, as they allow us to uncover
            relationships, find optimal paths, and analyze connectivity patterns
            within complex networks.
          </Typography>
          <Typography variant="body1" color="secondary">
            Graph traversals, a fundamental concept in graph theory, enable us
            to explore the intricate relationships and connections within
            complex data structures. While graph theory may seem abstract, its
            practical applications extend far beyond academia. In this article,
            we will dive into the world of graph traversals and uncover their
            real-world significance. We will explore various industries where
            graph traversals are useful, showcasing how this powerful tool aids
            in solving real-world problems and optimizing processes.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Social Networks and Recommendations
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Graph traversals play a vital role in social networks by providing
            personalized recommendations and improving user experiences. By
            analyzing the graph of connections between users, graph algorithms
            can identify communities, find influencers, and suggest relevant
            content or connections. Platforms like Facebook, LinkedIn, and
            Twitter leverage graph traversals to recommend friends, connections,
            or news feeds tailored to individual preferences.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Web Crawling and Search Engines
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Search engines rely heavily on graph traversals to index and
            navigate the vast web of interconnected pages. Crawlers employ
            traversal algorithms to explore web links, discover new pages, and
            index their content. Graph-based ranking algorithms, such as
            PageRank, analyze link structures to determine the importance and
            relevance of web pages, ultimately improving search results and
            enhancing user experiences.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Transportation and Route Optimization{" "}
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Graph traversals find extensive use in transportation and route
            optimization systems. Algorithms like Dijkstra's or A* help find the
            shortest or fastest routes between locations, taking into account
            variables such as distance, traffic, or public transportation
            schedules. Companies like Uber, Google Maps, and logistics providers
            rely on graph traversals to optimize navigation, reduce travel time,
            and streamline their operations.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Recommendations and Personalization in E-commerce
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            E-commerce platforms harness the power of graph traversals to
            deliver personalized recommendations and enhance customer
            satisfaction. By analyzing user behavior, purchase history, and
            product similarities, graph algorithms can identify related items,
            suggest complementary products, and create personalized shopping
            experiences. Amazon's "Customers Who Bought This Also Bought"
            feature is a prime example of using graph traversals to improve
            product discovery and increase sales.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Fraud Detection and Network Analysis
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Graph traversals serve as a valuable tool in fraud detection and
            network analysis. By modeling relationships between entities, such
            as financial transactions, social connections, or communication
            patterns, graph algorithms can identify suspicious activities,
            detect fraud networks, and enhance cyber security measures. Banks,
            credit card companies, and cyber security firms utilize graph
            traversals to uncover fraudulent schemes and protect their
            customers.
          </Typography>
          <Typography variant="body1" color="secondary">
            <Typography
              variant={isMobile ? "subtitle2" : "subtitle1"}
              color="secondary"
              style={{
                fontWeight: 600,
              }}
            >
              Recommendation Systems in Media and Entertainment{" "}
            </Typography>
            <Box py={2.5}>
              <Divider />
            </Box>
            Graph traversals are employed in media and entertainment industries
            to power recommendation systems that personalize content delivery.
            By analyzing user preferences, viewing history, and content
            similarities, graph algorithms can suggest movies, TV shows, music,
            or articles that align with individual tastes. Platforms like
            Netflix, Spotify, and news aggregators leverage graph traversals to
            enhance user engagement and cater to diverse interests.
          </Typography>
          <Typography variant="body1" color="secondary">
            Graph traversals provide a powerful framework for navigating and
            uncovering hidden insights within complex interconnected data
            structures. From social networks and web crawling to transportation
            optimization and fraud detection, the real-world applications of
            graph traversals are vast and impactful. By leveraging the ability
            to traverse graphs efficiently, industries can enhance user
            experiences, optimize processes, and make data-driven decisions. As
            we continue to navigate the digital age, understanding and
            harnessing the potential of graph traversals will undoubtedly shape
            the way we explore and analyze the complex web of interconnected
            information.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
