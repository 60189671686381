import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import {
  useBoldStyles,
  useRadioStyles,
} from "../../../../../themes/style-hooks";
import { CustomCheckbox } from "../../../../common/elements/CustomCheckbox";

type ServicesProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const initialServicesValues = {
  services: {
    designing: false,
    marketing: false,
    development: false,
    writing: false,
  },
  project: "new",
};

export const Services: React.FC<ServicesProps> = ({
  handleBlur,
  handleChange,
  errors,
  values,
}) => {
  const radioStyles = useRadioStyles();
  const boldStyles = useBoldStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box display="flex" gridColumnGap={332} alignItems="flex-start">
        <Box width="30%" display="flex" flexDirection="column" gridRowGap={20}>
          <Typography variant="subtitle1" className={boldStyles.root}>
            Services Sought
          </Typography>
          <Typography variant="subtitle2">
            Select the kind of professional you're looking for.
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gridRowGap={60}
          >
            <Box display="flex" flexDirection="column" gridRowGap={10}>
              <Field
                component={CustomCheckbox}
                label="Design & Creative"
                name="services.designing"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.designing}
                value={values.services.designing}
              />
              <Field
                component={CustomCheckbox}
                label="Marketing & Strategy"
                name="services.marketing"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.marketing}
                value={values.services.marketing}
              />
              <Field
                component={CustomCheckbox}
                label="Web, Mobile & Software Development"
                name="services.development"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.development}
                value={values.services.development}
              />
              <Field
                component={CustomCheckbox}
                label="Writing & Content Creation"
                name="services.writing"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.writing}
                value={values.services.writing}
              />
            </Box>

            {/*TODO: don't remove commented code, will be added back in the future*/}
            {/*<Box>*/}
            {/*  <Box pb={5}>*/}
            {/*    <Typography variant="subtitle2" className={boldStyles.root}>*/}
            {/*      Do you want to create a new design, website*/}
            {/*      <br />*/}
            {/*      or app, or change an existing one?*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}

            {/*  <RadioGroup>*/}
            {/*    <Field*/}
            {/*      as={FormControlLabel}*/}
            {/*      name="project"*/}
            {/*      value="new"*/}
            {/*      control={*/}
            {/*        <Radio*/}
            {/*          className={radioStyles.root}*/}
            {/*          checked={values.project === "new"}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label="Build something new"*/}
            {/*    />*/}
            {/*    <Field*/}
            {/*      as={FormControlLabel}*/}
            {/*      name="project"*/}
            {/*      value="existing"*/}
            {/*      control={*/}
            {/*        <Radio*/}
            {/*          className={radioStyles.root}*/}
            {/*          checked={values.project === "existing"}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label="Change something existing"*/}
            {/*    />*/}
            {/*  </RadioGroup>*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
