import { Box, Button, Divider, Typography } from "@material-ui/core";
import { FormikErrors, FormikValues, useFormikContext } from "formik";
import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FeaturedProject } from "../../../../../api-types/profile-information";
import {
  useBoldStyles,
  useRedButtonStyles,
} from "../../../../../themes/style-hooks";
import { ImagePreview } from "../../../../client/job-requests/utils";
import { ConfirmationDialog } from "../../../../common/components";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { BackButton } from "../../../../common/elements/BackButton";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { GridWrapper } from "../../../../common/professionals-search/projects/GridWrapper";
import { useConfirmPortfolioProjectDeletion } from "../state/hooks";
import { AddNewProject } from "./AddNewProject";

type ProjectsProps = {
  onNextStep?: () => void;
  onSaveDraft?: () => void;
  onBack?: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: (e: ChangeEvent) => void;
  submitForm?: () => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const Projects: React.FC<ProjectsProps> = ({
  onBack,
  setFieldValue,
  submitForm,
  values,
}) => {
  const { setStatus } = useFormikContext();
  const boldStyles = useBoldStyles();
  const [addedProjects, setAddedProjects] = useState<FeaturedProject[]>(
    values?.projects ?? []
  );
  const [isAddingNew, setIsAddingNew] = useState(!values.projects.length);
  const [isEditingProjectIndex, setIsEditingProjectIndex] = useState<
    number | null
  >(null);

  const redButtonStyles = useRedButtonStyles();

  const handleRemoveProject = useCallback(
    (index: number) => {
      const updatedProjects = [...addedProjects];

      updatedProjects.splice(index, 1);

      setAddedProjects(updatedProjects);

      submitForm?.();
    },
    [addedProjects, submitForm]
  );

  const confirmPortfolioProjectDeletion = useScopedDowngradedStateValue(
    useConfirmPortfolioProjectDeletion()
  );

  const setConfirmPortfolioProjectDeletion =
    useConfirmPortfolioProjectDeletion().set;

  useEffect(() => {
    setFieldValue("projects", addedProjects);
  }, [addedProjects, setFieldValue]);

  const handleConfirm = useCallback(() => {
    handleRemoveProject(confirmPortfolioProjectDeletion.index);
    setConfirmPortfolioProjectDeletion({
      open: false,
      index: -1,
    });
  }, [
    confirmPortfolioProjectDeletion.index,
    handleRemoveProject,
    setConfirmPortfolioProjectDeletion,
  ]);

  const handleAddNewProject = useCallback(
    (newProject: FeaturedProject) => {
      setAddedProjects([...addedProjects, newProject]);
      setIsAddingNew(false);
      setStatus(undefined);
      submitForm?.();
    },
    [addedProjects, setStatus, submitForm]
  );

  const handleUpdateProject = useCallback(
    (updatedProject: FeaturedProject) => {
      if (isEditingProjectIndex === null) {
        return;
      }
      const updatedAddedProjects = [...addedProjects];
      updatedAddedProjects[isEditingProjectIndex] = updatedProject;
      setAddedProjects(updatedAddedProjects);
      setIsEditingProjectIndex(null);
      setStatus(undefined);
      submitForm?.();
    },
    [addedProjects, isEditingProjectIndex, setStatus, submitForm]
  );

  const handleBack = () => {
    setIsAddingNew(false);
    setIsEditingProjectIndex(null);
    onBack?.();
  };

  const handleClose = () => {
    setConfirmPortfolioProjectDeletion({
      open: false,
      index: -1,
    });
  };

  return (
    <Box pt={10}>
      {onBack && <BackButton onClick={handleBack} backLabel="Back" />}
      <Box pt={5} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h1" className={boldStyles.root}>
            Add Projects
          </Typography>
        </Box>
        {!isAddingNew && isEditingProjectIndex === null && (
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              setIsAddingNew(true);
              setStatus("editing");
            }}
          >
            Add new project
          </Button>
        )}
      </Box>
      {isAddingNew || isEditingProjectIndex !== null ? (
        <AddNewProject
          onAdd={handleAddNewProject}
          onUpdate={handleUpdateProject}
          onCancel={() => {
            setIsAddingNew(false);
            setIsEditingProjectIndex(null);
            setStatus(undefined);
          }}
          projectValues={
            isEditingProjectIndex !== null
              ? addedProjects[isEditingProjectIndex]
              : undefined
          }
        />
      ) : (
        <Fragment>
          <Box py={4}>
            <Divider />
          </Box>
          <GridWrapper
            GridContainerProps={{
              gridColumnGap: "2%",
              gridRowGap: 48,
              gridTemplateColumns: "49% 49%",
              paddingBottom: 0,
              paddingTop: 12,
            }}
          >
            {addedProjects.map((addedProject, index) => {
              const skills = addedProject.skills.map((skill) => skill.name);

              return (
                <Box
                  width="100%"
                  key={index}
                  display="flex"
                  flexDirection="column"
                  gridRowGap={16}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    pb={2}
                    px={2}
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                      {addedProject.name}
                    </Typography>
                    <Box
                      display="flex"
                      gridColumnGap={12}
                      justifyContent="flex-end"
                    >
                      <Button
                        size="small"
                        className={redButtonStyles.delete}
                        onClick={() => {
                          setConfirmPortfolioProjectDeletion({
                            open: true,
                            index,
                          });
                        }}
                        // onClick={() => handleRemoveProject(index)}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setIsEditingProjectIndex(index);
                          setStatus("editing");
                        }}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Box>
                  {addedProject?.images.length > 0 && (
                    <ImagePreview
                      color="white"
                      width={600}
                      isLocalImages={false}
                      selectedFiles={addedProject.images}
                    />
                  )}
                  <SkillsOutput skills={skills} />
                  <Divider />
                  <Box>
                    <Typography variant="subtitle2" className={boldStyles.root}>
                      Description
                    </Typography>
                    <Typography variant="body1" noWrap>
                      {addedProject.description}
                    </Typography>
                  </Box>
                  {addedProject.url && (
                    <Fragment>
                      <Divider />
                      <Typography
                        color="primary"
                        component="a"
                        href={addedProject.url}
                        variant="body1"
                        noWrap
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        {addedProject.url}
                      </Typography>
                    </Fragment>
                  )}
                </Box>
              );
            })}
          </GridWrapper>
        </Fragment>
      )}
      <ConfirmationDialog
        open={Boolean(confirmPortfolioProjectDeletion.open)}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmLabel={"Delete Portfolio Project"}
        title={"Are you sure you want to delete this project?"}
        message={
          "This action is irreversible. You'll have to re-fill all the project details again to add it back."
        }
      />
    </Box>
  );
};
