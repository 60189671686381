import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { Section } from "../../../common/components";
import { InfoBox } from "../../../common/components/InfoBox";
import { CollectionNavigationActions } from "../../../common/elements/CollectionNavigationActions";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { usePastProjectsQuery } from "../../../common/hooks/projects/usePastProjectsQuery";
import { Details } from "./details";
import { PastProjectHeader } from "./header";
import { useDefaultPastProjectPage } from "./hooks";

export const PastProject: React.FC = () => {
  useDefaultPastProjectPage();
  const { projectId } = useParams();

  const { data: projects, isLoading: isLoadingProjects } =
    usePastProjectsQuery();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const contentLoading = isLoadingProject || isLoadingProjects;

  const collectionConfig = {
    type: Collection.PAST_PROJECTS,
    items: projects,
  };

  const { index, handleSelectNext, handleSelectPrevious } =
    useNavigationHandlers(collectionConfig, isLoadingProjects, projectId);

  const errorMessage = useMemo(() => {
    return !contentLoading && !project
      ? "All completed or canceled projects that haven’t been included in your portfolio projects will be shown here."
      : "";
  }, [contentLoading, project]);

  const closingDetails = useMemo(() => {
    return project?.closingDetails
      ? [
          "The client wrote the following regarding the cancellation:",
          project.closingDetails,
        ]
      : [];
  }, [project?.closingDetails]);

  return (
    <PageWrapper>
      <Section isLoading={contentLoading} errorMessage={errorMessage}>
        <Box display="flex" flexDirection="column" height="100%">
          <PastProjectHeader />
          {project?.closingRequester === "client" && (
            <Box py={5}>
              <InfoBox
                hideNumbers
                alertTitle="The client has canceled the project."
                messages={[
                  "The funds for the milestone you were working on are being transferred to your account.",
                  "There are no ratings or reviews associated with a canceled project. A notice of cancellation will be indicated in the “Timeframe” section of the project once published.",
                  ...closingDetails,
                ]}
              />
            </Box>
          )}
          <Box flexGrow={1}>
            <Details />
          </Box>
          <CollectionNavigationActions
            label={"Project"}
            index={index}
            totalCount={projects?.length ?? 0}
            handleSelectNext={handleSelectNext}
            handleSelectPrevious={handleSelectPrevious}
          />
        </Box>
      </Section>
    </PageWrapper>
  );
};
