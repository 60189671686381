import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../../themes/style-hooks";

type DescriptionProps = { description?: string; label?: string };

export const Description: React.FC<DescriptionProps> = ({
  description,
  label = "Description",
}) => {
  const boldStyles = useBoldStyles();

  return (
    <Box flexWrap="wrap" mt={3}>
      {label && (
        <Box pb={2}>
          <Typography variant="subtitle1" className={boldStyles.root}>
            {label}
          </Typography>
        </Box>
      )}
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
};
