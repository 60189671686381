import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";

export const InvitationHeaderSummary: React.FC = () => {
  const { data: metadata } = useMetadataQuery();
  const boldStyles = useBoldStyles();
  const { professionalReferralsCount = 0, accountInquiriesCount = 0 } =
    metadata ?? {};

  return (
    <Box display="flex" flexDirection="column" gridRowGap={2} width="35%">
      <Box display="flex" flexDirection="column" gridRowGap={6}>
        <Typography variant="subtitle1" className={boldStyles.root}>
          Job Request Inquiries
        </Typography>
        <Typography variant="subtitle2">
          You placed {metadata?.accountInquiriesCount ?? "-"} job request
          inquiry(ies)
        </Typography>
        {accountInquiriesCount > 0 && (
          <Typography variant="subtitle2">
            <Link href="/inquiries/">View Job Inquiries Page</Link>
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={2}>
        <Typography variant="subtitle1" className={boldStyles.root}>
          Job Referrals from Pros
        </Typography>
        <Typography variant="subtitle2">
          You are referred to {metadata?.professionalReferralsCount ?? "-"}{" "}
          projects
        </Typography>
        {professionalReferralsCount > 0 && (
          <Typography variant="subtitle2">
            <Link href="/professional-referrals/">View Referrals Page</Link>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
