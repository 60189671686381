import { Box, Fade, makeStyles, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { ProfileInformation } from "../../../api-types/profile-information";
import { YELLOW } from "../../../themes/components/utils";
import { useWaveStyles } from "../../../themes/style-hooks";

type ProfileUploadProps = {
  profileInformation?: ProfileInformation;
  isLoading?: boolean;
  readOnly?: boolean;
  width?: number;
  isHovering?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles({
  profilePic: {
    cursor: "pointer",
    borderRadius: "50%",
    objectFit: "cover",
  },
  viewProfile: {
    visibility: "hidden",
  },
});

export const ProfilePicture: React.FC<ProfileUploadProps> = ({
  profileInformation,
  isLoading,
  width = 200,
  isHovering = false,
  onClick,
}) => {
  const styles = useStyles();
  const waveStyles = useWaveStyles();

  return (
    <Box>
      {isLoading ? (
        <Box>
          <Skeleton
            classes={{
              wave: waveStyles.root,
            }}
            animation="wave"
            variant="circle"
            width={width}
            height={width}
          />
        </Box>
      ) : (
        <Box
          height={width}
          width={width}
          onClick={onClick}
          style={{
            borderRadius: "50%",
          }}
        >
          {profileInformation?.profileImage ? (
            <img
              className={styles.profilePic}
              style={{
                width: "inherit",
                height: "inherit",
              }}
              src={profileInformation?.profileImage}
              alt=""
            />
          ) : (
            <AccountCircleIcon
              style={{
                transform: "scale(1.2)",
                color: "white",
                width: "inherit",
                height: "inherit",
              }}
            />
          )}
          <Fade in={isHovering} timeout={500}>
            <Box
              bgcolor={YELLOW}
              height={width / 2}
              position="relative"
              top="-53.5%"
              className={!isHovering ? styles.viewProfile : ""}
              style={{
                borderBottomRightRadius: 100,
                borderBottomLeftRadius: 100,
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                color="secondary"
                style={{
                  fontWeight: "bold",
                }}
              >
                View Profile
              </Typography>
            </Box>
          </Fade>
        </Box>
      )}
    </Box>
  );
};
