import { createState } from "@hookstate/core";
import { AppState } from "./types";

export const state = createState<AppState>({
  confirmationModal: {
    open: false,
  },
  currentProject: {
    index: 0,
  },
});
