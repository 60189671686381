import axios from "axios";
import { Project } from "../../../api-types/projects";
import { projectUrl } from "../../utils";

export const updateProject = (
  projectId: string,
  updatedProject: Partial<Project>
) => {
  return axios.patch(projectUrl(projectId), updatedProject);
};
