import { useQuery } from "react-query";
import { fetchReceivedInvitations } from "../../../../../../api-services/job-requests/invitations/fetchReceivedInvitations";
import { Invitation } from "../../../../../../api-types/job-requests/invitations";
import { JOB_REQUESTS_QUERY_KEY } from "../../useJobRequests";

export const RECEIVED_INVITATIONS_QUERY_KEY = `${JOB_REQUESTS_QUERY_KEY}-received-invitations`;

export const useReceivedClientInvitations = () => {
  return useQuery<Invitation[], unknown, Invitation[]>(
    RECEIVED_INVITATIONS_QUERY_KEY,
    async () => {
      const { data } = await fetchReceivedInvitations();

      return data;
    }
  );
};
