import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ACCOUNT_BIDS } from "../../hooks/useAccountBids";
import { useBidDeleteMutation } from "./useBidDeleteMutation";

export const useOnConfirmWithdrawalHandler = (
  id?: string,
  onSuccess?: () => void
) => {
  const { bidId } = useParams();
  const { mutate: deleteBid } = useBidDeleteMutation();
  const queryClient = useQueryClient();

  return useCallback(() => {
    if (!id && !bidId) return;

    deleteBid(id ?? bidId, {
      onSuccess: () => {
        queryClient.invalidateQueries(ACCOUNT_BIDS);
        onSuccess?.();
      },
    });
  }, [bidId, deleteBid, id, onSuccess, queryClient]);
};
