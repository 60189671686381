import { Box, Typography } from "@material-ui/core";
import { Field, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { BackButton } from "../../elements/BackButton";
import { CustomCheckbox } from "../../elements/CustomCheckbox";

type SignUpProfessionalServicesProps = {
  onBack?: () => void;
  values: FormikValues;
  handleChange: (e: ChangeEvent) => void;
};

export const initialServicesValues = {
  services: {
    designing: false,
    marketing: false,
    development: false,
    writing: false,
  },
};

export const SignUpProfessionalServices: React.FC<
  SignUpProfessionalServicesProps
> = ({ onBack, values, handleChange }) => {
  const { designing, marketing, development, writing } = values.services;

  return (
    <Box
      height="100%"
      pt={10}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
    >
      <BackButton onClick={() => onBack?.()} />
      <Box
        display="flex"
        pt={10}
        width="80%"
        height="100%"
        justifyContent="space-between"
        gridColumnGap={12}
      >
        <Box maxWidth="35%">
          <Typography variant="subtitle1">
            Select the categories that best fit your skills.
          </Typography>
        </Box>
        <Box
          maxWidth="35%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            <Field
              component={CustomCheckbox}
              label="Design & Creative"
              name="services.designing"
              value={designing}
              onChange={handleChange}
            />
            <Field
              component={CustomCheckbox}
              label="Marketing & Strategy"
              name="services.marketing"
              value={marketing}
              onChange={handleChange}
            />
            <Field
              component={CustomCheckbox}
              label="Web, Mobile & Software Development"
              name="services.development"
              value={development}
              onChange={handleChange}
            />
            <Field
              component={CustomCheckbox}
              label="Writing & Content Creation"
              name="services.writing"
              value={writing}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
