import { useMemo } from "react";
import { useReceivedClientInvitations } from "../../../../../common/hooks/job-requests/job-request/invites/useReceivedClientInvitations";

export const useInvitationsOptions = () => {
  const { data: invitations, isLoading: isLoadingInvitations } =
    useReceivedClientInvitations();

  return useMemo(() => {
    if (isLoadingInvitations || !invitations) return [];

    return invitations.map((invitation, index) => ({
      title: invitation.jobRequestTitle,
      id: invitation._id,
      index,
    }));
  }, [invitations, isLoadingInvitations]);
};
