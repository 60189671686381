import { useState } from "@hookstate/core";
import { state } from "./state";

export const useSearchFilters = () => {
  return useState(state.searchFilters);
};

export const useJobPostSearchFilters = () => {
  return useState(state.jobPostSearchFilters);
};

export const useSelectedFilterTypes = () => {
  return useState(state.selectedFilterTypes);
};

export const useWithdrawApplication = () => {
  return useState(state.withdrawApplication);
};
