import {
  Checkbox,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormikContext } from "formik";
import { ChangeEvent, FC, useCallback, useLayoutEffect, useRef } from "react";
import { YELLOW } from "../../../../themes/components/utils";
import { useTransparentSelectStyles } from "../../../../themes/style-hooks";
import { Milestone } from "../../../client/job-requests/create-job-request-wizard/validation-schema";
import { TableCellWithInput } from "../../../client/job-requests/utils";
import { stringToTitleCase } from "../../../utils";

const useStyles = makeStyles({
  root: {
    height: "auto",
  },
  select: {
    "&.MuiSelect-select": {
      paddingRight: 34,
    },
  },
});

type FixedPriceEditableTableRowProps = {
  hasBorder?: boolean;
  milestone: Milestone;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  rowIndex: number;
  selected?: boolean;
  remove: any;
};

export const FixedPriceEditableTableRow: FC<
  FixedPriceEditableTableRowProps
> = ({ hasBorder, milestone, rowIndex, selected, onSelect, remove }) => {
  const transparentSelectStyles = useTransparentSelectStyles();
  const styles = useStyles();

  const rowRef = useRef<HTMLTableRowElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { setFieldValue } = useFormikContext();

  const { description, amount, dueDate, isFunded, status } = milestone;

  const onDueDateTypeChange = useCallback(
    (
      event: ChangeEvent<{
        name?: string;
        value: unknown;
      }>
    ) => {
      setFieldValue(`milestones.${rowIndex}.dueDate.type`, event.target.value);
    },
    [rowIndex, setFieldValue]
  );

  useLayoutEffect(() => {
    if (rowRef.current) {
      rowRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

    if (inputRef.current) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <TableRow
      ref={rowRef}
      style={{
        border: hasBorder ? `1px solid ${YELLOW}` : "none",
        height: 64,
      }}
    >
      <TableCell>
        <Checkbox
          disabled={isFunded}
          checked={selected || isFunded}
          onChange={onSelect}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        />
      </TableCell>
      <TableCellWithInput
        ref={inputRef}
        name={`milestones.${rowIndex}.description`}
        value={description}
        inputWidth={240}
      />
      <TableCellWithInput
        inputWidth={80}
        labelBefore="$"
        name={`milestones.${rowIndex}.amount`}
        type="number"
        value={amount}
      />
      <TableCellWithInput
        inputWidth={80}
        name={`milestones.${rowIndex}.dueDate.count`}
        type="number"
        value={dueDate?.count ?? 0}
      >
        <Select
          className={transparentSelectStyles.root}
          classes={{
            root: styles.root,
            select: styles.select,
          }}
          disableUnderline
          IconComponent={KeyboardArrowDownIcon}
          defaultValue={dueDate.type}
          name={`milestones.${rowIndex}.dueDate.type`}
          onChange={onDueDateTypeChange}
          style={{
            fontSize: 16,
            height: "auto",
            width: 240,
          }}
        >
          <MenuItem value="weeks">{`week(s) from start of ${
            rowIndex === 0 ? "project" : "milestone"
          }`}</MenuItem>
          <MenuItem value="days">{`day(s) from start of ${
            rowIndex === 0 ? "project" : "milestone"
          }`}</MenuItem>
          <MenuItem value="hours">{`hour(s) from start of ${
            rowIndex === 0 ? "project" : "milestone"
          }`}</MenuItem>
        </Select>
      </TableCellWithInput>
      <TableCell>
        <Typography component="span" variant="body2">
          {isFunded ? "Yes" : "No"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography component="span" variant="body2">
          {stringToTitleCase(status ?? "")}
        </Typography>
      </TableCell>
      <TableCell>
        <ListItemIcon
          style={{
            minWidth: "auto",
            marginRight: 8,
            cursor: "pointer",
          }}
          onClick={() => remove(rowIndex)}
        >
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
      </TableCell>
    </TableRow>
  );
};
