import { Typography } from "@material-ui/core";
import React from "react";
import { YELLOW } from "../../../themes/components/utils";

type StepProps = {
  label: string;
  isSelected?: boolean;
  color?: string;
};

export const Step: React.FC<StepProps> = ({
  label,
  isSelected,
  color = YELLOW,
}) => {
  return (
    <Typography
      variant="subtitle2"
      style={{
        opacity: isSelected ? 1 : 0.5,
        color: isSelected ? color : "gray",
        fontWeight: 600,
      }}
    >
      {label}
    </Typography>
  );
};
