import { useMutation, useQueryClient } from "react-query";
import { deleteJobRequest } from "../../../../../api-services/job-requests/job-request/deleteJobRequest";
import { JOB_REQUESTS_QUERY_KEY } from "../useJobRequests";

export const useJobRequestDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteJobRequest(id), {
    onSuccess() {},
    onSettled() {
      queryClient.invalidateQueries(JOB_REQUESTS_QUERY_KEY);
    },
  });
};
