import { BadgeClassKey, BadgeProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiBadge: MuiComponentThemeDefinition<BadgeClassKey, BadgeProps> =
  {
    props: {},
    override: () => ({
      colorPrimary: {
        height: 24,
        minWidth: 24,
        borderRadius: 20,
        fontSize: 16,
        fontWeight: 650,
        backgroundColor: "#f48fb1",
      },
    }),
  };
