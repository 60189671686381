import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useCommonMainButtonsStyles,
  useMediumItalic,
  useHeadlineText,
  useDescriptionText,
  useStepCStyles,
} from "../../../../../../themes/style-hooks";
import { AccountTypeSwitch } from "../../../client/parallax/AccountTypeSwitch";
import { MainActions } from "../../../MainActions";

export const LandingStepD: FC = () => {
  const styles = useStepCStyles();
  const { root, common } = useCommonMainButtonsStyles({
    background: COLOR.RED,
    color: COLOR.WHITE,
    hover: COLOR.RED,
  });
  const mediumItalic = useMediumItalic();
  const { skewedText, transparent } = useHeadlineText({
    primary: COLOR.GREEN,
    secondary: COLOR.DARK_BROWN,
    background: COLOR.RED,
  });
  const descriptionText = useDescriptionText({
    color: COLOR.DARK_BROWN,
  });
  // const { root, common } = useCommonMainButtonsStyles({
  //   background: COLOR.YELLOW,
  //   color: COLOR.BROWN,
  //   hover: COLOR.YELLOW,
  // });
  // const mediumItalic = useMediumItalic();
  // const { transparent, skewedText } = useHeadlineText({
  //   primary: COLOR.BROWN,
  //   secondary: COLOR.WHITE_BROWN,
  //   background: COLOR.YELLOW,
  // });
  // const descriptionText = useDescriptionText({
  //   color: COLOR.WHITE_BROWN,
  // });

  return (
    <React.Fragment>
      <Box textAlign="left" width="100%">
        <Box className={mediumItalic.root}>
          <Box className={`${skewedText} ${styles.headline} `}>
            <span>&nbsp;Keep all&nbsp;</span>
          </Box>
          <Box className={`${styles.headline} ${transparent}`}>
            <span>&nbsp;the money&nbsp;</span>
          </Box>
        </Box>
        <br />
        <Box className={mediumItalic.root}>
          <Box className={`${styles.headline} ${transparent}`}>
            <span>&nbsp;you earn.&nbsp;</span>
          </Box>
          <Box className={`${skewedText} ${styles.headline}`}>
            <span>&nbsp;No commission.&nbsp;</span>
          </Box>
        </Box>
      </Box>
      {/*<Box width="100%" display="flex" flexGrow={1} pl={8}>*/}
      {/*  <Box width="100%" height="70%" alignSelf="flex-end">*/}
      {/*    <Box className={descriptionText.root}>*/}
      {/*      Keep all the money you earn. No commission, no payment tier model.*/}
      {/*    </Box>*/}
      {/*    <Box className={descriptionText.root}>*/}
      {/*      No free work! Only do work that you get paid for.*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      <Box width="100%" pl={8}>
        <span>&nbsp;</span>
        <Box textAlign="left" color={COLOR.WHITE_PINK}>
          <Box className={descriptionText.root}>
            Keep all the money you earn. No commission, no payment tier model.
          </Box>
          <Box className={descriptionText.root}>
            No free work! Only do work that you get paid for.
          </Box>
        </Box>
      </Box>
      <MainActions classNames={`${common} ${root}`} />
      {/*<AccountTypeSwitch defaultChecked="professional" />*/}
    </React.Fragment>
  );
};
