import { useMutation } from "react-query";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { publishProject } from "../../../../../../api-services/projects/project/publishProject";
import { useToast } from "../../../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../job-requests/hooks/feedbackErrors";

export const usePublishPastProject = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { notifySuccess } = useToast();

  return useMutation(
    async () => {
      const { data } = await publishProject(projectId);

      return data;
    },
    {
      onSuccess: () => {
        notifySuccess(feedbackMessage("project", FEEDBACK_TYPE.SUBMIT));
        navigate("/projects/history");
      },
    }
  );
};
