import { useMutation } from "react-query";
import {
  ShareJobPostImageProps,
  shareJobPostImage,
} from "../../../../../api-services/job-posts/job-post/shareJobPostImage";

export const useShareJobPostImageMutation = () => {
  return useMutation(async (props: ShareJobPostImageProps) => {
    const { data } = await shareJobPostImage(props);
    return data;
  });
};
