import { Box } from "@material-ui/core";
import React from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useMediumItalic,
  useHeadlineText,
  useMobileStyles,
  useStepBStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepCHeadline = () => {
  const styles = useStepBStyles();
  const mediumItalic = useMediumItalic();
  const { mobileHeadlineWrapper } = useMobileStyles();
  const { skewedText, transparent } = useHeadlineText({
    primary: COLOR.BROWN,
    secondary: COLOR.WHITE_BROWN,
    background: COLOR.YELLOW,
  });

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;Unlimited free bids&nbsp;</span>
        </Box>
      </Box>
      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;before&nbsp;</span>
        </Box>
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;and after&nbsp;</span>
        </Box>
      </Box>
      <Box className={mediumItalic.root}>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;sign-up.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
