import { useQuery } from "react-query";
import { fetchProfessionalsInteraction } from "../../../../../../api-services/projects/collaborations/collaboration/fetchProfessionalsInteraction";
import { ProfessionalsInteractions } from "../../../../../../api-types/interactions";

export const PROFESSIONALS_INTERACTIONS_HISTORY_QUERY_KEY =
  "professionals-interactions";

export const useProfessionalsInteractions = () => {
  return useQuery<
    ProfessionalsInteractions,
    unknown,
    ProfessionalsInteractions
  >(PROFESSIONALS_INTERACTIONS_HISTORY_QUERY_KEY, async () => {
    const { data } = await fetchProfessionalsInteraction();

    return data;
  });
};
