import axios, { AxiosResponse } from "axios";
import { AddMessagesRequest } from "../../../api-types/job-requests/common";
import { InquiryData } from "../../../api-types/job-requests/inquiries";
import { addInquiryMessageUrl } from "../../utils";

export const addInquiryMessage = (
  inquiryId: string,
  request: AddMessagesRequest
): Promise<AxiosResponse<InquiryData>> => {
  return axios.post(addInquiryMessageUrl(inquiryId), request);
};
