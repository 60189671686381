import axios, { AxiosResponse } from "axios";
import {
  BidData,
  CreateBidRequest,
} from "../../../api-types/job-requests/bids";
import { bidUrl } from "../../utils";

export const updateBid = (
  bidData: CreateBidRequest
): Promise<AxiosResponse<BidData>> => {
  return axios.patch(bidUrl(bidData.id ?? ""), bidData);
};
