import jwt from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../../api-types/common";
import { DecodedJWT } from "../../../api-types/sign-up";
import { BROWSE_DEFAULT_URL } from "../../../routes/utils";
import { useLocalState } from "../hooks/utils";

const decodeToken = (token: string) => {
  if (!token) return { isTokenExpired: true, tokenType: null };

  const decodedToken: DecodedJWT = jwt(token);

  const currentTick = (new Date().getTime() + 1) / 1000;

  return {
    isTokenExpired: decodedToken.exp < currentTick,
    tokenType: decodedToken.type,
    restricted: decodedToken.restricted,
  };
};

export const useAutoLogin = () => {
  const [token, setToken] = useLocalState("access_token", "");
  const navigate = useNavigate();

  useEffect(() => {
    const { isTokenExpired, tokenType, restricted } = decodeToken(token);

    if (!token) return;

    if (isTokenExpired) {
      setToken("");
      return;
    }

    let path;

    if (tokenType === AccountType.PROFESSIONAL) {
      path = restricted ? "/account" : "/job-requests/search";
    }

    if (tokenType === AccountType.CLIENT) {
      path = BROWSE_DEFAULT_URL;
    }

    if (path) {
      navigate(path, { replace: true });
      return;
    }
  }, [navigate, setToken, token]);

  return token;
};
