import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchJobRequestInquiries } from "../../../../../../api-services/inquiries/fetchJobRequestInquiries";
import { InquiryData } from "../../../../../../api-types/job-requests/inquiries";
import { JOB_REQUESTS_QUERY_KEY } from "../../useJobRequests";

export const jobRequestsInquiresKey = (id: null | string) => {
  return `${JOB_REQUESTS_QUERY_KEY}-inquires-${id}`;
};

export const useJobRequestInquires = () => {
  const { jobRequestId } = useParams();

  return useQuery<InquiryData[], unknown, InquiryData[]>(
    jobRequestsInquiresKey(jobRequestId),
    async () => {
      const { data } = await fetchJobRequestInquiries(jobRequestId);

      return data;
    },
    {
      enabled: Boolean(jobRequestId),
    }
  );
};
