import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  BLUE,
  MAX_VIEWPORT_WIDTH,
  YELLOW,
} from "../../../../../themes/components/utils";
import { Step } from "../../../../common/elements/Step";
import { TheTalentedLogo } from "../../../../common/logo";

type StepType = {
  label: string;
  color?: string;
};

const stepNames: StepType[] = [
  { label: "Services" },
  { label: "Project Description" },
  { label: "Images" },
  { label: "Skills" },
  // { label: "Timeframe & Cost" },
  // { label: "Visibility" },
  { label: "Budget" },
  { label: "Milestones" },
];

export const JobRequestProgressBar: React.FC = () => {
  const [searchParams] = useSearchParams();
  const smallWidth = useMediaQuery("(max-width: 1440px)");

  const currentStepIndex = Number(searchParams.get("step"));

  return (
    <Box
      height={100}
      px={5}
      bgcolor={YELLOW}
      display="flex"
      alignItems="center"
      justifyContent={smallWidth ? "center" : "space-between"}
    >
      {!smallWidth && <TheTalentedLogo inverse />}
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="center"
        width={MAX_VIEWPORT_WIDTH - 420}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          {stepNames.map(({ label, color }, index) => (
            <Step
              key={label}
              label={label}
              isSelected={currentStepIndex === index + 1}
              color={color ?? BLUE}
            />
          ))}
        </Box>
        <Box width="100%" height="12px" bgcolor="white">
          <Box
            width={`${(currentStepIndex / stepNames.length) * 100}%`}
            height="inherit"
            bgcolor={BLUE}
          />
        </Box>
      </Box>
      {!smallWidth && <Box width={180} />}
    </Box>
  );
};
