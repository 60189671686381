import { useCallback } from "react";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { fetchJobPost } from "../../../../../api-services/job-posts";
import { JobPost } from "../../../../../api-types/job-posts";
import { JOB_POSTS_QUERY_KEY } from "../useJobPosts";

export const jobPostKey = (id: string | null) => {
  return `${JOB_POSTS_QUERY_KEY}-${id}`;
};

export const useJobPostQuery = (
  id?: string,
  options?: UseQueryOptions<JobPost>
) => {
  const { jobPostId } = useParams();

  return useQuery<JobPost, unknown, JobPost>(
    jobPostKey(id ?? jobPostId),
    async () => {
      const { data } = await fetchJobPost(id ?? jobPostId);

      return data;
    },
    {
      ...options,
      enabled: Boolean(id || jobPostId) && (options?.enabled ?? true),
    }
  );
};

export const useInvalidateJobRequestQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (id: string) => {
      queryClient.invalidateQueries(jobPostKey(id));
    },
    [queryClient]
  );
};
