import { useCallback } from "react";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { fetchJobApplication } from "../../../../api-services/job-applications";
import { JobApplication } from "../../../../api-types/job-applications";
import { JOB_APPLICATIONS_QUERY_KEY } from "./useJobApplicationsQuery";

export const jobApplicationKey = (id: string) =>
  `${JOB_APPLICATIONS_QUERY_KEY}-${id}`;

export const useJobApplicationQuery = (
  jobApplicationId: string,
  options?: UseQueryOptions
) => {
  const enabled = Boolean(jobApplicationId) && (options?.enabled ?? true);

  return useQuery<JobApplication[], unknown, JobApplication[]>(
    jobApplicationKey(jobApplicationId),
    async () => {
      const { data } = await fetchJobApplication(jobApplicationId);
      return data;
    },
    {
      enabled,
    }
  );
};

export const useInvalidateJobApplicationQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (id: string) => {
      queryClient.invalidateQueries(jobApplicationKey(id));
    },
    [queryClient]
  );
};
