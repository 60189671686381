import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useBoldStyles,
  usePointerStyles,
} from "../../../../../themes/style-hooks";
import { LoadingTypography } from "../../../../common/components";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";

export const PastProjectHeaderSummary: React.FC = () => {
  const boldStyles = useBoldStyles();
  const pointerStyles = usePointerStyles();
  const navigate = useNavigate();

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  return (
    <Box display="flex" flexDirection="column" gridRowGap={20} py={10}>
      <LoadingTypography
        isLoading={isLoadingMetadata}
        typographyProps={{
          variant: "subtitle1",
          className: boldStyles.root,
        }}
      >
        Status of Your {metadata?.projectsCount} current Project(s)
      </LoadingTypography>
      <Box
        onClick={() => {
          navigate("/projects/");
        }}
        className={pointerStyles.root}
      >
        <Typography variant="subtitle2" color="primary">
          View on-going projects
        </Typography>
      </Box>
    </Box>
  );
};
