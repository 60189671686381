/* eslint-disable no-unused-vars */
import { AnyObject } from "yup/es/types";

export enum AccountType {
  CLIENT = "client",
  PROFESSIONAL = "professional",
}

export type WithTimestamps = {
  createdAt: string;
  updatedAt: string;
};

export type BoolMap = {
  [k: string]: boolean;
};

export type ObjectMap = {
  [k: string]: AnyObject;
};

export type Metadata = {
  professionalReferralsCount?: number;
  accountBidsCount?: number;
  accountInquiriesCount?: number;
  jobRequestsCount?: number;
  jobPostsCount?: number;
  projectsCount?: number;
  clientInvitationsCount?: number;
  invitedCount?: number;
  notificationsCount?: number;
  placedInquiriesCount?: number;
  placedBidsCount?: number;
  userId: string;
  unpublishedPastProjectsCount?: number;
  finishedPastProjectsCount?: number;
  publishedPastProjectsCount?: number;
  onGoingProjectsTimestamps?: string[];
  hasBillingInformation?: boolean;
  balance?: {
    total: number;
    available: number;
    pending: number;
  };
  stripeOnBoarding?: boolean;
  restricted?: boolean;
  theTalentedPro?: boolean;
  pendingProjectOffersCount?: number;
  averageRating?: number;
  ratingsCount?: number;
  freeTrial?: {
    isExpired: boolean;
    freeTrialJobPosts: number;
    startedAt: string;
  };
  jobPackages?: {
    jobPostsCredits: number;
  };
  jobApplicationsCount: number;
  email?: string;
  snooze?: string;
};
