import { useMutation, useQueryClient } from "react-query";
import { unsaveJobRequest } from "../../../../api-services/saved-job-requests";
import { useToast } from "../../../utils/useToast";
import { FEEDBACK_TYPE, feedbackMessage } from "./feedbackErrors";
import { SAVE_JOB_REQUESTS_QUERY_KEY } from "./useSavedJobRequests";

export const useRemoveFromSavedJobRequestMutation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation((id: string) => unsaveJobRequest(id), {
    onSuccess() {
      notifySuccess(feedbackMessage("job request", FEEDBACK_TYPE.REMOVE));
      queryClient.invalidateQueries(SAVE_JOB_REQUESTS_QUERY_KEY);
    },
  });
};
