import {
  Box,
  FormControlLabel,
  Input,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { COLOR, RED } from "../../../../../themes/components/utils";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import {
  RadioGroupContainer,
  RadioWithInput,
} from "../../../../client/job-requests/utils";
import { CustomTooltip } from "../../../../common/components";
import { BackButton } from "../../../../common/elements/BackButton";
import { ProfileInformationInterface } from "../EditProfessionalAccount";

type PricingProps = {
  onNextStep: () => void;
  onSaveDraft: () => void;
  onBack: () => void;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<ProfileInformationInterface>;
};

const useStyles = makeStyles({
  titleTextArea: { width: 800, height: 80, fontSize: 24, padding: 12 },
  descriptionTextArea: {
    width: 800,
    height: 140,
    fontSize: 21,
    padding: 12,
  },
  withdrawButton: {
    fontWeight: "normal",
    fontSize: 24,
    width: 300,
    height: 60,
  },
  textField: {
    "& .MuiInputBase-root": {
      fontSize: 21,
    },
  },
  input: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: COLOR.WHITE,
    borderRadius: 2,
    color: COLOR.WHITE,
    fontSize: 16,
    width: 52,
    height: 28,

    "& .MuiInputBase-input": {
      paddingRight: 2,
    },

    "& input": {
      padding: "4px 6px",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
});

export const Pricing: React.FC<PricingProps> = ({
  onBack,
  handleBlur,
  handleChange,
  values,
  errors,
}) => {
  const styles = useStyles();
  const boldStyles = useBoldStyles();

  return (
    <Box pt={10}>
      <BackButton onClick={onBack} backLabel="Back" />

      <Box display="flex" gridColumnGap={180}>
        <Box width="40%" pt={8}>
          <Typography variant="h2" className={boldStyles.root}>
            Pricing Your Work
          </Typography>
          <Box pt={4}>
            <Typography variant="subtitle1">
              All bids are free for professionals, even before signing up. Bid
              on any project! As a pro, you don't need to build or purchase
              “credits” to become eligible to bid on projects.
              <br />
              <br />
              Keep all the money you earn. There is no commission.
              <br />
              <br />
              There is no payment tier model. You have access to all features
              from the moment you sign up.
              <br />
              There is no free work. Professionals only work on projects they
              win.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gridRowGap={48}>
          <Box>
            <Typography className={boldStyles.root} variant="subtitle2">
              Set Hourly Rate
            </Typography>
            <Box display="flex" alignItems="center" pt={2.5} gridGap={8}>
              <Typography variant="body1">$</Typography>
              <Field
                as={Input}
                disableUnderline
                className={`${styles.input} ${
                  !errors?.pricing?.hourlyRate ? "" : styles.inputError
                }`}
                value={values.pricing?.hourlyRate ?? ""}
                name="pricing.hourlyRate"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                style={{
                  paddingRight: 4,
                  fontSize: 24,
                  height: 43,
                  width: 96,
                }}
                endAdornment={
                  errors.pricing?.hourlyRate && (
                    <CustomTooltip
                      title={errors.pricing.hourlyRate}
                      type="error"
                    >
                      <ErrorRoundedIcon
                        color="error"
                        fontSize="small"
                        data-cy-error="pricing.hourlyRate"
                        style={{ cursor: "initial" }}
                      />
                    </CustomTooltip>
                  )
                }
              />
              <Typography variant="body1">/hr</Typography>
            </Box>
          </Box>
          <Box>
            <RadioGroupContainer
              subTitle="Estimate the hours per week you'd like to dedicate to projects."
              title={"Set Your Availability"}
            >
              <FormControlLabel
                control={
                  <RadioWithInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    colorRadio
                    name={`pricing.availabilityPerWeek`}
                    minValue={
                      values?.pricing?.availabilityPerWeek?.estimate?.min ?? ""
                    }
                    maxValue={
                      values?.pricing?.availabilityPerWeek?.estimate?.max ?? ""
                    }
                    checked={
                      values?.pricing?.availabilityPerWeek?.type === "estimate"
                    }
                    radioValue="estimate"
                    isMinMaxInput
                    leftLabel="Between"
                    betweenInputsLabel="and"
                    minError={
                      errors.pricing?.availabilityPerWeek?.estimate
                        ?.min as string
                    }
                    maxError={
                      errors.pricing?.availabilityPerWeek?.estimate
                        ?.max as string
                    }
                  />
                }
                label="hours per week"
              />
              <FormControlLabel
                control={
                  <RadioWithInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    leftLabel="Exactly"
                    colorRadio
                    name={`pricing.availabilityPerWeek`}
                    value={values?.pricing?.availabilityPerWeek?.fixed ?? ""}
                    checked={
                      values?.pricing?.availabilityPerWeek?.type === "fixed"
                    }
                    radioValue="fixed"
                    error={errors?.pricing?.availabilityPerWeek?.fixed}
                  />
                }
                label="hour(s) per week"
              />
            </RadioGroupContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
