import {
  TablePaginationClassKey,
  TablePaginationProps,
} from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiTablePaginationItem: MuiComponentThemeDefinition<
  TablePaginationClassKey,
  TablePaginationProps
> = {
  props: {},
  override: () => ({
    root: {
      color: "white",
    },
  }),
};
