import React from "react";
import { useSearchParams } from "react-router-dom";
import { Professionals } from "./professionals/Professionals";
import { ProjectsList } from "./projects/ProjectsList";

type BrowseContentProps = {
  page: number;
  setPage: (page: number) => void;
};

export const BrowseContent: React.FC<BrowseContentProps> = ({
  page,
  setPage,
}: BrowseContentProps) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get("type") === "professionals")
    return <Professionals page={page} setPage={setPage} />;

  return <ProjectsList page={page} setPage={setPage} />;
};
