import { useMutation, UseMutationOptions } from "react-query";
import { deleteAccount } from "../../../../api-services/account-information/deleteAccount";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { useLogout } from "../sign-in";

export const useDeleteAccountMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const logout = useLogout();
  const { notifySuccess } = useToast();

  return useMutation(() => deleteAccount(), {
    ...mutationOptions,
    onSuccess() {
      notifySuccess(feedbackMessage("", FEEDBACK_TYPE.ACCOUNT_DELETE));
      logout();
    },
  });
};
