import { Box, Divider, Typography } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { JobPost } from "../../../../api-types/job-posts";
import { JobRequest } from "../../../../api-types/job-requests/job-request";
import { YELLOW } from "../../../../themes/components/utils";
import { useJobApplicationsQuery } from "../../../common/hooks/job-applications";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { SkeletonCollection } from "../../../common/professionals-search/browse-mainbar/SkeletonCollection";
import { PaginationFooter } from "../../../common/professionals-search/PaginationFooter";
import { useAccountBids } from "../../bids/hooks/useAccountBids";
import { useAccountInquiries } from "../../inquiries/hooks/useAccountInquiries";
import { useSearchJobPosts } from "../../job-posts/hooks";
import { useSearchJobRequests } from "../hooks";
import { useSelectedFilterTypes } from "../state/hooks";
import { JobPostItem } from "./job-post-item";
import { JobRequestItem } from "./job-request-item";
import { JobRequestsSkeleton } from "./JobRequestsSkeleton";

const defaultRowsPerPage = 4;
const itemsPerRow = 1;

export const JobRequestsList: React.FC = () => {
  const { data: jobRequests, isLoading: isLoadingJobRequests } =
    useSearchJobRequests();

  const { data: jobPosts, isLoading: isLoadingJobPosts } = useSearchJobPosts();
  const { data: jobApplications } = useJobApplicationsQuery();

  const { data: accountInquiries, isLoading: isLoadingAccountInquiries } =
    useAccountInquiries();

  const { data: accountBids, isLoading: isLoadingAccountBids } =
    useAccountBids();

  const [searchParams] = useSearchParams();

  const itemsToFilter = useMemo(
    () => searchParams.get("queryArray")?.split(",") ?? [],
    [searchParams]
  );

  const [currentPage, setCurrentPage] = useState(1);

  const { isFreelanceProjectsSelected, isJobPostsSelected } =
    useScopedDowngradedStateValue(useSelectedFilterTypes());

  const filteredJobs = useMemo(() => {
    const jobs = isFreelanceProjectsSelected
      ? jobRequests?.filter(
          (jobRequest) =>
            accountInquiries?.every(
              (accountInquiry) =>
                accountInquiry.jobRequest?._id !== jobRequest?._id
            ) &&
            accountBids?.every(
              (accountBid) => accountBid.jobRequest?._id !== jobRequest?._id
            )
        )
      : [];

    const appliedJobPostIds =
      jobApplications?.map((jobApplication) => jobApplication?.jobPost?._id) ??
      [];

    const filteredJobPosts = isJobPostsSelected
      ? jobPosts?.filter((jobPost) => !appliedJobPostIds.includes(jobPost?._id))
      : [];

    const mergedJobs = [...(jobs ?? []), ...(filteredJobPosts ?? [])].sort(
      (a, b) => {
        if (a.createdAt && b.createdAt) {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        }
        return 0;
      }
    );

    if (itemsToFilter?.length === 0) return mergedJobs;

    return mergedJobs?.filter((jR) => itemsToFilter.includes(jR._id));
  }, [
    accountBids,
    accountInquiries,
    isFreelanceProjectsSelected,
    isJobPostsSelected,
    itemsToFilter,
    jobApplications,
    jobPosts,
    jobRequests,
  ]);

  const totalPages = useMemo(
    () =>
      filteredJobs
        ? Math.round(filteredJobs.length / (defaultRowsPerPage * itemsPerRow)) +
          1
        : 1,
    [filteredJobs]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return filteredJobs
      ? filteredJobs.slice(endIndex - itemsCount, endIndex)
      : [];
  }, [currentPage, filteredJobs]);

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const isLoading =
    isLoadingJobRequests || isLoadingAccountInquiries || isLoadingAccountBids;

  const [inquiriesExpandedState, setInquiriesExpandedState] = useState({});

  const [bidsExpandedState, setBidsExpandedState] = useState({});

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {!isLoading && currentPage === totalPages ? (
        <Box
          bgcolor={YELLOW}
          flexGrow={1}
          minHeight={300}
          maxHeight={300}
          mt={20}
          mx={-15}
          px={15}
          py={8}
        >
          <Typography
            color="secondary"
            variant="subtitle1"
            style={{ fontWeight: 700 }}
          >
            Job Requests Notification
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            style={{ fontWeight: 500 }}
          >
            The site is in an early access stage. For the time being we will be
            automatically notifying you of job requests that match your skills.
          </Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <SkeletonCollection
            isLoading={isLoading}
            skeleton={
              <Box>
                <JobRequestsSkeleton />
                <JobRequestsSkeleton />
                <JobRequestsSkeleton />
              </Box>
            }
          >
            {currentPageFilteredItems.map(
              (job: JobRequest | JobPost, index) => {
                if ((job as JobPost)?.jobTitle) {
                  return (
                    <Box key={job._id} py={10}>
                      <JobPostItem jobPost={job as JobPost} />
                      <Box py={2.5}>
                        <Divider />
                        <Divider />
                      </Box>
                    </Box>
                  );
                }

                return (
                  <Box key={job._id} py={10}>
                    <JobRequestItem
                      index={index}
                      inquiriesExpandedState={inquiriesExpandedState}
                      bidsExpandedState={bidsExpandedState}
                      onUpdateInquiriesState={(
                        index: number,
                        newValue: boolean
                      ) => {
                        if (newValue) {
                          const newObj: any = {};

                          Object.keys(inquiriesExpandedState).forEach((key) => {
                            newObj[key] = false;
                          });

                          newObj[index] = newValue;

                          setInquiriesExpandedState(newObj);
                          setBidsExpandedState({ ...newObj, [index]: false });
                        } else {
                          setInquiriesExpandedState({
                            ...inquiriesExpandedState,
                            [index]: false,
                          });
                        }
                      }}
                      onUpdateBidsState={(index: number, newValue: boolean) => {
                        if (newValue) {
                          const newObj: any = {};

                          Object.keys(bidsExpandedState).forEach((key) => {
                            newObj[key] = false;
                          });

                          newObj[index] = newValue;

                          setBidsExpandedState(newObj);
                          setInquiriesExpandedState({
                            ...newObj,
                            [index]: false,
                          });
                        } else {
                          setBidsExpandedState({
                            ...inquiriesExpandedState,
                            [index]: false,
                          });
                        }
                      }}
                      jobRequest={job as JobRequest}
                      onCreateBidOrInquiry={() => {
                        setCurrentPage(1);
                        const newObj: any = {};

                        Object.keys(bidsExpandedState).forEach((key) => {
                          newObj[key] = false;
                        });

                        setBidsExpandedState(newObj);
                        setInquiriesExpandedState(newObj);
                      }}
                    />
                    <Box py={2.5}>
                      <Divider />
                    </Box>
                  </Box>
                );
              }
            )}
          </SkeletonCollection>
        </Box>
      )}
      <PaginationFooter
        isLoading={isLoading}
        rowsPerPage={defaultRowsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handleChangePage}
        totalCount={filteredJobs?.length}
        objectNamePlural="jobs"
        objectNameSingular="job"
        boxProps={{ py: 10, justifySelf: "flex-end" }}
      />
    </Box>
  );
};
