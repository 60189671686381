import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { CustomCheckbox } from "../../../elements/CustomCheckbox";
import { useIsMobileView } from "../../hooks";

type AccountTypeSwitchProps = {
  defaultChecked?: string;
};

export const AccountTypeSwitch: FC<AccountTypeSwitchProps> = ({
  defaultChecked = "client",
}) => {
  const isClientSelected = defaultChecked === "client";
  const isMobileView = useIsMobileView();

  return (
    <Box
      display="flex"
      gridColumnGap={12}
      top={"calc(100vh / 2)"}
      right={isMobileView ? -120 : -140}
      zIndex={10}
      style={{
        position: "fixed",
        transform: "rotate(-90deg)",
      }}
    >
      <CustomCheckbox
        label="I Hire Creatives"
        width={isMobileView ? 140 : 170}
        height={isMobileView ? 34 : 44}
        typographyVariant={isMobileView ? "body2" : "body1"}
        isSelected={isClientSelected}
        onClick={() => {
          window.location.replace("/client");
          window.sessionStorage.setItem("accountType", "client");
        }}
      />
      <CustomCheckbox
        label="I'm A Creative"
        typographyVariant={isMobileView ? "body2" : "body1"}
        width={isMobileView ? 132 : 160}
        height={isMobileView ? 34 : 44}
        isSelected={!isClientSelected}
        onClick={() => {
          window.location.replace("/");
          window.sessionStorage.setItem("accountType", "professional");
        }}
      />
    </Box>
  );
};
