import { RadioClassKey, RadioProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiRadio: MuiComponentThemeDefinition<RadioClassKey, RadioProps> =
  {
    props: {},
    override: () => ({
      root: {
        transform: "scale(.8) !important",
        "&.Mui-checked svg:last-child": {
          transform: "scale(1.3) !important",
        },
      },
    }),
  };
