import { useMutation, useQueryClient } from "react-query";
import { createBid } from "../../../../../api-services/bids/bid";
import { CreateBidRequest } from "../../../../../api-types/job-requests/bids";
import { METADATA_QUERY_KEY } from "../../../../common/hooks/metadata/useMetadataQuery";
import { useToast } from "../../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../job-requests/hooks/feedbackErrors";
import { ACCOUNT_BIDS } from "../../hooks/useAccountBids";

export const useBidCreateMutation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation(
    async (bid: CreateBidRequest) => {
      const { data } = await createBid(bid);

      return data;
    },
    {
      onSuccess() {
        notifySuccess(feedbackMessage("bid", FEEDBACK_TYPE.SUBMIT));
      },
      onSettled() {
        queryClient.invalidateQueries(ACCOUNT_BIDS);
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
      },
    }
  );
};
