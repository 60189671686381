/* eslint-disable no-undef */
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Gleap from "gleap";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./modules/app";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

if (process.env.REACT_APP_GLEAP_API_KEY) {
  Gleap.initialize(process.env.REACT_APP_GLEAP_API_KEY);
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
