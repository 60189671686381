import { useQuery } from "react-query";
import { fetchAccountInformation } from "../../../../api-services/account-information";
import { AccountInformation } from "../../../../api-types/account-information";

export const ACCOUNT_INFORMATION_QUERY_KEY = "account-information";

export const useAccountInformation = () => {
  return useQuery<AccountInformation>(
    ACCOUNT_INFORMATION_QUERY_KEY,
    async () => {
      const { data } = await fetchAccountInformation();

      return data;
    }
  );
};
