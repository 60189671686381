import { TextFieldClassKey, TextFieldProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE } from "./utils";

export const MuiTextField: MuiComponentThemeDefinition<
  TextFieldClassKey,
  TextFieldProps
> = {
  props: {
    variant: "outlined",
    size: "small",
    autoComplete: "off",
  },
  override: () => ({
    root: {
      width: "100%",
      height: 68,
      borderRadius: 1,
      "& ::placeholder": {
        color: BLUE,
      },
    },
  }),
};
