import { Box, RadioGroup, Typography } from "@material-ui/core";
import { FC, ReactNode } from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";

type RadioGroupContainerProps = {
  subTitle?: string;
  title: ReactNode;
};

export const RadioGroupContainer: FC<RadioGroupContainerProps> = ({
  children,
  subTitle,
  title,
}) => {
  const boldStyles = useBoldStyles();

  return (
    <Box pb={10}>
      <Box pb={4}>
        <Typography variant="subtitle2" className={boldStyles.root}>
          {title}
        </Typography>
      </Box>
      {subTitle && (
        <Box pb={2}>
          <Typography variant="body2">{subTitle}</Typography>
        </Box>
      )}

      <RadioGroup style={{ rowGap: 12 }}>{children}</RadioGroup>
    </Box>
  );
};
