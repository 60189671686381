import { Box, Button, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Card } from "../../elements/Card";
import { useNavigateBack } from "../../hooks/navigation";

export const SignUpAccountType: React.FC = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  useEffect(() => {
    navigate(
      {
        pathname: "/sign-up/",
        search: `?${createSearchParams({
          step: "1",
        })}`,
      },
      { replace: true, state: { from: "the-talented" } }
    );
  }, [navigate]);

  const handleChooseTypeHandler = useCallback(
    (type: string) => {
      navigate(
        {
          pathname: "/sign-up/",
          search: `?${createSearchParams({
            type,
            step: "2",
          })}`,
        },
        {
          state: { from: "the-talented" },
        }
      );
    },
    [navigate]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexGrow={1}
      pt={10}
    >
      <Box width="30%">
        <Typography variant="subtitle1">
          Let’s start with the account type you want to set up.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-end"
        height="100%"
      >
        <Box display="flex" gridColumnGap={20}>
          <Card
            width={380}
            height={210}
            label="Client"
            onClick={() => handleChooseTypeHandler("client")}
          />
          <Card
            width={380}
            height={210}
            label="Professional"
            onClick={() => handleChooseTypeHandler("professional")}
          />
        </Box>
        <Box py={10}>
          <Button variant="outlined" size="medium" onClick={navigateBack}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
