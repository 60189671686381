import { useMutation } from "react-query";
import { addJobRequest } from "../../../../../api-services/job-requests/job-request";
import { JobRequest } from "../../../../../api-types/job-requests/job-request";
import { useToast } from "../../../../utils/useToast";
import {
  useNavigateToJobRequestDrafts,
  useNavigateToSearchProfessionals,
} from "../../navigation";

export const useJobRequestCreateMutation = () => {
  const navigateToSearchProfessionals = useNavigateToSearchProfessionals();
  const navigateToJobRequestDrafts = useNavigateToJobRequestDrafts();
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    async (jobRequest: JobRequest) => {
      const { data } = await addJobRequest(jobRequest);

      return data;
    },
    {
      onSuccess(jobRequest) {
        const jobRequestIsPublished = jobRequest.isDraft === false;
        notifySuccess(
          jobRequestIsPublished
            ? "Job request was successfully created"
            : "Job request was saved as a draft."
        );
        if (jobRequest?._id && jobRequestIsPublished) {
          navigateToSearchProfessionals(jobRequest._id);
        } else {
          navigateToJobRequestDrafts();
        }
      },
      onError() {
        notifyError(
          "There was an error creating the job request. Please try again"
        );
      },
    }
  );
};
