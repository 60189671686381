import { Box, Divider, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { InquiryData } from "../../../../../api-types/job-requests/inquiries";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { Loader } from "../../../../common/elements/Loader";
import { useJobRequestInquires } from "../../../../common/hooks/job-requests/job-request/inquires/useJobRequestInquires";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../../state/hooks";
import { useAddInquiryClientMessageMutations } from "../hooks/useAddInquiryClientMessageMutation";
import { Inquiry } from "./Inquiry";

export const Inquires: React.FC = () => {
  const boldStyles = useBoldStyles();
  const { id, accountType } = useScopedDowngradedStateValue(useUser());
  const { mutate: addInquiryMessage, isLoading: isAddingNewMessage } =
    useAddInquiryClientMessageMutations();

  const { data: inquires, isLoading } = useJobRequestInquires();

  const onNewMessageHandler = useCallback(
    (newMessage: string, inquiry: InquiryData) => {
      addInquiryMessage({
        id: inquiry._id,
        messages: [
          {
            content: newMessage,
            from: id ?? "",
            to: inquiry.createdBy._id,
          },
        ],
        accountType,
      });
    },
    [accountType, addInquiryMessage, id]
  );

  if (isLoading || !inquires) {
    return <Loader />;
  }

  if (inquires.length === 0) {
    return (
      <Box height="50%">
        <Box>
          <Typography variant="subtitle1" className={boldStyles.root}>
            Inquiries
          </Typography>
          <Box py={2.5}>
            <Divider />
          </Box>
        </Box>
        <Box textAlign="center">
          <Typography variant="body1">
            No one has any questions or comments about the job request.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Typography variant="subtitle1" className={boldStyles.root}>
          Inquiries
        </Typography>
        <Box py={2.5}>
          <Divider />
        </Box>
      </Box>
      {inquires.map((inquiry, index) => (
        <Box key={inquiry._id} pt={2.5}>
          <Inquiry
            key={index}
            inquiry={inquiry}
            onNewMessage={(newMessage) =>
              onNewMessageHandler(newMessage, inquiry)
            }
            isLoading={isAddingNewMessage}
          />
        </Box>
      ))}
    </Box>
  );
};
