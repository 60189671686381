import jszip from "jszip";
import { AWSFile } from "../common/types";

// eslint-disable-next-line no-undef
export const getZipFiles = async (files: AWSFile[]) => {
  const zip = new jszip();
  files.forEach(({ key, buffer }) => {
    zip.file(key, buffer);
  });

  return zip.generateAsync({ type: "blob" });
};
