import { useQuery } from "react-query";
import { fetchCollaboratorsHistory } from "../../../../../../api-services/projects/collaborations/collaboration/fetchCollaboratorsHistory";
import { Professional } from "../../../../../../api-types/professionals/professional";
import { Project } from "../../../../../../api-types/projects";

export const COLLABORATORS_HISTORY_QUERY_KEY = "collaborators-history-key";

export type Collaborator = {
  professional: Professional;
  openProjects: Project[];
  closedProjects: Project[];
};

export const useCollaboratorsHistory = (professionalId?: string) => {
  return useQuery<Collaborator[], unknown, Collaborator[]>(
    COLLABORATORS_HISTORY_QUERY_KEY,
    async () => {
      const { data } = await fetchCollaboratorsHistory(professionalId);

      return data;
    }
  );
};
