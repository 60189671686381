import {
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { FC, useMemo, useState } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { Radio } from "../../../../common/components";

type PackagesCardsProps = {
  selectLabel?: string;
  onSelect: (option: string) => void;
  inverse?: boolean;
  buttonStyles?: string;
  radioColor?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const PackagesCards: FC<PackagesCardsProps> = ({
  onSelect,
  selectLabel = "Select",
  inverse,
  buttonStyles,
  radioColor,
  isLoading,
  onClick,
}) => {
  const [selectedJobPackage, setSelectedJobPackage] = useState("3-jobs");
  const mobileView = useMediaQuery("(max-width: 800px)");
  const smallWidth = useMediaQuery("(max-width: 380px)");

  const boxWidth = useMemo(() => {
    if (smallWidth) {
      return 260;
    }

    if (mobileView) {
      return 340;
    }

    return "fit-content";
  }, [mobileView, smallWidth]);

  const innerWidth = useMemo(() => {
    if (smallWidth) {
      return 180;
    }

    if (mobileView) {
      return 240;
    }

    return 300;
  }, [mobileView, smallWidth]);

  return (
    <Box
      display="flex"
      gridColumnGap={12}
      height="100%"
      gridGap={mobileView ? 20 : 40}
      flexDirection={mobileView ? "column" : "row"}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        alignItems={"center"}
        flexDirection="column"
        justifyContent="space-around"
        width={boxWidth}
        px={mobileView ? 5 : 10}
        py={mobileView ? 5 : 9.5}
        height={mobileView ? "50%" : "100%"}
        style={{
          border: `1px solid ${inverse ? BLUE : YELLOW}`,
        }}
      >
        <Box
          height={mobileView ? "10%" : "20%"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            variant={mobileView ? "subtitle2" : "subtitle1"}
            style={{
              fontWeight: 600,
              color: radioColor,
            }}
          >
            Single Job Post
          </Typography>
        </Box>
        <Box
          height="60%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            variant={mobileView ? "subtitle2" : "subtitle1"}
            color={inverse ? "secondary" : "primary"}
            style={{
              fontWeight: 600,
            }}
          >
            $99
          </Typography>
        </Box>
        <Box
          height="20%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Button
            data-cy-btn="credits-package-single"
            disabled={isLoading}
            variant="contained"
            size={mobileView ? "small" : "medium"}
            className={buttonStyles}
            onClick={() => {
              onSelect("1-job");
              onClick?.();
            }}
          >
            {selectLabel}
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        flexDirection="column"
        justifyContent="space-around"
        width={boxWidth}
        px={mobileView ? 5 : 10}
        py={mobileView ? 5 : 9.5}
        height={mobileView ? "50%" : "100%"}
        style={{
          border: `1px solid ${inverse ? BLUE : YELLOW}`,
        }}
      >
        <Box
          height={mobileView ? "10%" : "20%"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            variant={mobileView ? "subtitle2" : "subtitle1"}
            style={{
              fontWeight: 600,
              color: radioColor,
            }}
          >
            Job Posts Packages
          </Typography>
        </Box>

        <Box
          height={mobileView ? "70%" : "60%"}
          width={"80%"}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <RadioGroup
            defaultValue={selectedJobPackage}
            onChange={(e) => {
              setSelectedJobPackage(e.target.value);
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Radio
                    radioColor={radioColor}
                    value={"3-jobs"}
                    data-cy="3-jobs"
                  />
                }
                label={
                  <Box
                    display="flex"
                    width={innerWidth}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={mobileView ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      3 Job Posts
                    </Typography>
                    <Typography
                      variant={mobileView ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                    >
                      $85/Job Post
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Radio
                    radioColor={radioColor}
                    value={"5-jobs"}
                    data-cy="5-jobs"
                  />
                }
                label={
                  <Box
                    display="flex"
                    width={innerWidth}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={mobileView ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      5 Job Posts
                    </Typography>
                    <Typography
                      variant={mobileView ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                    >
                      $75/Job Post
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Radio
                    radioColor={radioColor}
                    value="10-jobs"
                    data-cy="10-jobs"
                  />
                }
                label={
                  <Box
                    display="flex"
                    width={innerWidth}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant={mobileView ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      10 Job Posts
                    </Typography>
                    <Typography
                      variant={mobileView ? "body2" : "body1"}
                      color={inverse ? "secondary" : "primary"}
                    >
                      $65/Job Post
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </RadioGroup>
        </Box>
        <Box
          height="20%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Button
            disabled={isLoading}
            data-cy-btn="credits-package"
            variant="contained"
            className={buttonStyles}
            size={mobileView ? "small" : "medium"}
            onClick={() => {
              onSelect(selectedJobPackage);
              onClick?.();
            }}
          >
            {selectLabel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
