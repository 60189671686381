import { Box, Divider } from "@material-ui/core";
import React from "react";
import { PastProjectHeaderContent } from "./PastProjectHeaderContent";
import { PastProjectHeaderSummary } from "./PastProjectHeaderSummary";

export const PastProjectHeader: React.FC = () => {
  return (
    <Box display="flex" gridColumnGap={40}>
      <PastProjectHeaderContent />
      <Divider orientation="vertical" flexItem />
      <PastProjectHeaderSummary />
    </Box>
  );
};
