import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../common/components";
import { Card } from "../../../../common/elements/Card";
import { useInvitationQuery } from "../../../../common/hooks/job-requests/job-request/invites/useInvitationQuery";
import { useReceivedClientInvitations } from "../../../../common/hooks/job-requests/job-request/invites/useReceivedClientInvitations";
import {
  Collection,
  useNavigationHandlers,
} from "../../../../common/hooks/navigation";
import { useInvitationsOptions } from "./hooks/useInvitationsOptions";

export const InvitationHeaderContent: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { invitationId } = useParams();

  const { data: clientInvitations, isLoading: isLoadingClientInvitations } =
    useReceivedClientInvitations();

  const { data: invitation, isLoading: isLoadingInvitation } =
    useInvitationQuery();

  const collectionConfig = {
    type: Collection.INVITATIONS,
    items: clientInvitations,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingClientInvitations,
    invitationId
  );

  const handleSelectInquiry = useCallback(
    (index: number) => {
      if (!clientInvitations) return;

      navigate({
        pathname: `/invitations/${clientInvitations[index]._id}`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientInvitations]
  );

  const clientInvitationsOptions = useInvitationsOptions();

  const isLoading = isLoadingClientInvitations || isLoadingInvitation;

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box width="65%">
      <LoadingTypography
        isLoading={isLoading}
        typographyProps={{
          variant: "subtitle2",
        }}
      >
        Client Invitation ({1 + index} of {clientInvitations?.length})
      </LoadingTypography>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={invitationId}
          options={clientInvitationsOptions ?? []}
          onSelect={handleSelectInquiry}
          placeholder={invitation?.jobRequestTitle}
        />
      </Box>
      <Box display="flex" gridColumnGap={18}>
        <Card
          label="Details"
          isSelected={searchParams.get("tab") === "details"}
          onClick={() => onTabNavigationHandler("details")}
        />
        {Boolean(invitation?.status !== "ACCEPTED") && (
          <Card
            label="Bid and Inquiries"
            isSelected={searchParams.get("tab") === "replies"}
            onClick={() => onTabNavigationHandler("replies")}
          />
        )}
      </Box>
    </Box>
  );
};
