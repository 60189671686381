import { Box } from "@material-ui/core";
import React, { ReactNode } from "react";

type SkeletonCollectionProps = {
  isLoading?: boolean;
  skeleton?: ReactNode;
};
export const SkeletonCollection: React.FC<SkeletonCollectionProps> = ({
  isLoading = false,
  skeleton,
  children,
}) => {
  if (isLoading) {
    return <Box>{skeleton}</Box>;
  }

  return <Box>{children}</Box>;
};
