import { ButtonClassKey, ButtonProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE, YELLOW } from "./utils";

export const MuiButton: MuiComponentThemeDefinition<
  ButtonClassKey,
  ButtonProps
> = {
  props: {
    variant: "outlined",
    size: "medium",
    color: "primary",
  },
  override: () => ({
    root: {
      fontSize: 14,
      textTransform: "none",
      borderRadius: 1,
      fontWeight: "normal",
      width: "fit-content",
      whiteSpace: "nowrap",
    },
    textPrimary: {
      "&:hover": {
        backgroundColor: "initial",
      },
    },
    containedPrimary: {
      "&.Mui-disabled": {
        backgroundColor: YELLOW,
        opacity: 1,
        "& .MuiButton-label": {
          opacity: 0.3,
          color: BLUE,
        },
      },
    },
    outlinedPrimary: {
      borderColor: YELLOW,
      borderWidth: 1,
      "&.Mui-disabled": {
        borderWidth: 1,
        opacity: 0.5,
        borderColor: YELLOW,
        "& .MuiButton-label": {
          color: YELLOW,
        },
      },
      "&:hover": {
        borderWidth: 1,
      },
    },
    // sizeMedium: {
    //   paddingLeft: 12,
    //   paddingRight: 12,
    //   fontSize: 18,
    //   height: 36,
    // },
    sizeSmall: {
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 12,
      height: 36,
    },
    sizeLarge: {
      paddingLeft: 18,
      paddingRight: 18,
      fontSize: 16,
      height: 50,
    },
    text: {
      padding: "6px 12px",
      "&.Mui-disabled": {
        color: `${YELLOW} !important`,
      },
    },
  }),
};
