import { useMemo } from "react";
import { useReceivedProfessionalReferralsProposals } from "../../../../../common/hooks/projects/project/collaborations";

export const useProfessionalReferralsOptions = () => {
  const {
    data: professionalReferrals,
    isLoading: isLoadingProfessionalReferrals,
  } = useReceivedProfessionalReferralsProposals();

  return useMemo(() => {
    if (isLoadingProfessionalReferrals || !professionalReferrals) return [];

    return professionalReferrals.map((professionalReferral, index) => ({
      title: professionalReferral.project?.jobRequest?.title,
      id: professionalReferral._id,
      index,
    }));
  }, [professionalReferrals, isLoadingProfessionalReferrals]);
};
