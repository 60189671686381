import {
  Box,
  Button,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Field, Formik } from "formik";
import { object, string } from "yup";
import { ContactMessage } from "../../../api-types/contact";
import { useTransparentSelectStyles } from "../../../themes/style-hooks";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useUser } from "../../state/hooks";
import { useToast } from "../../utils/useToast";
import { ConfirmationDialog } from "../components";
import { CustomTextArea } from "../elements";
import { useAddMessage } from "../hooks/contact";
import { useScopedDowngradedStateValue } from "../hooks/utils/useScopedDowngradedStateValue";
import { useMessageSentDialog } from "./state/hooks";

const useStyles = makeStyles({
  root: {
    fontSize: 21,
    fontWeight: 700,
    height: "auto",
    padding: 0,
  },
  descriptionTextArea: {
    background: "transparent",
    border: "none",
    color: "#fff",
    fontSize: 16,
    fontFamily: "Jost",
    outline: "none",
    padding: "26px 15px",
    resize: "none",
    width: "100%",
  },
});

const initialValues: ContactMessage = {
  type: "comment",
  content: "",
  userId: "",
};

const validationSchema = object().shape({
  content: string().required("A message is required"),
});

export const Contact = () => {
  const transparentSelectStyles = useTransparentSelectStyles();
  const styles = useStyles();

  const { notifyError } = useToast();

  const user = useUser();

  const { mutate: addMessage, isLoading: isAddingMessage } = useAddMessage();

  const messageSentDialog = useScopedDowngradedStateValue(
    useMessageSentDialog()
  );

  const setMessageSentDialog = useMessageSentDialog().set;

  const handleClose = () => {
    setMessageSentDialog({ open: false });
  };

  return (
    <PageWrapper>
      <Box
        display="flex"
        flexDirection="column"
        gridRowGap={48}
        pt={10}
        height="100%"
      >
        <Typography variant="h1">Contact Us</Typography>

        <Box display="flex" flexDirection="column" gridRowGap={10}>
          <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
            We Want To Hear From You
          </Typography>
          <Typography variant="body1">
            Select whether you want to reach out to us about site functionality
            or about anything else at all. Thank you for getting in touch and
            for your feedback.
          </Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setFieldValue }) => {
            if (!user.id.value) return;

            addMessage(
              { ...values, userId: user.id.value },
              {
                onSuccess: () => {
                  setMessageSentDialog({ open: true });
                  setFieldValue("content", "");
                },
                onError: () => {
                  notifyError(
                    "There was an error sending your message. Please try again"
                  );
                },
              }
            );
          }}
          validationSchema={validationSchema}
        >
          {({ handleBlur, handleChange, handleSubmit, values, errors }) => {
            return (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                }}
              >
                <Box
                  height="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box>
                    <Select
                      autoWidth
                      className={transparentSelectStyles.root}
                      classes={{
                        root: styles.root,
                      }}
                      defaultValue={values.type}
                      disableUnderline
                      IconComponent={KeyboardArrowDownIcon}
                      name="type"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{
                        height: "auto",
                        width: 380,
                      }}
                      value={values.type}
                    >
                      <MenuItem value="comment">
                        Leaving a comment about this site
                      </MenuItem>
                      <MenuItem value="other">
                        Reaching out about something else
                      </MenuItem>
                    </Select>

                    <Box width="100%" pr={2} pt={10}>
                      <Field
                        component={CustomTextArea}
                        maxRows={4}
                        minRows={3}
                        name="content"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter Text Here"
                        value={values.content}
                        error={errors.content}
                      />
                    </Box>
                  </Box>
                  <Box py={10} alignSelf="flex-end">
                    <Button
                      size="medium"
                      disabled={isAddingMessage || !user.id.value}
                      type="submit"
                      variant="contained"
                    >
                      Send
                    </Button>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
      <ConfirmationDialog
        open={messageSentDialog.open}
        onClose={handleClose}
        cancelLabel="Close"
        title="Thank You For Reaching Out To Us"
        message="Your message has been sent."
      />
    </PageWrapper>
  );
};
