import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Professional } from "../../../api-types/professionals/professional";
import { ProfilePicture } from "../summary-profile";

type ProfessionalSummaryProps = {
  professional?: Professional;
  width?: number;
};

export const ProfessionalSummary: React.FC<ProfessionalSummaryProps> = ({
  professional,
  width = 96,
}) => {
  const navigate = useNavigate();
  if (!professional) return null;

  const { accountInformation, profileInformation } = professional;

  return (
    <Box display="flex" gridColumnGap={24}>
      <Box display="flex" alignItems="center">
        <ProfilePicture
          width={width}
          onClick={() => {
            navigate(`/professionals/${professional._id}`);
          }}
          profileInformation={profileInformation}
        />
        <Box px={4}>
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: 700,
            }}
          >
            {accountInformation?.firstName} {accountInformation?.lastName}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {profileInformation?.position}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
