import { FormHelperTextClassKey, FormHelperTextProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiFormHelperText: MuiComponentThemeDefinition<
  FormHelperTextClassKey,
  FormHelperTextProps
> = {
  props: {},
  override: () => ({
    root: {
      display: "none",
    },
  }),
};
