import { useEffect, useMemo } from "react";
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import { useProjectsQuery } from "../../../common/hooks/projects/useProjectsQuery";
import { useCurrentProjectIndex } from "../state/hooks";

export const useDefaultProjectPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();
  const setCurrentProjectIndex = useCurrentProjectIndex().set;

  const firstProject = useMemo(() => {
    if (!projects || isLoadingProjects) return "";

    return projects[0]?._id;
  }, [projects, isLoadingProjects]);

  useEffect(() => {
    if (isLoadingProjects) return;

    if (projectId) return;

    if (!firstProject) return;

    setCurrentProjectIndex(0);

    navigate(
      {
        pathname: firstProject,
        search: `?${createSearchParams({ tab: "details" })}`,
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, firstProject, isLoadingProjects, projects]);
};
