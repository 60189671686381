import { useMutation, useQueryClient } from "react-query";
import { saveJobPost } from "../../../../api-services/saved-job-posts";
import { useToast } from "../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../job-requests/hooks/feedbackErrors";
import { SAVE_JOB_POSTS_QUERY_KEY } from "./useSavedJobPosts";

export const useSaveJobPostMutation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation((id: string) => saveJobPost(id), {
    onSuccess() {
      queryClient.invalidateQueries(SAVE_JOB_POSTS_QUERY_KEY);
      notifySuccess(
        "The job post has been successfully added to your saved list."
      );
    },
  });
};
