import { Box, Typography } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useBoldStyles,
  usePointerStyles,
} from "../../../../../themes/style-hooks";
import { useProjectQuery } from "../../../../common/hooks/projects/project/useProjectQuery";

type HighlightsProps = { variant?: "project" | "collaboration" };

const numberOfCollaboratorsToDisplay = 3;

export const ProjectHeaderSummary: React.FC<HighlightsProps> = ({
  variant = "project",
}) => {
  const [namesExtended, setNamesExtended] = useState(false);
  const boldStyles = useBoldStyles();
  const pointerStyles = usePointerStyles();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const professionalNames = project?.professionals.map((professional) => {
    const { firstName, lastName } =
      professional.professional.accountInformation ?? {};
    return `${firstName} ${lastName}`;
  });
  const hasCollaborators = (professionalNames?.length ?? 0) > 1;

  const onBoxClick = useCallback(() => {
    if (variant === "collaboration") {
      navigate("/projects/");
      return;
    }

    if (hasCollaborators) {
      navigate("collaborators");
    } else {
      navigate(`/professional-referral/?projectId=${projectId}`);
    }
  }, [hasCollaborators, navigate, projectId, variant]);

  const headingTitle = useMemo(() => {
    if (isLoadingProject) {
      return "";
    }
    if (variant === "collaboration") {
      return "View current project(s)";
    }

    if (hasCollaborators) {
      return "Go to collaborators page";
    }

    return "Refer a professional";
  }, [isLoadingProject, variant, hasCollaborators]);

  const professionalNamesCompact = useMemo(() => {
    const length = professionalNames?.length ?? 0;
    if (length <= numberOfCollaboratorsToDisplay) {
      return professionalNames?.join(", ");
    }

    return (
      professionalNames?.slice(0, numberOfCollaboratorsToDisplay).join(", ") +
      ` + ${length - numberOfCollaboratorsToDisplay} more`
    );
  }, [professionalNames]);

  const professionalNamesExtended = useMemo(() => {
    return professionalNames?.join(", ");
  }, [professionalNames]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={4}
      width="100%"
      flex={1}
      py={10}
    >
      <Box>
        <Typography variant="subtitle1" className={boldStyles.root}>
          Collaborators on This Project
        </Typography>
      </Box>
      {/*<Box>*/}
      {/*  <LoadingTypography*/}
      {/*    isLoading={isLoadingProject}*/}
      {/*    typographyProps={{ variant: "subtitle1" }}*/}
      {/*  />*/}
      {/*</Box>*/}
      {hasCollaborators && (
        <Box
          className={
            (professionalNames?.length ?? 0) > numberOfCollaboratorsToDisplay
              ? pointerStyles.root
              : ""
          }
          onClick={() => setNamesExtended(!namesExtended)}
        >
          <Typography
            variant="subtitle2"
            component="span"
            style={{ fontWeight: "normal" }}
          >
            {namesExtended
              ? professionalNamesExtended
              : professionalNamesCompact}
          </Typography>
        </Box>
      )}
      <Box onClick={onBoxClick} className={pointerStyles.root}>
        <Typography variant="subtitle2" color="primary" component="span">
          {headingTitle}
        </Typography>
      </Box>
    </Box>
  );
};
