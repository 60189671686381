import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { CurrentProjectHeaderContent } from "./CurrentProjectHeaderContent";
import { CurrentProjectHeaderSummary } from "./CurrentProjectHeaderSummary";

export const CurrentProjectHeader: React.FC = () => {
  const boldStyles = useBoldStyles();
  const [searchParams] = useSearchParams();

  return (
    <Box>
      <Box display="flex" gridColumnGap={40} py={10}>
        <CurrentProjectHeaderContent />
        <Divider orientation="vertical" flexItem />
        <CurrentProjectHeaderSummary />
      </Box>
      {searchParams.get("tab") === "replies" && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" className={boldStyles.root}>
            You Are Viewing Communication With
          </Typography>
          <Typography variant="body1">
            Select from the Professionals you are working with in the Files and
            Messages dropdown above
          </Typography>
        </Box>
      )}
    </Box>
  );
};
