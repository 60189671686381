import { useQuery } from "react-query";
import { fetchSentCollaborations } from "../../../../../../api-services/projects/collaborations";
import { ProfessionalReferrals } from "../../../../../../api-types/projects/collaborations";
import { PROJECTS_QUERY_KEY } from "../../useProjectsQuery";

const COLLABORATIONS_QUERY_KEY = `${PROJECTS_QUERY_KEY}-collaborations-sent`;

export const useSentCollaborationProposals = () => {
  return useQuery<ProfessionalReferrals[], unknown, ProfessionalReferrals[]>(
    COLLABORATIONS_QUERY_KEY,
    async () => {
      const { data } = await fetchSentCollaborations();

      return data;
    }
  );
};
