import { useState } from "@hookstate/core";
import { state } from "./state";

export const useConfirmationModal = () => {
  return useState(state.confirmationModal);
};

export const useCurrentProject = () => {
  return useState(state.currentProject);
};
