import { StyleRules, Theme } from "@material-ui/core";
// eslint-disable-next-line import/no-unresolved
import { Overrides } from "@material-ui/core/styles/overrides";
// eslint-disable-next-line import/no-unresolved
import { ComponentsProps } from "@material-ui/core/styles/props";

export type MuiComponentThemeDefinition<TClassKey extends string, TProps> = {
  props: Partial<TProps>;
  // eslint-disable-next-line no-unused-vars
  override: (theme: Theme) => Partial<StyleRules<TClassKey>>;
};

export const makeThemeComponentProps = (
  definitions: Record<string, MuiComponentThemeDefinition<string, object>>
) =>
  Object.entries(definitions).reduce<ComponentsProps>(
    (defaults, [name, { props }]) => ({
      ...defaults,
      [name]: props,
    }),
    {}
  );

export const makeThemeComponentOverrides = (
  definitions: Record<string, MuiComponentThemeDefinition<string, object>>,
  theme: Theme
) =>
  Object.entries(definitions).reduce<Overrides>(
    (defaults, [name, { override }]) => ({
      ...defaults,
      [name]: override(theme),
    }),
    {}
  );
