import { Box, Divider, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { Section, ShowImages, Timeframe } from "../../../common/components";
import { ProfessionalSummary } from "../../../common/components/ProfessionalSummary";
import { SkillsOutput } from "../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../../professional/job-requests/search-job-requests-list/job-request-item/content";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const skills = useMemo(() => {
    if (project?.skills && !isLoadingProject) {
      return project?.skills.map((skill) => skill.name);
    }

    return [];
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.images?.length);

  const projectDuration = useMemo(() => {
    const budgetType = project?.jobRequest.budget.type;

    const dueDate =
      budgetType === "fixed"
        ? project?.jobRequest.fixedProjectFunding.dueDate
        : project?.jobRequest.budget.timeFrame;

    if (!budgetType || !dueDate) return;

    return dueDate;
  }, [
    project?.jobRequest.budget.timeFrame,
    project?.jobRequest.budget.type,
    project?.jobRequest.fixedProjectFunding.dueDate,
  ]);

  return (
    <Box height="100%">
      <Section isLoading={isLoadingProject}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="subtitle1" className={boldStyles.root}>
            Description
          </Typography>
        </Box>
        <Box py={2.5}>
          <Divider />
        </Box>
        <Description description={project?.description} />
        {Boolean(skills.length) && (
          <Box pt={2.5}>
            <SkillsOutput skills={skills} />
          </Box>
        )}
        {hasImages && (
          <Box pt={2.5}>
            <ShowImages selectedFiles={project?.images} />
          </Box>
        )}
        <Box pt={10}>
          <Typography variant="subtitle2" className={boldStyles.root}>
            Professional(s) Working On This Project
          </Typography>
        </Box>
        <Box pt={2.5} pb={5}>
          <Divider />
        </Box>
        {project?.professionals.map((professional) => (
          <Box key={professional.professional._id}>
            <ProfessionalSummary professional={professional.professional} />
            <Box pt={2.5}>
              <Timeframe
                startDate={new Date(project.createdAt)}
                weeksDuration={projectDuration}
                label="Timeframe"
              />
            </Box>
            <Box py={2.5}>
              <Divider />
            </Box>
            <AvailabilityCostBids
              budget={project?.jobRequest?.budget}
              fixedProjectFunding={project?.jobRequest?.fixedProjectFunding}
              jobRequestId={project?.jobRequest?._id}
            />
          </Box>
        ))}
      </Section>
    </Box>
  );
};
