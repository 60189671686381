import { format, subDays } from "date-fns";
import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { searchJobPosts } from "../../../../api-services/job-posts";
import { JobPost } from "../../../../api-types/job-posts";
import { JobPostsSearchFilters } from "../../../../api-types/job-requests/common";
import { formatShortDate } from "../../../common/components/LoadingTypography";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useJobPostSearchFilters } from "../../job-requests/state/hooks";

export const SEARCH_JOB_POSTS_QUERY_KEY = "search-job-posts";

const searchKey = (filters: any) => {
  return `${SEARCH_JOB_POSTS_QUERY_KEY}-${JSON.stringify(filters)}`;
};

export const defaultJobPostSearchFilters: JobPostsSearchFilters = {
  search: "",
  date: "All",
  budget: {
    min: 0,
    max: 0,
  },
};

const formatDate = (dateOption: string) => {
  if (dateOption === "Today") {
    return new Date();
  }

  if (dateOption === "Yesterday") {
    return subDays(new Date(), 1);
  }

  if (dateOption === "Last Week") {
    return subDays(new Date(), 7);
  }

  if (dateOption === "Last Month") {
    return subDays(new Date(), 30);
  }

  if (dateOption === "Past 3 months") {
    return subDays(new Date(), 90);
  }

  if (dateOption === "Past 6 months") {
    return subDays(new Date(), 180);
  }

  return undefined;
};

export const useSearchJobPosts = (
  useDefaultFilters?: boolean,
  options?: UseQueryOptions<JobPost[]>
) => {
  const queryClient = useQueryClient();

  const filters = useScopedDowngradedStateValue(useJobPostSearchFilters());

  const formattedDate = formatDate(filters.date);

  const parsedFilters = useMemo(
    () => ({
      ...filters,
      date: formattedDate ? format(formattedDate, formatShortDate) : undefined,
    }),
    [filters, formattedDate]
  );

  const queryResult = useQuery<JobPost[], unknown, JobPost[]>(
    searchKey(useDefaultFilters ? defaultJobPostSearchFilters : parsedFilters),
    async () => {
      const { data } = await searchJobPosts(
        useDefaultFilters ? defaultJobPostSearchFilters : parsedFilters
      );

      return data;
    },
    {
      ...options,
    }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(
      searchKey(useDefaultFilters ? defaultJobPostSearchFilters : parsedFilters)
    );
  }, [parsedFilters, queryClient, useDefaultFilters]);

  return { ...queryResult, invalidate };
};
