import { useMemo } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { AccountType } from "../api-types/common";
import { useScopedDowngradedStateValue } from "../modules/common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../modules/state/hooks";
import { clientRoutes } from "./client";
import { commonRoutes } from "./common";
import { professionalRoutes } from "./professional";

export const RouterIndex = () => {
  const user = useScopedDowngradedStateValue(useUser());

  const userRoutes = useMemo(() => {
    if (user.accountType === AccountType.PROFESSIONAL)
      return professionalRoutes.map((pR, idx) => ({
        ...pR,
        key: `professional-${idx}`,
      }));
    return clientRoutes.map((cR, idx) => ({ ...cR, key: `client-${idx}` }));
  }, [user.accountType]);

  const routes = useMemo(() => {
    return [
      ...userRoutes,
      ...commonRoutes.map((cR, idx) => ({ ...cR, key: `common-${idx}` })),
    ];
  }, [userRoutes]);

  return (
    <Router>
      <Routes>{routes}</Routes>
    </Router>
  );
};
