const currentPlatform = window.sessionStorage.getItem("platform") ?? "dev";

const PROD_URL = "https://api.thetalented.co";

const DEV_URL = "https://dev-api.thetalented.co";

export const LOCAL_URL = "http://localhost:3001";

const isRemote = currentPlatform === "dev";

export const BASE_URL =
  // eslint-disable-next-line no-nested-ternary,no-undef
  process.env.REACT_APP_ENV === "prod"
    ? PROD_URL
    : isRemote
    ? DEV_URL
    : LOCAL_URL;

// account
export const ACCOUNT_INFORMATION_URL = `${BASE_URL}/account-information`;
export const CANCEL_SUBSCRIPTION_URL = `${BASE_URL}/stripe/cancel_subscription`;
export const DELETE_ACCOUNT_URL = `${BASE_URL}/delete-account`;
export const START_FREE_TRIAL = `${BASE_URL}/start-free-trial`;
export const END_FREE_TRIAL = `${BASE_URL}/end-free-trial`;
export const SNOOZE_NOTIFICATION = `${BASE_URL}/snooze`;
export const PROFILE_INFORMATION_URL = `${BASE_URL}/profile-information`;
export const STRIPE_ACCOUNT_URL = `${BASE_URL}/stripe/account`;
export const BILLING_INFORMATION_URL = `${BASE_URL}/billing-information`;

// bids
export const BIDS_URL = `${BASE_URL}/bids`;

export const acceptBidUrl = (bidId: string) => `${BIDS_URL}/accept/${bidId}`;
export const acceptOfferUrl = (bidId: string) =>
  `${BIDS_URL}/accept-offer/${bidId}`;

export const addBidMessageUrl = (bidId: string) =>
  `${BIDS_URL}/${bidId}/messages`;

export const bidUrl = (bidId: string) => `${BIDS_URL}/${bidId}`;

export const USERS_BIDS_URL = `${BASE_URL}/bids/users`;

export const userBidsUrl = (userId: string) => `${USERS_BIDS_URL}/${userId}`;

// inquiries

export const INQUIRIES_URL = `${BASE_URL}/inquiries`;
export const USER_INQUIRIES_URL = `${INQUIRIES_URL}/users`;

export const addInquiryMessageUrl = (inquiryId: string) =>
  `${INQUIRIES_URL}/${inquiryId}/messages`;

export const inquiryUrl = (inquiryId: string) =>
  `${INQUIRIES_URL}/${inquiryId}`;

export const userInquiriesUrl = (userId: string) =>
  `${USER_INQUIRIES_URL}/${userId}`;

// companies
export const COMPANIES_URL = `${BASE_URL}/companies`;

// company collaboration
export const COMPANY_COLLABORATION = `${BASE_URL}/company-collaboration`;
export const COMPANY_COLLABORATION_RECEIVED = `${COMPANY_COLLABORATION}/received`;
export const COMPANY_COLLABORATION_SENT = `${COMPANY_COLLABORATION}/sent`;
export const removeCompanyCollaborationUrl = (id: string) =>
  `${COMPANY_COLLABORATION}/remove/${id}`;
export const cancelCompanyCollaborationUrl = (id: string) =>
  `${COMPANY_COLLABORATION}/cancel/${id}`;
export const acceptCompanyCollaborationUrl = (id: string) =>
  `${COMPANY_COLLABORATION}/accept/${id}`;
export const rejectCompanyCollaborationUrl = (id: string) =>
  `${COMPANY_COLLABORATION}/reject/${id}`;

// job posts
export const JOB_POSTS_URL = `${BASE_URL}/job-posts`;
export const JOB_POSTS_DRAFT_URL = `${BASE_URL}/job-posts?isDraft=true`;
export const SEARCH_JOB_POSTS_URL = `${JOB_POSTS_URL}/search`;

export const jobPostUrl = (jobPostId: string) =>
  `${JOB_POSTS_URL}/${jobPostId}`;
export const shareJobPostUrl = (jobPostId: string) =>
  `${JOB_POSTS_URL}/share-image/${jobPostId}`;

// job applications
export const JOB_APPLICATIONS_URL = `${BASE_URL}/job-applications`;

export const jobApplicationUrl = (jobApplicationId: string) =>
  `${JOB_APPLICATIONS_URL}/${jobApplicationId}`;

// job requests
export const JOB_REQUESTS_URL = `${BASE_URL}/job-requests`;
export const JOB_REQUESTS_DRAFT_URL = `${BASE_URL}/job-requests?isDraft=true`;
export const SEARCH_JOB_REQUESTS_URL = `${JOB_REQUESTS_URL}/search`;

const JOB_REQUEST_BIDS_URL = `${BIDS_URL}/job-requests`;
const JOB_REQUEST_INQUIRIES_URL = `${INQUIRIES_URL}/job-requests`;

export const jobRequestsFundMilestonesUrl = (jobRequestId: string) =>
  `${JOB_REQUESTS_URL}/${jobRequestId}/fund-milestones`;

export const jobRequestBidsUrl = (jobRequestId: string) =>
  `${JOB_REQUEST_BIDS_URL}/${jobRequestId}`;

export const jobRequestInquiriesUrl = (jobRequestId: string) =>
  `${JOB_REQUEST_INQUIRIES_URL}/${jobRequestId}`;

export const jobRequestUrl = (jobRequestId: string) =>
  `${JOB_REQUESTS_URL}/${jobRequestId}`;

// collaborations

export const INVITATIONS_URL = `${BASE_URL}/invitations`;
export const SENT_INVITATIONS_URL = `${INVITATIONS_URL}/sent`;
export const RECEIVED_INVITATIONS_URL = `${INVITATIONS_URL}/received`;
export const invitationUrl = (invitationId: string) =>
  `${INVITATIONS_URL}/${invitationId}`;

export const inviteProUrl = (jobRequestId: string) =>
  `${INVITATIONS_URL}/${jobRequestId}`;

// collaborations

export const COLLABORATIONS_URL = `${BASE_URL}/collaborate`;
export const SENT_COLLABORATIONS_URL = `${COLLABORATIONS_URL}/sent`;
export const RECEIVED_COLLABORATIONS_URL = `${COLLABORATIONS_URL}/received`;
export const COLLABORATION_HISTORY_URL = `${COLLABORATIONS_URL}/history`;

export const collaboratorsHistoryUrl = (professionalId?: string) => {
  return professionalId
    ? `${COLLABORATION_HISTORY_URL}/professionals/${professionalId}`
    : `${COLLABORATION_HISTORY_URL}`;
};

export const collaborationUrl = (collaborationId: string) =>
  `${COLLABORATIONS_URL}/${collaborationId}`;

// professionals interaction

export const PROFESSIONALS_INTERACTION_URL = `${BASE_URL}/interactions`;
export const PROFESSIONALS_INTERACTION_ADD_MESSAGE_URL = `${PROFESSIONALS_INTERACTION_URL}/messages`;
export const PROFESSIONALS_INTERACTION_ADD_FILES_URL = `${PROFESSIONALS_INTERACTION_URL}/files`;

// notifications

export const NOTIFICATIONS_URL = `${BASE_URL}/notifications`;

export const readNotificationUrl = (notificationId: string) =>
  `${NOTIFICATIONS_URL}/read/${notificationId}`;

// clients
export const CLIENTS_URL = `${BASE_URL}/clients`;
export const SEARCH_CLIENTS_URL = `${CLIENTS_URL}/search`;

// professionals

export const PROFESSIONALS_URL = `${BASE_URL}/professionals`;

export const SEARCH_PROFESSIONALS_URL = `${PROFESSIONALS_URL}/search`;

export const professionalUrl = (professionalId: string) =>
  `${PROFESSIONALS_URL}/${professionalId}`;

export const PROJECTS_URL = `${BASE_URL}/projects`;

export const projectsUrl = (professionalId?: string) => {
  return professionalId
    ? `${PROJECTS_URL}/professionals/${professionalId}`
    : `${PROJECTS_URL}`;
};

export const SEARCH_PROJECTS_URL = `${PROJECTS_URL}/projects-search`;

export const projectUrl = (projectId: string) => {
  return `${PROJECTS_URL}/${projectId}`;
};

export const unpublishedProjectsUrl = () => {
  return `${PROJECTS_URL}/unpublished`;
};

export const pastProjectsUrl = (professionalId?: string) => {
  return professionalId
    ? `${PROJECTS_URL}/history/${professionalId}`
    : `${PROJECTS_URL}/history/`;
};

export const addProjectMessageUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/messages`;

export const addProjectFileUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/files`;

export const closeProjectRequestUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/close`;

export const completeProjectRequestUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/complete`;

export const confirmClosingRequestUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/close/confirm`;

export const publishProjectUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/publish`;

export const toggleFeaturedProjectUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/toggleFeatured`;

export const milestonesUrl = (projectId: string) =>
  `${PROJECTS_URL}/${projectId}/milestones`;

export const milestoneAcceptChangesUrl = (
  projectId: string,
  milestoneId: string
) => `${milestonesUrl(projectId)}/${milestoneId}/accept-changes`;

export const milestoneAcceptWorkUrl = (
  projectId: string,
  milestoneId: string
) => `${milestonesUrl(projectId)}/${milestoneId}/accept-work`;

export const milestoneDenyChangesUrl = (
  projectId: string,
  milestoneId: string
) => `${milestonesUrl(projectId)}/${milestoneId}/deny-changes`;

export const milestoneRequestChangesUrl = (
  projectId: string,
  milestoneId: string
) => `${milestonesUrl(projectId)}/${milestoneId}/request-changes`;

export const milestoneSubmitWorkUrl = (
  projectId: string,
  milestoneId: string
) => `${milestonesUrl(projectId)}/${milestoneId}/submit-work`;

// saved job requests

export const SAVED_JOB_REQUESTS_URL = `${BASE_URL}/saved-job-requests`;

export const SAVE_JOB_REQUEST_URL = `${SAVED_JOB_REQUESTS_URL}/save`;

export const UNSAVE_JOB_REQUEST_URL = `${SAVED_JOB_REQUESTS_URL}/remove`;

// saved job posts

export const SAVED_JOB_POSTS_URL = `${BASE_URL}/saved-job-posts`;

export const SAVE_JOB_POST_URL = `${SAVED_JOB_POSTS_URL}/save`;

export const UNSAVE_JOB_POST_URL = `${SAVED_JOB_POSTS_URL}/remove`;

// sing in

export const AUTH_URL = `${BASE_URL}/auth`;

export const ACTIVATE_URL = `${AUTH_URL}/activate`;

export const SIGN_IN_URL = `${AUTH_URL}/sign_in`;

export const SIGN_UP_URL = `${AUTH_URL}/sign_up`;

// subscribe

export const SUBSCRIBE_URL = `${BASE_URL}/subscribe`;

export const PASSWORD_RESET_URL = `${BASE_URL}/users/password_reset`;
export const PASSWORD_RESET_CONFIRM_URL = `${BASE_URL}/users/password_confirm`;

export const JOB_PACKAGES_URL = `${BASE_URL}/job-packages`;

// skills

export const SKILLS_URL = `${BASE_URL}/skills`;

export const skillUrl = (skillId: string) => `${SKILLS_URL}/${skillId}`;

export const METADATA_URL = `${BASE_URL}/users/metadata`;

export const MILESTONE_INVOICES_URL = `${BASE_URL}/milestone-invoices`;

export const WITHDRAWAL_HISTORY_URL = `${BASE_URL}/withdrawal-history`;

export const milestoneInvoicesUrl = (projectId?: string) => {
  return `${MILESTONE_INVOICES_URL}/${projectId}`;
};

export const paymentRequestUrl = (paymentRequest?: string) => {
  return `${MILESTONE_INVOICES_URL}/${paymentRequest}`;
};

export const IMAGE_MAX_SIZE_MB = parseInt(
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_IMAGE_MAX_SIZE_MB ?? "2"
);

export const CONTACT_URL = `${BASE_URL}/contact-us`;
