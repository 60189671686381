import { Box, Divider } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { Project } from "../../../../../../api-types/projects";
import { Section } from "../../../../../common/components";
import { usePastProjectsQuery } from "../../../../../common/hooks/projects/usePastProjectsQuery";
import { PortfolioSkeleton } from "../../../sections/portfolio/PortfolioSkeleton";
import { PortfolioRow } from "./PortfolioRow";

export const Portfolio: React.FC = () => {
  const { professionalId } = useParams();

  const { data: projects, isLoading: isLoadingProjects } =
    usePastProjectsQuery(professionalId);

  return (
    <Section
      isLoading={isLoadingProjects}
      loadingSkeleton={
        <Box display="flex" flexDirection="column" gridRowGap={24} pb={10}>
          <PortfolioSkeleton />
          <PortfolioSkeleton />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" gridRowGap={24}>
        {projects?.map((project: Project) => (
          <Box key={project._id} width="100%">
            <PortfolioRow project={project} />
            <Box py={4}>
              <Divider />
            </Box>
          </Box>
        ))}
      </Box>
    </Section>
  );
};
