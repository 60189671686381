import React from "react";
import { AccountType } from "../../../api-types/common";
import { ClientNavbar } from "../../client/navbar";
import { ProfessionalNavbar } from "../../professional/navbar";

type UserNavbarProps = {
  accountType: AccountType;
};

export const UserNavbar: React.FC<UserNavbarProps> = ({ accountType }) => {
  if (accountType === AccountType.CLIENT) {
    return <ClientNavbar />;
  }

  return <ProfessionalNavbar />;
};
