import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { YELLOW } from "../../../../themes/components/utils";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const ProjectsSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <Box pt={2.5} gridColumnGap={12} display="flex">
      <Skeleton
        classes={{
          wave: styles.root,
        }}
        variant="rect"
        animation="wave"
        width={"50%"}
        height={400}
      />
      <Skeleton
        classes={{
          wave: styles.root,
        }}
        variant="rect"
        animation="wave"
        width={"50%"}
        height={400}
      />
    </Box>
  );
};
