import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../../themes/style-hooks";
import { SavedJobRequestHighlights } from "./SavedJobRequestsHighlights";
import { SearchForm } from "./SearchForm";

const useStyles = makeStyles({
  container: {
    width: "70%",
    ["@media screen and (max-width: 1300px)"]: {
      width: "75%",
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: "80%",
    },
  },
});

export const SearchMainbar: React.FC = () => {
  const boldStyles = useBoldStyles();
  const styles = useStyles();

  return (
    <Box
      display="flex"
      gridColumnGap={24}
      px={15}
      style={{
        pointerEvents: "none",
      }}
      width="100%"
    >
      <Box height="340px" className={styles.container}>
        <Box py={10}>
          <Typography variant="h1" className={boldStyles.root}>
            Job Requests
          </Typography>
        </Box>
        <SearchForm />
      </Box>
      <Divider orientation="vertical" flexItem />
      <SavedJobRequestHighlights />
    </Box>
  );
};
