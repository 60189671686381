import { Box, Button } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRedButtonStyles } from "../../../themes/style-hooks";
import { ConfirmationDialog } from "../../common/components";
import { useJobPostUpdateMutationCore } from "../../common/hooks/job-posts";
import { JOB_POSTS_QUERY_KEY } from "../../common/hooks/job-posts/useJobPosts";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../utils/useToast";

type JobPostActionsProps = {
  onClose?: () => void;
  jobPostId: string;
};

export const JobPostActions = ({ jobPostId, onClose }: JobPostActionsProps) => {
  const redButtonStyles = useRedButtonStyles();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { notifySuccess } = useToast();

  const onEditJobPost = () => {
    navigate(`/job-posts/${jobPostId}`);
  };

  const { mutate: updateJobPost, isLoading: isUpdatingJobPost } =
    useJobPostUpdateMutationCore({
      onSuccess: () => {
        queryClient.invalidateQueries(JOB_POSTS_QUERY_KEY);
        notifySuccess(feedbackMessage("job post", FEEDBACK_TYPE.UPDATE));
      },
    });

  const [openCloseJobDialog, setOpenCloseJobDialog] = useState(false);

  const onCloseJobPost = useCallback(() => {
    updateJobPost({ _id: jobPostId, isDraft: true });
    setOpenCloseJobDialog(false);
  }, [updateJobPost, jobPostId]);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      gridColumnGap={8}
      justifyContent="flex-end"
    >
      <Button
        className={redButtonStyles.delete}
        disabled={isUpdatingJobPost}
        onClick={() => setOpenCloseJobDialog(true)}
        size="medium"
        variant="outlined"
        data-cy-btn="close-job-post"
      >
        Close
      </Button>
      <Button
        onClick={onEditJobPost}
        size="medium"
        variant="contained"
        disabled={isUpdatingJobPost}
        data-cy-btn="edit-job-post"
      >
        Edit
      </Button>

      <ConfirmationDialog
        confirmLabel="Close Job Post"
        open={openCloseJobDialog}
        onClose={() => setOpenCloseJobDialog(false)}
        onConfirm={onCloseJobPost}
        title="Are you sure you want to close this job post?"
      />
    </Box>
  );
};
