import { PaginationItemClassKey, PaginationItemProps } from "@material-ui/lab";
import { MuiComponentThemeDefinition } from "../utils";
import { BLUE } from "./utils";

export const MuiPaginationItem: MuiComponentThemeDefinition<
  PaginationItemClassKey,
  PaginationItemProps
> = {
  props: {},
  override: () => ({
    icon: {
      color: BLUE,
    },
  }),
};
