import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateToJobRequestDrafts = () => {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate("/job-requests/drafts", {
      replace: true,
    });
  }, [navigate]);
};
