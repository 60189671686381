import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useValidateMutation } from "../../hooks/sign-up";
import { useIsMobileView } from "../hooks";
import { ClientLargeViewParallax, ClientSmallViewParallax } from "./parallax";
import "../styles.css";

export const ClientLanding: React.FC = () => {
  const mobileView = useIsMobileView();
  const { mutate: validateTokenMutation } = useValidateMutation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      validateTokenMutation(token);
      return;
    }
  }, [searchParams, validateTokenMutation]);

  return useMemo(
    () =>
      mobileView ? <ClientSmallViewParallax /> : <ClientLargeViewParallax />,
    [mobileView]
  );
};
