import { Box, Button, Divider, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BidData } from "../../../../api-types/job-requests/bids";
import { BLUE, YELLOW } from "../../../../themes/components/utils";
import { useRedButtonStyles } from "../../../../themes/style-hooks";
import { CustomTooltip, PlacedBidDetails } from "../../../common/components";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";

type ProposedBidActionsProps = {
  bidData?: BidData;
  isNewBid?: boolean;
  onPlaceBid: () => void;
  onWithdraw: () => void;
  onAcceptOffer?: () => void;
  isLoading?: boolean;
};

export const ProposedBidActions: React.FC<ProposedBidActionsProps> = ({
  bidData,
  isNewBid,
  onPlaceBid,
  onWithdraw,
  onAcceptOffer,
  isLoading,
}) => {
  const navigate = useNavigate();
  const redButtonStyles = useRedButtonStyles();

  const isOfferInPending =
    bidData?.offer === "pending" && bidData?.status === "accepted";

  const hasProjectStarted =
    bidData?.status === "accepted" && bidData?.offer === "accepted";

  const { data: metadata, isLoading: isLoadingMetadata } = useMetadataQuery();

  const isAcceptOfferDisabled = !metadata?.theTalentedPro || isLoadingMetadata;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: 600,
          }}
        >
          Proposed Bid
        </Typography>
        {hasProjectStarted ? (
          <Box>
            <Typography
              style={{
                fontWeight: "bold",
                color: YELLOW,
              }}
            >
              Project has started
            </Typography>
          </Box>
        ) : (
          <Box display="flex" gridColumnGap={10}>
            {!isNewBid && (
              <Button
                size="small"
                onClick={onWithdraw}
                className={redButtonStyles.delete}
              >
                Withdraw
              </Button>
            )}
            {isOfferInPending && onAcceptOffer ? (
              <CustomTooltip
                interactive
                title={
                  isAcceptOfferDisabled ? (
                    <Box>
                      <Typography
                        style={{
                          color: BLUE,
                        }}
                      >
                        The client accepted your bid on the project. Subscribe
                        to TheTalented.co to begin working on this and future
                        projects. Click{" "}
                        <span
                          style={{
                            color: BLUE,
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/account-information?tab=subscription");
                          }}
                        >
                          here
                        </span>{" "}
                        for more details.
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )
                }
                color="primary"
              >
                <div>
                  <Button
                    disabled={isAcceptOfferDisabled || isLoading}
                    variant="contained"
                    onClick={onAcceptOffer}
                    size="small"
                  >
                    Accept Client Offer & Start Project
                  </Button>
                </div>
              </CustomTooltip>
            ) : (
              <Button variant="contained" onClick={onPlaceBid} size="small">
                {isNewBid ? "Place new bid" : "Change Bid Terms"}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Box display="flex" alignItems="center" gridColumnGap={60}>
        {bidData && <PlacedBidDetails bid={bidData} />}
        {/*<Box display="flex" gridColumnGap={12}>*/}
        {/*  <Button variant="contained" onClick={onPlaceBid} size="small">*/}
        {/*    {isNewBid ? "Place new bid" : "Change Bid Terms"}*/}
        {/*  </Button>*/}
        {/*  {!isNewBid && (*/}
        {/*    <Button size="small" onClick={onWithdraw}>*/}
        {/*      Withdraw Bid*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</Box>*/}
      </Box>
    </Box>
  );
};
