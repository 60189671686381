import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ProfileIcon } from "../../../icons/ProfileIcon";

type ProfessionalsProps = {
  list: any[];
};

const useStyles = makeStyles({
  smallIcon: {
    width: 64,
    height: 64,
  },
});

export const Professionals: React.FC<ProfessionalsProps> = ({ list }) => {
  const styles = useStyles();

  return (
    <Box display="flex" gridColumnGap={24} py={4}>
      {list.map(({ name, position }) => (
        <Box display="flex" key={`${name}-${position}`}>
          <IconButton className={styles.smallIcon}>
            <ProfileIcon />
          </IconButton>
          <Box>
            <Typography variant="h2" color="secondary">
              {name}
            </Typography>
            <Typography variant="h2" color="secondary">
              {position}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
