import { useMutation, useQueryClient } from "react-query";
import { addInteractionFile } from "../../../../api-services/projects/collaborations/collaboration/addInteractionFile";
import { InteractionFileRequest } from "../../../../api-types/job-requests/common";

export const useAddInteractionFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ files, professionalId }: InteractionFileRequest) =>
      addInteractionFile(professionalId, files),
    {
      onSuccess() {},
      onSettled() {
        queryClient.invalidateQueries("interactions");
      },
    }
  );
};
