import { Box, Icon, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React from "react";

type ErrorWrapperProps = {
  errorMessage?: string;
};

export const ErrorWrapper: React.FC<ErrorWrapperProps> = ({ errorMessage }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gridColumnGap={8}
    >
      <ErrorOutlineIcon color="primary" />
      <Typography variant="body1" color="primary">
        {errorMessage}
      </Typography>
    </Box>
  );
};
