import { Box, Collapse, Divider } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Message } from "../../../../../../api-types/job-requests/common";
import { InquiryData } from "../../../../../../api-types/job-requests/inquiries";
import { JobRequest } from "../../../../../../api-types/job-requests/job-request";
import { useScopedDowngradedStateValue } from "../../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useUser } from "../../../../../state/hooks";
import { MessagesForm } from "../../../../bids/communication/MessagesForm";
import { useAccountInquiryQuery } from "../../../../inquiries/hooks/useAccountInquiry";
import { useAddInquiryMessageMutations } from "../../../../inquiries/hooks/useAddInquiryMessageMutation";
import { useCreateInquiryMutation } from "../../../../inquiries/hooks/useCreateInquiryMutation";
import { useSearchJobRequests } from "../../../hooks";

type ShowInquiryProps = {
  isExpanded: boolean;
  jobRequest: JobRequest;
  onPlaceInquiry?: () => void;
  scrollIntoView?: boolean;
  topY: number;
};

export const ShowInquiry: React.FC<ShowInquiryProps> = ({
  isExpanded,
  jobRequest,
  onPlaceInquiry,
  scrollIntoView,
  topY,
}) => {
  const [inquiryId, setInquiryId] = useState<string>("");
  const [prevJobRequestId, setPrevJobRequestId] = useState(jobRequest._id);
  const { id, accountType } = useScopedDowngradedStateValue(useUser());
  const { invalidate: invalidateJobRequests } = useSearchJobRequests();

  useEffect(() => {
    if (prevJobRequestId !== jobRequest?._id) {
      setPrevJobRequestId(jobRequest?._id);
      setInquiryId("");
    }
  }, [jobRequest?._id, prevJobRequestId]);

  const { mutate: createInquiry, isLoading: isCreating } =
    useCreateInquiryMutation();

  const { mutate: addInquiryMessage, isLoading: isAddingNewInquiryMessage } =
    useAddInquiryMessageMutations({}, inquiryId);

  const { data: inquiryData, isLoading: isLoadingInquiry } =
    useAccountInquiryQuery(inquiryId);

  const onNewMessageHandler = useCallback(
    (newMessage: string) => {
      if (!id) return;

      const messages: Message[] = [
        {
          content: newMessage,
          from: id ?? "",
          to: jobRequest?.createdBy?._id ?? "",
        },
      ];

      if (!inquiryId) {
        createInquiry(
          { jobRequestId: jobRequest?._id, messages },
          {
            onSuccess: (inquiry: InquiryData) => {
              invalidateJobRequests();
              setInquiryId(inquiry._id);
              onPlaceInquiry?.();
            },
          }
        );
        return;
      }

      addInquiryMessage({
        messages,
        accountType,
        jobRequestId: inquiryData?.jobRequest._id,
      });
    },
    [
      id,
      jobRequest?.createdBy?._id,
      jobRequest?._id,
      inquiryId,
      addInquiryMessage,
      accountType,
      inquiryData?.jobRequest._id,
      createInquiry,
      invalidateJobRequests,
      onPlaceInquiry,
    ]
  );

  const messages = useMemo(() => {
    if (isLoadingInquiry || !inquiryData) return [];

    return inquiryData.messages;
  }, [inquiryData, isLoadingInquiry]);

  const isLoading = isLoadingInquiry || isCreating || isAddingNewInquiryMessage;

  useEffect(() => {
    if (!isExpanded || !topY) return;

    window.scroll({ top: topY, behavior: "smooth" });
  }, [isExpanded, topY]);

  return (
    <Collapse in={isExpanded}>
      <Box width="100%" pb={4}>
        <Divider />
        <MessagesForm
          messages={messages}
          height={150}
          onNewMessage={onNewMessageHandler}
          isLoading={isLoading}
          scrollIntoView={scrollIntoView}
        />
      </Box>
    </Collapse>
  );
};
