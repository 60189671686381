import { format, subDays } from "date-fns";
import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { searchJobRequests } from "../../../../api-services/job-requests";
import { JobRequestsSearchFilters } from "../../../../api-types/job-requests/common";
import { JobRequest } from "../../../../api-types/job-requests/job-request";
import { formatShortDate } from "../../../common/components/LoadingTypography";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useSearchFilters } from "../state/hooks";

export const SEARCH_JOB_REQUESTS_QUERY_KEY = "search-job-requests";

const searchKey = (filters: any) => {
  return `${SEARCH_JOB_REQUESTS_QUERY_KEY}-${JSON.stringify(filters)}`;
};

export const defaultJobRequestSearchFilters: JobRequestsSearchFilters = {
  search: "",
  date: "All",
  budget: {
    min: 0,
    max: 0,
  },
  bids: {
    min: 0,
    max: 0,
  },
};

const formatDate = (dateOption: string) => {
  if (dateOption === "Today") {
    return new Date();
  }

  if (dateOption === "Yesterday") {
    return subDays(new Date(), 1);
  }

  if (dateOption === "Last Week") {
    return subDays(new Date(), 7);
  }

  if (dateOption === "Last Month") {
    return subDays(new Date(), 30);
  }

  if (dateOption === "Past 3 Months") {
    return subDays(new Date(), 90);
  }

  if (dateOption === "Past 6 Months") {
    return subDays(new Date(), 180);
  }

  return undefined;
};

export const useSearchJobRequests = (
  useDefaultFilters?: boolean,
  options?: UseQueryOptions<JobRequest[]>
) => {
  const queryClient = useQueryClient();

  const filters = useScopedDowngradedStateValue(useSearchFilters());

  const formattedDate = formatDate(filters.date);

  const parsedFilters = useMemo(
    () => ({
      ...filters,
      date: formattedDate ? format(formattedDate, formatShortDate) : undefined,
    }),
    [filters, formattedDate]
  );

  const queryResult = useQuery<JobRequest[], unknown, JobRequest[]>(
    searchKey(
      useDefaultFilters ? defaultJobRequestSearchFilters : parsedFilters
    ),
    async () => {
      const { data } = await searchJobRequests(
        useDefaultFilters ? defaultJobRequestSearchFilters : parsedFilters
      );

      return data;
    },
    {
      ...options,
    }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(
      searchKey(
        useDefaultFilters ? defaultJobRequestSearchFilters : parsedFilters
      )
    );
  }, [parsedFilters, queryClient, useDefaultFilters]);

  return { ...queryResult, invalidate };
};
