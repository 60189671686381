import { Box, Typography } from "@material-ui/core";
import { Field, FormikErrors, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { JOB_REQUEST_FREELANCE_CREATION_ROUTE_URL } from "../../../../../routes/client";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { CustomCheckbox } from "../../../../common/elements/CustomCheckbox";
import { useJobPackages } from "../hooks/useJobPackages";

type ServicesProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const JobType: React.FC<ServicesProps> = ({
  handleBlur,
  handleChange,
  errors,
  values,
}) => {
  const boldStyles = useBoldStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleNext = () => {
    const step = Number(searchParams.get("step"));
    searchParams.set("step", String(step + 1));
    setSearchParams(searchParams);
  };

  const { jobPostsCredits } = useJobPackages();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box display="flex" gridColumnGap={332} alignItems="flex-start">
        <Box width="30%" display="flex" flexDirection="column" gridRowGap={12}>
          <Box>
            <Typography variant="subtitle1" className={boldStyles.root}>
              What Kind of Job
            </Typography>{" "}
            <Typography variant="subtitle1" className={boldStyles.root}>
              Are You Posting?
            </Typography>
          </Box>
          <Typography variant="subtitle2">Welcome!</Typography>
          <Typography variant="subtitle2">
            To get started, select the kind of job you are looking to post.{" "}
          </Typography>
        </Box>
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gridRowGap={60}
          >
            <Box display="flex" flexDirection="column" gridRowGap={10}>
              <Field
                component={CustomCheckbox}
                width={675}
                onClick={() => {
                  window.location.replace(
                    JOB_REQUEST_FREELANCE_CREATION_ROUTE_URL
                  );
                }}
                label="A freelance project (free to post)"
                onChange={handleChange}
                onBlur={handleBlur}
                name="freelance"
              />
              <Box py={6}>
                <Typography variant="subtitle2">
                  Or any one of these:
                </Typography>
              </Box>
              <Box>
                <Field
                  component={CustomCheckbox}
                  width={675}
                  height={340}
                  multiple
                  labels={[
                    "A permanent, full-time job",
                    "A contract job that will not be completed on this platform",
                    "A job posted on behalf of another company",
                    "An in-person or hybrid job",
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.marketing}
                  onClick={handleNext}
                  name="permanent"
                />

                {jobPostsCredits > 0 && (
                  <Field
                    component={CustomCheckbox}
                    isSelected
                    width={675}
                    label={`You have ${jobPostsCredits} Job Post credit(s) - Post Now`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.writing}
                    onClick={handleNext}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
