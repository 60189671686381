import { createState } from "@hookstate/core";
import { AppState } from "./types";

export const state = createState<AppState>({
  confirmAccountDeletion: {
    open: false,
  },
  confirmCancelSubscription: {
    open: false,
  },
  subscriptionModal: {
    open: false,
  },
});
