import { Box, useMediaQuery } from "@material-ui/core";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import React, { useMemo } from "react";
import {
  DESKTOP_MIN_HEIGHT,
  DESKTOP_NAVBAR_HEIGHT,
  CLIENT_DESKTOP_VIEW_CONFIG,
  MOBILE_NAVBAR_HEIGHT,
} from "../../constants";
import { CurrentPageContent } from "../../CurrentPageContent";
import { useScrollEffects } from "../../hooks";
import { Topbar } from "../../Topbar";
import {
  LandingStepBContent,
  LandingStepBHeadline,
  LandingStepC,
  LandingStepCContent,
  LandingStepCHeadline,
  LandingStepD,
  LandingStepDContent,
  LandingStepDHeadline,
  LandingStepE,
  LandingStepA,
  LandingStepB,
} from "../steps";
import { LandingStepEContent } from "../steps/LandingStepE/LandingStepEContent";
import { LandingStepEHeadline } from "../steps/LandingStepE/LandingStepEHeadline";
import { LandingStepF } from "../steps/LandingStepF";
import { LandingStepG } from "../steps/LandingStepG";
import { AccountTypeSwitch } from "./AccountTypeSwitch";

export const clientDesktopPages = [
  <React.Fragment />,
  <LandingStepB />,
  <LandingStepC />,
  <LandingStepD />,
  <LandingStepE />,
  <LandingStepF />,
  <LandingStepG />,
];

export const clientMobilePages = [
  <React.Fragment />,
  <LandingStepBHeadline />,
  <LandingStepBContent />,
  <LandingStepCHeadline />,
  <LandingStepCContent />,
  <LandingStepDHeadline />,
  <LandingStepDContent />,
  <LandingStepEHeadline />,
  <LandingStepEContent />,
  <LandingStepF />,
  <LandingStepG />,
];

export const ClientLargeViewParallax = () => {
  const { backgroundColors, pagesCount } = CLIENT_DESKTOP_VIEW_CONFIG;
  const mobileHeight = useMediaQuery("(max-height: 750px)", { noSsr: true });

  const { isScrolling, currentVisibleView, lastVisitedView } = useScrollEffects(
    {
      pagesCount,
      backgroundColors,
    }
  );

  const height = useMemo(() => {
    if (mobileHeight) return MOBILE_NAVBAR_HEIGHT;

    return DESKTOP_NAVBAR_HEIGHT;
  }, [mobileHeight]);

  return (
    <Box height="100%">
      <Topbar
        height={height * (isScrolling ? 0.8 : 1)}
        inverse
        color="#F5895F"
        containerProps={{
          top: 0,
          position: "fixed",
        }}
      />
      <Parallax
        className="parallax-container"
        style={{
          backgroundColor: "#B1D9D9",
        }}
        pages={pagesCount}
      >
        <ParallaxLayer
          style={{
            justifyContent: "flex-start",
          }}
        >
          <LandingStepA />
          <AccountTypeSwitch defaultChecked="client" />
        </ParallaxLayer>
        <ParallaxLayer
          sticky={{ start: 1, end: pagesCount - 2 }}
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            minHeight: DESKTOP_MIN_HEIGHT,
          }}
        >
          <Box height="100%" display="flex" justifyContent="center">
            <CurrentPageContent
              desktopPages={clientDesktopPages}
              mobilePages={clientMobilePages}
              currentPage={currentVisibleView}
              previousPage={lastVisitedView}
            >
              <AccountTypeSwitch defaultChecked="client" />
            </CurrentPageContent>
          </Box>
        </ParallaxLayer>
        <ParallaxLayer
          offset={pagesCount - 1}
          style={{ minHeight: DESKTOP_MIN_HEIGHT }}
        >
          <LandingStepA />
        </ParallaxLayer>
      </Parallax>
    </Box>
  );
};
