import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateOnCondition = (path: string, condition: boolean) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (condition) {
      navigate(path);
    }
  }, [navigate, path, condition]);
};
