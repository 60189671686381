import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { BLACK, BLUE, YELLOW } from "../../themes/components/utils";

const addLogo = (doc: jsPDF) => {
  doc.setDrawColor(BLUE);
  doc.rect(12, 8, 47, 16);

  doc.setDrawColor("white");
  doc.setFillColor(BLUE);
  doc.rect(27, 10, 30, 12, "FD");

  doc.setFontSize(19);
  doc.setTextColor(BLUE);
  doc.text("The", 14, 18);

  doc.setTextColor(YELLOW);
  doc.text("Talented", 29, 18);
};

const addFooter = (doc: jsPDF, pageNumber: string) => {
  doc.setTextColor(BLACK);
  doc.setFontSize(8);
  doc.text(pageNumber, 200, 290);
};

export const generatePDF = (): jsPDF => {
  const doc = new jsPDF();
  const date = new Date(Date.now());

  doc.setFontSize(10);
  doc.text(date.toLocaleString(), 168, 10);

  addLogo(doc);

  return doc;
};

export const generateInvoicesPDF = (
  tableColumns: string[][],
  tableRows: string[][]
) => {
  const doc = generatePDF();

  autoTable(doc, {
    body: tableRows,
    didDrawPage: ({ doc, pageNumber }) => {
      addLogo(doc);
      addFooter(doc, pageNumber.toString());
    },
    head: tableColumns,
    headStyles: { fillColor: BLUE, textColor: "white" },
    margin: { left: 12, top: 30 },
    startY: 40,
  });

  doc.save("invoice.pdf");
};
