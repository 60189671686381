import { CheckboxClassKey, CheckboxProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiCheckbox: MuiComponentThemeDefinition<
  CheckboxClassKey,
  CheckboxProps
> = {
  props: {
    size: "medium",
  },
  override: () => ({
    root: {
      color: "white",
    },
  }),
};
