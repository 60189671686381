import { Box, Divider, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  divider: {
    backgroundColor: palette.secondary.main,
  },
}));

export const FixedPriceMoreInfo = () => {
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="column" gridRowGap={14}>
      <Typography
        color="secondary"
        variant="subtitle2"
        style={{ fontWeight: 700 }}
      >
        Fixed Price Projects
      </Typography>

      <Divider className={styles.divider} />

      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Working with Milestones
      </Typography>
      <Typography color="secondary" variant="body2">
        Setting up project milestones is a useful way to break up a larger
        project into smaller parts.
      </Typography>
      <Box>
        <Typography color="secondary" variant="body2">
          Once work has been submitted for the current milestone, the Client may
          request changes once, or release payment.
        </Typography>
        <Typography color="secondary" variant="body2">
          If no action is taken on a submitted milestone, the money assigned to
          it will be automatically released to you, 5 business days after it is
          submitted.
        </Typography>
      </Box>
      <Typography color="secondary" variant="body2">
        Once work begins on a milestone, it is no longer editable. All other
        future milestones are always editable.
      </Typography>

      <Divider className={styles.divider} />

      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Funding The Project and Receiving Payment
      </Typography>
      <Typography color="secondary" variant="body2">
        By default, all fixed price projects have one milestone.
      </Typography>
      <Box>
        <Typography color="secondary" variant="body2">
          In order to begin a fixed price project, at least the first project
          milestone has to be funded.
        </Typography>
        <Typography color="secondary" variant="body2">
          If multiple milestones are set up, at least the upcoming milestone has
          to be funded for work to continue.
        </Typography>
      </Box>
      <Typography color="secondary" variant="body2">
        The amounts specified in the milestones are moved into an escrow
        account, to be released into your account on completion of the
        milestone.
      </Typography>

      <Divider className={styles.divider} />

      <Typography color="secondary" variant="body2" style={{ fontWeight: 700 }}>
        Your Account Balance and Withdrawing Funds
      </Typography>
      <Typography color="secondary" variant="body2">
        All funds from projects are deposited into your Account Balance until
        you withdraw them.
      </Typography>
      <Typography color="secondary" variant="body2">
        Upon withdrawal, the money will be deposited to your bank account or
        card on file. You can view your account from the Payments Dashboard. The
        transfer is facilitated by Stripe.
      </Typography>
      <Typography color="secondary" variant="body2">
        Invoices of withdrawals are available in the Withdrawal Invoices section
        on the Account Balance page, and on the Payments Dashboard.
      </Typography>
      <Typography color="secondary" variant="body2">
        Invoices deposited to your Account Balance are available in the
        Milestone Deposits section on that project’s page and on the Account
        Balance page.
      </Typography>
    </Box>
  );
};
