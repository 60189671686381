import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { BackButton } from "../../../common/elements/BackButton";

type SavedMainbarProps = {};

export const SavedMainbar: React.FC<SavedMainbarProps> = () => {
  const boldStyles = useBoldStyles();
  const navigate = useNavigate();

  return (
    <Box>
      <Box pt={10}>
        <BackButton
          onClick={() => {
            navigate("/job-requests/search");
          }}
          backLabel="Back to Search"
        />
      </Box>
      <Box pl={1} py={10}>
        <Typography variant="h1" className={boldStyles.root}>
          Saved Job Request(s)
        </Typography>
      </Box>
    </Box>
  );
};
