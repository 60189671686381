import { Box, Typography } from "@material-ui/core";
import { Field, FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { CustomInput, CustomTextArea } from "../../../../common/elements";
import { BackButton } from "../../../../common/elements/BackButton";
import { useRequiredFieldLabel } from "../../../../common/hooks/utils";

type JobDetailsProps = {
  onBack?: () => void;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  errors: FormikValues;
};

export const initialJobDetailsValues = {
  title: "",
  description: "",
};

export const JobRequestDetails: React.FC<JobDetailsProps> = ({
  onBack,
  handleBlur,
  handleChange,
  values,
  errors,
}) => {
  const boldStyles = useBoldStyles();
  const requiredFieldLabel = useRequiredFieldLabel();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
      height="100%"
    >
      <BackButton onClick={() => onBack?.()} />
      <Box display="flex" gridColumnGap={50} pt={10} height="100%">
        <Box
          maxWidth="30%"
          display="flex"
          flexDirection="column"
          gridRowGap={20}
        >
          <Typography variant="subtitle1" className={boldStyles.root}>
            Title and Description
          </Typography>
          <Typography variant="subtitle2">
            Add the title and description for your Job Request.
          </Typography>
        </Box>
        <Box width="100%" height="100%">
          <Box display="flex" flexDirection="column" gridRowGap={40}>
            <Field
              component={CustomInput}
              placeholder={requiredFieldLabel("Title")}
              name="title"
              value={values.title}
              error={errors.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Field
              component={CustomTextArea}
              placeholder={requiredFieldLabel("Description")}
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.description}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
