import { Box, Divider } from "@material-ui/core";
import React from "react";
import { JobRequestHeaderContent } from "./JobRequestHeaderContent";
import { JobRequestHeaderSummary } from "./JobRequestHeaderSummary";

export const JobRequestHeader: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      gridColumnGap={40}
    >
      <Box width="65%" py={10}>
        <JobRequestHeaderContent />
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box width="25%" py={10}>
        <JobRequestHeaderSummary />
      </Box>
    </Box>
  );
};
