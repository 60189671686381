import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { fetchMilestoneInvoices } from "../../../../../../api-services/payment-request/fetchMilestoneInvoices";
import { MilestoneInvoice } from "../../../../../../api-types/projects/milestone-invoices";

export const MILESTONE_INVOICE_KEY = "milestone-invoice-key";

export const milestoneInvoiceKey = (projectId?: string) => {
  if (!projectId) return MILESTONE_INVOICE_KEY;

  return `${MILESTONE_INVOICE_KEY}-${projectId}`;
};

export const useMilestonesInvoices = () => {
  const { projectId } = useParams();

  return useQuery<MilestoneInvoice[], unknown, MilestoneInvoice[]>(
    milestoneInvoiceKey(projectId),
    async () => {
      const { data } = await fetchMilestoneInvoices(projectId);

      return data;
    }
  );
};

export const useInvalidateMilestonesInvoicesQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (id: string) => {
      queryClient.invalidateQueries(milestoneInvoiceKey(id));
    },
    [queryClient]
  );
};
