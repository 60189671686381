import { BudgetType } from "../../../modules/client/job-requests/create-job-request-wizard/freelance-job-request/BudgetStep";
import {
  FixedProjectFundingType,
  HourlyRateFundingType,
} from "../../../modules/client/job-requests/create-job-request-wizard/validation-schema";

export enum ProjectTimeframeType {
  WEEKS = "weeks",
  ONGOING = "onGoing",
  NOT_SURE = "notSure",
}

export type ProjectTimeframe = {
  type: ProjectTimeframeType;
  weeks?: number;
};

export enum TimeDedicatedType {
  HRS_PER_WEEK = "hoursPerWeek",
  NOT_SURE = "notSure",
}

type TimeDedicated = {
  type: TimeDedicatedType;
  hoursPerWeek?: number;
};

export enum FeeType {
  PER_HR = "perHour",
  FIXED_PRICE = "fixedPrice",
  NOT_SURE = "notSure",
}

type Fee = {
  type: FeeType;
  perHour?: number;
  fixedPrice?: number;
};

export type TimeFrame = {
  projectTimeFrame: ProjectTimeframe;
  timeDedicated: TimeDedicated;
  fee: Fee;
};

export type JobRequest = {
  description: string;
  title: string;
  timeFrame: TimeFrame;
  images?: any[];
  skills: any[];
  visibility: string;
  serviceCategory: string;
  projectType: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: any;
  _id: string;
  isDraft?: boolean;
  budget: BudgetType;
  fixedProjectFunding: FixedProjectFundingType;
  hourlyRateProjectFunding: HourlyRateFundingType;
};
