import { PaperClassKey, PaperProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { LIGHT_BLUE } from "./utils";

export const MuiPaper: MuiComponentThemeDefinition<PaperClassKey, PaperProps> =
  {
    props: {},
    override: () => ({
      root: {
        p: 0,
        backgroundColor: LIGHT_BLUE,
      },
    }),
  };
