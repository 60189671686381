import { InputAdornment, makeStyles, Tooltip } from "@material-ui/core";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { FieldProps } from "formik";
import React, { useState, useEffect, ChangeEvent, ReactNode } from "react";
import { CustomTooltip } from "../components";
import { useStyles as useInputStyle } from "./CustomInput";

type CustomTextAreaProps = FieldProps & {
  value: string;
  placeholder: ReactNode;
  className?: string;
  handleChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  error: string;
};

const useTextAreaStyle = makeStyles({
  noResize: {
    resize: "none",
  },

  textAreaContainer: {
    height: "268px",
  },
});

export const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  field,
  form,
  value,
  placeholder,
  className,
  handleChange,
  maxLength,
  error,
}) => {
  const styles = useInputStyle();
  const textAreaStyles = useTextAreaStyle();
  const hasValue = Boolean(value);
  const [focus, setFocus] = useState(false);

  const fieldName = field.name;

  useEffect(() => {
    if (Boolean(value) && !focus) {
      setFocus(true);
    }
  }, [focus, value]);

  return (
    <div
      className={`${styles.container} ${textAreaStyles.textAreaContainer} ${className}`}
      onFocus={() => setFocus(true)}
      onBlur={() => !hasValue && setFocus(false)}
    >
      <textarea
        id={`id-${fieldName}`}
        className={`${styles.input} ${focus ? styles.inputFocus : ""} ${
          textAreaStyles.noResize
        } ${error ? styles.inputError : ""}`}
        onChange={(event) => {
          if (maxLength && event.target.value.length > maxLength) {
            form.setFieldValue(
              fieldName,
              event.target.value.substring(0, maxLength)
            );
          } else {
            form.setFieldValue(fieldName, event.target.value);
          }

          handleChange?.(event);
        }}
        value={value}
      />
      <label
        className={`${styles.label} ${focus ? styles.labelFocus : ""}`}
        htmlFor={`id-${fieldName}`}
      >
        <span>{placeholder}</span>
      </label>
      {error && (
        <InputAdornment position="start" className={styles.endAdornment}>
          <CustomTooltip title={error} type="error">
            <ErrorRoundedIcon
              color="error"
              data-cy-error={fieldName}
              style={{ cursor: "initial" }}
            />
          </CustomTooltip>
        </InputAdornment>
      )}
    </div>
  );
};
