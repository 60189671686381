import axios from "axios";
import { ACTIVATE_URL } from "../utils";

export const validateToken = async (token: string) => {
  return await axios.get(ACTIVATE_URL, {
    params: {
      token,
    },
  });
};
