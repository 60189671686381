import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "../../types";

export const useNavigateBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  return useCallback(() => {
    if (state?.from === "the-talented") {
      navigate(-1);
    } else {
      navigate("/", { replace: true });
    }
  }, [navigate, state?.from]);
};
