import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchInquiry } from "../../../../api-services/inquiries/inquiry";
import { InquiryData } from "../../../../api-types/job-requests/inquiries";
import { ACCOUNT_INQUIRIES } from "./useAccountInquiries";

export const accountInquiryKey = (id?: string) => {
  return `${ACCOUNT_INQUIRIES}-${id}`;
};

export const useAccountInquiryQuery = (id?: string) => {
  const { inquiryId } = useParams();

  return useQuery<InquiryData, unknown, InquiryData>(
    accountInquiryKey(id ?? inquiryId),
    async () => {
      const { data } = await fetchInquiry(id ?? inquiryId);

      return data;
    },
    {
      enabled: Boolean(id ?? inquiryId),
    }
  );
};
