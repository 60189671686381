import { useFormikContext } from "formik";
import { useEffect } from "react";
import { BudgetSchema, HourlyRateFundingSchema } from "../validation-schema";

export const useUpdateBudgetTimeFrame = () => {
  const { values, setFieldValue } = useFormikContext<
    HourlyRateFundingSchema & BudgetSchema
  >();

  useEffect(() => {
    if (!values?.budget?.type || values.budget.type === "fixed") return;

    const timeFrameType = values.budget.timeFrame.type;

    if (timeFrameType === "ongoing" || timeFrameType === "notSure") {
      const minDueDateWeeks =
        values?.hourlyRateProjectFunding?.milestones?.reduce(
          (acc, currentMilestone) => {
            return acc + (currentMilestone?.dueDate?.min ?? 0);
          },
          0
        );

      const maxDueDateWeeks =
        values?.hourlyRateProjectFunding?.milestones?.reduce(
          (acc, currentMilestone) => {
            return acc + (currentMilestone?.dueDate?.max ?? 0);
          },
          0
        );

      setFieldValue("budget.timeFrame.estimate.min", minDueDateWeeks);
      setFieldValue("budget.timeFrame.estimate.max", maxDueDateWeeks);
    }
  }, [
    setFieldValue,
    values.budget.timeFrame.type,
    values.budget.type,
    values?.hourlyRateProjectFunding?.milestones,
  ]);
};
