import { Box } from "@material-ui/core";
import React, { FC } from "react";
import {
  useHeadlineText,
  useMediumItalic,
  useMobileStyles,
  useStepDStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepEHeadline: FC = () => {
  const styles = useStepDStyles();
  const mediumItalic = useMediumItalic();
  const { skewedText, transparent } = useHeadlineText({
    primary: "#C8E3CE",
    secondary: "#EF7B7E",
    background: "#EF7B7E",
  });
  const { mobileHeadlineWrapper } = useMobileStyles();

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box
        className={mediumItalic.root}
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;Jobs are posted&nbsp;</span>
        </Box>
        <br />
        <Box className={`${transparent} ${styles.headline}`}>
          <span>&nbsp;for&nbsp;</span>
        </Box>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;90 days.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
