import { COLOR } from "../../../../themes/components/utils";

export const CLIENT_DESKTOP_BACKGROUND_COLORS: string[] = [
  "#B1D9D9",
  "#B1D9D9",
  "#FFC200",
  "#F9ECC2",
  "#C8E3CE",
  "#F5895F",
];

export const CLIENT_MOBILE_BACKGROUND_COLORS: string[] = [
  "#B1D9D9",
  "#B1D9D9",
  "#B1D9D9",
  "#FFC200",
  "#FFC200",
  "#F9ECC2",
  "#F9ECC2",
  "#C8E3CE",
  "#C8E3CE",
  "#F5895F",
];

export const PROFESSIONAL_DESKTOP_BACKGROUND_COLORS: string[] = [
  COLOR.PINK,
  COLOR.PINK,
  COLOR.BROWN,
  COLOR.GREEN,
  "#FFDF00",
  COLOR.BLUE,
];

export const PROFESSIONAL_MOBILE_BACKGROUND_COLORS: string[] = [
  COLOR.PINK,
  COLOR.PINK,
  COLOR.PINK,
  COLOR.BROWN,
  COLOR.BROWN,
  COLOR.GREEN,
  COLOR.GREEN,
  "#FFDF00",
  "#FFDF00",
  COLOR.BLUE,
];

export const SCROLL_OFFSET = 10;

export const DESKTOP_PAGES_COUNT = 9;
export const DESKTOP_MIN_HEIGHT = 750;
export const DESKTOP_NAVBAR_HEIGHT = 100;

export const MOBILE_PAGES_COUNT = 13;
export const MOBILE_MIN_HEIGHT = 256;
export const MOBILE_NAVBAR_HEIGHT = 80;
export const SMALL_MOBILE_NAVBAR_HEIGHT = 60;

export const CLIENT_DESKTOP_VIEW_CONFIG = {
  backgroundColors: CLIENT_DESKTOP_BACKGROUND_COLORS,
  pagesCount: DESKTOP_PAGES_COUNT,
  navbarHeight: DESKTOP_NAVBAR_HEIGHT,
};

export const PROFESSIONAL_DESKTOP_VIEW_CONFIG = {
  backgroundColors: PROFESSIONAL_DESKTOP_BACKGROUND_COLORS,
  pagesCount: DESKTOP_PAGES_COUNT,
  navbarHeight: DESKTOP_NAVBAR_HEIGHT,
};

export const PROFESSIONAL_MOBILE_VIEW_CONFIG = {
  backgroundColors: PROFESSIONAL_MOBILE_BACKGROUND_COLORS,
  pagesCount: MOBILE_PAGES_COUNT,
  navbarHeight: MOBILE_NAVBAR_HEIGHT,
};

export const CLIENT_MOBILE_VIEW_CONFIG = {
  backgroundColors: CLIENT_MOBILE_BACKGROUND_COLORS,
  pagesCount: MOBILE_PAGES_COUNT,
  navbarHeight: MOBILE_NAVBAR_HEIGHT,
};
