/* eslint-disable no-undef */
import { Box, makeStyles, Typography } from "@material-ui/core";
import Gleap from "gleap";
import React, { FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Slide, ToastContainer } from "react-toastify";
import WebFont from "webfontloader";
import { RouterIndex } from "../../../routes";
import { BLUE, COLOR, YELLOW } from "../../../themes/components/utils";
import { Metadata } from "../../common/components";
import { useCheckAccessExpiry } from "../../common/hooks/sign-in";
import { useWindowDimensions } from "../../common/window";
import { ThemeContext } from "../contexts";
import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line import/order
import { InfoBox } from "../../common/components/InfoBox";

export const SUBSCRIBE_ROUTE = "/subscribe";

WebFont.load({
  custom: {
    families: ["Jost"],
  },
});

const useStyle = makeStyles(() => ({
  toast: {
    "& .Toastify__toast-theme--dark": {
      backgroundColor: COLOR.WHITE_BLUE,
      color: BLUE,
    },
    "& .Toastify__close-button": {
      color: BLUE,
    },
  },
}));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: 3,
      retryOnMount: true,
      staleTime: 1000 * 60 * 2,
    },
  },
});

export const App: FC = () => {
  useCheckAccessExpiry();
  const { width, height } = useWindowDimensions();

  const styles = useStyle();

  useEffect(() => {
    if (!sessionStorage.getItem("platform")) {
      if (process.env.REACT_APP_ENV === "dev") {
        sessionStorage.setItem("platform", "dev");
        return;
      }

      if (process.env.REACT_APP_ENV === "prod") {
        sessionStorage.setItem("platform", "prod");
        return;
      }
      sessionStorage.setItem("platform", "local");
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/client") {
      Gleap.showFeedbackButton(false);
    } else {
      Gleap.showFeedbackButton(true);
    }
  });

  const excludePath = "/job-requests/search/";

  const isLandingRoute = () =>
      location.pathname === "/" ||
      location.pathname.includes(SUBSCRIBE_ROUTE) ||
      (location.pathname.startsWith(excludePath) &&
      location.pathname.length > excludePath.length) ||
      location.pathname === "/client" ||
      location.pathname === "/blog";


  if (!isLandingRoute()) {
    if (width < 1224 || height < 240) {
      return (
        <Box
          height="100vh"
          bgcolor={BLUE}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            style={{
              color: YELLOW,
            }}
            variant="subtitle1"
          >
            App is not responsive. Please use a bigger screen.
          </Typography>
        </Box>
      );
    }
  }

  return (
    <ThemeContext>
      <QueryClientProvider client={queryClient}>
        {/*<Metadata />*/}
        <ToastContainer
          className={styles.toast}
          position="bottom-center"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          transition={Slide}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
          <RouterIndex />
        </Box>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeContext>
  );
};
