import { useMutation, useQueryClient } from "react-query";
import { deleteJobApplication } from "../../../../api-services/job-applications/deleteJobApplication";
import { JOB_APPLICATIONS_QUERY_KEY } from "../../../common/hooks/job-applications/useJobApplicationsQuery";
import { METADATA_QUERY_KEY } from "../../../common/hooks/metadata/useMetadataQuery";
import { useToast } from "../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../job-requests/hooks/feedbackErrors";

export const useDeleteJobApplication = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation(
    async (applicationId: string) => {
      await deleteJobApplication(applicationId);
    },
    {
      onSuccess() {
        notifySuccess(feedbackMessage("job application", FEEDBACK_TYPE.DELETE));
      },
      onSettled() {
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
        queryClient.invalidateQueries(JOB_APPLICATIONS_QUERY_KEY);
      },
    }
  );
};
