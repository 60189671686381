import {
  Box,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { forwardRef, ReactNode, useState } from "react";
import { BLUE, YELLOW } from "../../../../../themes/components/utils";
import { useMetadataQuery } from "../../../hooks/metadata/useMetadataQuery";
import { useHandleCloseDialog } from "../../../hooks/utils";
import { useDialogStyles } from "../subscribe-dialog/SubscribeDialog";

export type WithdrawFundsDialogProps = {
  open: boolean;
  message?: ReactNode | string;
  onClose: () => void;
  onConfirm?: (amount: number) => void;
};

const useStyles = makeStyles({
  disabled: {
    "& .MuiButton-label": {
      color: `${YELLOW} !important`,
    },
  },
  button: {
    // height: 64,
    width: "fit-content",
    "& .MuiButton-label": {
      fontSize: 19,
      fontWeight: 550,
    },
    ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]: {
      height: 48,
      width: 75,
      "& .MuiButton-label": {
        fontSize: 16,
        fontWeight: 550,
      },
    },
    ["@media screen and (max-height: 300px)"]: {
      height: 30,
      width: 60,
      "& .MuiButton-label": {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
});

export const WithdrawFundsDialog = forwardRef<
  HTMLDialogElement,
  WithdrawFundsDialogProps
>((props, ref) => {
  const styles = useDialogStyles(true);
  const buttonStyles = useStyles();
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const { data: metadata } = useMetadataQuery();

  const { onClose, onConfirm } = props;

  const [amount, setAmount] = useState(0);

  const handleConfirm = () => {
    setIsWithdrawing(true);
    setTimeout(() => {
      setIsWithdrawing(false);
    }, 3000);
    onConfirm?.(amount);
  };

  const handleCloseDialog = useHandleCloseDialog(onClose);

  const canWithdraw = amount <= (metadata?.balance?.available ?? 0);

  return (
    <Dialog
      ref={ref}
      open={props.open}
      className={`${styles.fullWidth} `}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        <Typography
          color="secondary"
          style={{
            fontSize: 27,
            fontWeight: 550,
          }}
        >
          Withdraw Money From Your Account Balance
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gridRowGap={12}
          width={420}
          mx="auto"
        >
          <Box display="flex" gridColumnGap={12}>
            <Typography
              color="secondary"
              style={{ fontWeight: 700 }}
              variant="subtitle2"
            >
              Available Account Balance
            </Typography>
            <Typography
              color="secondary"
              style={{ fontWeight: 700 }}
              variant="subtitle2"
            >
              ${metadata?.balance?.available ?? 0}
            </Typography>
          </Box>

          <Box alignItems="center" display="flex" gridColumnGap={12}>
            <Typography
              color="secondary"
              style={{ fontWeight: 700 }}
              variant="body2"
            >
              Enter Amount You Want To Withdraw
            </Typography>

            <Box
              alignItems="center"
              display="flex"
              width={110}
              gridColumnGap={4}
            >
              <Typography
                color="secondary"
                style={{ fontWeight: 700 }}
                variant="body2"
              >
                $
              </Typography>
              <TextField
                size="small"
                fullWidth
                value={amount}
                onChange={(e) => setAmount(Number(e?.target?.value) ?? 0)}
                style={{ height: 40 }}
                error={!canWithdraw}
              />
            </Box>
          </Box>
          <Box height={20}>
            {!canWithdraw && (
              <Typography
                color="error"
                // sx={{
                //   color: COLOR.RED,
                // }}
                variant="body2"
              >
                Amount to withdraw should be less or equal to maximum available.
              </Typography>
            )}
          </Box>

          <Typography color="secondary" variant="body2">
            The money will be deposited to the card on file. (View)
          </Typography>
          <Typography color="secondary" variant="body2">
            An invoice of this withdrawal will be available in the Withdrawal
            Invoices section of the Account Balance page.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gridColumnGap={10}>
          <Button
            variant="contained"
            onClick={onClose}
            className={buttonStyles.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: BLUE,
              color: YELLOW,
            }}
            className={`${buttonStyles.button} ${buttonStyles.disabled}`}
            onClick={handleConfirm}
            autoFocus
            disabled={!canWithdraw || isWithdrawing}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
