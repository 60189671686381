import { useQuery } from "react-query";
import { fetchSkills } from "../../../../api-services/skills";

export const KNOWN_SKILLS_KEY = "known-skills";

export const useKnownSkills = () => {
  return useQuery<any[]>(KNOWN_SKILLS_KEY, async () => {
    const { data } = await fetchSkills();

    return data.map((skill: any) => skill.name);
  });
};
