import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";

export const PublishProject: React.FC = () => {
  const boldStyles = useBoldStyles();

  return (
    <Box display="flex" flexDirection="column" gridRowGap={20}>
      <Typography variant="subtitle1" className={boldStyles.root}>
        Unpublished past project
      </Typography>
      <Typography variant="body1">
        Once a project is completed, it's listed here under Unpublished Past
        Projects. It will not be visible to potential Clients unless you review
        and publish it.
      </Typography>
    </Box>
  );
};
