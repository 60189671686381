import { Box } from "@material-ui/core";
import { FC, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "../../elements/Card";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";

type ProfileTabsProps = {
  disableCollaboratorsTab?: boolean;
  disableCurrentProjectsTab?: boolean;
  disablePortfolioProjectsTab?: boolean;
};

export const ProfileTabs: FC<ProfileTabsProps> = ({
  disableCollaboratorsTab,
  disableCurrentProjectsTab,
  disablePortfolioProjectsTab,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading } = useMetadataQuery();

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.get("tab")) {
      return;
    }

    const enabledTabs = [];

    if (!disablePortfolioProjectsTab) {
      enabledTabs.push("portfolio");
    }

    if (!disableCurrentProjectsTab) {
      enabledTabs.push("current");
    }

    if (!disableCollaboratorsTab) {
      enabledTabs.push("collaborators");
    }

    if (enabledTabs.length) {
      searchParams.set("tab", enabledTabs[0]);
      setSearchParams(searchParams, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    disablePortfolioProjectsTab,
    disableCurrentProjectsTab,
    disableCollaboratorsTab,
  ]);

  return (
    <Box display="flex" py={10} gridColumnGap={16}>
      <Card
        width={190}
        disabled={disablePortfolioProjectsTab}
        label="Portfolio Projects"
        isSelected={searchParams.get("tab") === "portfolio"}
        onClick={() => onTabNavigationHandler("portfolio")}
        isLoading={isLoading}
      />
      <Card
        isLoading={isLoading}
        width={184}
        disabled={disableCurrentProjectsTab}
        label={`Current Projects`}
        isSelected={searchParams.get("tab") === "current"}
        onClick={() => onTabNavigationHandler("current")}
      />
      <Card
        width={162}
        isLoading={isLoading}
        disabled={disableCollaboratorsTab}
        label={`Collaborators`}
        isSelected={searchParams.get("tab") === "collaborators"}
        onClick={() => onTabNavigationHandler("collaborators")}
      />
    </Box>
  );
};
