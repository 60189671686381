import React from "react";

type FileIconProps = {
  fillColor?: string;
};

export const FileIcon: React.FC<FileIconProps> = ({
  fillColor = "#d0e4f4",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="55"
      viewBox="0 0 44 55"
    >
      <path
        id="Path_69"
        data-name="Path 69"
        d="M216.214,3129.374a3.628,3.628,0,0,0-.165-.743v-.247a2.928,2.928,0,0,0-.523-.77h0l-16.5-16.5h0a2.95,2.95,0,0,0-.77-.523h-.247l-.88-.3H180.464a8.25,8.25,0,0,0-8.25,8.25v38.5a8.251,8.251,0,0,0,8.25,8.25h27.5a8.251,8.251,0,0,0,8.25-8.25v-27.665Zm-16.5-9.708,7.122,7.123h-7.122Zm11,37.373a2.75,2.75,0,0,1-2.75,2.75h-27.5a2.75,2.75,0,0,1-2.75-2.75v-38.5a2.75,2.75,0,0,1,2.75-2.75h13.75v13.75a2.75,2.75,0,0,0,2.75,2.75h13.75Z"
        transform="translate(-172.214 -3110.289)"
        fill={fillColor}
      />
    </svg>
  );
};
