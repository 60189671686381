import { useInvalidateJobRequestQuery } from "./useJobRequestQuery";
import { useJobRequestUpdateMutationCore } from "./useJobRequestUpdateMutationCore";

export const useJobRequestUpdateMutation = () => {
  const invalidateJobRequestQuery = useInvalidateJobRequestQuery();

  return useJobRequestUpdateMutationCore({
    onSuccess(updatedJobRequest) {
      invalidateJobRequestQuery(updatedJobRequest._id);
    },
  });
};
