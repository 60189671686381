import { Box, Button } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper } from "../../app/container/PageWrapper";
import { useCurrentProfessionalIndex } from "../../client/projects/state/hooks";
import { Section } from "../../common/components";
import { useProjectQuery } from "../../common/hooks/projects/project/useProjectQuery";
import { useScopedDowngradedStateValue } from "../../common/hooks/utils/useScopedDowngradedStateValue";
import { ProfessionalToProfessionalCommunication } from "./communication";
import { Details } from "./details/";
import { ProjectHeader } from "./sections/header";

export const ProjectCollaborators: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();
  const currentProfessionalIndex = useScopedDowngradedStateValue(
    useCurrentProfessionalIndex()
  );

  const hasCollaborators = (project?.professionals?.length ?? 0) > 1;
  const errorMessage = useMemo(() => {
    return !isLoadingProject && !hasCollaborators
      ? "You don't have any collaborations yet. Invite a professional or accept an invitation first."
      : "";
  }, [hasCollaborators, isLoadingProject]);

  const content = useMemo(() => {
    if (searchParams.get("tab") === "replies") {
      return (
        <ProfessionalToProfessionalCommunication
          professional={
            project?.professionals[currentProfessionalIndex].professional
          }
        />
      );
    }

    return <Details />;
  }, [searchParams, project?.professionals, currentProfessionalIndex]);

  return (
    <PageWrapper>
      <Section errorMessage={errorMessage} isLoading={isLoadingProject}>
        <Box px={10} height="100%">
          <ProjectHeader variant="collaboration" />
          {content}
          <Box py={10} display="flex" justifyContent="flex-end">
            <Button variant="contained" disabled>
              Next Project
            </Button>
          </Box>
        </Box>
      </Section>
    </PageWrapper>
  );
};
