import { makeStyles } from "@material-ui/core";
import { BLUE, COLOR, LIGHT_BLUE, RED, YELLOW } from "../components/utils";

type HeadlineTextProps = {
  primary: string;
  secondary: string;
  background: string;
};

type ButtonProps = {
  color?: string;
  background?: string;
  hover?: string;
};

// export const useMainButtonsStyles = makeStyles(() => ({
//   sizeMedium: {
//     paddingLeft: 16,
//     paddingRight: 16,
//     fontSize: 15,
//     height: 48,
//     borderWidth: 2,
//   },
// }));

export const useWaveStyles = makeStyles({
  root: {
    "&:after": {
      background:
        "linear-gradient(90deg, transparent, rgba(255, 228, 98, 0.13), transparent) !important",
    },
  },
});

export const useHeadlineText = makeStyles(() => ({
  skewedText: {
    width: "fit-content",
    display: "inline-flex",
    color: (props: HeadlineTextProps) => props.primary,
    background: (props: HeadlineTextProps) => props.background,
    transform: "skew(10deg)",
    "& span": {
      display: "inline-block",
      transform: "skew(-10deg)",
    },
  },
  transparent: {
    width: "fit-content",
    display: "inline-flex",
    color: (props: HeadlineTextProps) => props.secondary,
  },
}));

export const useStepAStyles = makeStyles({
  container: {
    paddingTop: 100,
    paddingLeft: 0,
    paddingRight: 0,
    width: "82%",
    ["@media screen and (max-width: 1024px), screen and (max-height: 900px)"]: {
      paddingTop: 48,
    },
    ["@media screen and (max-width: 720px)"]: {
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
    },
  },
  typewriter: {
    display: "inline",
    background: YELLOW,
    color: BLUE,
  },
  descriptionWrapper: {
    paddingTop: 48,
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      paddingTop: 24,
    },
    ["@media screen and (max-height: 400px)"]: {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  mediumItalic: {
    transform: "skew(-10deg)",
  },
  headlineText: {
    letterSpacing: 0,
    color: COLOR.WHITE_BLUE,
    fontSize: 145,
    fontWeight: 500,
    paddingTop: 72,
    textAlign: "center",
    marginLeft: "0.1em",
    ["@media screen and (max-width: 1750px)"]: {
      fontSize: "8.2vw",
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: "8.6vw",
      display: "flex",
      alignItems: "center",
      flexGrow: "0.4",
      marginLeft: "0.2em",
    },
    ["@media screen and (max-width: 700px)"]: {
      fontSize: "9.8vw",
    },
    ["@media screen and (max-width: 550px)"]: {
      fontSize: "9.6vw",
    },
    ["@media screen and (max-width: 450px)"]: {
      fontSize: "9.4vw",
    },
    ["@media screen and (max-width: 360px)"]: {
      fontSize: "9.2vw",
    },
    ["@media screen and (max-width: 310px)"]: {
      fontSize: "9vw",
    },
    ["@media screen and (max-width: 280px)"]: {
      fontSize: "25px",
    },
    ["@media screen and (max-height: 500px)"]: {
      paddingTop: 30,
      fontSize: "36px",
    },
    ["@media screen and (max-height: 350px)"]: {
      paddingTop: 32,
      fontSize: "28px",
    },
  },
  clientHeadlineText: {
    letterSpacing: 0,
    color: COLOR.BLUE,
    fontSize: 145,
    fontWeight: 500,
    paddingTop: 72,
    textAlign: "center",
    marginLeft: "0.1em",
    ["@media screen and (max-width: 1750px)"]: {
      fontSize: "8.2vw",
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: "8.6vw",
      display: "flex",
      alignItems: "center",
      flexGrow: "0.4",
      marginLeft: "0.2em",
    },
    ["@media screen and (max-width: 700px)"]: {
      fontSize: "9.8vw",
    },
    ["@media screen and (max-width: 550px)"]: {
      fontSize: "9.6vw",
    },
    ["@media screen and (max-width: 450px)"]: {
      fontSize: "9.4vw",
    },
    ["@media screen and (max-width: 360px)"]: {
      fontSize: "9.2vw",
    },
    ["@media screen and (max-width: 310px)"]: {
      fontSize: "9vw",
    },
    ["@media screen and (max-width: 280px)"]: {
      fontSize: "25px",
    },
    ["@media screen and (max-height: 500px)"]: {
      paddingTop: 30,
      fontSize: "36px",
    },
    ["@media screen and (max-height: 350px)"]: {
      paddingTop: 32,
      fontSize: "28px",
    },
  },
  descriptionText: {
    fontSize: 28,
    color: "white",
    ["@media screen and (max-width: 1200px)"]: {
      fontSize: 26,
    },
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 700px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
  clientDescriptionText: {
    fontSize: 28,
    color: COLOR.BLUE,
    ["@media screen and (max-width: 1200px)"]: {
      fontSize: 26,
    },
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 700px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
  mobileCardWrapper: {
    marginBottom: 24,
    paddingLeft: 64,
    paddingRight: 64,
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      paddingRight: 48,
      paddingLeft: 48,
    },
    ["@media screen and (max-width: 1000px)"]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
    ["@media screen and (max-width: 850px)"]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
    ["@media screen and (max-height: 450px)"]: {
      marginBottom: 12,
    },
  },
  cardWrapper: {
    marginBottom: 24,
    paddingLeft: 64,
    paddingRight: 64,
    ["@media screen and (max-width: 1100px), screen and (max-height: 950px)"]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
    ["@media screen and (max-width: 1000px)"]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    ["@media screen and (max-width: 850px)"]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    ["@media screen and (max-height: 450px)"]: {
      marginBottom: 12,
    },
  },
  cardText: {
    color: "white",
    fontSize: 26,
    fontWeight: 550,
    // flexGrow: 1,
    // margin: 36,
    // marginBottom: "10%",
    ["@media screen and (max-width: 1440px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 1340px)"]: {
      fontSize: 23,
    },
    ["@media screen and (max-width: 1240px)"]: {
      fontSize: 22,
    },
    ["@media screen and (max-width: 1140px)"]: {
      fontSize: 21,
    },
    ["@media screen and (max-width: 1040px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 940px), screen and (max-height: 700px)"]: {
      fontSize: 18,
      textAlign: "left",
    },
    ["@media screen and (max-width: 800px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 625px)"]: {
      fontSize: 15,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 12,
    },
    ["@media screen and (max-width: 300px)"]: {
      fontSize: 10,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
  clientCardText: {
    color: "#7B65AA",
    fontSize: 26,
    fontWeight: 550,
    ["@media screen and (max-width: 1440px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 1340px)"]: {
      fontSize: 23,
    },
    ["@media screen and (max-width: 1240px)"]: {
      fontSize: 22,
    },
    ["@media screen and (max-width: 1140px)"]: {
      fontSize: 21,
    },
    ["@media screen and (max-width: 1040px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 940px), screen and (max-height: 700px)"]: {
      fontSize: 18,
      textAlign: "left",
    },
    ["@media screen and (max-width: 800px), screen and (max-height: 500px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 625px)"]: {
      fontSize: 15,
    },
    ["@media screen and (max-width: 400px)"]: {
      fontSize: 12,
    },
    ["@media screen and (max-width: 300px)"]: {
      fontSize: 10,
    },
    ["@media screen and (max-height: 400px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-height: 350px)"]: {
      fontSize: 12,
    },
  },
});

export const useStepBStyles = makeStyles({
  headline: {
    fontSize: 116,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "5.7vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "7vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "10vw",
    },
    ["@media screen and (max-aspect-ratio: 3/2)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "10.1vw",
      },
      ["@media screen and (max-width: 650px)"]: {
        fontSize: "9.9vw",
      },
      ["@media screen and (max-width: 500px)"]: {
        fontSize: "9.7vw",
      },
      ["@media screen and (max-width: 400px)"]: {
        fontSize: "9.5vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "9.3vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "26px",
      },
    },
    ["@media screen and (min-aspect-ratio: 3/2)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "12vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "13.4vh",
      },
      ["@media screen and (max-height: 300px)"]: {
        fontSize: "12.7vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "35px",
      },
    },
  },
});

export const useStepCStyles = makeStyles({
  headline: {
    fontSize: 112,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "5.5vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "7vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "10vw",
    },
    ["@media screen and (max-aspect-ratio: 7/4)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "10vw",
      },
      ["@media screen and (max-width: 650px)"]: {
        fontSize: "9.7vw",
      },
      ["@media screen and (max-width: 500px)"]: {
        fontSize: "9.5vw",
      },
      ["@media screen and (max-width: 400px)"]: {
        fontSize: "9.3vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "9.1vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "25px",
      },
    },
    ["@media screen and (min-aspect-ratio: 7/4)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "11.5vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "13.6vh",
      },
      ["@media screen and (max-height: 300px)"]: {
        fontSize: "12.6vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "35px",
      },
    },
  },
});

export const useStepEStyles = makeStyles({
  headline: {
    fontSize: 112,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "5.5vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "6.5vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "10vw",
    },
    ["@media screen and (max-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "11.4vw",
      },
      ["@media screen and (max-width: 700px)"]: {
        fontSize: "11.2vw",
      },
      ["@media screen and (max-width: 550px)"]: {
        fontSize: "10.9vw",
      },
      ["@media screen and (max-width: 450px)"]: {
        fontSize: "10.7vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "10.5vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "29px",
      },
    },
    ["@media screen and (min-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "11.7vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "11.3vh",
      },
      ["@media screen and (max-height: 400px)"]: {
        fontSize: "10.3vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "29px",
      },
    },
  },
});

export const useStepDStyles = makeStyles({
  headline: {
    fontSize: 100,
    fontWeight: 500,
    ["@media screen and (max-width: 2050px)"]: {
      fontSize: "4.5vw",
    },
    ["@media screen and (max-width: 1024px)"]: {
      fontSize: "5vw",
    },
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      fontSize: "7vw",
    },
    ["@media screen and (max-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-width: 850px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 700px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 550px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 450px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 350px)"]: {
        fontSize: "9vw",
      },
      ["@media screen and (max-width: 280px)"]: {
        fontSize: "28px",
      },
    },
    ["@media screen and (min-aspect-ratio: 5/4)"]: {
      ["@media screen and (max-height: 650px)"]: {
        fontSize: "11.7vh",
      },
      ["@media screen and (max-height: 500px)"]: {
        fontSize: "11.3vh",
      },
      ["@media screen and (max-height: 400px)"]: {
        fontSize: "10.3vh",
      },
      ["@media screen and (max-height: 280px)"]: {
        fontSize: "29px",
      },
    },
  },
});

export const useDescriptionText = makeStyles({
  root: {
    fontSize: 28,
    color: (props: { color: string }) => props.color,
    ["@media screen and (max-width: 1200px), screen and (max-height: 900px)"]: {
      fontSize: 22,
    },
    ["@media screen and (max-width: 1024px), screen and (max-height: 650px)"]: {
      paddingRight: 0,
    },
    ["@media screen and (max-width: 850px)"]: {
      fontSize: 24,
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 650px)"]: {
      fontSize: 20,
    },
    ["@media screen and (max-width: 340px), screen and (max-height: 430px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-height: 320px)"]: {
      fontSize: 14,
    },
  },
});

export const useMobileStyles = makeStyles({
  mobileContentWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    // alignItems: "center",
  },
  mobileHeadlineWrapper: {
    textAlign: "left",
    width: "fit-content",
    height: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // ["@media screen and (orientation: landscape)"]: {
    //   height: "auto",
    // paddingTop: 30,
    // },
  },
  mobileDescriptionWrapper: {
    width: "fit-content",
    justifyContent: "center",
    alignSelf: "center",
    // ["@media screen and (orientation: landscape)"]: {
    //   width: "90%",
    //   alignSelf: "center",
    // },
  },
});

export const useMediumItalic = makeStyles({
  root: {
    transform: "skew(-10deg)",
    display: "inline-block",
    marginTop: -1,
    ["@media screen and (max-width: 850px), screen and (max-height: 650px)"]: {
      width: "fit-content",
    },
  },
});

export const useWhiteSelectStyles = makeStyles(() => ({
  root: {
    height: 70,
    verticalAlign: "center",
    lineHeight: 3.5,
    paddingLeft: "20px !important",
    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
  },
}));

export const useRedButtonStyles = makeStyles({
  delete: {
    borderColor: RED,
    "& .MuiButton-label": {
      color: RED,
    },
    "&:hover": {
      borderColor: RED,
      color: RED,
    },
    "&.Mui-disabled": {
      borderColor: RED,
    },
    "&.Mui-disabled > .MuiButton-label": {
      color: RED,
    },
  },
});

export const useCommonMainButtonsStyles = makeStyles(() => ({
  root: {
    borderRadius: `10px !important`,
    backgroundColor: (props: ButtonProps) => `${props.background} !important`,
    color: (props: ButtonProps) => `${props.color} !important`,
    "&:hover": {
      backgroundColor: (props: ButtonProps) => `${props.hover} !important`,
    },
  },
  common: {
    height: "60px !important",
    width: "fit-content",
    minWidth: 80,
    ["@media screen and (max-width: 850px)"]: {
      height: "50px !important",
    },
    ["@media screen and (max-width: 550px), screen and (max-height: 650px)"]: {
      padding: "0px 12px !important",
      height: "40px !important",
    },
    ["@media screen and (max-width: 340px), screen and (max-height: 370px)"]: {
      padding: "0px 10px !important",
      height: "32px !important",
    },
    ["@media screen and (max-height: 290px)"]: {
      height: "30px !important",
    },
    "& .MuiButton-label": {
      fontSize: 18,
      fontWeight: 600,
      ["@media screen and (max-width: 1200px), screen and (max-height: 900px)"]:
        {
          fontSize: 17,
        },
      ["@media screen and (max-width: 850px)"]: {
        fontSize: 18,
      },
      ["@media screen and (max-width: 550px), screen and (max-height: 650px)"]:
        {
          fontSize: 16,
        },
      ["@media screen and (max-width: 400px), screen and (max-height: 320px)"]:
        {
          fontSize: 12,
        },
    },
  },
}));

export const useWhiteBorderTextArea = makeStyles({
  root: {
    width: "100%",
    background: "none",
    border: "2px solid",
    borderColor: COLOR.WHITE,
    outline: "none",
    fontFamily: "inherit",
    color: "white",
    padding: 8,
    fontSize: 21,
  },
});

export const useYellowSelectStyles = makeStyles(() => ({
  root: {
    height: "40px !important",
    width: "150px !important",
    backgroundColor: `${YELLOW} !important`,
    textAlign: "center",
    textAlignLast: "center",
    verticalAlign: "center",
    display: "flex",
    alignItems: "center",
  },
}));

export const useSelectPaperStyles = makeStyles({
  root: {
    marginTop: 12,
  },
});

export const useBlueTextColorStyles = makeStyles(() => ({
  root: {
    height: 68,
    color: "#0F1E4A",
    "& .MuiSvgIcon-root": {
      color: `${BLUE} !important`,
      marginRight: 15,
    },
  },
}));

export const useProfessionalActionSelectStyles = makeStyles(() => ({
  root: {
    height: 36,
    lineHeight: 1.75,
  },
}));

export const useProfessionalActionInputStyles = makeStyles(() => ({
  root: {
    height: "40px !important",
    color: `${BLUE} !important`,
    backgroundColor: `${YELLOW} !important`,
    "& .MuiSvgIcon-root": {
      color: `${BLUE} !important`,
      paddingRight: 4,
    },
  },
}));

export const useYellowInputStyles = makeStyles(() => ({
  root: {
    height: 68,
    border: `2px solid ${YELLOW} !important`,
    color: `${YELLOW} !important`,
    backgroundColor: `${BLUE} !important`,
    "& .MuiSvgIcon-root": {
      color: `${YELLOW} !important`,
      marginRight: 15,
    },
  },
}));

export const useTransparentSelectStyles = makeStyles(() => ({
  root: {
    height: 68,
    color: `white !important`,
    backgroundColor: `${BLUE} !important`,
    "& .MuiSvgIcon-root": {
      color: `${YELLOW} !important`,
    },
    "& :focus": { backgroundColor: BLUE },
  },
}));

export const useNextButtonStyles = makeStyles({
  root: {
    fontWeight: "normal",
    height: 60,
    width: 160,
  },
});

export const useBoldStyles = makeStyles({
  root: {
    fontWeight: "bold",
  },
});

export const useLargeIcon = makeStyles({
  root: {
    width: 128,
    height: 128,
  },
});

export const useDisabledStyles = makeStyles({
  root: {
    opacity: 0.35,
    cursor: "not-allowed",
  },
});

export const useDividerStyles = makeStyles({
  root: {
    height: 2,
  },
});

export const useMessagesInputStyles = makeStyles({
  root: {
    width: "100% !important",
    height: `48px !important`,
    background: "none !important",
    border: "1px solid !important",
    borderRadius: "10px !important",
    borderWidth: "2px !important",
    borderColor: `${LIGHT_BLUE} !important`,
    color: "white !important",
    padding: "8px 9px 8px 6px !important",
    fontSize: "19px !important",
    "& .MuiInputBase-input::placeholder": {
      color: "white !important",
      opacity: "1 !important",
      fontSize: "19px !important",
    },
  },
});

export const useSmallIconStyles = makeStyles({
  smallIcon: {
    width: 64,
    height: 64,
  },
});

export const useMenuItemStyles = makeStyles({
  menuItem: {
    cursor: "pointer",
  },
  menuItemButtonText: {
    fontSize: 18,
    fontWeight: "normal",
  },
});

export const useLandingButtonStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#7DF4A6",
    borderRadius: 10,
    top: 32,
    height: 60,
    width: 280,
    "& .MuiButton-label": {
      fontWeight: "normal",
      fontSize: 24,
    },
    "&:hover": {
      backgroundColor: "#7DF4A6 ",
    },
  },
}));

export const usePointerStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
  },
}));

export const useIconStyles = makeStyles({
  root: {
    fontSize: "48px",
    fill: "white",
  },
});

export const useRadioStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      width: 48,
      height: 48,
      fill: YELLOW,
    },
  },
});
