import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  LoadingTypography,
  HeaderDropdown,
} from "../../../../../../../common/components";
import { BackButton } from "../../../../../../../common/elements/BackButton";
import { useNavigationHandlers } from "../../../../../../../common/hooks/navigation";
import { useProjectQuery } from "../../../../../../../common/hooks/projects/project/useProjectQuery";
import { usePastProjectsOptions } from "../../../../../../bids/hooks/usePastProjectOptions";

export const PortfolioProjectHeader: React.FC = () => {
  const navigate = useNavigate();
  const { professionalId, projectId } = useParams();
  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: pastProjects, isLoading: isLoadingPastProjects } =
    usePastProjectsOptions(professionalId);

  const isLoading = isLoadingProject || isLoadingPastProjects;

  const collectionConfig = {
    type: `professionals/${professionalId}/portfolio/projects`,
    items: pastProjects,
  };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingPastProjects,
    projectId
  );

  const onBackHandler = useCallback(() => {
    navigate(`/professionals/${professionalId}`, { replace: true });
  }, [navigate, professionalId]);

  const handleSelectCurrentPastProject = useCallback(
    (index: number) => {
      if (!pastProjects) return;

      navigate(
        `/professionals/${professionalId}/portfolio/projects/${pastProjects[index]._id}`
      );
    },
    [navigate, pastProjects, professionalId]
  );

  return (
    <Box pt={10}>
      <BackButton onClick={onBackHandler} backLabel="Back To Profile" />
      <Box width="40%" pt={10}>
        <Box pb={2}>
          <LoadingTypography
            typographyProps={{
              variant: "subtitle2",
            }}
          >
            Portfolio Project ({index + 1} of {pastProjects?.length})
          </LoadingTypography>
        </Box>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={projectId}
          options={pastProjects}
          onSelect={handleSelectCurrentPastProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>
    </Box>
  );
};
