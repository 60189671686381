import { useMutation, UseMutationOptions } from "react-query";
import { repostJobPost } from "../../../../../api-services/job-posts/job-post/repostJobPost";

export const useJobPostRepostMutation = (
  options?: UseMutationOptions<unknown, unknown, unknown>
) => {
  return useMutation(
    async (jobPostId: string) => {
      const { data } = await repostJobPost(jobPostId);
      return data;
    },
    {
      ...options,
    }
  );
};
