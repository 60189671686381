import { useMemo } from "react";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";

export const useJobPackages = () => {
  const { data: metadata } = useMetadataQuery();

  const daysPassedSinceFreeTrialStarted = useMemo(() => {
    if (metadata?.freeTrial?.startedAt) {
      const diffTime =
        new Date().getTime() - new Date(metadata.freeTrial.startedAt).getTime();

      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    return 10;
  }, [metadata?.freeTrial?.startedAt]);

  const isEligibleForFreeTrial = useMemo(() => {
    return !metadata?.freeTrial?.startedAt;
  }, [metadata?.freeTrial?.startedAt]);

  const isFreeTrialExpired = useMemo(() => {
    return (
      daysPassedSinceFreeTrialStarted >= 10 || metadata?.freeTrial?.isExpired
    );
  }, [daysPassedSinceFreeTrialStarted, metadata?.freeTrial?.isExpired]);

  const remainingFreeTrialJobPostsCredits =
    metadata?.freeTrial?.freeTrialJobPosts ?? 0;

  const jobsPackagesCredits = metadata?.jobPackages?.jobPostsCredits ?? 0;

  const isFreeTrialOngoing =
    !isFreeTrialExpired && remainingFreeTrialJobPostsCredits > 0;

  return {
    isEligibleForFreeTrial,
    daysPassedSinceFreeTrialStarted,
    isFreeTrialExpired,
    isFreeTrialOngoing,
    remainingFreeTrialJobPostsCredits,
    jobPostsCredits: remainingFreeTrialJobPostsCredits + jobsPackagesCredits,
  };
};
