import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Field, FormikValues } from "formik";
import { noop } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { COLOR, RED, YELLOW } from "../../../../../themes/components/utils";
import {
  HeaderDropdown,
  Option,
} from "../../../../common/components/HeaderDropdown";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { CustomInput, CustomTextArea } from "../../../../common/elements";
import { useRequiredFieldLabel } from "../../../../common/hooks/utils";
import { RadioWithInput } from "../../utils";

type JobPostSetUpFormFieldsProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  handleUpdateSkills: (newSkills: string[]) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  onPreview?: () => void;
  onNext?: () => void;
  isValid?: boolean;
};

const useStyles = makeStyles(() => ({
  requiredLocationRadio: {
    "& .MuiSvgIcon-root": {
      fill: (props: any) => (props.jobLocation ? YELLOW : "red"),
      width: 32,
      height: 32,
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          width: 26,
          height: 26,
        },
    },
  },
  requiredJobLevelRadio: {
    "& .MuiSvgIcon-root": {
      fill: (props: any) => (props.jobLevel ? YELLOW : "red"),
      width: 32,
      height: 32,
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          width: 26,
          height: 26,
        },
    },
  },
  requiredJobTypeRadio: {
    "& .MuiSvgIcon-root": {
      fill: (props: any) => (props.jobType ? YELLOW : "red"),
      width: 32,
      height: 32,
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          width: 26,
          height: 26,
        },
    },
  },
  radio: {
    "& .MuiSvgIcon-root": {
      fill: YELLOW,
      width: 32,
      height: 32,
      ["@media screen and (max-width: 600px), screen and (max-height: 500px)"]:
        {
          width: 26,
          height: 26,
        },
    },
  },
  input: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: COLOR.WHITE,
    borderRadius: 2,
    marginRight: 12,
    color: COLOR.WHITE,
    fontSize: 16,
    width: 80,
    height: 32,
    "& input": {
      padding: "0px 12px",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  inputColor: {
    borderColor: YELLOW,
    color: "white",
  },
  requiredInputColor: {
    borderColor: "red",
    color: "white",
  },
  inputWidthSmall: {
    width: 68,
  },
  leftLabel: {
    paddingRight: 12,
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
  jobDetails: {
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "35px",
  },
  dropdownIcon: {
    transform: "scale(.8)",
    height: 32,
    marginRight: "10px",
    marginLeft: "10px",
  },
  boxContainerReset: {
    marginLeft: "16px",
    borderRadius: 2,
    borderColor: "white",
    fontSize: 12,
    height: 32,
  },
  boxContainer: {
    border: `2px solid${YELLOW}`,
    height: 32,
    width: 120,
  },
  mainBoxContainer: {
    height: 32,
    marginLeft: "16px",
  },
  longBoxContainer: {
    border: `2px solid${YELLOW}`,
    height: 32,
    width: 140,
  },
  dropdownTitle: {
    fontWeight: 400,
    fontSize: 18,
  },
}));

export const dummyCurrencies: Option[] = [
  { id: "EUR", title: "EUR" },
  { id: "USD", title: "USD" },
  { id: "RON", title: "RON" },
];

export const dummyTimeIntervals: Option[] = [
  { id: "week", title: "week" },
  { id: "month", title: "month" },
  { id: "quarter", title: "quarter" },
];

export const JobPostSetUpFormFields: React.FC<JobPostSetUpFormFieldsProps> = ({
  handleBlur,
  handleChange,
  handleUpdateSkills,
  setFieldValue,
  errors,
  values,
}) => {
  const styles = useStyles({
    jobLevel: values.jobLevel,
    jobType: values.jobType,
    jobLocation: values.jobLocation,
  });

  const requiredFieldLabel = useRequiredFieldLabel();

  const [ratePerHourSelectedCurrency, setRatePerHourSelectedCurrency] =
    useState<string>("EUR");
  const [salaryPerYearSelectedCurrency, setSalaryPerYearSelectedCurrency] =
    useState<string>("EUR");

  const [selectedTime, setSelectedTime] = useState<string>("week");

  const handleRatePerHourCurrencySelect = useCallback(
    (index: number) => {
      const currencies = ["EUR", "USD", "RON"];
      setFieldValue("ratePerHour.currency", currencies[index]);
      setRatePerHourSelectedCurrency(currencies[index]);
    },
    [setFieldValue]
  );

  const handleSalaryPerYearCurrencySelect = useCallback(
    (index: number) => {
      const currencies = ["EUR", "USD", "RON"];
      setFieldValue("salaryPerYear.currency", currencies[index]);
      setSalaryPerYearSelectedCurrency(currencies[index]);
    },
    [setFieldValue]
  );

  const handleTimeInterval = (index: number) => {
    const intervals = ["week", "month", "quarter"];
    setFieldValue("daysRequiredInOffice.timeframe", intervals[index]);
    setSelectedTime(intervals[index]);
  };

  useEffect(() => {
    if (values?.ratePerHour?.currency) {
      const index = dummyCurrencies.findIndex(
        (currency) => currency.id === values?.ratePerHour?.currency
      );
      handleRatePerHourCurrencySelect(index);
    }

    if (values?.salaryPerYear?.currency) {
      const index = dummyCurrencies.findIndex(
        (currency) => currency.id === values?.salaryPerYear?.currency
      );
      handleSalaryPerYearCurrencySelect(index);
    }
  }, [
    handleRatePerHourCurrencySelect,
    handleSalaryPerYearCurrencySelect,
    values?.ratePerHour?.currency,
    values?.salaryPerYear?.currency,
  ]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      gridRowGap={47}
      py={10}
    >
      <Typography className={styles.jobDetails} variant="subtitle1">
        Job Details
      </Typography>
      <Field
        component={CustomInput}
        placeholder={requiredFieldLabel("Job Title")}
        name="jobTitle"
        value={values.jobTitle}
        error={errors.jobTitle}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Box>
        <Field
          component={CustomInput}
          placeholder={"Company Name"}
          name="companyName"
          value={values.companyName}
          error={errors.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <FormControlLabel
          control={<Checkbox />}
          label="Post as Confidential"
          name="isConfidential"
          onChange={(event, checked) => {
            setFieldValue?.("isConfidential", checked);
          }}
          checked={values.isConfidential}
        />
      </Box>
      <Field
        component={CustomInput}
        placeholder={"Company Web Address"}
        name="companyUrl"
        value={values.companyUrl}
        error={errors.companyUrl}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Field
        component={CustomTextArea}
        placeholder={"About the Company"}
        name="aboutCompany"
        value={values.aboutCompany}
        error={errors.aboutCompany}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Field
        component={CustomTextArea}
        placeholder={requiredFieldLabel("Job Description")}
        name="description"
        value={values.description}
        error={errors.description}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Field
        component={CustomTextArea}
        placeholder={"Responsibilities and Duties"}
        name="responsibilities"
        value={values.responsibilities}
        error={errors.responsibilities}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Box>
        <Typography variant="subtitle2">
          {requiredFieldLabel("Job Level")}
        </Typography>
        <RadioGroup
          aria-required
          name="jobLevel"
          onChange={handleChange}
          value={values.jobLevel}
        >
          <Box display="flex" gridRowGap={12}>
            <FormControlLabel
              value="intern"
              control={
                <Radio
                  className={styles.requiredJobLevelRadio}
                  data-cy="intern"
                />
              }
              label={<Typography>Intern</Typography>}
            />
            <FormControlLabel
              value="junior"
              control={
                <Radio
                  className={styles.requiredJobLevelRadio}
                  data-cy="junior"
                />
              }
              label={<Typography>Junior</Typography>}
            />
            <FormControlLabel
              value="mid"
              control={
                <Radio className={styles.requiredJobLevelRadio} data-cy="mid" />
              }
              label={<Typography>Mid-Level</Typography>}
            />
            <FormControlLabel
              value="senior"
              control={
                <Radio
                  className={styles.requiredJobLevelRadio}
                  data-cy="senior"
                />
              }
              label={<Typography>Senior</Typography>}
            />
            <FormControlLabel
              value="executive"
              control={
                <Radio
                  className={styles.requiredJobLevelRadio}
                  data-cy="executive"
                />
              }
              label={<Typography>Executive</Typography>}
            />
          </Box>
        </RadioGroup>
        {values.jobLevel !== "intern" && (
          <Box display="flex" flexDirection="column" gridRowGap={12}>
            <Typography variant="subtitle2">Years of Experience</Typography>
            <FormControlLabel
              control={
                <RadioWithInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={noop}
                  colorRadio
                  colorInput
                  name={`yearsOfExperience`}
                  minValue={values?.yearsOfExperience.estimate.min}
                  maxValue={values?.yearsOfExperience.estimate.max}
                  checked={values?.yearsOfExperience.type === "estimate"}
                  radioValue="estimate"
                  isMinMaxInput
                  leftLabel="Between "
                  betweenInputsLabel=" and "
                  minError={errors?.yearsOfExperience?.estimate?.min}
                  maxError={errors?.yearsOfExperience?.estimate?.max}
                />
              }
              label="year(s)"
              style={{ width: "max-content" }}
            />
            <FormControlLabel
              control={
                <RadioWithInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={noop}
                  colorRadio
                  checked={values?.yearsOfExperience.type === "fixed"}
                  colorInput
                  name={`yearsOfExperience`}
                  radioValue="fixed"
                  value={values?.yearsOfExperience.fixed}
                  error={errors?.yearsOfExperience?.fixed}
                />
              }
              label=" and above"
              style={{ width: "max-content" }}
            />
          </Box>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle2">
          {requiredFieldLabel("Job Type")}
        </Typography>
        <RadioGroup
          onChange={handleChange}
          defaultValue={values.jobType}
          name="jobType"
          value={values.jobType}
        >
          <Box display="flex" gridRowGap={12}>
            <FormControlLabel
              value="full-time"
              control={
                <Radio
                  className={styles.requiredJobTypeRadio}
                  data-cy="full-time"
                />
              }
              label={<Typography>Full Time</Typography>}
            />
            <FormControlLabel
              value="contract, 40hrs/wk"
              control={
                <Radio
                  className={styles.requiredJobTypeRadio}
                  data-cy="contract"
                />
              }
              label={<Typography>Contract, 40hrs/wk</Typography>}
            />
            <FormControlLabel
              value="temp-to-perm"
              control={
                <Radio
                  className={styles.requiredJobTypeRadio}
                  data-cy="temp-to-perm"
                />
              }
              label={<Typography>Temp-to-perm</Typography>}
            />
            <FormControlLabel
              value="part-time"
              control={
                <Radio
                  className={styles.requiredJobTypeRadio}
                  data-cy="part-time"
                />
              }
              label={<Typography>Part-time</Typography>}
            />
          </Box>
        </RadioGroup>
        <Box pt={4}>
          {values.jobType === "full-time" && (
            <Box display="flex" flexDirection="column" gridRowGap={12} pt={3}>
              <Typography variant="subtitle2">Salary Per Year</Typography>
              <FormControlLabel
                control={
                  <>
                    <RadioWithInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={noop}
                      colorRadio
                      colorInput
                      name={`salaryPerYear`}
                      minValue={values?.salaryPerYear.estimate.min}
                      maxValue={values?.salaryPerYear.estimate.max}
                      checked={values?.salaryPerYear.type === "estimate"}
                      radioValue="estimate"
                      isMinMaxInput
                      leftLabel="Between "
                      betweenInputsLabel=" and "
                      max={1000000}
                      minError={errors?.salaryPerYear?.estimate?.min}
                      maxError={errors?.salaryPerYear?.estimate?.min}
                    />
                    {values?.salaryPerYear.type === "estimate" && (
                      <>
                        <Typography
                          className={styles.dropdownTitle}
                          variant="subtitle2"
                        >
                          Select Currency
                        </Typography>
                        <HeaderDropdown
                          selectedOptionId={salaryPerYearSelectedCurrency}
                          options={dummyCurrencies}
                          onSelect={handleSalaryPerYearCurrencySelect}
                          variant="body1"
                          fontWeight={400}
                          boxContainerClassName={styles.boxContainerReset}
                          dropdownIcon={styles.dropdownIcon}
                          mainContainer={styles.boxContainer}
                          mainBox={styles.mainBoxContainer}
                          minWidth="80px"
                        />
                      </>
                    )}
                  </>
                }
                label=""
                style={{ width: "max-content" }}
              />
              <FormControlLabel
                control={
                  <>
                    <RadioWithInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={noop}
                      colorRadio
                      checked={values?.salaryPerYear.type === "fixed"}
                      colorInput
                      leftLabel="Exactly "
                      name={`salaryPerYear`}
                      radioValue="fixed"
                      value={values?.salaryPerYear.fixed}
                      error={errors?.salaryPerYear?.fixed}
                    />
                    {values?.salaryPerYear.type === "fixed" && (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={styles.dropdownTitle}
                        >
                          Select Currency
                        </Typography>
                        <HeaderDropdown
                          selectedOptionId={salaryPerYearSelectedCurrency}
                          options={dummyCurrencies}
                          onSelect={handleSalaryPerYearCurrencySelect}
                          variant="body1"
                          fontWeight={400}
                          boxContainerClassName={styles.boxContainerReset}
                          dropdownIcon={styles.dropdownIcon}
                          mainContainer={styles.boxContainer}
                          mainBox={styles.mainBoxContainer}
                          minWidth="130px"
                        />
                      </>
                    )}
                  </>
                }
                label=""
                style={{ width: "max-content" }}
              />
            </Box>
          )}
          {(values.jobType === "contract, 40hrs/wk" ||
            values.jobType === "part-time" ||
            values.jobType === "temp-to-perm") && (
            <Box
              display="flex"
              flexDirection="column"
              gridRowGap={12}
              width="fit-content"
            >
              <Box display="flex" gridColumnGap={12} alignItems="center">
                {values.jobType === "part-time" && (
                  <>
                    <Box width={80}>
                      <Field
                        size="medium"
                        component={Input}
                        disableUnderline
                        className={`${styles.input} ${
                          values.hoursPerWeek
                            ? styles.inputColor
                            : styles.requiredInputColor
                        }  ${styles.inputWidthSmall}`}
                        name="hoursPerWeek"
                        required
                        value={values.hoursPerWeek}
                        error={errors.hoursPerWeek}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="hoursPerWeek"
                        type="number"
                      />
                    </Box>
                    <Typography variant="body1">
                      {requiredFieldLabel("hours per week")}
                    </Typography>
                  </>
                )}
              </Box>
              <Typography variant="subtitle2">Rate Per Hour</Typography>
              <FormControlLabel
                control={
                  <>
                    <RadioWithInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={noop}
                      colorRadio
                      colorInput
                      name={`ratePerHour`}
                      minValue={values?.ratePerHour?.estimate?.min ?? 0}
                      maxValue={values?.ratePerHour?.estimate?.max ?? 999}
                      checked={values?.ratePerHour?.type === "estimate"}
                      radioValue="estimate"
                      isMinMaxInput
                      leftLabel="Between "
                      betweenInputsLabel=" and "
                      minError={errors?.ratePerHour?.estimate?.min}
                      maxError={errors?.ratePerHour?.estimate?.min}
                    />
                    {values?.ratePerHour?.type === "estimate" && (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={styles.dropdownTitle}
                        >
                          Select Currency
                        </Typography>
                        <HeaderDropdown
                          selectedOptionId={ratePerHourSelectedCurrency}
                          options={dummyCurrencies}
                          onSelect={handleRatePerHourCurrencySelect}
                          variant="body1"
                          fontWeight={400}
                          boxContainerClassName={styles.boxContainerReset}
                          dropdownIcon={styles.dropdownIcon}
                          mainContainer={styles.boxContainer}
                          mainBox={styles.mainBoxContainer}
                          minWidth="130px"
                        />
                      </>
                    )}
                  </>
                }
                label=""
                style={{ width: "max-content" }}
              />
              <FormControlLabel
                control={
                  <>
                    <RadioWithInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={noop}
                      colorRadio
                      checked={values?.ratePerHour?.type === "fixed"}
                      colorInput
                      leftLabel="Exactly "
                      name={`ratePerHour`}
                      radioValue="fixed"
                      value={values?.ratePerHour?.fixed}
                      error={errors?.ratePerHour?.fixed}
                    />
                    {values?.ratePerHour?.type === "fixed" && (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={styles.dropdownTitle}
                        >
                          Select Currency
                        </Typography>
                        <HeaderDropdown
                          selectedOptionId={ratePerHourSelectedCurrency}
                          options={dummyCurrencies}
                          onSelect={handleRatePerHourCurrencySelect}
                          variant="body1"
                          fontWeight={400}
                          boxContainerClassName={styles.boxContainerReset}
                          dropdownIcon={styles.dropdownIcon}
                          mainContainer={styles.boxContainer}
                          mainBox={styles.mainBoxContainer}
                          minWidth="130px"
                        />
                      </>
                    )}
                  </>
                }
                label=""
                style={{ width: "max-content" }}
              />
              {values.jobType === "temp-to-perm" && (
                <Box display="flex" alignItems="center">
                  <Box width={80}>
                    <Field
                      size="medium"
                      component={Input}
                      className={`${styles.input} ${
                        values.monthsBeforeTransfer
                          ? styles.inputColor
                          : styles.requiredInputColor
                      } ${styles.inputWidthSmall}`}
                      placeholder={""}
                      disableUnderline
                      name="monthsBeforeTransfer"
                      value={values.monthsBeforeTransfer}
                      error={errors.monthsBeforeTransfer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="monthsBeforeTransfer"
                    />
                  </Box>
                  <Typography variant="body1">
                    {requiredFieldLabel("months before transfer to perm")}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2">
          {requiredFieldLabel("Job Location")}
        </Typography>
        <RadioGroup
          defaultValue={values.jobLocation}
          onChange={handleChange}
          name="jobLocation"
          value={values.jobLocation}
        >
          <Box display="flex" gridRowGap={12}>
            <FormControlLabel
              value="remote"
              control={
                <Radio
                  className={styles.requiredLocationRadio}
                  data-cy="remote"
                />
              }
              label={<Typography>Remote Job</Typography>}
            />
            <FormControlLabel
              value="on-site"
              control={
                <Radio
                  className={styles.requiredLocationRadio}
                  data-cy="on-site"
                />
              }
              label={<Typography>On-site only</Typography>}
            />
            <FormControlLabel
              value="hybrid"
              control={
                <Radio
                  className={styles.requiredLocationRadio}
                  data-cy="hybrid"
                />
              }
              label={<Typography>Hybrid required</Typography>}
            />
          </Box>
        </RadioGroup>
        {(values.jobLocation === "on-site" ||
          values.jobLocation === "hybrid") && (
          <Box pt={6}>
            <Box width={800}>
              <Field
                size="medium"
                component={CustomInput}
                placeholder={requiredFieldLabel("Location")}
                name="location"
                value={values.location}
                error={errors.location}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
            {values.jobLocation === "hybrid" && (
              <Box pt={2.5}>
                <Typography variant="subtitle2">
                  Days Required In Office
                </Typography>
                <Box display="flex" flexDirection="column" gridRowGap={12}>
                  <FormControlLabel
                    control={
                      <>
                        <RadioWithInput
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={noop}
                          colorRadio
                          colorInput
                          name={`daysRequiredInOffice`}
                          minValue={values?.daysRequiredInOffice.estimate.min}
                          maxValue={values?.daysRequiredInOffice.estimate.max}
                          checked={
                            values?.daysRequiredInOffice.type === "estimate"
                          }
                          radioValue="estimate"
                          isMinMaxInput
                          leftLabel="Between "
                          betweenInputsLabel=" and "
                          minError={errors?.daysRequiredInOffice?.estimate?.min}
                          maxError={errors?.daysRequiredInOffice?.estimate?.min}
                        />
                        {values?.daysRequiredInOffice.type === "estimate" && (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={styles.dropdownTitle}
                            >
                              Per
                            </Typography>
                            <HeaderDropdown
                              selectedOptionId={
                                values.daysRequiredInOffice.timeframe
                              }
                              options={dummyTimeIntervals}
                              onSelect={handleTimeInterval}
                              variant="body1"
                              fontWeight={400}
                              boxContainerClassName={`${styles.boxContainerReset}  per-dropdown`}
                              dropdownIcon={styles.dropdownIcon}
                              mainContainer={styles.longBoxContainer}
                              mainBox={styles.mainBoxContainer}
                              minWidth="200px"
                            />
                          </>
                        )}
                      </>
                    }
                    label=""
                    style={{ width: "max-content" }}
                  />

                  <FormControlLabel
                    control={
                      <>
                        <RadioWithInput
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={noop}
                          colorRadio
                          checked={
                            values?.daysRequiredInOffice.type === "fixed"
                          }
                          colorInput
                          leftLabel="Exactly "
                          name={`daysRequiredInOffice`}
                          radioValue="fixed"
                          value={values?.daysRequiredInOffice.fixed}
                          error={errors?.daysRequiredInOffice?.fixed}
                        />
                        {values?.daysRequiredInOffice.type === "fixed" && (
                          <>
                            <Typography
                              variant="subtitle2"
                              className={styles.dropdownTitle}
                            >
                              Per
                            </Typography>
                            <HeaderDropdown
                              selectedOptionId={
                                values.daysRequiredInOffice.timeframe
                              }
                              options={dummyTimeIntervals}
                              onSelect={handleTimeInterval}
                              variant="body1"
                              fontWeight={400}
                              boxContainerClassName={`${styles.boxContainerReset} per-second-dropdown`}
                              dropdownIcon={styles.dropdownIcon}
                              mainContainer={styles.longBoxContainer}
                              mainBox={styles.mainBoxContainer}
                              minWidth="130px"
                            />
                          </>
                        )}
                      </>
                    }
                    label=""
                    style={{ width: "max-content" }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle2">Education Level</Typography>
        <RadioGroup
          defaultValue="no-education"
          onChange={handleChange}
          name="educationLevel"
          value={values.educationLevel}
        >
          <Box display="flex" gridRowGap={12}>
            <FormControlLabel
              value="no-education"
              control={
                <Radio className={styles.radio} data-cy="no-education" />
              }
              label={<Typography>None specified</Typography>}
            />
            <FormControlLabel
              value="college"
              control={<Radio className={styles.radio} data-cy="college" />}
              label={<Typography>College degree</Typography>}
            />
            <FormControlLabel
              value="post-college"
              control={
                <Radio className={styles.radio} data-cy="post-college" />
              }
              label={<Typography>Post college education</Typography>}
            />
          </Box>
        </RadioGroup>
        {values.educationLevel === "college" && (
          <Box pt={6}>
            <Box width={800}>
              <Field
                size="medium"
                component={CustomInput}
                placeholder={
                  "Additional Details, ex: BS, Computer Science or BFA, Design"
                }
                name="collegeAdditionalInformation"
                value={values.collegeAdditionalInformation}
                error={errors.collegeAdditionalInformation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
        )}
        {values.educationLevel === "post-college" && (
          <Box pt={6}>
            <Box width={800}>
              <Field
                size="medium"
                component={CustomInput}
                placeholder={
                  "Additional Details, ex: MS, Computer Science or MS, HCI"
                }
                name="postCollegeAdditionalInformation"
                value={values.postCollegeAdditionalInformation}
                error={errors.postCollegeAdditionalInformation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
        )}
      </Box>

      <SkillsInput
        handleChange={handleUpdateSkills}
        initialSkills={values?.skills?.map(
          (skill: { name: string }) => skill.name
        )}
      />

      <Field
        component={CustomTextArea}
        placeholder={"Additional Qualifications and Certifications"}
        name="qualifications"
        value={values.qualifications}
        error={errors.qualifications}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Field
        component={CustomTextArea}
        placeholder={"Perks and Benefits"}
        name="perks"
        value={values.perks}
        error={errors.perks}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Box>
        <Typography variant="subtitle2">Status Requirement</Typography>
        <RadioGroup
          defaultValue={values.statusRequirement}
          onChange={handleChange}
          name="statusRequirement"
          value={values.statusRequirement}
        >
          <Box display="flex" gridRowGap={12}>
            <FormControlLabel
              value="sponsorship"
              control={<Radio className={styles.radio} data-cy="sponsorship" />}
              label={<Typography>Sponsorship offered</Typography>}
            />
            <FormControlLabel
              value="noSponsorship"
              control={
                <Radio className={styles.radio} data-cy="noSponsorship" />
              }
              label={<Typography>Sponsorship not offered</Typography>}
            />
            <FormControlLabel
              value="usCitizen"
              control={<Radio className={styles.radio} data-cy="usCitizen" />}
              label={<Typography>US Citizen or Green Card Required</Typography>}
            />
            <FormControlLabel
              value="other"
              control={<Radio className={styles.radio} data-cy="other" />}
              label={<Typography>Other</Typography>}
            />
            <FormControlLabel
              value="none"
              control={<Radio className={styles.radio} data-cy="none" />}
              label={<Typography>None Specified</Typography>}
            />
          </Box>
        </RadioGroup>
      </Box>

      {values.statusRequirement === "other" && (
        <Field
          component={CustomInput}
          placeholder={"Other Status Requirement"}
          name="other"
          value={values.other}
          error={errors.other}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}

      <Field
        component={CustomTextArea}
        placeholder={"Additional Notes"}
        name="notes"
        value={values.notes}
        error={errors.notes}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <Divider />

      <Box>
        <Typography variant="subtitle2">
          {requiredFieldLabel("Contact Information")}
        </Typography>
        <Typography variant="subtitle2">
          How would you like the applicant to contact you?
        </Typography>
        <RadioGroup
          defaultValue={values.contact}
          onChange={handleChange}
          name="contact"
          value={values.contact}
        >
          <Box display="flex" gridRowGap={12}>
            <FormControlLabel
              value="online"
              control={<Radio className={styles.radio} data-cy="online" />}
              label={
                <Typography>
                  Through this site (applicants will upload resumes)
                </Typography>
              }
            />
            <FormControlLabel
              value="external"
              control={<Radio className={styles.radio} data-cy="external" />}
              label={<Typography>Apply on external website</Typography>}
            />
            <FormControlLabel
              value="email"
              control={<Radio className={styles.radio} data-cy="email" />}
              label={<Typography>Apply by email</Typography>}
            />
          </Box>
        </RadioGroup>
        {values.contact === "external" && (
          <Box pt={6}>
            <Box width={800}>
              <Field
                size="medium"
                component={CustomInput}
                placeholder={requiredFieldLabel("Enter Website Address")}
                name="externalWebsite"
                value={values.externalWebsite}
                error={errors.externalWebsite}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
        )}
        {values.contact === "email" && (
          <Box pt={6}>
            <Box width={800}>
              <Field
                size="medium"
                component={CustomInput}
                placeholder={requiredFieldLabel("Enter Email Address")}
                name="externalEmail"
                value={values.externalEmail}
                error={errors.externalEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
