import { Box, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { ShowImages, LoadingTypography } from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../common/components/SkillsOutput";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { Description } from "../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../job-requests/search-job-requests-list/job-request-item/content";
import { Collaboration } from "../sections";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const skills = useMemo(() => {
    if (!project?.skills || isLoadingProject) {
      return [];
    }

    return project?.skills.map((skill) => skill.name);
  }, [isLoadingProject, project?.skills]);

  const hasImages = Boolean(project?.images?.length ?? 0 > 0);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="baseline"
        gridColumnGap={40}
      >
        <LoadingTypography
          width={400}
          isLoading={isLoadingProject}
          typographyProps={{
            variant: "subtitle1",
            className: boldStyles.root,
          }}
        >
          {project?.title}
        </LoadingTypography>
        {project?.createdAt && (
          <Typography variant="body2">
            Started{" "}
            {format(new Date(project?.createdAt), formatNominativeMonth)}
          </Typography>
        )}
        {project?.client && (
          <Box display="flex" gridColumnGap={10} width="15%">
            <Typography variant="body2">
              Posted by{" "}
              {`${project?.client?.accountInformation?.firstName} ${project?.client?.accountInformation?.lastName}`}
            </Typography>
          </Box>
        )}
      </Box>
      <Box py={2.5}>
        <Divider />
      </Box>
      <Description description={project?.description} />
      <Box pt={2.5}>
        <AvailabilityCostBids
          budget={project?.jobRequest?.budget}
          fixedProjectFunding={project?.jobRequest?.fixedProjectFunding}
        />
      </Box>
      {Boolean(skills.length) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={project?.images ?? []} />{" "}
        </Box>
      )}
      <Box py={2.5}>
        <Divider />
      </Box>
      <Collaboration />
    </Box>
  );
};
