import React from "react";

export const GearIcon: React.FC = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 284 284">
      <path
        fill="#FFE462"
        d="M284,153.5v-23h-19.5c-1.3-13.8-4.9-27.3-10.7-39.9l16.8-9.7l-11.5-19.9l-16.9,9.7c-8-11.3-17.9-21.1-29.2-29.2
      l9.7-16.9l-19.9-11.5l-9.7,16.9c-12.6-5.8-26.1-9.4-39.8-10.7V0h-23v19.5c-13.8,1.3-27.3,4.9-39.8,10.7l-9.7-16.8L61.1,24.8
      l9.7,16.9c-11.3,8-21.1,17.9-29.2,29.2l-16.9-9.7L13.3,80.9l16.8,9.7c-5.8,12.6-9.4,26.1-10.7,39.8H0v23h19.5
      c1.3,13.8,4.9,27.3,10.7,39.9l-16.8,9.7l11.5,19.9l16.9-9.7c8,11.3,17.9,21.1,29.2,29.2l-9.7,16.9l19.9,11.5l9.7-16.9
      c12.6,5.8,26.1,9.4,39.8,10.7V284h23v-19.5c13.8-1.3,27.3-4.9,39.9-10.7l9.7,16.8l19.9-11.5l-9.7-16.9c11.3-8,21.1-17.9,29.2-29.2
      l16.9,9.7l11.5-19.9l-16.9-9.7c5.8-12.6,9.4-26.1,10.7-39.8H284z M223.9,55c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1
      c-2.8,0-5.1-2.3-5.1-5.1S221.1,55,223.9,55z M60.1,55c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1
      S57.3,55,60.1,55z M60.1,229c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1c2.8,0,5.1,2.3,5.1,5.1C65.3,226.7,62.9,229,60.1,229z
      M223.9,229c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1c2.8,0,5.1,2.3,5.1,5.1C229,226.7,226.7,229,223.9,229z M251,142
      c-0.1,60.1-48.8,108.9-109,109h0c-60.2,0-109-48.8-109-109C33,81.8,81.8,33,142,33C202.2,33,251,81.8,251,142
      C251,142,251,142,251,142z"
      />
      <path
        fill="#FFE462"
        d="M110.8,104.3v14.5h23.5v72.9h15.4v-72.9h23.6v-14.5H110.8z M167.3,113h-23.5v72.8h-3.7V113h-23.5v-2.8h50.7V113
      z"
      />
    </svg>
  );
};
