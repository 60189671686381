import { Box, Typography, Divider, Button } from "@material-ui/core";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Message } from "../../../../api-types/job-requests/common";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { Section } from "../../../common/components";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useCurrentJobRequest, useUser } from "../../../state/hooks";
import { MessagesForm } from "../../bids/communication/MessagesForm";
import { useAccountInquiryQuery } from "../hooks/useAccountInquiry";
import { useAddInquiryMessageMutations } from "../hooks/useAddInquiryMessageMutation";
import { useBidByJobRequestId } from "../hooks/useBidByProjectId";
import { useCreateInquiryMutation } from "../hooks/useCreateInquiryMutation";
import { NEW_ID } from "../hooks/useDefaultInquiryPage";
import { BidSection } from "./BidSection";
import { InquiriesCommunicationSkeleton } from "./InquiriesCommunicationSkeleton";

type CommunicationProps = {};

export const Communication: React.FC<CommunicationProps> = () => {
  const { inquiryId } = useParams();
  const boldStyles = useBoldStyles();
  const navigate = useNavigate();

  const [placeBid, setPlaceBid] = useState(false);

  const { data: inquiryData, isLoading: isLoadingInquiry } =
    useAccountInquiryQuery();

  const currentJobRequest = useScopedDowngradedStateValue(
    useCurrentJobRequest()
  );

  const { id, accountType } = useScopedDowngradedStateValue(useUser());

  const { mutate: addInquiryMessage, isLoading: isAddingNew } =
    useAddInquiryMessageMutations();

  const { mutate: createInquiry, isLoading: isCreating } =
    useCreateInquiryMutation();

  const onNewMessageHandler = (newMessage: string) => {
    if (isLoadingInquiry) return;

    const messages: Message[] = [
      {
        content: newMessage,
        from: id ?? "",
        to: inquiryData?.jobRequest.createdBy ?? "",
      },
    ];

    if (inquiryId === NEW_ID && currentJobRequest?._id) {
      createInquiry(
        { jobRequestId: currentJobRequest?._id, messages },
        {
          onSuccess: (inquiry) => {
            if (inquiryId === NEW_ID) {
              navigate(`/inquiries/${inquiry._id}`, { replace: true });
            }
          },
        }
      );
    } else {
      addInquiryMessage({
        messages,
        accountType,
        jobRequestId: inquiryData?.jobRequest._id,
      });
    }
  };

  const messages = useMemo(() => {
    if (isLoadingInquiry || !inquiryData) return [];

    return inquiryData.messages;
  }, [inquiryData, isLoadingInquiry]);

  const accountBidId = useBidByJobRequestId(inquiryData?.jobRequest._id);

  const hasPlaceBidButton = useMemo(
    () => !placeBid && !accountBidId,
    [accountBidId, placeBid]
  );

  const handlePlaceBid = useCallback(() => {
    setPlaceBid(true);
  }, []);

  const handleCancelBid = useCallback(() => {
    setPlaceBid(false);
  }, []);

  const hasBid = useMemo(() => {
    if (accountBidId) {
      handleCancelBid();
    }
    return placeBid || accountBidId;
  }, [accountBidId, handleCancelBid, placeBid]);

  return (
    <Box pb={10}>
      <Section
        isLoading={isLoadingInquiry}
        loadingSkeleton={<InquiriesCommunicationSkeleton />}
      >
        {hasBid ? (
          <BidSection editing={placeBid} onCancel={handleCancelBid} />
        ) : (
          <Fragment>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              pb={2}
              gridColumnGap={8}
            >
              <Typography variant="subtitle1" className={boldStyles.root}>
                Inquiries and Replies
              </Typography>
              {hasPlaceBidButton && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handlePlaceBid}
                >
                  Place Bid
                </Button>
              )}
            </Box>
            <Divider />
            <MessagesForm
              messages={messages}
              height={250}
              onNewMessage={onNewMessageHandler}
              isLoading={isCreating || isAddingNew}
            />
          </Fragment>
        )}
      </Section>
    </Box>
  );
};
