import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { Skeleton } from "@material-ui/lab";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { LIGHT_BLUE, YELLOW } from "../../../themes/components/utils";
import { OptionsDropdown } from "../components";
import { Option } from "../components/HeaderDropdown";

type CardProps = {
  width?: number;
  height?: number;
  select?: boolean;
  onSelect?: (index: number) => void;
  selectedIndex?: number;
  options?: Option[];
  label: string;
  onClick?: () => void;
  isSelected?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: "body1" | "subtitle1" | "subtitle2";
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
}));

export const Card: React.FC<CardProps> = ({
  width,
  height,
  label,
  onSelect,
  onClick,
  isSelected,
  disabled = false,
  isLoading,
  options = [],
  select,
  selectedIndex = 0,
  variant = "body1",
}) => {
  const theme = useTheme();

  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onOptionsDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const onDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSelectHandler = useCallback(
    (index: number) => {
      onSelect?.(index);
      onDropdownClose();
    },
    [onSelect, onDropdownClose]
  );

  const ref = useRef(null);
  const hasSelect = Boolean((options?.length ?? 0) > 1 && select);

  return (
    <Box
      // @ts-ignore
      ref={ref}
      style={
        disabled
          ? {
              pointerEvents: "none",
              opacity: 0.4,
            }
          : {}
      }
      width={width ?? "fit-content"}
      height={height ?? 100}
    >
      {isLoading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height="100%"
          animation="wave"
          classes={{
            wave: styles.root,
          }}
        />
      ) : (
        <StyledCard
          color={theme.palette.primary.main}
          onClick={onClick}
          selected={isSelected}
          data-cy={label.toLowerCase()}
        >
          <Box
            px={6}
            display="flex"
            alignItems="center"
            justifyContent={select ? "space-between" : "center"}
          >
            <Typography
              variant={variant}
              color="inherit"
              noWrap
              style={{
                fontWeight: 600,
              }}
            >
              {label}
            </Typography>
            {hasSelect && (
              <React.Fragment>
                <IconButton edge="end" onClick={onOptionsDropdownClick}>
                  <ExpandMoreRoundedIcon fontSize="large" />
                </IconButton>
                <OptionsDropdown
                  anchorEl={anchorEl}
                  onClose={onDropdownClose}
                  selectedOptionId={options[selectedIndex]?.id}
                  options={options}
                  onOptionSelect={onSelectHandler}
                />
              </React.Fragment>
            )}
          </Box>
        </StyledCard>
      )}
    </Box>
  );
};

const StyledCard = styled(Box)<{
  selected?: boolean;
}>`
  :hover {
    padding-top: 14px;
    border-top: 2px solid;
    border-top-color: ${(props) => props.color};
    border-bottom: 16px solid;
    border-bottom-color: ${(props) => props.color};
    color: ${(props) => props.color};
    border-color: ${(props) => YELLOW};
  }
  border: 2px solid;
  border-color: ${(props) => (props.selected ? props.color : LIGHT_BLUE)};
  padding-top: ${(props) => (props.selected ? "14px" : "0px")};
  border-top: ${(props) => (props.selected ? "2px solid" : "2px solid")};
  border-top-color: ${(props) => (props.selected ? props.color : LIGHT_BLUE)};
  border-bottom: ${(props) => (props.selected ? "16px solid" : "")};
  border-bottom-color: ${(props) => (props.selected ? props.color : "")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.selected ? YELLOW : "white")};
  cursor: pointer;
`;
