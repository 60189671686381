import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { fetchProject } from "../../../../../api-services/projects/project";
import { Project } from "../../../../../api-types/projects";
import { PROJECTS_QUERY_KEY } from "../useProjectsQuery";

export const projectKey = (id: string | null) => {
  return `${PROJECTS_QUERY_KEY}-${id}`;
};

export const useProjectQuery = (id?: string) => {
  const { projectId } = useParams();
  return useQuery<Project, unknown, Project>(
    projectKey(id ?? projectId),
    async () => {
      const { data } = await fetchProject(id ?? projectId);

      return data;
    },
    {
      enabled: Boolean(id ?? projectId),
    }
  );
};

export const useInvalidateProjectQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (id: string) => {
      queryClient.invalidateQueries(projectKey(id));
    },
    [queryClient]
  );
};
