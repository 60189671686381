import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { addInquiryMessage } from "../../../../../api-services/inquiries/inquiry";
import { AddClientMessageVariables } from "../../../../../api-types/job-requests/common";
import { jobRequestsInquiresKey } from "../../../../common/hooks/job-requests/job-request/inquires/useJobRequestInquires";

export const useAddInquiryClientMessageMutations = (
  options?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const queryClient = useQueryClient();
  const { jobRequestId } = useParams();

  return useMutation(
    async (variables: AddClientMessageVariables) => {
      const { id, messages, accountType } = variables;

      const { data } = await addInquiryMessage(id, {
        jobRequestId,
        messages,
        accountType,
      });

      return data;
    },
    {
      ...options,
      onSettled() {
        queryClient.invalidateQueries(jobRequestsInquiresKey(jobRequestId));
      },
    }
  );
};
