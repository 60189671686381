import { SchemaOf, array, object, string } from "yup";

export type JobLocationFilterFormData = {
  options: string[];
  location: string;
};

export type JobLocationFilterSchema = SchemaOf<JobLocationFilterFormData>;

export const jobLocationFilterSchema: JobLocationFilterSchema = object({
  options: array().of(string().default("")),
  location: string().default(""),
});
