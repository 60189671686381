import axios, { AxiosResponse } from "axios";
import {
  JobApplication,
  JobApplicationData,
} from "../../api-types/job-applications";
import { JOB_APPLICATIONS_URL } from "../utils";

export const createJobApplication = (
  applicationData: JobApplicationData
): Promise<AxiosResponse<JobApplication>> => {
  return axios.post(JOB_APPLICATIONS_URL, applicationData);
};
