import { Box } from "@material-ui/core";
import React from "react";
import { PageWrapper } from "../../app/container/PageWrapper";
import { SavedJobRequestsList } from "./saved-job-requests-list";
import { SavedMainbar } from "./saved-job-requests-list/SavedMainbar";

type SavedJobRequestsPageProps = {};

export const SavedJobRequestsPage: React.FC<SavedJobRequestsPageProps> = () => {
  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        <SavedMainbar />
        <Box flexGrow={1} pt={1}>
          <SavedJobRequestsList />
        </Box>
      </Box>
    </PageWrapper>
  );
};
