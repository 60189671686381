import { useQuery } from "react-query";
import { fetchProfessional } from "../../../../../api-services/professionals/professional";
import { Professional } from "../../../../../api-types/professionals/professional";
import { PROFESSIONALS_QUERY_KEY } from "../useProfessionals";

export const useProfessional = (id: string) => {
  return useQuery<Professional, unknown, Professional>(
    `${PROFESSIONALS_QUERY_KEY}-${id}`,
    async () => {
      const { data } = await fetchProfessional(id);

      return data;
    }
  );
};
