import React from "react";
import { AccountType } from "../../../api-types/common";
import { ClientMenu } from "../../client/navbar";
import { ProfessionalMenu } from "../../professional/navbar/ProfessionalMenu";

type UserMenuProps = {
  accountType: AccountType;
};

export const UserMenu: React.FC<UserMenuProps> = ({ accountType }) => {
  if (accountType === AccountType.CLIENT) {
    return <ClientMenu />;
  }

  return <ProfessionalMenu />;
};
