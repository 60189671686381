import { Box, Divider, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { FC } from "react";
import { useStepAStyles } from "../../../../../../themes/style-hooks";

export const CardsCollection: FC = () => {
  const mobileView = useMediaQuery("(max-width: 600px)");
  const styles = useStepAStyles();

  return mobileView ? (
    <Box
      width="100%"
      className={`${styles.mobileCardWrapper}`}
      height="25%"
      display="flex"
      alignItems="flex-end"
    >
      <Box
        gridRowGap={8}
        flexGrow={1}
        display="grid"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box width="100%">
          <Box pt={4}>
            <Typography className={styles.clientCardText}>
              Post jobs and freelance projects. All in one place.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: "#7B65AA",
            }}
          />
          <Box pt={4}>
            <Typography className={styles.clientCardText}>
              Freelance projects are free to post.{" "}
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: "#7B65AA",
            }}
          />
          <Box pt={4}>
            <Typography className={styles.clientCardText}>
              All job packages start with a 10-day free trial.{" "}
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: "#7B65AA",
            }}
          />
          <Box pt={4}>
            <Typography className={styles.clientCardText}>
              Jobs are posted for 90 days.{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box display="flex" className={`${styles.cardWrapper}`} height={140}>
      <Box
        display="flex"
        justifyContent="space-evenly"
        height="100%"
        alignSelf="flex-end"
      >
        <Divider
          orientation="vertical"
          style={{
            backgroundColor: "#7B65AA",
          }}
        />
        <Box width="20%" className={styles.clientCardText}>
          Post jobs and freelance projects. All in one place.
        </Box>
        <Divider
          orientation="vertical"
          style={{
            backgroundColor: "#7B65AA",
          }}
        />
        <Box width="20%" className={styles.clientCardText}>
          Freelance projects are free to post.{" "}
        </Box>
        <Divider
          orientation={"vertical"}
          style={{
            backgroundColor: "#7B65AA",
          }}
        />
        <Box width="20%" className={styles.clientCardText}>
          All job packages start with a 10-day free trial.{" "}
        </Box>
        <Divider
          orientation={"vertical"}
          style={{
            backgroundColor: "#7B65AA",
          }}
        />
        <Box width="20%" className={styles.clientCardText}>
          Jobs are posted for 90 days.{" "}
        </Box>
      </Box>
    </Box>
  );
};
