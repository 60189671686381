import { useMutation, UseMutationOptions } from "react-query";
import { acceptBid } from "../../../../../../api-services/bids/bid";
import { acceptOffer } from "../../../../../../api-services/bids/bid/acceptOffer";
import { AcceptBidMutationResponse } from "../../../../../../api-types/bids";
import { AcceptContractOfferMutationResponse } from "../../../../../../api-types/bids/accept";

export const useAcceptContractOfferMutation = (
  options?: UseMutationOptions<
    AcceptContractOfferMutationResponse,
    unknown,
    unknown
  >
) => {
  return useMutation((id: string) => acceptOffer(id), {
    ...options,
  });
};
