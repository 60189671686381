import axios from "axios";
import { AddMessagesRequest } from "../../../api-types/job-requests/common";
import { addProjectMessageUrl } from "../../utils";

export const addProjectMessage = (
  projectId: string,
  messages: AddMessagesRequest
) => {
  return axios.post(addProjectMessageUrl(projectId), messages);
};
