import { Box } from "@material-ui/core";
import { Formik, FormikValues } from "formik";
import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { object, number, string, boolean, array, ref } from "yup";
import { PageWrapper } from "../../../../app/container/PageWrapper";
import { useJobPostCreateMutation } from "../../../../common/hooks/job-posts";
import { JOB_POSTS_QUERY_KEY } from "../../../../common/hooks/job-posts/useJobPosts";
import { useStartFreeTrialMutation } from "../../../../common/hooks/job-requests/job-request/useStartFreeTrialMutation";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useToast } from "../../../../utils/useToast";
import { useJobPackages } from "../hooks/useJobPackages";
import { useJobPackage } from "../state/hooks";
import { parseJobPostForm } from "../utils";
import { CurrentJobPostStep } from "./CurrentJobPostStep";
import { JobPostWizardActions } from "./JobPostWizardActions";
import { JobPostWizardProgressBar } from "./JobPostWizardProgressBar";

export const createJobPostValidationSchema = object().shape({
  step: number(),
  jobTitle: string().required(),
  companyName: string(),
  isConfidential: boolean(),
  aboutCompany: string(),
  description: string().required(),
  responsibilities: string(),
  jobLevel: string().required(),
  yearsOfExperience: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(
            0,
            "Minimum years of experience must be greater than or equal to zero"
          )
          .lessThan(
            ref("max"),
            "Minimum years of experience must be less than maximum years of experience"
          )
          .required("Minimum years of experience is required"),
        max: number()
          .min(1, "Maximum years of experience must be greater than zero")
          .required("Maximum years of experience is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number()
        .min(0, "Years of experience must be greater than or equal to zero")
        .required("Years of experience is required"),
    }),
  }),
  salaryPerYear: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(1, "Minimum salary per year must be greater than zero")
          .lessThan(
            ref("max"),
            "Minimum salary per year must be less than maximum salary per year"
          )
          .required("Minimum salary per year is required"),
        max: number()
          .min(1, "Maximum salary per year must be greater than zero")
          .required("Maximum salary per year is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number()
        .min(1, "Salary per year must be greater than zero")
        .required("Salary per year is required"),
    }),
  }),
  ratePerHour: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(1, "Minimum rate per hour must be greater than zero")
          .lessThan(
            ref("max"),
            "Minimum rate per hour must be less than maximum rate per hour"
          )
          .required("Minimum rate per hour is required"),
        max: number()
          .min(1, "Maximum rate per hour must be greater than zero")
          .required("Maximum rate per hour is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number()
        .min(1, "Rate per hour must be greater than zero")
        .required("Rate per hour is required"),
    }),
  }),
  daysRequiredInOffice: object({
    estimate: object({
      min: number(),
      max: number(),
    }).when("type", {
      is: "estimate",
      then: object().shape({
        min: number()
          .min(
            0,
            "Minimum days required in office must be greater than or equal to zero"
          )
          .lessThan(
            ref("max"),
            "Minimum days required in office must be less than maximum days required in office"
          )
          .required("Minimum days required in office is required"),
        max: number()
          .min(1, "Maximum days required in office must be greater than zero")
          .required("Maximum days required in office is required"),
      }),
    }),
    fixed: number().when("type", {
      is: "fixed",
      then: number(),
    }),
  }),
  jobType: string().required(),
  jobLocation: string().required(),
  educationLevel: string(),
  collegeAdditionalInformation: string(),
  postCollegeAdditionalInformation: string(),
  skills: array(),
  location: string().when("jobLocation", {
    is: (loc: string) => loc === "on-site" || loc === "hybrid",
    then: string().required(),
  }),
  qualifications: string(),
  perks: string(),
  statusRequirement: string(),
  other: string().when("statusRequirement", {
    is: "other",
    then: string().required(),
  }),
  hoursPerWeek: string().when("jobType", {
    is: "part-time",
    then: string().required(),
  }),
  notes: string(),
  companyUrl: string(),
  externalWebsite: string().when("contact", {
    is: (contact: string) => contact === "external",
    then: string().required(),
  }),
  externalEmail: string().when("contact", {
    is: (contact: string) => contact === "email",
    then: string().required(),
  }),
  monthsBeforeTransfer: string().when("jobType", {
    is: (jobType: string) => jobType === "temp-to-perm",
    then: string().required(),
  }),
  contact: string().required(),
});

export const initialJobPostValues = {
  step: 1,
  jobTitle: "",
  companyName: "",
  isConfidential: false,
  companyUrl: "",
  aboutCompany: "",
  description: "",
  responsibilities: "",
  jobLevel: "",
  yearsOfExperience: {
    estimate: {
      min: 0,
      max: 0,
    },
    fixed: 0,
  },
  salaryPerYear: {
    estimate: {
      min: 0,
      max: 0,
    },
    fixed: 0,
    currency: "EUR",
  },
  ratePerHour: {
    estimate: {
      min: 0,
      max: 0,
    },
    fixed: 0,
    currency: "EUR",
  },
  daysRequiredInOffice: {
    estimate: {
      min: 0,
      max: 0,
    },
    fixed: 0,
    timeframe: "week",
  },
  jobType: "",
  jobLocation: "",
  educationLevel: "no-education",
  collegeAdditionalInformation: "",
  postCollegeAdditionalInformation: "",
  location: "",
  qualifications: "",
  perks: "",
  statusRequirement: "sponsorship",
  other: "",
  notes: "",
  contact: "online",
  skills: [],
};

export const JobPostWizard: React.FC = () => {
  const { pathname, search } = useLocation();
  const { notifyError, notifySuccess } = useToast();
  const queryClient = useQueryClient();

  const startFreeTrial = useStartFreeTrialMutation({
    onSuccess() {
      notifySuccess("Free trial successfully started.");
      window.location.replace("/job-posts");
    },
    onError() {
      notifyError("Something went wrong. Please try again later.");
    },
    onSettled() {
      queryClient.invalidateQueries(JOB_POSTS_QUERY_KEY);
    },
  });

  const jobPackage = useScopedDowngradedStateValue(useJobPackage());

  const navigate = useNavigate();
  const { isEligibleForFreeTrial, jobPostsCredits } = useJobPackages();

  const { mutate: createJobPostMutation } = useJobPostCreateMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const hasCredits = jobPostsCredits > 0;

  useEffect(() => {
    if (searchParams.get("step")) return;

    navigate(
      {
        pathname,
        search: `?${createSearchParams({
          step: "1",
        })}`,
      },
      { replace: true }
    );
  }, [navigate, pathname, searchParams]);

  const createJobPost = useCallback(
    (values: FormikValues, isDraft: boolean = false) => {
      if (jobPackage.type === "free-trial" && isEligibleForFreeTrial) {
        startFreeTrial(parseJobPostForm({ ...values, isDraft }, true));
      } else {
        createJobPostMutation(parseJobPostForm({ ...values, isDraft }, true));
      }
    },
    [
      createJobPostMutation,
      isEligibleForFreeTrial,
      jobPackage.type,
      startFreeTrial,
    ]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  const handleNext = useCallback(() => {
    const step = Number(searchParams.get("step"));
    searchParams.set("step", String(step + 1));
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <Box
      style={{
        height: "calc(100% - 100px)",
      }}
    >
      <JobPostWizardProgressBar />
      <PageWrapper hideNavbar>
        <Box height="100%">
          <Formik
            initialValues={initialJobPostValues}
            onSubmit={(values) => {
              createJobPost(values);
            }}
            validationSchema={createJobPostValidationSchema}
            enableReinitialize
          >
            {({
              submitForm,
              handleSubmit,
              setFieldValue,
              handleChange,
              handleBlur,
              values,
              errors,
              isValid,
              setValues,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CurrentJobPostStep
                  handleUpdateSkills={(newSkills: string[]) => {
                    setFieldValue(
                      "skills",
                      newSkills.map((skill) => ({ name: skill }))
                    );
                  }}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  onSubmit={submitForm}
                  values={values}
                  errors={errors}
                  onNext={handleNext}
                  isValid={isValid}
                  setValues={setValues}
                />
                <JobPostWizardActions
                  submitStep={hasCredits ? 2 : 4}
                  isDisabled={!isValid}
                  onNext={handleNext}
                  onSubmit={submitForm}
                />
              </form>
            )}
          </Formik>
        </Box>
      </PageWrapper>
    </Box>
  );
};
