import { TooltipProps } from "@material-ui/core";
import { debounce } from "lodash";
import { RefObject, useEffect, useState } from "react";

export const useTooltipPlacement = (
  ref: RefObject<HTMLElement>
): TooltipProps["placement"] => {
  const [placement, setPlacement] =
    useState<TooltipProps["placement"]>("right");

  useEffect(() => {
    const onScroll = debounce(() => {
      if (!ref.current) return;

      const { scrollY } = window;
      const { top } = ref.current.getBoundingClientRect();

      if (top < scrollY) {
        setPlacement("top");
        return;
      }

      setPlacement("right");
    }, 300);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [placement, ref]);

  return placement;
};
