import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { addBidMessage } from "../../../../../api-services/bids/bid";
import { AccountType } from "../../../../../api-types/common";
import { Message } from "../../../../../api-types/job-requests/common";
import { accountInquiryKey } from "../../../inquiries/hooks/useAccountInquiry";
import { accountBidKey } from "../../hooks/useAccountBid";

export const useAddBidMessageMutation = (id?: string) => {
  const queryClient = useQueryClient();
  const { bidId } = useParams();

  return useMutation(
    (request: {
      messages: Message[];
      accountType: AccountType;
      jobRequestId: string;
    }) => addBidMessage(id ?? bidId ?? "", request),
    {
      onSuccess() {},
      onSettled() {
        queryClient.invalidateQueries(accountBidKey(id ?? bidId));
        queryClient.invalidateQueries(accountInquiryKey(id ?? bidId));
      },
    }
  );
};
