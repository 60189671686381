import { Box, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useBoldStyles } from "../../../themes/style-hooks";
import { PageWrapper } from "../../app/container/PageWrapper";
import { BrowseMainbar } from "./browse-mainbar";
import { BrowseContent } from "./BrowseContent";

export const Browse: React.FC = () => {
  const boldStyles = useBoldStyles();
  const [page, setPage] = useState(1);

  const onChangeCategoryHandler = useCallback(() => {
    setPage(1);
  }, []);

  return (
    <Box height="100%">
      <PageWrapper>
        <Box height={220}>
          <Box py={10}>
            <Typography variant="h1" className={boldStyles.root}>
              Professionals and Teams
            </Typography>
            <BrowseMainbar
              onChangeCategory={onChangeCategoryHandler}
              setPage={setPage}
            />
          </Box>
        </Box>
        {/*{showProfessionals ? (*/}
        <Box flexGrow={1} pt={15} height="calc(100% - 220px)">
          <BrowseContent page={page} setPage={setPage} />
        </Box>
        {/*) : (*/}
        {/*  <Box*/}
        {/*    bgcolor={palette.primary.main}*/}
        {/*    flexGrow={1}*/}
        {/*    maxHeight={300}*/}
        {/*    mt={30}*/}
        {/*    mx={-15}*/}
        {/*    px={15}*/}
        {/*    py={8}*/}
        {/*  >*/}
        {/*    <Typography*/}
        {/*      color="secondary"*/}
        {/*      variant="subtitle1"*/}
        {/*      style={{ fontWeight: 700 }}*/}
        {/*    >*/}
        {/*      Professionals and Teams Notifications*/}
        {/*    </Typography>*/}
        {/*    <Typography*/}
        {/*      color="secondary"*/}
        {/*      variant="body1"*/}
        {/*      style={{ fontWeight: 500 }}*/}
        {/*    >*/}
        {/*      The site is in an early access stage. For the time being we will*/}
        {/*      be automatically notifying you of matches for your job request.*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*)}*/}
      </PageWrapper>
    </Box>
  );
};
