import { MenuItemClassKey, MenuItemProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiMenuItem: MuiComponentThemeDefinition<
  MenuItemClassKey,
  MenuItemProps
> = {
  props: {},
  override: () => ({
    root: {
      fontSize: 18,
    },
  }),
};
