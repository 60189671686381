import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchInvitation } from "../../../../../../api-services/job-requests/invitations/invitation/fetchInvitation";
import { Invitation } from "../../../../../../api-types/job-requests/invitations";
import { RECEIVED_INVITATIONS_QUERY_KEY } from "./useReceivedClientInvitations";

export const invitationKey = (id: string | null) => {
  return `${RECEIVED_INVITATIONS_QUERY_KEY}-${id}`;
};

export const useInvitationQuery = () => {
  const { invitationId } = useParams();

  return useQuery<Invitation, unknown, Invitation>(
    invitationKey(invitationId),
    async () => {
      const { data } = await fetchInvitation(invitationId);

      return data;
    },
    {
      enabled: Boolean(invitationId),
    }
  );
};
