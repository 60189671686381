export const enum FEEDBACK_TYPE {
  MONEY_WITHDRAW,
  WITHDRAW,
  ACCOUNT_DELETE,
  DELETE,
  UPDATE,
  ADD,
  SAVE,
  REMOVE,
  PURCHASE,
  PURCHASE_ERROR,
  GENERAL_ERROR,
  SEND,
  SUBMIT,
  SUBSCRIPTION_CANCELED,
  SUBSCRIPTION_STARTED,
  CHECK_EMAIL,
  PASSWORD_RESET,
  ACCOUNT_VALIDATED,
  CONFIRMED,
  CHANGES_ACCEPTED,
  ACCEPT,
  DENY,
  WRONG_CREDENTIALS,
  ACCOUNT_NOT_FOUND,
  JOB_APPLICATION_REJECTED,
  JOB_APPLICATION_SHORTLISTED,
}

export const feedbackMessage = (item: string, type: FEEDBACK_TYPE) => {
  switch (type) {
    case FEEDBACK_TYPE.ADD:
      return "";
    case FEEDBACK_TYPE.REMOVE:
      return `The ${item} has been successfully removed from your saved list.`;
    case FEEDBACK_TYPE.SAVE:
      return `The ${item} has been successfully added to your saved list.`;
    case FEEDBACK_TYPE.WITHDRAW:
      return `Your ${item} has been successfully withdrawn.`;
    case FEEDBACK_TYPE.UPDATE:
      return `Great news! Your ${item} has been successfully updated.`;
    case FEEDBACK_TYPE.DELETE:
      return `Your ${item} has been successfully deleted.`;
    case FEEDBACK_TYPE.PURCHASE:
      return `Congratulations! You have successfully purchased the ${item}.`;
    case FEEDBACK_TYPE.PURCHASE_ERROR:
      return `We're sorry, there was an error processing your ${item} purchase.`;
    case FEEDBACK_TYPE.GENERAL_ERROR:
      return `Oops, something went wrong. Please try again.`;
    case FEEDBACK_TYPE.SUBMIT:
      return `Success! Your ${item} has been created and submitted.`;
    case FEEDBACK_TYPE.SEND:
      return `Congratulations! Your ${item} has been successfully sent.`;
    case FEEDBACK_TYPE.MONEY_WITHDRAW:
      return `You have successfully withdrawn money from your account. The funds will be transferred to your chosen account shortly`;
    case FEEDBACK_TYPE.SUBSCRIPTION_CANCELED:
      return `Your subscription has been successfully cancelled. You will no longer be billed for this service.`;
    case FEEDBACK_TYPE.ACCOUNT_DELETE:
      return `Your account has been successfully deleted. We're sorry to see you go.`;
    case FEEDBACK_TYPE.CHECK_EMAIL:
      return `Please check your email for further instructions!`;
    case FEEDBACK_TYPE.PASSWORD_RESET:
      return `Success! Your password has been reset.`;
    case FEEDBACK_TYPE.ACCOUNT_VALIDATED:
      return `Great news! Your account has been successfully validated.`;
    case FEEDBACK_TYPE.CONFIRMED:
      return `Congratulations! Your ${item} has been confirmed.`;
    case FEEDBACK_TYPE.DENY:
      return `Noted! You have successfully denied the ${item}.`;
    case FEEDBACK_TYPE.ACCEPT:
      return `Well done! You have successfully accepted the ${item}`;
    case FEEDBACK_TYPE.WRONG_CREDENTIALS:
      return `Oops! It looks like the credentials you entered are incorrect. Please double-check your email and password and try again.`;
    case FEEDBACK_TYPE.ACCOUNT_NOT_FOUND:
      return `Oops! We couldn't find an account associated with the email address you entered. Please double-check your email or create a new account.`;
    case FEEDBACK_TYPE.JOB_APPLICATION_REJECTED:
      return `Great news! The ${item} is rejected.`;
    case FEEDBACK_TYPE.JOB_APPLICATION_SHORTLISTED:
      return `Great news! The ${item} is shortlisted.`;
  }

  return "";
};
