import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchCollaboration } from "../../../../../../api-services/projects/collaborations";
import { ProfessionalReferrals } from "../../../../../../api-types/projects/collaborations";
import { RECEIVED_PROFESSIONAL_REFERRALS_QUERY_KEY } from "./useReceivedProfessionalReferralsProposals";

export const professionalReferralKey = (id: string | null) => {
  return `${RECEIVED_PROFESSIONAL_REFERRALS_QUERY_KEY}-${id}`;
};

export const useProfessionalReferralQuery = () => {
  const { professionalReferralId } = useParams();

  return useQuery<ProfessionalReferrals, unknown, ProfessionalReferrals>(
    professionalReferralKey(professionalReferralId),
    async () => {
      const { data } = await fetchCollaboration(professionalReferralId);

      return data;
    },
    {
      enabled: Boolean(professionalReferralId),
    }
  );
};
