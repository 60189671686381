import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import { FC, Fragment, ReactNode, useMemo, useState } from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useSnoozeNotificationMutation } from "../hooks/job-requests/job-request/useSnoozeNotificationMutation";
import { useMetadataQuery } from "../hooks/metadata/useMetadataQuery";
import IxDF from "./../../../icons/download.svg";

type CustomInfoBoxProps = {
  alertTitle?: string;
  hideNumbers?: boolean;
  messages: string | string[] | ReactNode;
  containerProps?: any;
};

export const CustomInfoBox: FC<CustomInfoBoxProps> = ({
  messages,
  containerProps,
}) => {
  const { mutate: snooze } = useSnoozeNotificationMutation();

  const { data: metadata, isLoading } = useMetadataQuery();

  const handleSnooze = () => {
    snooze();
  };

  const [showMessage, setShowMessage] = useState(
    window.sessionStorage.getItem("showMessage") !== "false"
  );

  const handleCloseMessage = () => {
    setShowMessage(false);
    window.sessionStorage.setItem("showMessage", "false");
  };

  const isSnoozedPast2Weeks = useMemo(() => {
    if (isLoading) return true;

    if (!metadata?.snooze) return false;

    const snoozeDate = new Date(metadata.snooze);
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - snoozeDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 14;
  }, [isLoading, metadata?.snooze]);

  return Boolean(messages) && showMessage && !isSnoozedPast2Weeks ? (
    <Box
      {...containerProps}
      style={{
        backgroundColor: BLUE,
      }}
      alignItems="stretch"
      display="flex"
      minHeight={68}
      width="100%"
      borderTop="2px solid white"
    >
      <Box
        bgcolor={BLUE}
        color={"white"}
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        pl={2.5}
        py={2.5}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={4}
        >
          <img src={IxDF} alt="IxDF" width={200} />
          <Box
            justifyContent="flex-end"
            bgcolor={BLUE}
            color={YELLOW}
            display="flex"
            px={2.5}
          >
            <IconButton
              color="inherit"
              onClick={handleSnooze}
              style={{
                fontSize: 22,
              }}
            >
              <Tooltip title={"Snooze"}>
                <NotificationsPausedIcon color="inherit" fontSize="inherit" />
              </Tooltip>
            </IconButton>{" "}
            <IconButton
              color="inherit"
              onClick={() => handleCloseMessage()}
              style={{
                fontSize: 32,
              }}
            >
              <CloseOutlinedIcon color="inherit" fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        <Fragment>
          {typeof messages === "string" ? (
            <Typography color="inherit" variant="body2">
              {messages}
            </Typography>
          ) : (
            messages
          )}
        </Fragment>
      </Box>
    </Box>
  ) : null;
};
