import { Box, Button } from "@material-ui/core";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { YELLOW } from "../../../themes/components/utils";
import { LoadingTypography } from "../../common/components";
import { useMetadataQuery } from "../../common/hooks/metadata/useMetadataQuery";
import { useJobPackages } from "../job-requests/create-job-request-wizard/hooks/useJobPackages";

type JobPostsCreditSummaryProps = {
  purchaseButton?: boolean;
};

export const JobPostsCreditSummary: FC<JobPostsCreditSummaryProps> = ({
  purchaseButton,
}) => {
  const navigate = useNavigate();

  const { isLoading: isLoadingMetadata } = useMetadataQuery();

  const { jobPostsCredits } = useJobPackages();

  const handlePostNow = useCallback(() => {
    navigate("/job-post/new");
  }, [navigate]);

  const handlePurchaseJobPoints = useCallback(() => {
    navigate("/jobs-packages?tab=packages");
  }, [navigate]);

  return (
    <Box
      alignItems="center"
      justifyContent="flex-end"
      display="flex"
      flexDirection="row"
      gridColumnGap={12}
    >
      <Box width={300}>
        <LoadingTypography
          isLoading={isLoadingMetadata}
          typographyProps={{
            variant: "body1",
          }}
          style={{
            color: YELLOW,
          }}
        >
          You have <strong>{jobPostsCredits}</strong> unused Job Post credit(s)
        </LoadingTypography>
      </Box>
      <Button
        data-cy-btn="post-new-job"
        disabled={jobPostsCredits === 0}
        onClick={handlePostNow}
        size="large"
        variant="outlined"
      >
        Post Now
      </Button>
      {Boolean(purchaseButton) && (
        <Button
          data-cy-btn="purchase-job-points"
          onClick={handlePurchaseJobPoints}
          size="large"
          variant="contained"
        >
          Purchase More Job Posts
        </Button>
      )}
    </Box>
  );
};
