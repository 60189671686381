import { Box, Typography } from "@material-ui/core";
import { FormikValues } from "formik";
import React from "react";
import { SkillV2 } from "../../../../../api-types/job-requests/common";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { BackButton } from "../../../../common/elements/BackButton";

type SkillsProps = {
  onBack?: () => void;
  handleUpdateSkills: (newSkills: SkillV2[]) => void;
  values: FormikValues;
};

export const Skills: React.FC<SkillsProps> = ({
  onBack,
  handleUpdateSkills,
  values,
}) => {
  const boldStyles = useBoldStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="column"
      pt={10}
    >
      <BackButton onClick={() => onBack?.()} />
      <Box
        display="flex"
        justifyContent="space-between"
        gridColumnGap={40}
        pt={10}
      >
        <Box
          maxWidth="35%"
          display="flex"
          flexDirection="column"
          gridRowGap={20}
        >
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="subtitle1"
              className={boldStyles.root}
            >
              Select the Skills That Apply
            </Typography>
          </Typography>
          <Typography variant="subtitle2">
            Adding these skills helps in finding a good fit for your project.
          </Typography>
        </Box>
        <Box width="65%">
          <SkillsInput
            handleChange={handleUpdateSkills}
            initialSkills={values.skills?.map(
              (skill: { name: string }) => skill.name
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
