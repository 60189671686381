import { MenuClassKey, MenuProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { LIGHT_BLUE } from "./utils";

export const MuiMenu: MuiComponentThemeDefinition<MenuClassKey, MenuProps> = {
  props: {},
  override: () => ({
    list: {
      backgroundColor: LIGHT_BLUE,
      padding: 0,
    },
  }),
};
