import { useEffect, useMemo } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useReceivedProfessionalReferralsProposals } from "../../../../common/hooks/projects/project/collaborations";

export const useDefaultProfessionalReferralPage = () => {
  const {
    data: professionalReferrals,
    isLoading: isLoadingProfessionalReferrals,
  } = useReceivedProfessionalReferralsProposals();
  const { professionalReferralId } = useParams();
  const navigate = useNavigate();

  const firstProfessionalReferralId = useMemo(() => {
    if (!professionalReferrals || isLoadingProfessionalReferrals) return "";

    return professionalReferrals[0]?._id;
  }, [professionalReferrals, isLoadingProfessionalReferrals]);

  useEffect(() => {
    if (isLoadingProfessionalReferrals) return;

    if (professionalReferralId) return;

    if (!firstProfessionalReferralId) return;

    navigate({
      pathname: `${firstProfessionalReferralId}`,
      search: `?${createSearchParams({ tab: "details" })}`,
    });
  }, [
    isLoadingProfessionalReferrals,
    professionalReferralId,
    firstProfessionalReferralId,
    navigate,
  ]);
};
