import { useMemo } from "react";
import { useSentCollaborationProposals } from "./project/collaborations";
import { useProjectsQuery } from "./useProjectsQuery";

export const useProjectsForCollaborating = (professionalId: string) => {
  const { data: projects } = useProjectsQuery();
  const { data: collaborationsSent } = useSentCollaborationProposals();

  const collaborationProjectIds = useMemo(
    () =>
      collaborationsSent
        ?.filter(({ professional }) => professional._id === professionalId)
        .map(({ project }) => project._id) ?? [],
    [collaborationsSent, professionalId]
  );

  return useMemo(
    () =>
      projects?.filter(({ professionals, _id }) => {
        const currentCollaboratorIds = professionals.map(
          ({ professional }) => professional._id
        );

        return (
          !currentCollaboratorIds.includes(professionalId) &&
          !collaborationProjectIds?.includes(_id)
        );
      }) ?? [],
    [collaborationProjectIds, professionalId, projects]
  );
};
