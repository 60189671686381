import { useMemo } from "react";
import { useAccountInquiries } from "./useAccountInquiries";

export const useInquiryByJobRequestId = (jobRequestId?: string) => {
  const { data: accountInquiries, isLoading: isLoadingAccountInquiries } =
    useAccountInquiries();

  return useMemo(() => {
    if (isLoadingAccountInquiries) return undefined;

    return accountInquiries?.find((accountInquiry) => {
      return accountInquiry?.jobRequest?._id == jobRequestId;
    })?._id;
  }, [accountInquiries, isLoadingAccountInquiries, jobRequestId]);
};
