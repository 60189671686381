import { Box } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { LoadingTypography, HeaderDropdown } from "../../../common/components";
import { Card } from "../../../common/elements/Card";
import {
  Collection,
  useNavigationHandlers,
} from "../../../common/hooks/navigation";
import { useProjectQuery } from "../../../common/hooks/projects/project/useProjectQuery";
import { useProjectsQuery } from "../../../common/hooks/projects/useProjectsQuery";
import { useNavigateOnCondition } from "../../../common/hooks/utils";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useProjectsOptions } from "../../../professional/bids/hooks/useProjectOptions";
import { useCurrentProfessionalIndex } from "../state/hooks";

export const CurrentProjectHeaderContent: React.FC = () => {
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { data: project, isLoading: isLoadingProject } = useProjectQuery();

  const { data: projects, isLoading: isLoadingProjects } = useProjectsQuery();

  const collectionConfig = { type: Collection.PROJECTS, items: projects };

  const { index } = useNavigationHandlers(
    collectionConfig,
    isLoadingProjects,
    projectId
  );

  const isLoading = isLoadingProject || isLoadingProjects;

  const noData = !isLoading && !project;

  useNavigateOnCondition("/projects/", noData);

  const handleSelectCurrentProject = useCallback(
    (index: number) => {
      if (!projects) return;

      navigate({
        pathname: `/projects/${projects[index]._id}`,
        search: `?${createSearchParams({
          tab: "details",
        })}`,
      });
    },
    [navigate, projects]
  );

  const projectsOptions = useProjectsOptions();

  const currentProfessionalIndex = useScopedDowngradedStateValue(
    useCurrentProfessionalIndex()
  );

  const setCurrentProfessionalIndex = useCurrentProfessionalIndex().set;

  const options = useMemo(() => {
    return (
      project?.professionals.map(({ professional }, index) => ({
        id: professional._id,
        title: `${professional.accountInformation?.firstName} ${professional.accountInformation?.lastName}`,
        index,
      })) ?? []
    );
  }, [project?.professionals]);

  const onTabNavigationHandler = useCallback(
    (tab: string) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box width="60%">
      <LoadingTypography
        typographyProps={{
          variant: "subtitle2",
        }}
      >
        Current Project ({index + 1} of {projects?.length})
      </LoadingTypography>
      <Box py={10}>
        <HeaderDropdown
          isLoading={isLoading}
          selectedOptionId={projectId}
          options={projectsOptions}
          onSelect={handleSelectCurrentProject}
          placeholder={project?.jobRequest?.title}
        />
      </Box>

      <Box display="flex" gridColumnGap={20}>
        <Card
          isLoading={isLoading}
          width={106}
          label="Details"
          isSelected={searchParams.get("tab") === "details"}
          onClick={() => onTabNavigationHandler("details")}
        />
        <Card
          width={350}
          select
          isLoading={isLoading}
          onSelect={(newIndex) => setCurrentProfessionalIndex(newIndex)}
          selectedIndex={currentProfessionalIndex}
          options={options}
          label={`Files and Messages (${options[
            currentProfessionalIndex
          ]?.title.substring(0, 14)}...)`}
          isSelected={searchParams.get("tab") === "replies"}
          onClick={() => onTabNavigationHandler("replies")}
        />
        <Card
          width={128}
          isLoading={isLoading}
          label="Payments"
          isSelected={searchParams.get("tab") === "payments"}
          onClick={() => onTabNavigationHandler("payments")}
        />
      </Box>
    </Box>
  );
};
