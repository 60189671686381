import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useMemo, Fragment } from "react";
import ShowMoreText from "react-show-more-text";
import { AccountInformation } from "../../../../api-types/account-information";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { YELLOW } from "../../../../themes/components/utils";
import { useBoldStyles, useWaveStyles } from "../../../../themes/style-hooks";
import { CustomTooltip } from "../../components";
import { SkillsOutput } from "../../components/SkillsOutput";

type ProfessionalAccountSummaryContentProps = {
  accountInformation?: AccountInformation;
  profileInformation?: ProfileInformation;
  isLoading?: boolean;
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    textDecoration: "none",
    color: YELLOW,
  },
  divider: {
    backgroundColor: palette.secondary.main,
  },
}));

export const ProfessionalAccountSummaryContent: React.FC<
  ProfessionalAccountSummaryContentProps
> = ({ profileInformation, accountInformation, isLoading }) => {
  const styles = useWaveStyles();
  const anchorStyles = useStyles();

  const boldStyles = useBoldStyles();

  const content = useMemo(
    () =>
      accountInformation ? (
        <Box display="flex" alignItems="baseline" gridColumnGap={12}>
          <Typography
            style={{
              fontSize: 36,
              fontWeight: 700,
            }}
          >
            {accountInformation?.firstName} {accountInformation?.lastName}
          </Typography>
          {accountInformation?.state && (
            <Box display="flex" gridColumnGap={12} alignItems="baseline">
              <Typography variant="subtitle1">|</Typography>
              <Typography variant="subtitle1">
                {accountInformation?.city}, {accountInformation?.state}
              </Typography>{" "}
            </Box>
          )}
        </Box>
      ) : null,
    [accountInformation]
  );

  const tooltipTitle = useMemo(() => {
    return (
      <Fragment>
        <Box display="flex" flexDirection="column" gridRowGap={14}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 700,
            }}
            variant="subtitle2"
          >
            {accountInformation?.firstName} {accountInformation?.lastName}
          </Typography>
          <Divider className={anchorStyles.divider} />

          <Box display="flex" flexDirection="column" gridRowGap={28}>
            <Typography
              color="secondary"
              style={{
                fontWeight: 700,
              }}
              variant="subtitle2"
            >
              {profileInformation?.position}
            </Typography>
            <Typography
              color="secondary"
              variant="body1"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {profileInformation?.description}
            </Typography>
          </Box>
        </Box>
      </Fragment>
    );
  }, [
    accountInformation?.firstName,
    accountInformation?.lastName,
    anchorStyles.divider,
    profileInformation?.description,
    profileInformation?.position,
  ]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={12}
      width="100%"
      py={10}
    >
      <Box
        display="flex"
        gridColumnGap={12}
        alignItems="center"
        width="90%"
        style={{
          height: 25,
        }}
      >
        {isLoading ? (
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            width="50%"
            height={30}
          />
        ) : (
          content
        )}
      </Box>

      {isLoading ? (
        <Box pt={4} display="flex" flexDirection="column" gridRowGap={12}>
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            width="90%"
            height={25}
          />
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            width="90%"
            height={25}
          />
          <Skeleton
            classes={{
              wave: styles.root,
            }}
            variant="rect"
            animation="wave"
            width="90%"
            height={25}
          />
          <Box
            pt={2}
            width="90%"
            display="flex"
            gridColumnGap={8}
            alignItems="center"
          >
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Box display="flex" gridColumnGap={4} alignItems="center">
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box height={100}>
          {profileInformation?.position && (
            <Typography variant="h4" className={boldStyles.root}>
              {profileInformation?.position}
            </Typography>
          )}

          <Box
            height={90}
            width={600}
            pt={2}
            style={{
              overflow: "auto",
            }}
          >
            {profileInformation?.description && (
              <Typography component="div" variant="body1">
                <ShowMoreText
                  lines={3}
                  more={
                    <CustomTooltip
                      arrow
                      interactive
                      placement="right"
                      title={tooltipTitle}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                      >
                        More
                      </Typography>
                    </CustomTooltip>
                  }
                  anchorClass={anchorStyles.root}
                  expanded={false}
                  // @ts-ignore: A bug in the library. The prop exists, but the interface is not updated.
                  expandByClick={false}
                >
                  {profileInformation?.description}
                </ShowMoreText>
              </Typography>
            )}
          </Box>

          <Box py={2}>
            <SkillsOutput
              skills={profileInformation?.skills?.map((skill) => skill.name)}
              color="transparent"
              isHovering
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
