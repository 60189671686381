import { useMutation } from "react-query";
import { addJobPost } from "../../../../../api-services/job-posts";
import { JobPost } from "../../../../../api-types/job-posts";
import { useToast } from "../../../../utils/useToast";

export const useJobPostCreateMutation = () => {
  // const navigateToSearchProfessionals = useNavigateToSearchProfessionals();
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    async (jobPost: JobPost) => {
      const { data } = await addJobPost(jobPost);

      return data;
    },
    {
      onSuccess() {
        const jobPostIsPublished = true;

        // navigateToSearchProfessionals(jobPost?._id);

        notifySuccess(
          jobPostIsPublished
            ? "Job Post was published successfully."
            : "Job Post was saved as a draft successfully."
        );

        window.location.replace("/job-posts");
      },
      onError() {
        notifyError(
          "There was an error creating the job post. Please try again"
        );
      },
    }
  );
};
