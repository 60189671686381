import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { useMetadataQuery } from "../../../common/hooks/metadata/useMetadataQuery";

export const CurrentProjectHeaderSummary: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: metadata } = useMetadataQuery();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <Typography variant="subtitle1" className={boldStyles.root}>
          Status of Your {metadata?.jobRequestsCount} Job Request(s)
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={10}>
        <Typography variant="subtitle2">
          A total of{" "}
          {(metadata?.placedInquiriesCount ?? 0) +
            (metadata?.placedBidsCount ?? 0)}{" "}
          professional(s) responded
        </Typography>
        <Typography variant="subtitle2">
          {metadata?.placedBidsCount} professional(s) placed bid(s)
        </Typography>
        <Typography variant="subtitle2">
          Out of {metadata?.invitedCount} professional(s) invited, {0} responded
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="primary">
          <Link
            href="/job-requests/"
            style={{
              cursor: "pointer",
            }}
            underline="none"
          >
            View Job Request(s)
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
