import { createState } from "@hookstate/core";
import { AppState } from "./types";

export const state = createState<AppState>({
  searchFilters: {
    date: "All",
    search: "",
    budget: {
      min: 0,
      max: 9999,
    },
    bids: {
      min: 0,
      max: 999,
    },
  },
  jobPostSearchFilters: {
    date: "All",
    jobLevel: "junior",
    jobLocation: {
      location: "",
      options: [],
    },
    jobType: [],
    jobSponsorship: {
      options: [],
      other: "",
    },
    ratePerHour: {
      checked: false,
      estimate: {
        min: 0,
        max: 999,
      },
      currency: "USD",
    },
    salaryPerYear: {
      checked: false,
      estimate: {
        min: 0,
        max: 99999,
      },
      currency: "USD",
    },
    showAll: true,
  },
  withdrawApplication: {
    open: false,
  },
  selectedFilterTypes: {
    isFreelanceProjectsSelected: true,
    isJobPostsSelected: true,
  },
});
