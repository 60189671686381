import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import { FormikValues } from "formik";
import { upperFirst } from "lodash";

import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobPost } from "../../../../../api-types/job-posts";
import {
  YELLOW,
  BLUE,
  LIGHT_BLUE,
} from "../../../../../themes/components/utils";
import { Metadata } from "../../../../common/components";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { BackButton } from "../../../../common/elements/BackButton";
import CopyToClipboardButton from "../../../../common/elements/CopyClipboardBtn";
import { useJobApplicationsQuery } from "../../../../common/hooks/job-applications";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { useIsMobileView } from "../../../../common/landing-page/hooks";
import { ApplyByEmailDialog } from "../../../../professional/job-posts";

import {
  useRemoveFromSavedJobPostMutation,
  useSavedJobPosts,
  useSaveJobPostMutation,
} from "../../../../professional/job-posts/hooks";

import { JobPostItemChips } from "../../../../professional/job-requests/search-job-requests-list/job-post-item/JobPostItemChips";
import { useIsAuthed } from "../../../../state/hooks";
import { ApplyByFormDialog } from "../../../job-posts/ApplyByFormDialog";
import { JobPostApplicationPage } from "./JobPostApplicationPage";
import { JobPostImageDownload } from "./JobPostImageDownload";

type JobPreviewProps = {
  isProfessionalView?: boolean;
  values: FormikValues;
  onClose: () => void;
  disableApplication?: boolean;
};

const useStyles = makeStyles({
  largeTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "52px",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "35px",
  },
  sectionHeader: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "35px",
    marginBottom: "8px",
  },
  salary: {
    borderRadius: 0,
    border: "1px solid white",
    backgroundColor: "transparent",
    color: "white",
    width: "100%",
    height: "51px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "23px",
  },
  button: {
    width: "160px",
    height: "51px",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "23px",
  },
  centeredBox: {
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    paddingBottom: "20px",
    paddingTop: "20px",
    maxWidth: "1440px",
    left: "50%", //amount of space
    transform: "translateX(-50%)",
    boxShadow: "0px -24px 16px -16px rgba(15,30,74, 1)",
    borderTop: `1px solid ${LIGHT_BLUE}`,
    background: BLUE,
    bottom: 0,
    width: "100%",
    boxSizing: "border-box",
    gap: "16px",
    paddingRight: "60px",
    paddingLeft: "60px",
  },
  socialMediaLink: {
    lineHeight: 1,
    "&:hover": {
      color: YELLOW,
    },
  },
});

export const JobPreview: React.FC<JobPreviewProps> = ({
  isProfessionalView,
  onClose,
  values,
  disableApplication,
}) => {
  const styles = useStyles();

  const navigate = useNavigate();

  const [isApplicationFormOpen, setIsApplicationFormOpen] = useState(false);

  const { data: savedJobPosts } = useSavedJobPosts();

  const { mutate: saveJobPost, isLoading: isSavingJobPost } =
    useSaveJobPostMutation();

  const handleOnSaveJobPost = useCallback(() => {
    saveJobPost(values._id);
  }, [saveJobPost, values._id]);

  const { mutate: removeJobPost } = useRemoveFromSavedJobPostMutation();

  const { data: jobApplications } = useJobApplicationsQuery();

  const appliedJobPostIds = useMemo(
    () =>
      jobApplications?.map((jobApplication) => jobApplication?.jobPost?._id) ??
      [],
    [jobApplications]
  );

  const isAppliedJob = appliedJobPostIds.includes(values._id);

  const handleOnRemoveJobPost = useCallback(() => {
    removeJobPost(values._id);
  }, [removeJobPost, values._id]);

  const saveButton = useMemo(() => {
    const isJobRequestSaved = savedJobPosts?.find(
      (savedJobPost) => savedJobPost._id === values._id
    );
    return !isJobRequestSaved ? (
      <Button
        size="medium"
        onClick={handleOnSaveJobPost}
        disabled={isSavingJobPost}
        data-cy-btn="save-job"
      >
        Save
      </Button>
    ) : (
      <Button
        size="medium"
        onClick={handleOnRemoveJobPost}
        disabled={isSavingJobPost}
        data-cy-btn="remove-job"
      >
        Remove
      </Button>
    );
  }, [
    handleOnRemoveJobPost,
    handleOnSaveJobPost,
    isSavingJobPost,
    savedJobPosts,
    values._id,
  ]);

  const renderSection = (
    title: string,
    content: string | React.ReactNode,
    shouldRender: boolean
  ) => {
    return (
      shouldRender &&
      content && (
        <Box pt={4}>
          <Typography variant="subtitle2" className={styles.sectionHeader}>
            {title}
          </Typography>
          <Typography
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {content}
          </Typography>
        </Box>
      )
    );
  };

  const educationLevelLabel = useMemo(() => {
    if (values.educationLevel === "college") {
      return (
        "College degree" +
        (values.collegeAdditionalInformation
          ? `, ${values.collegeAdditionalInformation}`
          : "")
      );
    }

    if (values.educationLevel === "post-college") {
      return (
        "Post college degree" +
        (values.postCollegeAdditionalInformation
          ? `, ${values.postCollegeAdditionalInformation}`
          : "")
      );
    }
  }, [
    values.collegeAdditionalInformation,
    values.educationLevel,
    values.postCollegeAdditionalInformation,
  ]);

  const statusRequirementLabel = useMemo(() => {
    if (values.statusRequirement === "other") {
      return upperFirst(values.other) || "Other";
    }

    if (values.statusRequirement === "usCitizen") {
      return "USC or GC";
    }

    if (values.statusRequirement === "noSponsorship") {
      return "No Sponsorship";
    }

    return upperFirst(values.statusRequirement);
  }, [values.other, values.statusRequirement]);

  const [openApplyByFormDialog, setOpenApplyByFormDialog] = useState(false);
  const [openApplyByEmailDialog, setOpenApplyByEmailDialog] = useState(false);

  const handleOpenApplyByFormDialog = () => {
    setOpenApplyByFormDialog(true);
  };
  const handleCloseApplyByFormDialog = () => {
    setOpenApplyByFormDialog(false);
  };
  const handleApplyByEmail = () => {
    setOpenApplyByEmailDialog(true);
  };

  /*const handleApplyOnline = () => {
    setIsApplicationFormOpen(true);
  };*/

  const isAuthed = useScopedDowngradedStateValue(useIsAuthed());
  const isMobile = useIsMobileView();

  const handleApplyExternal = () => {
    window.open(values?.externalWebsite ?? "", "_blank");
  };

  return (
    <Box>
      <Metadata title={values.jobTitle} description={values.description} />
      {isAuthed && (
        <Box pt={10}>
          <BackButton
            onClick={onClose}
            data-cy-btn="close-preview"
            backLabel="Back"
          />
        </Box>
      )}
      {!isProfessionalView && (
        <Box pt={15} pb={5}>
          <Typography className={styles.largeTitle} variant="subtitle1">
            Preview Your Job Post
          </Typography>
        </Box>
      )}
      <Box pt={5} pb={20}>
        <Box display="flex" justifyContent="flex-end">
          <Box display="flex" alignItems="center" gridColumnGap={3}>
            {!isMobile && (
              <Typography variant="subtitle2">Share this job</Typography>
            )}
            <Box
              color="white"
              display="flex"
              alignItems="center"
              gridColumnGap={1}
            >
              <Link
                className={styles.socialMediaLink}
                color="inherit"
                href={` https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}?_escaped_fragment_=1`}
                target="_blank"
              >
                <LinkedInIcon
                  color="inherit"
                  style={{ height: 32, width: 32 }}
                />
              </Link>
              <Divider flexItem orientation="vertical" />
              <Link
                className={styles.socialMediaLink}
                color="inherit"
                href={`https://twitter.com/intent/tweet?url=${window.location.href}?_escaped_fragment_=1`}
                target="_blank"
              >
                <TwitterIcon
                  color="inherit"
                  style={{ height: 32, width: 32 }}
                />
              </Link>
              {isAuthed && (
                <>
                  <Divider flexItem orientation="vertical" />
                  <JobPostImageDownload jobPostId={values._id} />
                </>
              )}
              <Divider flexItem orientation="vertical" />
              <CopyToClipboardButton />
            </Box>
          </Box>
        </Box>
        <Box pb={8}>
          <Typography className={styles.largeTitle} variant="subtitle1">
            {upperFirst(values.jobTitle)}
          </Typography>
          {values.companyName && (
            <Typography className={styles.subtitle} variant="subtitle2">
              {values.isConfidential
                ? "Confidential Listing"
                : values.companyName}
            </Typography>
          )}
        </Box>
        <JobPostItemChips jobPost={values as JobPost} />

        <Box py={4}>
          {renderSection(
            "About The Company",
            values.aboutCompany,
            !!values?.aboutCompany
          )}
          <Box pt={4}>
            <a
              href={values?.companyUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Typography style={{ color: YELLOW, cursor: "pointer" }}>
                {values?.companyUrl}
              </Typography>
            </a>
          </Box>
          {renderSection("Job Description", values.description, true)}
          {renderSection(
            "Responsibilities and Duties",
            values.responsibilities,
            !!values.responsibilities
          )}
          {educationLevelLabel &&
            renderSection("Education Level", educationLevelLabel, true)}
          {(values?.skills?.length ?? 0) > 0 && (
            <Box pt={4}>
              <Typography variant="subtitle2" className={styles.sectionHeader}>
                Skills
              </Typography>
              <SkillsOutput
                skills={values.skills.map((skill: any) => skill.name)}
              />
            </Box>
          )}
          {renderSection(
            "Additional Qualifications",
            values.qualifications,
            !!values.qualifications
          )}
          {renderSection("Perks and Benefits", values.perks, !!values.perks)}
          {renderSection("Status Requirement", statusRequirementLabel, true)}
          {/*{renderSection(*/}
          {/*  "Other Status Requirements",*/}
          {/*  values.other,*/}
          {/*  !!values.other*/}
          {/*)}*/}
          {renderSection("Additional Notes", values.notes, !!values.notes)}
        </Box>

        {isProfessionalView && !isAppliedJob && (
          <Fragment>
            <Box py={5} className={styles.centeredBox}>
              <Box display="flex" flexGrow={1}>
                {/*{!disableApplication && !isApplicationFormOpen && (
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => setOpenApplyByEmailDialog(true)}
                  data-cy-btn="upload-resume"
                >
                  Resume
                </Button>
              )}*/}
                <Typography className={styles.subtitle} variant="subtitle2">
                  {values.isConfidential
                    ? "Confidential Listing"
                    : values.companyName}
                </Typography>
              </Box>
              {!isAppliedJob && isAuthed && saveButton}

              {!isAuthed && (
                <Box display="flex" px={5}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => navigate("/sign-in")}
                  >
                    Apply to Job
                  </Button>
                </Box>
              )}

              {!disableApplication && isAuthed && !isApplicationFormOpen && (
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    if (values?.contact === "online") {
                      handleOpenApplyByFormDialog();
                    } else if (values?.contact === "email") {
                      handleApplyByEmail();
                    } else if (values?.contact === "external") {
                      handleApplyExternal();
                    }
                  }}
                  data-cy-btn="apply"
                >
                  Apply
                </Button>
              )}

              <ApplyByFormDialog
                open={openApplyByFormDialog}
                onClose={handleCloseApplyByFormDialog}
                jobPostId={values._id}
              />

              <ApplyByEmailDialog
                open={openApplyByEmailDialog}
                onClose={() => setOpenApplyByEmailDialog(false)}
              />
            </Box>
          </Fragment>
        )}
      </Box>
      {/*<Divider />*/}

      {isApplicationFormOpen && isProfessionalView && (
        <JobPostApplicationPage
          id={values._id}
          onCancel={() => setIsApplicationFormOpen(false)}
          onApplySuccessful={onClose}
        />
      )}
    </Box>
  );
};
