import { useQuery } from "react-query";
import { fetchBids } from "../../../../api-services/bids";
import { BidData } from "../../../../api-types/job-requests/bids";

export const BIDS_QUERY_KEY = "bids";

export const useBids = () => {
  return useQuery<BidData[], unknown, BidData[]>(BIDS_QUERY_KEY, async () => {
    const { data: bids } = await fetchBids();

    return bids;
  });
};
