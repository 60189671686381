import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import {
  useBoldStyles,
  usePointerStyles,
} from "../../../../../../themes/style-hooks";
import { LoadingTypography } from "../../../../../common/components";
import { useMetadataQuery } from "../../../../../common/hooks/metadata/useMetadataQuery";
import { useProjectQuery } from "../../../../../common/hooks/projects/project/useProjectQuery";

export const UnpublishedPastProjectHeaderSummary: React.FC = () => {
  const boldStyles = useBoldStyles();
  const pointerStyles = usePointerStyles();

  const { isLoading: isLoadingProject } = useProjectQuery();
  const { data: metadata } = useMetadataQuery();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      py={10}
    >
      <Box>
        <Typography variant="subtitle1" className={boldStyles.root}>
          Published Past Projects
        </Typography>
      </Box>
      <Box>
        <LoadingTypography
          isLoading={isLoadingProject}
          typographyProps={{ variant: "subtitle2" }}
        >
          {metadata?.publishedPastProjectsCount ?? 0} Past Projects are
          published
        </LoadingTypography>
      </Box>
      <Box className={pointerStyles.root}>
        <Link variant="subtitle2" href={"/projects/history"} underline="none">
          View published past projects
        </Link>
      </Box>
    </Box>
  );
};
