import { useQuery } from "react-query";
import { fetchPastProjects } from "../../../../api-services/projects/fetchPastProjects";
import { Project } from "../../../../api-types/projects";

export const PAST_PROJECTS_QUERY_KEY = "past-projects-key";

export const publicPastProjectsKey = (professionalId?: string) =>
  `${PAST_PROJECTS_QUERY_KEY}-${professionalId}`;

export const usePastProjectsQuery = (professionalId?: string) => {
  return useQuery<Project[], unknown, Project[]>(
    publicPastProjectsKey(professionalId),
    async () => {
      const { data } = await fetchPastProjects(professionalId);

      return data;
    },
    { staleTime: 10 }
  );
};
