import { createTheme } from "@material-ui/core/styles";
import * as components from "./components";
import { BLUE, YELLOW } from "./components/utils";
import { makeThemeComponentOverrides, makeThemeComponentProps } from "./utils";

const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: "Jost",
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 24,
    },
    subtitle2: {
      fontSize: 21,
      fontWeight: 400,
    },
    h1: {
      fontSize: 36,
      fontWeight: 600,
    },
    h2: {
      fontSize: 34,
    },
    h3: {
      fontSize: 31,
    },
    h4: {
      fontSize: 27,
    },
  },
  palette: {
    background: {
      default: BLUE,
    },
    primary: {
      main: YELLOW,
      contrastText: BLUE,
    },
    secondary: {
      main: BLUE,
      contrastText: YELLOW,
    },
  },
});

theme.props = makeThemeComponentProps(components);
theme.overrides = makeThemeComponentOverrides(components, theme);

export default theme;
