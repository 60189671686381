import { Box, Typography } from "@material-ui/core";
import { FormikValues } from "formik";
import React from "react";
import { Skill, SkillV2 } from "../../../../../api-types/job-requests/common";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { SkillsInput } from "../../../../common/components/SkillsInput";
import { BackButton } from "../../../../common/elements/BackButton";

type SkillsProps = {
  // onNextStep: () => void;
  // onSaveDraft: () => void;
  onBack?: () => void;
  handleChange: (newSkills: SkillV2[]) => void;
  // handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  // errors: FormikErrors<FormikValues>;
};

export const Skills: React.FC<SkillsProps> = ({
  onBack,
  // onNextStep,
  // onSaveDraft,
  handleChange,
  values,
}) => {
  const boldStyles = useBoldStyles();

  return (
    <Box pt={10} width="100%">
      <BackButton onClick={onBack} backLabel="Back" />
      <Box display="flex" gridColumnGap={60} pt={8}>
        <Box width="30%">
          <Typography variant="h1" className={boldStyles.root}>
            Select Skills You're Proficient In
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" width="80%">
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <SkillsInput
              initialSkills={values.skills.map((skill: Skill) => skill.name)}
              handleChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
