import { Box, Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  document: {
    alignItems: "center",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",

    "& .react-pdf__Page": {
      maxWidth: "calc(100% - 2em)",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
      margin: "1em",
    },

    "& .react-pdf__Page canvas": {
      maxWidth: "100%",
      height: "auto !important",
    },
  },
}));

type JobPostResumeViewerProps = {
  resumeUrl: string;
};

export const JobPostResumeViewer = ({
  resumeUrl,
}: JobPostResumeViewerProps) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);
  const styles = useStyles();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  console.log(numPages);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={16}
      justifyContent="center"
      overflow="auto"
    >
      <Document
        className={styles.document}
        file={resumeUrl}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>

      {numPages && (
        <Box display="flex" justifyContent="center" gridColumnGap={8}>
          <Button
            disabled={pageNumber === 1}
            onClick={() => setPageNumber((p) => p - 1)}
            size="small"
          >
            Prev page
          </Button>
          <Button
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber((p) => p + 1)}
            size="small"
          >
            Next page
          </Button>
        </Box>
      )}
    </Box>
  );
};
