import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { JobRequestsSearchFilters } from "../../../../api-types/job-requests/common";
import { BLUE, COLOR } from "../../../../themes/components/utils";
import { useScopedDowngradedStateValue } from "../../../common/hooks/utils/useScopedDowngradedStateValue";
import { defaultJobRequestSearchFilters } from "../hooks/useSearchJobRequests";
import { useSearchFilters, useSelectedFilterTypes } from "../state/hooks";
import { SelectBids } from "./SelectBids";
import { SelectBudget } from "./SelectBudget";
import { SelectDate } from "./SelectDate";

const useStyles = makeStyles(({ palette }) => ({
  buttonClear: {
    border: "none",
    borderRadius: 10,
    alignSelf: "center",
    backgroundColor: BLUE,
    color: "white",
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
  feeButton: {
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  filtersContainer: {
    width: 900,
    ["@media screen and (max-width: 1340px)"]: {
      width: 800,
    },
    ["@media screen and (max-width: 1280px)"]: {
      width: 700,
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: 600,
    },
    ["@media screen and (max-width: 1140px)"]: {
      width: 400,
    },
  },
  searchInput: {
    "& .MuiInputBase-root": {
      height: 68,
    },
    width: 900,
    "& ::placeholder": {
      color: BLUE,
    },
    ["@media screen and (max-width: 1340px)"]: {
      width: 800,
    },
    ["@media screen and (max-width: 1280px)"]: {
      width: 700,
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: 600,
    },
    ["@media screen and (max-width: 1140px)"]: {
      width: 400,
    },
  },
  dateInput: {
    width: "115px",
    ["@media screen and (max-width: 1300px)"]: {
      width: "90px",
    },
  },
  budgetInput: {
    width: "135px",
    ["@media screen and (max-width: 1300px)"]: {
      width: "100px",
    },
  },
  bidsInput: {
    width: "175px",
    ["@media screen and (max-width: 1300px)"]: {
      width: "140px",
    },
  },
  feeTextField: {
    height: 28,
    width: 52,

    "& .MuiOutlinedInput-input": {
      fontSize: 16,
      paddingLeft: 8,
      paddingRight: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.secondary.dark,
    },
  },
  largeCheckbox: {
    "& .MuiSvgIcon-root": {
      height: 32,
      width: 32,
    },
  },
  disabledLabel: {
    opacity: 1,
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "#fff !important",
      opacity: 1,
    },
  },
}));

type SearchFormProps = {
  onOpenJobFiltersDialog: () => void;
};

export const SearchForm = ({ onOpenJobFiltersDialog }: SearchFormProps) => {
  const styles = useStyles();
  const { isFreelanceProjectsSelected, isJobPostsSelected } =
    useScopedDowngradedStateValue(useSelectedFilterTypes());

  const setIsFreelanceProjectsSelected =
    useSelectedFilterTypes().isFreelanceProjectsSelected.set;

  const setIsJobPostsSelected = useSelectedFilterTypes().isJobPostsSelected.set;

  const filters = useScopedDowngradedStateValue(useSearchFilters());
  const setFilters = useSearchFilters().set;

  const validationSchema = Yup.object({});

  return (
    <Box display="flex" gridColumnGap={48} className={styles.filtersContainer}>
      <Box display="flex">
        <Formik
          initialValues={defaultJobRequestSearchFilters}
          onSubmit={(values: JobRequestsSearchFilters) => {
            setFilters(values);
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, handleChange, handleBlur, errors, values }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <Box className={styles.searchInput}>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.search}
                    name="search"
                    placeholder={"Search"}
                    InputProps={{
                      endAdornment: (
                        <Button
                          className={styles.buttonClear}
                          color="secondary"
                          type="submit"
                          variant="contained"
                        >
                          <SearchIcon style={{ color: COLOR.WHITE }} />
                        </Button>
                      ),
                    }}
                    data-cy="search-input"
                  />
                </Box>
                <Box
                  height={140}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box display="flex" justifyContent="space-between">
                      {isFreelanceProjectsSelected && (
                        <Box
                          alignItems="center"
                          display="flex"
                          gridColumnGap={12}
                          py={3}
                          // style={{
                          //   borderBottom:
                          //     isFreelanceProjectsSelected && isJobPostsSelected
                          //       ? "1px solid #fff"
                          //       : "none",
                          // }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 600 }}
                          >
                            Freelance Projects:
                          </Typography>
                          <SelectBudget
                            label="Budget"
                            min={0}
                            max={0}
                            onSave={(values) => {
                              setFilters({
                                ...filters,
                                budget: values,
                              });
                            }}
                          />
                          <SelectBids
                            label="No of Bids"
                            options={["< 10", "10 - 20", "20+", "All"]}
                            defaultSelected={"< 10"}
                            onSave={(selected) => {
                              let min = 0;
                              let max = 9999;

                              if (selected.includes("< 10")) {
                                max = 10;
                              } else if (selected.includes("10 - 20")) {
                                min = 10;
                                max = 20;
                              } else if (selected.includes("20+")) {
                                min = 20;
                              }

                              setFilters({
                                ...filters,
                                bids: { min, max },
                              });
                            }}
                          />
                        </Box>
                      )}

                      {isJobPostsSelected && (
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="flex-end"
                          flexGrow={1}
                          py={3}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            Job Posts:
                          </Typography>

                          <Button
                            onClick={onOpenJobFiltersDialog}
                            size="medium"
                            variant="text"
                          >
                            Add/Edit Filters
                          </Button>
                        </Box>
                      )}
                    </Box>

                    <Divider />
                  </Box>

                  <Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      gridColumnGap={28}
                      py={3}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 600 }}
                      >
                        View:
                      </Typography>

                      <FormControlLabel
                        className={styles.disabledLabel}
                        control={
                          <Checkbox
                            checked={isFreelanceProjectsSelected}
                            className={styles.largeCheckbox}
                            data-cy="freelance-projects"
                            disabled={!isJobPostsSelected}
                            name="freelanceProjects"
                            onChange={() =>
                              setIsFreelanceProjectsSelected((prev) => !prev)
                            }
                            size="medium"
                          />
                        }
                        label="Freelance Projects"
                        name="freelanceProjects"
                      />
                      <FormControlLabel
                        className={styles.disabledLabel}
                        control={
                          <Checkbox
                            checked={isJobPostsSelected}
                            className={styles.largeCheckbox}
                            data-cy="job-posts"
                            disabled={!isFreelanceProjectsSelected}
                            name="jobPosts"
                            onChange={() =>
                              setIsJobPostsSelected((prev) => !prev)
                            }
                            size="medium"
                          />
                        }
                        label="Jobs"
                        name="jobPosts"
                      />
                      <Box ml="auto">
                        <SelectDate
                          label="Date"
                          defaultSelected={filters.date}
                          options={[
                            "All",
                            "Last Month",
                            "Past 3 Months",
                            "Past 6 Months",
                          ]}
                          onSave={(selectedOption) => {
                            setFilters({
                              ...filters,
                              date: selectedOption,
                            });
                          }}
                        />
                      </Box>
                    </Box>

                    <Divider />
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
