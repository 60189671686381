import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { BASE_URL } from "../../../../api-services/utils";
import { BLUE, YELLOW } from "../../../../themes/components/utils";
import { useBoldStyles } from "../../../../themes/style-hooks";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../utils/useToast";
import { CustomTooltip, Section } from "../../components";
import { WithdrawFundsDialog } from "../../components/modals/withdraw-funds-dialog";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";
import { useStripeAccount } from "../../hooks/stripe-account/useStripeAccount";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { AccountBalanceSkeleton } from "./AccountBalanceSkeleton";
import { DepositInvoices } from "./DepositInvoices";
import { useWithdrawFundsModal } from "./state/hooks";
import { WithdrawalHistory } from "./WithdrawalHistory";

type AccountBalanceHeaderProps = {
  label?: string;
  isWithdrawing?: boolean;
};

const useStyles = makeStyles({
  balance: {
    cursor: "pointer",
    "& :hover": {
      color: YELLOW,
    },
  },
});

export const AccountBalance: React.FC<AccountBalanceHeaderProps> = () => {
  const { data: metadata, isLoading } = useMetadataQuery();

  const [isWithdrawing, setIsWithdrawing] = React.useState(false);
  const { notifySuccess, notifyError } = useToast();

  const styles = useStyles();

  const { data: stripeAccount, isLoading: isLoadingStripeAccount } =
    useStripeAccount();

  const boldStyles = useBoldStyles();

  const setWithdrawFundsDialog = useWithdrawFundsModal().set;

  const isWithdrawDisabled = isLoadingStripeAccount || !stripeAccount?.url;

  const handleSubmit = async (e: any) => {
    const response = await axios.post(`${BASE_URL}/stripe/connect`);

    if (response?.data?.url) {
      window.location = response.data.url;
    }
  };

  const withdrawFundsDialog = useScopedDowngradedStateValue(
    useWithdrawFundsModal()
  );

  const handleWithdrawAmount = async (amount: number) => {
    const response = await axios.post(`${BASE_URL}/stripe/withdraw`, {
      amount,
    });
    if (response.status == 200 || response.status == 201) {
      notifySuccess(feedbackMessage("", FEEDBACK_TYPE.MONEY_WITHDRAW));
    } else {
      notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
    }

    setWithdrawFundsDialog({ open: false });
  };

  return (
    <Section
      sectionProps={{
        height: 1000,
      }}
      isLoading={isLoading || isLoadingStripeAccount}
      loadingSkeleton={<AccountBalanceSkeleton />}
    >
      <Box pt={10}>
        <Typography variant="h1" className={boldStyles.root}>
          Account Balance
        </Typography>
        {!isWithdrawDisabled ? (
          <Box display="flex" pt={10} gridRowGap={20} flexDirection="column">
            <Typography variant="subtitle2">
              All payments are processed by Stripe. Access the Payments
              Dashboard to manage your account and withdraw funds to your bank
              account or credit card.
            </Typography>
          </Box>
        ) : (
          <Box display="flex" pt={10} gridRowGap={20} flexDirection="column">
            <Typography variant="subtitle2">
              All payments are processed by Stripe. Set up your account with
              Stripe Connect in order to be able to withdraw funds to your bank
              account or debit card.
            </Typography>
            <Typography variant="subtitle2">
              Once you set up your account, access your Payments Dashboard to
              manage your account.
            </Typography>{" "}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gridColumnGap={12}>
          <Box display="flex" gridColumnGap={8} className={styles.balance}>
            <Typography
              style={{
                fontWeight: "bold",
              }}
              component="span"
            >
              Available Account Balance ${metadata?.balance?.available ?? 0}
            </Typography>
          </Box>
          <Box height="20px">
            <Divider
              style={{
                backgroundColor: "white",
              }}
              orientation="vertical"
            />
          </Box>
          <Box display="flex" gridColumnGap={8} className={styles.balance}>
            <CustomTooltip
              arrow
              title={
                <Box>
                  <Typography
                    style={{
                      color: BLUE,
                    }}
                    variant="body2"
                  >
                    In order to reduce risks and protect against fraud, payment
                    processor <b>Stripe</b> has a required review process for
                    transfers, lasting up to <b>5 business days</b> after the
                    client places money in escrow.
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: BLUE,
                    }}
                  >
                    Once the money is released from escrow by the client and the
                    transfer is reviewed by <b>Stripe</b>, the{" "}
                    <b>Pending Amount</b> listed here will be posted as{" "}
                    <b>Available Balance</b>, and you will be able to withdraw
                    it to your account.
                  </Typography>
                </Box>
              }
            >
              <Typography
                style={{
                  fontWeight: "bold",
                }}
                component="span"
              >
                Total Pending Amount ${metadata?.balance?.pending ?? 0}
              </Typography>
            </CustomTooltip>{" "}
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" gridColumnGap={12} py={2}>
          <Button
            disabled={isLoading || !stripeAccount?.url}
            onClick={() => {
              window.location = stripeAccount?.url;
            }}
            variant="contained"
            size="small"
          >
            Payments Dashboard
          </Button>
          {isWithdrawDisabled ? (
            <Button
              onClick={handleSubmit}
              disabled={isLoading}
              variant="outlined"
              size="small"
            >
              Connect Stripe
            </Button>
          ) : (
            // <CustomTooltip
            //   arrow
            //   interactive
            //   placement="right"
            //   title={isWithdrawDisabled ? tooltipTitle : ""}
            // >
            <Button
              variant="outlined"
              size="small"
              disabled={
                isWithdrawDisabled ||
                (metadata?.balance?.available ?? 0) <= 0 ||
                isWithdrawing
              }
              onClick={() => {
                setWithdrawFundsDialog({ open: true });
              }}
            >
              Withdraw
            </Button>
            // </CustomTooltip>
          )}
        </Box>
      </Box>
      <Box width="100%">
        <Divider />
      </Box>
      <Box pt={4} height={250}>
        <WithdrawalHistory />
      </Box>
      <Box pt={4} height={250}>
        <DepositInvoices />
      </Box>
      <WithdrawFundsDialog
        open={withdrawFundsDialog.open}
        onClose={() => {
          setWithdrawFundsDialog({ open: false });
        }}
        onConfirm={(amount) => {
          setIsWithdrawing(true);
          setTimeout(() => {
            setIsWithdrawing(false);
          }, 10000);
          handleWithdrawAmount?.(amount);
        }}
      />
    </Section>
  );
};
