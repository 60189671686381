import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { PackagesCards } from "../../../job-posts/jobs-packages/components/PackagesCards";
import { useJobPackages } from "../hooks/useJobPackages";
import { useJobPackage } from "../state/hooks";

type JobPackageProps = { onNext?: () => void };

export const JobPackage: React.FC<JobPackageProps> = ({ onNext }) => {
  const boldStyles = useBoldStyles();

  const setJobPackage = useJobPackage().set;

  const { isEligibleForFreeTrial } = useJobPackages();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box display="flex" gridColumnGap={200} alignItems="flex-start">
        <Box width={400} display="flex" flexDirection="column" gridRowGap={12}>
          <Typography variant="subtitle1" className={boldStyles.root}>
            Select Your Plan{" "}
          </Typography>
          <Typography variant="subtitle2">
            Each job stays posted for 90 days
          </Typography>
          <Typography variant="subtitle2">Can post confidentially </Typography>
          <Typography variant="subtitle2">
            Unposted jobs never expire{" "}
          </Typography>
        </Box>
        <Box height={360}>
          <PackagesCards
            selectLabel={
              isEligibleForFreeTrial ? "Start 10-Day Free Trial" : "Select"
            }
            onSelect={(option) => {
              setJobPackage({
                package: option,
                type: isEligibleForFreeTrial ? "free-trial" : "effective",
              });
              onNext?.();
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
