import { DividerClassKey, DividerProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { LIGHT_BLUE } from "./utils";

export const MuiDivider: MuiComponentThemeDefinition<
  DividerClassKey,
  DividerProps
> = {
  props: {},
  override: () => ({
    root: {
      height: 1,
      backgroundColor: LIGHT_BLUE,
      opacity: 0.5,
    },
  }),
};
