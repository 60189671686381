import { useState } from "@hookstate/core";
import { state } from "./state";

export const useFreeTrialModal = () => {
  return useState(state.freeTrialModal);
};

export const usePackagesModal = () => {
  return useState(state.packagesModal);
};
