import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { Fragment, useMemo } from "react";
import { COLOR } from "../../../../themes/components/utils";
import { useBoldStyles } from "../../../../themes/style-hooks";
import { useTotalEstimatedCost } from "../create-job-request-wizard/hooks";
import {
  BudgetSchema,
  HourlyRateFundingSchema,
} from "../create-job-request-wizard/validation-schema";

const useStyles = makeStyles({
  input: {
    backgroundColor: "transparent",
    border: "1px solid",
    borderRadius: 2,
    color: COLOR.WHITE,
    fontSize: 16,
    width: 52,
    height: 28,
    "& input": {
      padding: "0px 12px",
    },

    /* Chrome, Safari, Edge, Opera */
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    /* Firefox */
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  boldLabel: {
    fontWeight: "bold",
  },
});

export const HourlyRateFundingDetails = () => {
  const boldStyles = useBoldStyles();
  const styles = useStyles();

  const { values } = useFormikContext<HourlyRateFundingSchema & BudgetSchema>();

  const totalEstimatedCost = useTotalEstimatedCost();

  const timeFrameContent = useMemo(() => {
    const timeFrameType = values.budget.timeFrame.type;
    switch (timeFrameType) {
      case "fixed":
        return (
          <Typography variant="body2">
            {values.budget.timeFrame.fixed ?? 0} week(s)
          </Typography>
        );
      case "estimate":
      case "ongoing":
      case "notSure":
        return (
          <Typography variant="body2">
            Between {values.budget.timeFrame.estimate.min ?? 0} and{" "}
            {values.budget.timeFrame.estimate.max ?? 0} weeks
          </Typography>
        );
      default:
        return null;
    }
  }, [
    values.budget.timeFrame.estimate.max,
    values.budget.timeFrame.estimate.min,
    values.budget.timeFrame.fixed,
    values.budget.timeFrame.type,
  ]);

  return (
    <Fragment>
      <Box pb={5}>
        <Typography variant="subtitle2" className={boldStyles.root}>
          Planning and Paying for Your Project
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body2">
          By default, all hourly rate projects have one milestone.
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body2">
          Once all the work on a milestone is submitted and reviewed, payment is
          made for the actual number of hours completed in the time span of that
          milestone. Work can then begin on the next milestone.
        </Typography>
      </Box>
      <Box pb={5}>
        <Typography variant="body2">
          All payments are enabled by Stripe. There is a 3.9% + $0.30 processing
          fee for each transaction.
        </Typography>
      </Box>

      <Box pb={5}>
        <Divider />

        <Box display="flex" flexWrap="wrap" gridColumnGap={40} pt={5}>
          <Box display="flex" alignItems="center" gridColumnGap={12}>
            <Typography className={styles.boldLabel} variant="body2">
              Milestones for the Project:
            </Typography>
            <Typography variant="body2">
              {values.hourlyRateProjectFunding.milestones?.length ?? 0}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gridColumnGap={8}>
            <Box mr={4}>
              <Typography
                className={styles.boldLabel}
                component="span"
                variant="body2"
              >
                Timeframe
              </Typography>
            </Box>
            {timeFrameContent}
          </Box>
          <Box display="flex" alignItems="center" gridColumnGap={8}>
            <Typography
              className={styles.boldLabel}
              component="span"
              variant="body2"
            >
              Estimated Cost:
            </Typography>
            <Typography variant="body2">
              {values?.budget?.timeFrame?.type === "fixed" &&
              values?.budget?.hours?.type === "fixed" &&
              values?.budget?.hourlyRate?.type === "fixed"
                ? `$${totalEstimatedCost?.min ?? 0}`
                : `$${totalEstimatedCost?.min ?? 0} - $${
                    totalEstimatedCost?.max ?? 0
                  }`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
