import { Box, Button, Link, Typography } from "@material-ui/core";
import { Formik, Field, FormikValues } from "formik";
import React from "react";
import * as Yup from "yup";
import { string } from "yup";
import { SignInData } from "../../../api-types/sign-in";
import { CustomInput } from "../elements";
import { useSignInMutation } from "../hooks/sign-in";
import { useRequiredFieldLabel } from "../hooks/utils";

const initialValues = {
  password: "",
  confirmPassword: "",
};

type PasswordResetFormProps = {
  formRef: any;
  onSubmit?: (values: any) => void;
};

export const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  formRef,
  onSubmit,
}) => {
  const requiredFieldName = useRequiredFieldLabel();
  const validationSchema = Yup.object({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match!")
      .required("Required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit?.(values);
      }}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        isValid,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="nope">
          <Box display="flex" flexDirection="column" gridRowGap={50}>
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder={requiredFieldName("Enter password")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              name="password"
              id="password"
            />
            <Field
              component={CustomInput}
              fullWidth
              variant="outlined"
              placeholder={requiredFieldName("Confirm password")}
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            gridRowGap={10}
            pt={10}
          >
            <Button variant="contained" type="submit" disabled={!isValid}>
              Submit
            </Button>
            {errors.confirmPassword?.includes("match") && (
              <Typography color="primary">{errors.confirmPassword}</Typography>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};
