import axios from "axios";
import { SubmitMilestoneConfig } from "../../../modules/common/hooks/projects/milestones";
import { milestoneSubmitWorkUrl } from "../../utils";

export const milestoneSubmitWork = (
  projectId: string,
  submitMilestoneConfig: SubmitMilestoneConfig
) => {
  return axios.post(
    milestoneSubmitWorkUrl(projectId, submitMilestoneConfig.milestoneId),
    submitMilestoneConfig.actualWork
  );
};
