export const FIXED_PRICE_DIFF_PROPS = [
  "description",
  "amount",
  "dueDate.type",
  "dueDate.count",
];

export const HOURLY_RATE_DIFF_PROPS = [
  "description",
  "dueDate.min",
  "dueDate.max",
  "dueDate.type",
];
