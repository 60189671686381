import { Box, Button, Divider, Typography } from "@material-ui/core";
import { FormikErrors, FormikValues } from "formik";
import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { YELLOW } from "../../../../../themes/components/utils";
import { useScopedDowngradedStateValue } from "../../../../common/hooks/utils/useScopedDowngradedStateValue";
import { JobPostPayment } from "../../../job-posts-billing";
import { useJobPackages } from "../hooks/useJobPackages";
import { useJobPackage } from "../state/hooks";
import { JobPreview } from "./JobPreview";

type JobPostBillingDetailsProps = {
  onSubmit?: () => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
};

export const JobPostBillingDetails: React.FC<JobPostBillingDetailsProps> = ({
  onSubmit,
  values,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const jobPackage = useScopedDowngradedStateValue(useJobPackage());

  const { isEligibleForFreeTrial, isFreeTrialExpired } = useJobPackages();

  const jobPackagePrice = useMemo(() => {
    if (jobPackage.package === "3-jobs") {
      return 85 * 3;
    }

    if (jobPackage.package === "5-jobs") {
      return 75 * 5;
    }

    if (jobPackage.package === "10-jobs") {
      return 10 * 65;
    }

    return 99;
  }, [jobPackage]);

  if (previewOpen) {
    return (
      <JobPreview
        values={values}
        onClose={() => {
          setPreviewOpen(false);
          searchParams.delete("isPreview");
          setSearchParams(searchParams);
        }}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      pt={10}
    >
      <Box pb={6}>
        <Typography
          variant="subtitle1"
          style={{
            lineHeight: "24px",
          }}
        >
          Billing Details
        </Typography>
        {/*{isEligibleForFreeTrial && (*/}
        {/*  <Typography>*/}
        {/*    Please enter your billing details below to start your 10-day free*/}
        {/*    trial.*/}
        {/*  </Typography>*/}
        {/*)}*/}
        {/*{isEligibleForFreeTrial && (*/}
        {/*  <Typography>*/}
        {/*    You will be billed automatically after the 10-day free trial period*/}
        {/*    is completed. You may cancel anytime during the10 day trial period*/}
        {/*    by going to the Job Packages section on the site.*/}
        {/*  </Typography>*/}
        {/*)}*/}
      </Box>
      <Divider />
      <Box pt={2} display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column" gridRowGap={20} pt={12}>
          <Box>
            <Box
              border={`1px solid ${YELLOW}`}
              display="flex"
              justifyContent="space-between"
              height={240}
              width={525}
              px={8}
            >
              <Box
                alignSelf="center"
                display="flex"
                flexDirection="column"
                gridRowGap={24}
                alignItems="center"
              >
                <Typography color={"primary"} variant="subtitle1">
                  <b>
                    {jobPackage.package === "1-job"
                      ? "Single Job Post"
                      : "Job Package"}
                  </b>
                </Typography>
                <Typography variant="subtitle1">
                  <b>${jobPackagePrice}</b>
                </Typography>
                <Typography variant="subtitle2">
                  {jobPackage.package?.replace("-", " ")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gridRowGap={16}
              >
                <Typography>
                  Each job stays posted for <b>90 days</b>
                </Typography>
                <Typography>Can post confidentially</Typography>
                <Typography>Unposted jobs never expire</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            border={`1px solid ${YELLOW}`}
            display="flex"
            justifyContent="space-between"
            p={6}
          >
            <Typography variant="subtitle2">{values.jobTitle}</Typography>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setPreviewOpen(true);
                searchParams.set("isPreview", "true");
                setSearchParams(searchParams);
              }}
            >
              Preview Job Post
            </Button>
          </Box>
        </Box>
        {!isEligibleForFreeTrial && isFreeTrialExpired && (
          <Box display="flex" flexDirection="column" pt={8}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
              }}
            >
              Card Information
            </Typography>
            <JobPostPayment
              amount={jobPackagePrice}
              noLabel
              buttonLabel={"& Post Now"}
              selectedPackage={jobPackage.package ?? "1-job"}
              onSuccess={() => {
                onSubmit?.();
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
