import { Box, Typography } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AccountType } from "../../../../api-types/common";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { useUser } from "../../../state/hooks";
import { Section } from "../../components";
import { useAccountInformation } from "../../hooks/account-information/useAccountInformation";
import { useUpdateAccountInformationMutation } from "../../hooks/account-information/useUpdateAccountInformationMutation";
import { useMetadataQuery } from "../../hooks/metadata/useMetadataQuery";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { AccountInformationSkeleton } from "./AccountInformationSkeleton";
import { AccountInformationStep } from "./AccountInformationStep";
import { SubscriptionInformationStep } from "./billing-information";
import { ProgressBar, Switch } from "./navigation";
import { SubscriptionInformationSkeleton } from "./SubscriptionInformationSkeleton";

export const AccountInformation: React.FC = () => {
  const [step, setStep] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldReturnToCreateJobRequest =
    searchParams.get("from") === "createJobRequest";

  const { isLoading: isUpdating } = useUpdateAccountInformationMutation();

  const { isLoading } = useAccountInformation();

  const { isLoading: isLoadingMetadata } = useMetadataQuery();

  const user = useScopedDowngradedStateValue(useUser());

  const isWizard = searchParams.get("type") === "wizard";

  const onSuccess = useCallback(() => {
    if (shouldReturnToCreateJobRequest) {
      window.location.replace("/job-request/new/");
      return;
    }
    if (isWizard) {
      window.location.replace("/browse?type=professionals");
    }
  }, [shouldReturnToCreateJobRequest, isWizard]);

  const content = useMemo(() => {
    if (searchParams.get("tab") === "subscription") {
      return (
        <Section
          isLoading={isLoadingMetadata}
          loadingSkeleton={<SubscriptionInformationSkeleton />}
        >
          <SubscriptionInformationStep
            onSuccess={onSuccess}
            label="Subscription Information"
          />
        </Section>
      );
    }

    return (
      <Section
        isLoading={isUpdating || isLoading}
        loadingSkeleton={<AccountInformationSkeleton />}
      >
        <AccountInformationStep label="Account Information" hasLabel />
      </Section>
    );
  }, [isLoading, isLoadingMetadata, isUpdating, onSuccess, searchParams]);

  if (user.accountType === AccountType.CLIENT) {
    return (
      <PageWrapper>
        <Box display="flex" flexDirection="column" height="100%" pt={10}>
          <Typography variant="h1">Account Information</Typography>
          <Box flexGrow={1} pt={10}>
            <Section
              isLoading={isUpdating || isLoading}
              loadingSkeleton={<AccountInformationSkeleton />}
            >
              <AccountInformationStep />
            </Section>
          </Box>
        </Box>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Box display="flex" flexDirection="column" height="100%">
        {isWizard ? (
          <ProgressBar currentStep={step} />
        ) : (
          <Switch isLoading={isLoading || isLoadingMetadata} />
        )}
        <Box flexGrow={1}>{content}</Box>
      </Box>
    </PageWrapper>
  );
};
