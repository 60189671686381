import { Box } from "@material-ui/core";
import { orderBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Section } from "../../components";
import { useSearchProjects } from "../hooks";
import { PaginationFooter } from "../PaginationFooter";
import { GridWrapper } from "./GridWrapper";
import { ProjectsListItem } from "./ProjectListItem";
import { ProjectsSkeleton } from "./ProjectsSkeleton";

const defaultRowsPerPage = 2;
const itemsPerRow = 2;

type ProjectsListProps = {
  page: number;
  setPage: (page: number) => void;
};

export const ProjectsList: React.FC<ProjectsListProps> = ({
  page,
  setPage,
}) => {
  const { data: projects, isLoading: isLoadingProjects } = useSearchProjects();

  const filteredProjects = useMemo(() => {
    return projects?.filter((project) => (project?.images?.length ?? 0) > 0);
  }, [projects]);

  const orderedProjects = useMemo(
    () =>
      orderBy(filteredProjects, ["isFeatured", "updatedAt"], ["desc", "desc"]),
    [filteredProjects]
  );

  const totalPages = useMemo(
    () =>
      orderedProjects
        ? Math.ceil(orderedProjects.length / (defaultRowsPerPage * itemsPerRow))
        : 0,
    [orderedProjects]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = page * itemsCount;

    return (orderedProjects ?? []).slice(endIndex - itemsCount, endIndex);
  }, [page, orderedProjects]);

  const handleChangePage = useCallback(
    (event: Object, page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const errorMessage = useMemo(() => {
    return (filteredProjects?.length ?? 0) === 0
      ? "There are no on-going projects at this moment"
      : "";
  }, [filteredProjects?.length]);

  return (
    <Section
      isLoading={isLoadingProjects}
      errorMessage={errorMessage}
      sectionProps={{
        paddingTop: 2.5,
      }}
      loadingSkeleton={
        <Box pb={10}>
          <ProjectsSkeleton />
          <ProjectsSkeleton />
        </Box>
      }
    >
      <Box height="calc(100% - 120px)">
        <GridWrapper
          GridContainerProps={{
            justifyContent: "space-between",
            gridRowGap: 20,
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 650px))",
            padding: 0,
          }}
        >
          {currentPageFilteredItems?.map((project) => (
            <ProjectsListItem key={project._id} project={project} />
          ))}
        </GridWrapper>
      </Box>
      <PaginationFooter
        boxProps={{ py: 10 }}
        rowsPerPage={defaultRowsPerPage}
        totalPages={totalPages}
        currentPage={page}
        onPageChange={handleChangePage}
        totalCount={orderedProjects?.length}
        objectNamePlural="projects"
        objectNameSingular="project"
      />
    </Section>
  );
};
