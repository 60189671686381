import { AccordionClassKey, AccordionProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiAccordion: MuiComponentThemeDefinition<
  AccordionClassKey,
  AccordionProps
> = {
  props: {},
  override: () => ({
    root: {
      width: "100%",
    },
  }),
};
