import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { updateProfileInformation } from "../../../../api-services/profile-information";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { PROFILE_INFORMATION_QUERY_KEY } from "./useProfileInformation";

export const useUpdateProfileInformationMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (profileInformation: Partial<ProfileInformation>) =>
      updateProfileInformation(profileInformation),
    {
      ...mutationOptions,
      onSuccess() {
        queryClient.invalidateQueries(PROFILE_INFORMATION_QUERY_KEY);
      },
    }
  );
};
