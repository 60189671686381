import { Box } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { JobRequest } from "../../../../api-types/job-requests/job-request";
import { BLUE } from "../../../../themes/components/utils";
import { Section } from "../../../common/components";
import { PaginationFooter } from "../../../common/professionals-search/PaginationFooter";
import { useSavedJobRequests } from "../hooks";
import { JobRequestItem } from "../search-job-requests-list/job-request-item";

const defaultRowsPerPage = 4;
const itemsPerRow = 1;

export const SavedJobRequestsList: React.FC = () => {
  const { data: savedJobRequests, isLoading } = useSavedJobRequests();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () =>
      savedJobRequests
        ? Math.round(
            savedJobRequests.length / (defaultRowsPerPage * itemsPerRow)
          )
        : 0,
    [savedJobRequests]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return savedJobRequests
      ? savedJobRequests.slice(endIndex - itemsCount, endIndex)
      : [];
  }, [currentPage, savedJobRequests]);

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const errorMessage = useMemo(() => {
    return !isLoading && savedJobRequests?.length
      ? ""
      : "There are no saved job requests";
  }, [savedJobRequests?.length, isLoading]);

  const [inquiriesExpandedState, setInquiriesExpandedState] = useState({});
  const [bidsExpandedState, setBidsExpandedState] = useState({});

  return (
    <Section isLoading={isLoading} errorMessage={errorMessage} color="primary">
      <Box height="calc(100% - 100px)">
        <Box display="flex" flexDirection="column" height="100%">
          {currentPageFilteredItems.map((jobRequest: JobRequest, index) => {
            return (
              <Box key={jobRequest._id} py={10}>
                <Box bgcolor={BLUE}>
                  <JobRequestItem
                    jobRequest={jobRequest}
                    index={index}
                    inquiriesExpandedState={inquiriesExpandedState}
                    bidsExpandedState={bidsExpandedState}
                    onUpdateInquiriesState={(
                      index: number,
                      newValue: boolean
                    ) => {
                      if (newValue) {
                        const newObj: any = {};

                        Object.keys(inquiriesExpandedState).forEach((key) => {
                          newObj[key] = false;
                        });

                        newObj[index] = newValue;

                        setInquiriesExpandedState(newObj);
                      } else {
                        setInquiriesExpandedState({
                          ...inquiriesExpandedState,
                          [index]: false,
                        });
                      }
                    }}
                    onUpdateBidsState={(index: number, newValue: boolean) => {
                      if (newValue) {
                        const newObj: any = {};

                        Object.keys(bidsExpandedState).forEach((key) => {
                          newObj[key] = false;
                        });

                        newObj[index] = newValue;

                        setBidsExpandedState(newObj);
                      } else {
                        setBidsExpandedState({
                          ...inquiriesExpandedState,
                          [index]: false,
                        });
                      }
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
        <PaginationFooter
          boxProps={{ pr: 4, py: 10, alignSelf: "flex-end" }}
          rowsPerPage={defaultRowsPerPage}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handleChangePage}
          totalCount={savedJobRequests?.length}
          objectNamePlural="saved job requests"
          objectNameSingular="saved job request"
        />
      </Box>
    </Section>
  );
};
