import { Formik } from "formik";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { object, string, ref } from "yup";
import { AccountType } from "../../../../api-types/common";
import { useToast } from "../../../utils/useToast";
import { useSignUpMutation } from "../../hooks/sign-up";
import { StepActions } from "../common";
import { CurrentProfessionalStep } from "./CurrentProfessionalStep";
import { initialProfessionalInfoValues } from "./SignUpProfessionalInfo";
import { initialProfessionalOptionalInfoValues } from "./SignUpProfessionalOptionalInfo";
import { initialServicesValues } from "./SignUpProfessionalServices";

const validationSchema = object().shape({
  email: string()
    .required("Email address is required")
    .email("Valid email required"),
  password: string()
    .required("Password is required")
    .min(6, "Password must contain at least 6 characters")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d).{6,}[!@#$%^&]$/,
      "Password must contain at least 1 numeric and 1 alpha char, and may only end with special characters ( !@#$%^& )."
    ),
  confirmPassword: string()
    .required("Confirmation password is required")
    .oneOf(
      [ref("password"), null],
      "Password and confirmation password must match"
    ),
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
});

const initialValues = {
  ...initialServicesValues,
  ...initialProfessionalInfoValues,
  ...initialProfessionalOptionalInfoValues,
};

type SignUpProfessionalProps = {
  onBack?: () => void;
};

export const SignUpProfessional: React.FC<SignUpProfessionalProps> = ({
  onBack,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { notifySuccess } = useToast();

  const { mutate: signUpMutation, isLoading } = useSignUpMutation({
    onSuccess: () => {
      notifySuccess(
        "Account was successfully created. Please verify your mail"
      );
      const nextStep = Number(searchParams.get("step")) + 1;
      searchParams.set("step", String(nextStep));
      setSearchParams(searchParams);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({
        description,
        position,
        profileImage,
        skills,
        ...values
      }) => {
        type Service = "designing" | "marketing" | "development" | "writing";
        const services = Object.keys(values.services).filter(
          (key) => values.services[key as Service]
        );

        const signUpData = {
          ...values,
          profileInformation: {
            description,
            position,
            profileImage,
            skills,
            services,
          },
        };

        signUpMutation({ ...signUpData, type: AccountType.PROFESSIONAL });
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        submitForm,
        isValid,
        setFieldValue,
      }) => (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          <CurrentProfessionalStep
            onBack={onBack}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          <StepActions
            isLoading={isLoading}
            setFieldValue={setFieldValue}
            submitStep={5}
            onSubmit={submitForm}
            isDisabled={
              (!isValid && Number(searchParams.get("step")) === 3) || isLoading
            }
          />
        </form>
      )}
    </Formik>
  );
};
