import axios from "axios";
import { milestoneDenyChangesUrl } from "../../utils";

export const milestoneDenyChanges = (
  projectId: string,
  milestoneId: string,
  isNew?: boolean
) => {
  return axios.post(
    milestoneDenyChangesUrl(projectId, milestoneId),
    {},
    { params: { isNew } }
  );
};
