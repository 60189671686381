import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  PaginationControls,
  PaginationControlsProps,
} from "./PaginationControls";

type PaginationFooterProps = PaginationControlsProps & {
  noCount?: boolean;
  isLoading?: boolean;
  boxProps?: any;
  totalCount?: number;
  objectNameSingular?: string;
  objectNamePlural?: string;
};

export const PaginationFooter: React.FC<PaginationFooterProps> = ({
  noCount,
  isLoading,
  boxProps,
  totalCount,
  objectNameSingular,
  objectNamePlural,
  totalPages,
  rowsPerPage,
  currentPage,
  onPageChange,
  color,
}) =>
  isLoading ? null : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      {...boxProps}
    >
      {!noCount ? (
        <Typography variant="body2" data-cy="pagination-footer-info">
          {totalCount}{" "}
          {totalCount === 1 ? objectNameSingular : objectNamePlural} found
        </Typography>
      ) : (
        <Box />
      )}
      <PaginationControls
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
        color={color}
      />
    </Box>
  );
