import { Box, makeStyles, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Field, FormikErrors, FormikValues, useFormikContext } from "formik";
import React, { ChangeEvent, useCallback, useMemo } from "react";
import countryList from "react-select-country-list";
import { YELLOW } from "../../../../themes/components/utils";
import { CustomInput } from "../../elements";
import { selectMenuProps } from "../../landing-page/subscribe/job-requests/search-form";

type ProfessionalInformationFormProps = {
  prefix?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  values?: FormikValues;
  errors?: FormikErrors<FormikValues>;
};

const useStyles = makeStyles({
  select: {
    color: "#FFFFFF",
    fontSize: 16,
    // opacity: 0.6,
    "&.MuiInputBase-root": {
      backgroundColor: "transparent",
      border: "2px solid",
      borderColor: "#FFFFFF",
      "&:hover": {
        borderColor: YELLOW,
      },
    },

    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },

    "& .MuiPaper-root": {
      height: "400px !important",
    },
  },
});

export const AccountInformationForm: React.FC<
  ProfessionalInformationFormProps
> = ({ prefix = "", disabled = false, onBlur, onChange, values, errors }) => {
  const styles = useStyles();

  const options = useMemo(() => countryList().getData(), []);

  const { setFieldValue } = useFormikContext();

  const getValue = useCallback(
    (name: string) => {
      if (prefix) {
        // @ts-ignore
        return values?.[prefix][name] ?? "";
      }

      return values?.[name] ?? "";
    },
    [prefix, values]
  );

  return (
    <Box
      style={
        disabled
          ? {
              pointerEvents: "none",
              opacity: 0.5,
            }
          : {}
      }
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      gridRowGap={48}
    >
      <Box display="flex" gridColumnGap={16}>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("firstName")}
            variant="outlined"
            placeholder="First Name"
            name={`${prefix}${prefix ? "." : ""}firstName`}
            id={`${prefix}${prefix ? "." : ""}firstName`}
          />
        </Box>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("lastName")}
            variant="outlined"
            placeholder={"Last Name"}
            name={`${prefix}${prefix ? "." : ""}lastName`}
            id={`${prefix}${prefix ? "." : ""}lastName`}
          />
        </Box>
      </Box>
      <Box display="flex" gridColumnGap={16}>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("addressLine1")}
            variant="outlined"
            placeholder="Address Line 1"
            name={`${prefix}${prefix ? "." : ""}addressLine1`}
            id={`${prefix}${prefix ? "." : ""}addressLine1`}
          />
        </Box>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("addressLine2")}
            variant="outlined"
            placeholder={"Address Line 2"}
            name={`${prefix}${prefix ? "." : ""}addressLine2`}
            id={`${prefix}${prefix ? "." : ""}addressLine2`}
          />
        </Box>
      </Box>
      <Box display="flex" gridColumnGap={16}>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("city")}
            variant="outlined"
            placeholder="City"
            name={`${prefix}${prefix ? "." : ""}city`}
            id={`${prefix}${prefix ? "." : ""}city`}
          />
        </Box>
        <Box width={400}>
          <Field
            required
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("state")}
            variant="outlined"
            placeholder={"State/Province/Region"}
            name={`${prefix}${prefix ? "." : ""}state`}
            id={`${prefix}${prefix ? "." : ""}state`}
          />
        </Box>
      </Box>

      <Box display="flex" gridColumnGap={16}>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("zipCode")}
            variant="outlined"
            placeholder="Zip/Postal Code"
            name={`${prefix}${prefix ? "." : ""}zipCode`}
            id={`${prefix}${prefix ? "." : ""}zipCode`}
          />
        </Box>
        <Box width={400}>
          <Select
            disableUnderline
            placeholder="Country"
            MenuProps={selectMenuProps}
            className={styles.select}
            onChange={(e) => {
              setFieldValue(
                `${prefix}${prefix ? "." : ""}country`,
                e.target.value
              );
            }}
            fullWidth
            name="country"
            value={getValue("country") || "country"}
            // onChange={onChange}
            // onBlur={onBlur}
            // classes={{
            //   root: whiteSelectStyles.root,
            // className={blueTextColorStyles.root}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value="country">Country</MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" gridColumnGap={16}>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("phoneNumber")}
            variant="outlined"
            placeholder={"Phone Number"}
            name={`${prefix}${prefix ? "." : ""}phoneNumber`}
            id={`${prefix}${prefix ? "." : ""}phoneNumber`}
          />
        </Box>
        <Box width={400}>
          <Field
            component={CustomInput}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            value={getValue("email")}
            variant="outlined"
            placeholder={"Email"}
            name={`${prefix}${prefix ? "." : ""}email`}
            id={`${prefix}${prefix ? "." : ""}email`}
          />
        </Box>
      </Box>
    </Box>
  );
};
