import { animated, Spring } from "@react-spring/web";
import { FC, ReactElement } from "react";

export type FadingStepProps = {
  children: ReactElement;
  previousPage: number;
  currentPage: number;
};

const defaultFadeDuration = 1500;
// const noFadeDuration = 0;

export const FadingPage: FC<FadingStepProps> = ({
  // previousPage,
  // currentPage,
  children,
}) => {
  // const blockFade = useMemo(() => {
  //   return inBetween(currentPage, 0, 1) && inBetween(previousPage, 0, 1);
  // }, [currentPage, previousPage]);

  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{
        duration: defaultFadeDuration,
        // duration: blockFade ? noFadeDuration : defaultFadeDuration,
      }}
      reset
    >
      {(styles) => (
        <animated.div
          style={{
            ...styles,
            width: "100%",
            height: "100%",
            // display: "flex",
            // justifyContent: "center",
          }}
        >
          {children}
        </animated.div>
      )}
    </Spring>
  );
};
