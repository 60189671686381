import { Box, makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { Message } from "../../../../api-types/job-requests/common";
import {
  BLUE,
  LIGHT_BLUE,
  BLACK,
  YELLOW,
} from "../../../../themes/components/utils";
import { useUser } from "../../../state/hooks";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { formatMessageDate } from "../LoadingTypography";
import { ScrollableSection } from "../ScrollableSection";

type MessagesProviderProps = {
  messages: Message[];
  label?: string;
  height?: number;
  boxProps?: any;
  maxScrollHeight?: number;
};

const useStyles = makeStyles({
  messageSent: {
    borderRadius: 10,
    backgroundColor: "#333333",
    padding: 12,
  },
  messageReceived: {
    borderRadius: 10,
    padding: 12,
    backgroundColor: LIGHT_BLUE,
    color: BLACK,
  },
  messageDate: {
    color: "#fff",
    fontSize: 14,
  },
  messageSystem: {
    color: BLUE,
    backgroundColor: YELLOW,
    borderRadius: 10,
    fontSize: 16,
    padding: 12,
  },
});

export const MessagesProvider: React.FC<MessagesProviderProps> = ({
  messages,
  label,
  boxProps,
  height,
  maxScrollHeight,
  children,
}) => {
  const styles = useStyles();
  const { id } = useScopedDowngradedStateValue(useUser());
  return (
    <React.Fragment>
      {label && (
        <Box py={4}>
          <Typography variant="subtitle1" color="secondary">
            {label}
          </Typography>
        </Box>
      )}
      <Box height={height || "100%"}>
        <ScrollableSection
          scrollable
          boxProps={boxProps}
          scrollToBottom
          maxScrollHeight={maxScrollHeight}
        >
          <Box py={4}>
            {messages.map(({ content, from, isSystem, ...rest }, idx) => {
              const userMessageStyle =
                id === from ? styles.messageSent : styles.messageReceived;

              return (
                <React.Fragment key={idx}>
                  <Box display="flex" justifyContent="center" py={2}>
                    {rest?.createdAt && (
                      <Typography className={styles.messageDate}>
                        {format(new Date(rest.createdAt), formatMessageDate)}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={
                      id === from || isSystem ? "flex-end" : "flex-start"
                    }
                    pt={4}
                    pb={2}
                  >
                    <Typography
                      className={
                        isSystem ? styles.messageSystem : userMessageStyle
                      }
                    >
                      {content}
                    </Typography>
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
        </ScrollableSection>
      </Box>
      <Box my={2}>{children}</Box>
    </React.Fragment>
  );
};
