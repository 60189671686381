import { useEffect, useMemo } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useReceivedClientInvitations } from "../../../../common/hooks/job-requests/job-request/invites/useReceivedClientInvitations";

export const useDefaultInvitationPage = () => {
  const { invitationId } = useParams();
  const navigate = useNavigate();
  const { data: invitations, isLoading: isLoadingInvitations } =
    useReceivedClientInvitations();

  const firstInvitationId = useMemo(() => {
    if (!invitations || isLoadingInvitations) return "";

    return invitations[0]?._id;
  }, [invitations, isLoadingInvitations]);

  useEffect(() => {
    if (isLoadingInvitations) return;

    if (invitationId) return;

    if (!firstInvitationId) return;

    navigate(
      {
        pathname: `${firstInvitationId}`,
        search: `?${createSearchParams({ tab: "details" })}`,
      },
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInvitations, invitationId, firstInvitationId]);
};
