import { useMutation, UseMutationOptions } from "react-query";
import { acceptBid } from "../../../../../../api-services/bids/bid";
import { AcceptBidMutationResponse } from "../../../../../../api-types/bids";

export const useAcceptBidMutation = (
  options?: UseMutationOptions<AcceptBidMutationResponse, unknown, unknown>
) => {
  return useMutation((id: string) => acceptBid(id), {
    ...options,
  });
};
