import axios from "axios";
import { ClosedProjectData } from "../../../api-types/projects/project";
import { closeProjectRequestUrl } from "../../utils";

export const closeProjectRequest = (
  id: string,
  closedProjectData?: ClosedProjectData
) => {
  return axios.post(closeProjectRequestUrl(id), closedProjectData);
};
