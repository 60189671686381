import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { addProjectMessage } from "../../../../api-services/projects/project";
import {
  AddMessagesRequest,
  Message,
} from "../../../../api-types/job-requests/common";
import { projectKey } from "../../../common/hooks/projects/project/useProjectQuery";

export const useAddProjectMessageMutation = () => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  return useMutation(
    (messages: AddMessagesRequest) =>
      addProjectMessage(projectId ?? "", messages),
    {
      onSuccess() {},
      onSettled() {
        queryClient.invalidateQueries(projectKey(projectId));
      },
    }
  );
};
