import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateToSearchProfessionals = () => {
  const navigate = useNavigate();

  return useCallback(
    (jobId?: string) => {
      if (!jobId) return;

      navigate(`/browse/?jobRequestId=${jobId}&type=professionals`, {
        replace: true,
      });
    },
    [navigate]
  );
};
