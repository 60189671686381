import { Formik } from "formik";
import React, { useState } from "react";
import { Message } from "../../../../api-types/job-requests/common";
import {
  MessagesInput,
  MessagesProvider,
} from "../../../common/components/messages";

type MessagesFormProps = {
  messages: Message[];
  onNewMessage: (value: string) => void;
  isLoading?: boolean;
  height?: number;
  scrollIntoView?: boolean;
};

export const MessagesForm: React.FC<MessagesFormProps> = ({
  messages,
  height,
  onNewMessage,
  isLoading = false,
  scrollIntoView,
}) => {
  const [initialMessage] = useState({
    message: "",
  });

  return (
    <Formik
      initialValues={initialMessage}
      enableReinitialize
      onSubmit={(values, { resetForm }) => {
        if (values.message !== "") {
          onNewMessage(values.message);
          resetForm();
        }
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <MessagesProvider
            messages={messages}
            height={height ?? 400}
            boxProps={{ borderColor: "transparent", padding: "0px" }}
          >
            <MessagesInput
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              isLoading={isLoading}
            />
          </MessagesProvider>
        </form>
      )}
    </Formik>
  );
};
