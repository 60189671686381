import { Box, Button, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";

export const SignUpConfirmation: React.FC = () => {
  const onCloseHandler = useCallback(() => {
    location.replace("/");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100%"
      pt={10}
    >
      <Typography variant="subtitle1">
        Please check your inbox and confirm your email address to finish setting
        up your account sign-in.
      </Typography>
      <Box alignSelf="flex-end" onClick={onCloseHandler} py={10}>
        <Button variant="contained" size="medium" data-cy-btn="close">
          Close
        </Button>
      </Box>
    </Box>
  );
};
