import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { JobRequest } from "../../../../api-types/job-requests/job-request";
import {
  useBoldStyles,
  usePointerStyles,
} from "../../../../themes/style-hooks";
import { ShowImages, TextOverflow } from "../../../common/components";
import { formatNominativeMonth } from "../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../common/components/SkillsOutput";
import { AvailabilityCostBids } from "../../../professional/job-requests/search-job-requests-list/job-request-item/content";

type DraftJobRequestItemProps = {
  jobRequest: JobRequest;
  isLoading: boolean;
};

const useStyles = makeStyles({
  root: {
    fontSize: 15,
    paddingBottom: 4,
    whiteSpace: "nowrap",
  },
});

const DraftJobRequestItem: React.FC<DraftJobRequestItemProps> = ({
  jobRequest,
  isLoading,
}) => {
  const navigate = useNavigate();
  const pointerStyles = usePointerStyles();
  const boldStyles = useBoldStyles();
  const jobHeaderDetailsStyles = useStyles();
  const { title, createdAt, images, updatedAt } = jobRequest;

  const skills = useMemo(() => {
    if (jobRequest?.skills) {
      return jobRequest?.skills.map((skill) => skill.name);
    }
    return [];
  }, [jobRequest]);

  const hasImages = (images ?? []).length > 0;

  return (
    <Box height="100%" className={pointerStyles.root}>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        gridColumnGap={40}
      >
        <TextOverflow
          width="30%"
          style={{
            fontSize: 24,
            color: "white",
          }}
          className={boldStyles.root}
        >
          {title}
        </TextOverflow>
        {updatedAt && (
          <Typography className={jobHeaderDetailsStyles.root}>
            Updated {format(new Date(updatedAt), formatNominativeMonth)}
          </Typography>
        )}
        {createdAt && (
          <Typography className={jobHeaderDetailsStyles.root}>
            Draft created {format(new Date(createdAt), formatNominativeMonth)}
          </Typography>
        )}
        <Box display="flex" gridColumnGap={10}>
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate(`/job-requests/drafts/${jobRequest._id}`)}
            disabled={isLoading}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Box py={2.6}>
        <Divider />
      </Box>
      <Box>
        <Typography variant="body1">{jobRequest?.description}</Typography>
      </Box>
      <Box pt={2.5}>
        <AvailabilityCostBids
          budget={jobRequest?.budget}
          fixedProjectFunding={jobRequest?.fixedProjectFunding}
          jobRequestId={jobRequest._id}
        />
      </Box>
      {skills.length > 0 && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={images} />
        </Box>
      )}
    </Box>
  );
};

export default DraftJobRequestItem;
