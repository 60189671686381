import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { FormikValues } from "formik";
import { filter } from "lodash";
import React, { Fragment, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BLUE, COLOR, YELLOW } from "../../../../themes/components/utils";
import {
  useWhiteSelectStyles,
  useYellowInputStyles,
} from "../../../../themes/style-hooks";
import { selectMenuProps } from "../../../professional/job-requests/search-form";
import { FilterDropdownCore, HeaderDropdown } from "../../components";
import { Option } from "../../components/HeaderDropdown";
import { useKnownSkills } from "../../hooks/skills/useKnownSkills";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { useSearchFilters } from "../state/hooks";

type MainbarFormProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent) => void;
  values: FormikValues;
  setFieldValue: (field: string, value: any) => void;
  jobRequestsOptions: (Option & { skills: string[] })[];
  onChangeCategory?: () => void;
  setPage?: (page: number) => void;
};

const useStyles = makeStyles(({ palette }) => ({
  buttonClear: {
    border: "none",
    borderRadius: 10,
    alignSelf: "center",
    backgroundColor: BLUE,
    color: "white",
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
  autocomplete: {
    flex: 1,
    "& .MuiTextField-root": {
      height: 68,
      outline: "none",

      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 28px)",
    },

    "&.MuiAutocomplete-hasClearIcon": {
      paddingRight: 130,
    },
  },
  searchInput: {
    position: "relative",
    width: "680px",
    "& ::placeholder": {
      color: BLUE,
    },
    "& .MuiInputBase-root": {
      height: 68,
    },
    ["@media screen and (max-width: 1420px)"]: {
      width: "535px",
    },
    ["@media screen and (max-width: 1350px)"]: {
      width: "500px",
    },
    ["@media screen and (max-width: 1300px)"]: {
      width: "460px",
    },
    ["@media screen and (max-width: 1200px)"]: {
      width: "420px",
    },
  },
  viewSelect: {
    width: "270px",
  },
  chip: {
    height: 40,
    flexDirection: "row-reverse",
    borderRadius: 0,
    backgroundColor: YELLOW,
    "& .MuiChip-label": {
      overflow: "visible",
    },
  },
  collapse: {
    position: "absolute",
    left: 0,
    right: 0,
    "& .MuiCollapse-wrapperInner": {
      zIndex: 99,
    },
  },
  buttonClearSkills: {
    borderRadius: 10,
    height: 40,
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: BLUE,
    },
  },
  feeTextField: {
    height: 28,
    width: 52,

    "& .MuiOutlinedInput-input": {
      fontSize: 16,
      paddingLeft: 8,
      paddingRight: 0,
    },

    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        margin: 0,
        "-webkit-appearance": "none",
      },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.secondary.dark,
    },
  },
}));

const serviceCategories = [
  { label: "All", value: "" },
  {
    label: "Design & Creative",
    value: "designing",
  },
  {
    label: "Marketing & Strategy",
    value: "marketing",
  },
  {
    label: "Web, Mobile & Software Development",
    value: "development",
  },
  {
    label: "Writing & Content Creation",
    value: "writing",
  },
];

export const BrowseMainbarForm: React.FC<MainbarFormProps> = ({
  setFieldValue,
  values,
  jobRequestsOptions,
  onChangeCategory,
  setPage,
}) => {
  const { palette, spacing } = useTheme();
  const yellowInputStyles = useYellowInputStyles();
  const whiteSelectStyles = useWhiteSelectStyles();
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSkills, setShowSkills] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [feeValues, setFeeValues] = useState<{ min: number; max: number }>({
    min: 0,
    max: 0,
  });

  const setFilters = useSearchFilters().set;
  const filters = useScopedDowngradedStateValue(useSearchFilters());

  const { data: knownSkills } = useKnownSkills();

  const onChangeViewHandler = useCallback(
    (event: React.ChangeEvent<any>) => {
      setPage?.(1);
      searchParams.set("type", event.target.value);
      searchParams.delete("jobRequestId");
      setSearchParams(searchParams);
    },
    [searchParams, setPage, setSearchParams]
  );

  const onSelectHandler = useCallback(
    (index: number) => {
      searchParams.set("jobRequestId", jobRequestsOptions[index].id);
      setSearchParams(searchParams);
      setFieldValue("skills", jobRequestsOptions[index].skills);
      setFilters({
        ...filters,
        skills: jobRequestsOptions[index].skills,
        fee: {
          min: feeValues.min,
          max: feeValues.max,
        },
      });
    },
    [
      feeValues.max,
      feeValues.min,
      filters,
      jobRequestsOptions,
      searchParams,
      setFieldValue,
      setFilters,
      setSearchParams,
    ]
  );

  const filterSkills = useCallback(
    (options: string[], state: FilterOptionsState<string>) => {
      return options.filter((option: string) => {
        const inputValue = state.inputValue;
        const lowerCaseOption = option.toLowerCase();

        return (
          lowerCaseOption.split(" ").filter((word) => word.match(inputValue))
            .length > 0 || lowerCaseOption.match(inputValue)
        );
      });
    },
    []
  );

  const updateSkills = useCallback(
    (newSkill: string) => {
      if (!filters.skills.includes(newSkill) && newSkill) {
        setFieldValue("skills", [...values.skills, newSkill]);
        setFilters({ ...filters, skills: [...filters.skills, newSkill] });
      }
    },
    [filters, setFieldValue, setFilters, values.skills]
  );

  const onSearchChangeHandler = useCallback(
    (_, value: string | null) => {
      if (!value) return;

      setSearchInputValue("");
      updateSkills(value);
    },
    [updateSkills]
  );

  const onClearAllSkillsHandler = useCallback(() => {
    setShowSkills(false);
    searchParams.delete("jobRequestId");
    setSearchParams(searchParams);
    setFieldValue("skills", []);
    setFilters({ ...filters, skills: [] });
  }, [filters, searchParams, setFieldValue, setFilters, setSearchParams]);

  const onRemoveSkillHandler = useCallback(
    (skill: string) => {
      const currentSkills = [...filters.skills];

      const updatedSkills = filter(currentSkills, (s) => s !== skill);

      setFieldValue("skills", updatedSkills);
      setFilters({ ...filters, skills: updatedSkills });

      if (!updatedSkills.length) {
        setShowSkills(false);
      }
    },
    [filters, setFieldValue, setFilters]
  );

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="flex-end"
        gridColumnGap={16}
        gridRowGap={16}
        mt={-5}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center">
          <Box className={styles.searchInput}>
            <Box pt={5}>
              <HeaderDropdown
                leftLabel="Skillset for:"
                selectedOptionId={searchParams.get("jobRequestId") ?? "none"}
                options={jobRequestsOptions}
                onSelect={onSelectHandler}
                variant="subtitle2"
              />
            </Box>
            <Box
              alignItems="center"
              bgcolor={palette.common.white}
              display="flex"
              height={68}
              mt={2}
            >
              {values.skills.length > 0 && (
                <Fragment>
                  <Box
                    alignItems="center"
                    bgcolor={palette.primary.main}
                    display="flex"
                    m={3}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      style={{
                        paddingLeft: spacing(1),
                        paddingRight: spacing(1),
                        minWidth: 40,
                      }}
                      onClick={() => setShowSkills(!showSkills)}
                    >
                      {showSkills ? (
                        <ExpandLessIcon color="secondary" />
                      ) : (
                        <ExpandMoreIcon color="secondary" />
                      )}
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        backgroundColor: palette.secondary.main,
                        opacity: 1,
                      }}
                    />
                    <Box color="secondary" px={2}>
                      {values.skills.length} selected
                    </Box>
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{
                      backgroundColor: palette.secondary.main,
                      opacity: 1,
                    }}
                  />
                </Fragment>
              )}
              <Autocomplete
                className={styles.autocomplete}
                freeSolo
                closeIcon={null}
                options={knownSkills ?? []}
                onChange={onSearchChangeHandler}
                onInputChange={(_, value) => {
                  setSearchInputValue(value);
                }}
                getOptionLabel={(option) => option}
                inputValue={searchInputValue}
                value={searchInputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search skills"
                    data-cy="search-skills"
                  />
                )}
                filterOptions={filterSkills}
                onKeyPress={(event) => {
                  if (event.key !== "Enter") return;
                  updateSkills(searchInputValue);
                  setSearchInputValue("");
                }}
              />
              <Box position="absolute" right={9}>
                {!searchInputValue ? (
                  <Button
                    className={styles.buttonClear}
                    color="secondary"
                    onClick={() => {
                      if (!searchInputValue) return;
                      updateSkills(searchInputValue);
                    }}
                    variant="contained"
                  >
                    <SearchIcon style={{ color: COLOR.WHITE }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.buttonClear}
                    onClick={() => setSearchInputValue("")}
                  >
                    Clear Search
                  </Button>
                )}
              </Box>
            </Box>
            <Collapse in={showSkills} className={styles.collapse}>
              <Box
                alignItems="flex-start"
                bgcolor={palette.common.white}
                border={1}
                borderColor={palette.secondary.dark}
                display="flex"
                flexWrap="wrap"
                gridGap={10}
                p={3}
              >
                {values.skills.map((skill: string) => (
                  <Box
                    bgcolor={palette.primary.main}
                    display="flex"
                    key={skill}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={() => onRemoveSkillHandler(skill)}
                      style={{
                        height: 40,
                        minWidth: 40,
                        padding: spacing(0, 1),
                      }}
                    >
                      <CloseIcon color="secondary" />
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        backgroundColor: palette.secondary.main,
                        opacity: 1,
                      }}
                    />
                    <Chip
                      classes={{
                        root: styles.chip,
                      }}
                      deleteIcon={<CloseIcon />}
                      label={skill}
                    />
                  </Box>
                ))}
                <Button
                  className={styles.buttonClearSkills}
                  color="secondary"
                  variant="contained"
                  onClick={onClearAllSkillsHandler}
                >
                  <Typography variant="body2">Clear All Skills</Typography>
                </Button>
              </Box>
            </Collapse>
          </Box>
          <Box ml={10} mt={17} display="flex">
            <FilterDropdownCore
              buttonLabel={
                <Typography variant="subtitle2">
                  Fee/hr:{" "}
                  {feeValues.min === 0 &&
                  (feeValues.max === 0 || feeValues.max === 999)
                    ? "All"
                    : `$${feeValues.min} - $${feeValues.max}`}
                </Typography>
              }
              onApply={() =>
                setFilters({
                  ...filters,
                  fee: {
                    min: feeValues.min,
                    max: feeValues.max,
                  },
                })
              }
              onClear={() => setFeeValues({ min: 0, max: 0 })}
            >
              <Box display="flex" gridColumnGap={24} p={4}>
                <Box>
                  <Typography color="secondary" variant="subtitle2">
                    From
                  </Typography>
                  <Box display="flex" gridColumnGap={4} alignItems="center">
                    <Typography color="secondary" variant="body2">
                      $
                    </Typography>
                    <TextField
                      className={styles.feeTextField}
                      onChange={(event) => {
                        const newMin = parseInt(event.target.value);

                        if (newMin >= 0 && newMin < 1000) {
                          setFeeValues(({ max }) => ({
                            max: newMin > max ? newMin : max,
                            min: newMin,
                          }));
                        }
                      }}
                      type="number"
                      value={feeValues.min}
                      data-cy="fee-min"
                    />
                    <Typography color="secondary" variant="body2">
                      /hr
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography color="secondary" variant="subtitle2">
                    To
                  </Typography>
                  <Box display="flex" gridColumnGap={4} alignItems="center">
                    <Typography color="secondary" variant="body2">
                      $
                    </Typography>
                    <TextField
                      className={styles.feeTextField}
                      onChange={(event) => {
                        const newMax = parseInt(event.target.value);
                        if (newMax >= 0 && newMax < 1000) {
                          setFeeValues(({ min }) => ({
                            min: newMax < min ? newMax : min,
                            max: newMax,
                          }));
                        }
                      }}
                      type="number"
                      value={feeValues.max}
                      data-cy="fee-max"
                    />
                    <Typography color="secondary" variant="body2">
                      /hr
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </FilterDropdownCore>
          </Box>
        </Box>
        <Box className={styles.viewSelect}>
          <Typography
            variant="subtitle1"
            color="primary"
            style={{
              paddingBottom: 18,
            }}
          >
            View:
          </Typography>
          <Select
            disableUnderline
            className={yellowInputStyles.root}
            classes={{
              root: whiteSelectStyles.root,
            }}
            MenuProps={selectMenuProps}
            defaultValue={searchParams.get("type")}
            onChange={onChangeViewHandler}
            fullWidth
            IconComponent={KeyboardArrowDownIcon}
            data-cy-btn="view-switch"
            value={searchParams.get("type")}
          >
            <MenuItem value="professionals">Browse Professionals</MenuItem>
            <MenuItem value="projects">Browse Projects</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box pt={8} pb={4}>
        <Divider />
      </Box>
      <Box alignItems="center" display="flex" gridColumnGap={8}>
        <Typography variant="body2" style={{ fontSize: 14 }}>
          Categories:
        </Typography>
        {serviceCategories.map(({ label, value }, index) => (
          <Button
            key={index}
            size="small"
            variant={filters.serviceCategory === value ? "contained" : "text"}
            onClick={() => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                serviceCategory: value,
              }));
              onChangeCategory?.();
            }}
            style={{
              fontSize: 14,
              paddingLeft: 12,
              paddingRight: 12,
              minWidth: 0,
              height: 24,
            }}
            data-cy-btn={value}
          >
            {label}
          </Button>
        ))}
      </Box>
    </Fragment>
  );
};
