import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { COLOR } from "../../../../../../themes/components/utils";
import {
  useHeadlineText,
  useMediumItalic,
  useMobileStyles,
  useStepCStyles,
} from "../../../../../../themes/style-hooks";

export const LandingStepCHeadline: FC = () => {
  const styles = useStepCStyles();
  const mediumItalic = useMediumItalic();
  const { mobileHeadlineWrapper } = useMobileStyles();
  const { skewedText, transparent } = useHeadlineText({
    primary: COLOR.ORANGE,
    secondary: COLOR.BLUE,
    background: COLOR.BLUE,
  });

  return (
    <Box className={mobileHeadlineWrapper}>
      <Box className={mediumItalic.root}>
        <Box className={`${transparent} ${styles.headline} `}>
          <span>Freelance projects&nbsp;</span>
        </Box>
        <Box className={`${styles.headline} ${transparent}`}>
          <span>&nbsp;are&nbsp;</span>
        </Box>
        <Box className={`${skewedText} ${styles.headline}`}>
          <span>&nbsp;free to post.&nbsp;</span>
        </Box>
      </Box>
    </Box>
  );
};
