import { Box } from "@material-ui/core";
import React from "react";
import { PageWrapper } from "../../../app/container/PageWrapper";
import { AccountBalance } from "./AccountBalance";

export const AccountBalancePage: React.FC = () => {
  return (
    <Box height="100%">
      <PageWrapper>
        <AccountBalance />
      </PageWrapper>
    </Box>
  );
};
