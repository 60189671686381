import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchJobApplications } from "../../../../api-services/job-applications";
import { JobApplication } from "../../../../api-types/job-applications";

export const JOB_APPLICATIONS_QUERY_KEY = "job-applications";

export const useJobApplicationsQuery = () => {
  return useQuery<JobApplication[], unknown, JobApplication[]>(
    JOB_APPLICATIONS_QUERY_KEY,
    async () => {
      const { data: jobApplications } = await fetchJobApplications();

      return jobApplications;
    }
  );
};

export const useInvalidateJobApplicationsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(JOB_APPLICATIONS_QUERY_KEY);
  }, [queryClient]);
};
