import {
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FC, Fragment, ReactNode } from "react";

type MilestonesTableProps = {
  onAddMilestone: () => void;
  TableHeadContent: ReactNode;
};

export const MilestonesTable: FC<MilestonesTableProps> = ({
  children,
  onAddMilestone,
  TableHeadContent,
}) => {
  return (
    <Fragment>
      <TableContainer
        style={{
          maxHeight: 400,
          marginLeft: -16,
          width: "calc(100% + 32px)",
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>{TableHeadContent}</TableRow>
          </TableHead>

          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <Box py={2.5}>
        <Button onClick={onAddMilestone} size="small" variant="contained">
          Add Milestone
        </Button>
      </Box>
    </Fragment>
  );
};
