import { useFormikContext } from "formik";
import { useEffect } from "react";
import { JobRequestFormValues } from "../freelance-job-request/FreelanceJobRequestWizard";
import { DueDate, ProjectDueDate } from "../validation-schema";

export const useUpdateFixedProjectFunding = () => {
  const { values, setFieldValue } = useFormikContext<JobRequestFormValues>();

  useEffect(() => {
    if (!values?.budget?.type || values?.budget?.type === "hourly") return;

    const totalAmount = (
      (values?.fixedProjectFunding?.milestones as any[]) ?? []
    ).reduce(
      (accumulator: number, current: { amount: any }) =>
        (accumulator += current.amount),
      0
    );

    const totalDueDate = (
      (values?.fixedProjectFunding?.milestones as any[]) ?? []
    ).reduce(
      (accumulator: ProjectDueDate, current: { dueDate: DueDate }) => {
        switch (current.dueDate.type) {
          case "weeks":
            return {
              ...accumulator,
              weeks: accumulator.weeks + current.dueDate.count,
            };
          case "days":
            return {
              ...accumulator,
              days: accumulator.days + current.dueDate.count,
            };
          default:
            return {
              ...accumulator,
              hours: accumulator.hours + current.dueDate.count,
            };
        }
      },
      {
        weeks: 0,
        hours: 0,
        days: 0,
      }
    );

    setFieldValue("fixedProjectFunding.totalPrice", totalAmount);
    setFieldValue("fixedProjectFunding.dueDate", totalDueDate);
  }, [
    setFieldValue,
    values?.budget?.type,
    values?.fixedProjectFunding?.milestones,
  ]);
};
