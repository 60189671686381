import { useMemo } from "react";
import { useAccountBids } from "../../bids/hooks/useAccountBids";

export const useBidByJobRequestId = (jobRequestId?: string) => {
  const { data: accountBids, isLoading: isLoadingAccountBids } =
    useAccountBids();

  return useMemo(() => {
    if (isLoadingAccountBids) return undefined;

    return accountBids?.find(
      (accountBid) => accountBid?.jobRequest?._id == jobRequestId
    )?._id;
  }, [accountBids, isLoadingAccountBids, jobRequestId]);
};
