import { Box, Chip, Divider, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { TextBox } from "../../../../client/job-requests/utils/TextBox";
import { Section, ShowImages } from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { SkillsOutput } from "../../../../common/components/SkillsOutput";
import { useInvitationQuery } from "../../../../common/hooks/job-requests/job-request/invites/useInvitationQuery";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import { Description } from "../../../../common/professionals-search/projects/project/sections/Description";
import { AvailabilityCostBids } from "../../../job-requests/search-job-requests-list/job-request-item/content";

export const Details: React.FC = () => {
  const boldStyles = useBoldStyles();

  const { data: invitation, isLoading: isLoadingInvitation } =
    useInvitationQuery();

  const { data: jobRequest, isLoading: isLoadingJobRequest } =
    useJobRequestQuery(invitation?.jobRequestId);

  const skills = useMemo(() => {
    if (!jobRequest?.skills || isLoadingInvitation || isLoadingJobRequest) {
      return [];
    }

    return jobRequest?.skills.map((skill) => skill.name);
  }, [isLoadingInvitation, isLoadingJobRequest, jobRequest?.skills]);

  const hasImages = Boolean(jobRequest?.images?.length ?? 0 > 0);

  return (
    <Section isLoading={isLoadingInvitation || isLoadingJobRequest}>
      <Box display="flex" justifyContent="space-between">
        <TextBox
          text="The Client Below Has Invited You To Consider a Job Request"
          variant="subtitle1"
          classes={boldStyles.root}
        />
        {/*{Boolean(invitation?.status === "ACCEPTED") && (*/}
        {/*  <Chip label="Invitation Accepted" />*/}
        {/*)}*/}
      </Box>
      {/*<Box py={2.5}>*/}
      {/*  <Divider />*/}
      {/*</Box>*/}
      <Box display="flex" flexDirection="column" gridRowGap={20}>
        {/*<Typography*/}
        {/*  variant="subtitle2"*/}
        {/*  style={{*/}
        {/*    fontWeight: "bold",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {invitation?.client?.accountInformation?.firstName}{" "}*/}
        {/*  {invitation?.client?.accountInformation?.lastName}*/}
        {/*</Typography>*/}
        <Typography variant="body1">
          Take a look at the job request and consider placing a bid or an
          inquiry.
        </Typography>
      </Box>
      <Box pt={2.5}>
        <Divider />
      </Box>
      <Box py={10} display="flex" flexDirection="column" gridRowGap={10}>
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: 600,
          }}
        >
          {invitation?.client?.accountInformation?.firstName}{" "}
          {invitation?.client?.accountInformation?.lastName}`s Invitation
          Message
        </Typography>
        <Typography variant="body1">
          {invitation?.introductoryMessage || "Empty message"}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        pt={2.5}
        alignItems="baseline"
      >
        <Typography variant="subtitle1" className={boldStyles.root}>
          {jobRequest?.title}
        </Typography>
        <Typography variant="body1">
          Posted {format(new Date(), formatNominativeMonth)}
        </Typography>
      </Box>
      <Box pb={2.5}>
        <Divider />
      </Box>
      <Description description={jobRequest?.description} label="" />
      <Box pt={2.5}>
        <AvailabilityCostBids
          budget={jobRequest?.budget}
          fixedProjectFunding={jobRequest?.fixedProjectFunding}
        />
      </Box>
      {Boolean(skills.length > 0) && (
        <Box pt={2.5}>
          <SkillsOutput skills={skills} />{" "}
        </Box>
      )}
      {hasImages && (
        <Box pt={2.5}>
          <ShowImages selectedFiles={jobRequest?.images} />
        </Box>
      )}
    </Section>
  );
};
