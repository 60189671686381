import axios, { AxiosResponse } from "axios";
import {
  JobApplication,
  JobApplicationData,
} from "../../api-types/job-applications";
import { jobApplicationUrl } from "../utils";

export const updateJobApplication = (
  applicationData: JobApplicationData
): Promise<AxiosResponse<JobApplication>> => {
  return axios.patch(
    jobApplicationUrl(applicationData?._id ?? ""),
    applicationData
  );
};
