import axios from "axios";
import { SEARCH_JOB_REQUESTS_URL, SEARCH_PROJECTS_URL } from "../utils";

//TODO use proper type
export const searchProjects = async (filter: any) => {
  return axios.post(SEARCH_PROJECTS_URL, {
    ...filter,
    searchKeywords: [filter.search],
  });
};
