import { useMutation, UseMutationOptions } from "react-query";
import { signUp } from "../../../../api-services/sign-up";
import { SignUpData } from "../../../../api-types/sign-up";
import { useToast } from "../../../utils/useToast";

export const useSignUpMutation = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const { notifyError } = useToast();

  return useMutation((signUpData: SignUpData) => signUp(signUpData), {
    ...mutationOptions,
    onError(err: any) {
      notifyError(
        err?.response.data.message ?? "Unexpected error. Please try again."
      );
    },
  });
};
