import { Box, makeStyles, useTheme } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { ChangeEvent } from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";

export type OnPageChange = (event: ChangeEvent<unknown>, page: number) => void;

export type PaginationControlsProps = {
  rowsPerPage?: number;
  totalPages: number;
  currentPage: number;
  onPageChange: OnPageChange;
  color?: "primary" | "secondary";
};

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-ellipsis": {
      color: (props: { color: "primary" | "secondary" }) =>
        `${props.color === "primary" ? YELLOW : BLUE}`,
    },

    "& .MuiPaginationItem-icon": {
      color: (props: { color: "primary" | "secondary" }) =>
        `${props.color === "primary" ? YELLOW : BLUE}`,
    },

    "& .MuiPaginationItem-textPrimary:not(.Mui-selected)": {
      color: (props: { color: "primary" | "secondary" }) =>
        `${props.color === "primary" ? YELLOW : BLUE}`,
    },
  },
});

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  color = "primary",
}) => {
  const { spacing } = useTheme();
  const styles = useStyles({ color });

  if (totalPages < 2) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: spacing(2) }}
      className={styles.root}
      ml={-3}
    >
      <Pagination
        color={color}
        count={totalPages}
        page={currentPage}
        onChange={onPageChange}
      />
    </Box>
  );
};
