import { alpha, DialogClassKey, DialogProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";
import { LIGHT_BLUE, YELLOW } from "./utils";

export const MuiDialog: MuiComponentThemeDefinition<
  DialogClassKey,
  DialogProps
> = {
  props: {
    transitionDuration: 100,
  },
  override: ({ zIndex }) => ({
    root: {
      backgroundColor: alpha(LIGHT_BLUE, 0.9),
    },
    container: {
      position: "relative",
      zIndex: zIndex.modal,
    },
    paper: {
      background: YELLOW,
      borderWidth: 0,
    },
    paperWidthSm: {
      height: 320,
      width: "100%",
      maxWidth: 1440,
    },
  }),
};
