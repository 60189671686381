import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getJobRequestBids } from "../../../../../../api-services/bids/fetchJobRequestBids";
import { BidData } from "../../../../../../api-types/job-requests/bids";
import { JOB_REQUESTS_QUERY_KEY } from "../../useJobRequests";

export const jobRequestsBidsKey = (id: null | string) => {
  return `${JOB_REQUESTS_QUERY_KEY}-bids-${id}`;
};

export const useJobRequestBids = (id?: string) => {
  const { jobRequestId } = useParams();

  return useQuery<BidData[], unknown, BidData[]>(
    jobRequestsBidsKey(id ?? jobRequestId),
    async () => {
      const { data } = await getJobRequestBids(id ?? jobRequestId);

      return data;
    },
    {
      enabled: Boolean(id ?? jobRequestId),
    }
  );
};
