import { Box, Button, Typography } from "@material-ui/core";
import { FormikValues } from "formik";
import React, { ChangeEvent } from "react";
import { Option } from "../../../../common/components/HeaderDropdown";
import { JobPostSetUpFormFields } from "./JobPostSetUpFormFields";

type JobPostSetUpFormProps = {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent) => void;
  values: FormikValues;
  handleUpdateSkills: (newSkills: string[]) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  onPreview?: () => void;
  onNext?: () => void;
  isValid?: boolean;
};

export const dummyCurrencies: Option[] = [
  { id: "EUR", title: "EUR" },
  { id: "USD", title: "USD" },
  { id: "RON", title: "RON" },
];

export const dummyTimeIntervals: Option[] = [
  { id: "Week", title: "Week" },
  { id: "Month", title: "Month" },
  { id: "Quarter", title: "Quarter" },
];

export const JobPostSetUpForm: React.FC<JobPostSetUpFormProps> = ({
  handleBlur,
  handleChange,
  handleUpdateSkills,
  setFieldValue,
  errors,
  values,
  onPreview,
  isValid,
}) => {
  return (
    <Box>
      <JobPostSetUpFormFields
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        handleUpdateSkills={handleUpdateSkills}
        setFieldValue={setFieldValue}
        errors={errors}
      />

      <Box
        pt={5}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          Jobs you don’t post now will be available to post later.{" "}
        </Typography>

        <Box pt={5}>
          <Button
            variant="contained"
            size="medium"
            onClick={onPreview}
            disabled={!isValid}
            data-cy-btn="preview-post"
          >
            Preview Post
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
