import { Box, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { AccountInformation } from "../../../../api-types/account-information";
import { AccountType } from "../../../../api-types/common";
import { ProfileInformation } from "../../../../api-types/profile-information";
import { useUser } from "../../../state/hooks";
import { useScopedDowngradedStateValue } from "../../hooks/utils/useScopedDowngradedStateValue";
import { ProfilePicture } from "../../summary-profile";
import { ClientSelect } from "./select/ClientSelect";
import { ProfessionalSelect } from "./select/ProfessionalSelect";

type ProfileSummaryInfoProps = {
  accountInformation?: AccountInformation;
  profileInformation?: ProfileInformation;
  _id: string;
  isHovering?: boolean;
  isLoading?: boolean;
  onView?: () => void;
  hideActionButton?: boolean;
};

export const ProfileSummaryInfo: React.FC<ProfileSummaryInfoProps> = ({
  profileInformation,
  accountInformation,
  _id,
  isHovering,
  isLoading,
  onView,
  hideActionButton,
}) => {
  const { accountType } = useScopedDowngradedStateValue(useUser());

  const hasFullName =
    accountInformation?.firstName || accountInformation?.lastName;

  const fullName = `${accountInformation?.firstName ?? ""} ${
    accountInformation?.lastName ?? ""
  }`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gridRowGap={8}
      style={{
        cursor: "pointer",
      }}
    >
      <ProfilePicture
        profileInformation={profileInformation}
        width={178}
        isHovering={isHovering}
        isLoading={isLoading}
        onClick={onView}
      />
      <Typography
        variant="subtitle2"
        style={{
          textAlign: "center",
          fontWeight: 550,
        }}
      >
        {hasFullName ? fullName : profileInformation?.username}
      </Typography>
      {!hideActionButton && (
        <Fragment>
          {accountType === AccountType.CLIENT ? (
            <ClientSelect professionalId={_id} />
          ) : (
            <ProfessionalSelect professionalId={_id} />
          )}
        </Fragment>
      )}
    </Box>
  );
};
