import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { AcceptBidMutationResponse } from "../../../../../api-types/bids";
import { useAcceptBidMutation } from "../../../../common/hooks/job-requests/job-request/bids/useAcceptBidMutation";
import { jobRequestsBidsKey } from "../../../../common/hooks/job-requests/job-request/bids/useJobRequestBids";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../utils/useToast";

export const useOnAcceptBidHandler = () => {
  const { jobRequestId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { notifySuccess } = useToast();

  const { mutate: onAcceptBid } = useAcceptBidMutation({
    onSuccess: (response: AcceptBidMutationResponse) => {
      notifySuccess(feedbackMessage("contract offer", FEEDBACK_TYPE.SEND));
      navigate({
        pathname: `/job-requests/${response.data.jobRequest._id}`,
        search: `?${createSearchParams({
          tab: "bids",
        })}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(jobRequestsBidsKey(jobRequestId));
    },
  });

  return useCallback(
    (bidId: string) => {
      if (!jobRequestId) return;

      onAcceptBid(bidId);
    },
    [jobRequestId, onAcceptBid]
  );
};
