import { PopoverClassKey, PopoverProps } from "@material-ui/core";
import { MuiComponentThemeDefinition } from "../utils";

export const MuiPopover: MuiComponentThemeDefinition<
  PopoverClassKey,
  PopoverProps
> = {
  props: {},
  override: () => ({
    paper: {
      top: "7,5vh",
    },
  }),
};
