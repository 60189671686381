import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const PortfolioSkeleton: React.FC = () => {
  return (
    <Box>
      <Box display="flex" gridColumnGap={24} justifyContent="space-between">
        <Skeleton variant="rect" animation="wave" width="60%" height={400} />
        <Box width="40%" display="flex" flexDirection="column" gridRowGap={8}>
          <Box display="flex" justifyContent="space-between">
            <Skeleton variant="rect" animation="wave" width="45%" height={30} />
            <Skeleton variant="rect" animation="wave" width="45%" height={30} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton variant="rect" animation="wave" width="45%" height={30} />
            <Skeleton variant="rect" animation="wave" width="45%" height={30} />
          </Box>
          <Box pt={5} display="flex" justifyContent="space-between">
            <Skeleton variant="rect" animation="wave" width="30%" height={25} />
            <Skeleton variant="rect" animation="wave" width="40%" height={25} />
            <Skeleton variant="rect" animation="wave" width="27%" height={25} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton variant="rect" animation="wave" width="27%" height={25} />
            <Skeleton variant="rect" animation="wave" width="40%" height={25} />
            <Skeleton variant="rect" animation="wave" width="30%" height={25} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton variant="rect" animation="wave" width="40%" height={25} />
            <Skeleton variant="rect" animation="wave" width="27%" height={25} />
            <Skeleton variant="rect" animation="wave" width="30%" height={25} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              variant="rect"
              animation="wave"
              width="20.5%"
              height={25}
            />
            <Skeleton variant="rect" animation="wave" width="25%" height={25} />
            <Skeleton variant="rect" animation="wave" width="25%" height={25} />
            <Skeleton variant="rect" animation="wave" width="25%" height={25} />
          </Box>

          <Box pt={10} display="flex" gridColumnGap={8} alignItems="center">
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Skeleton
              variant="rect"
              animation="wave"
              width="17.5%"
              height={40}
            />
            <Box display="flex" gridColumnGap={4} alignItems="center">
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
              <Skeleton
                variant="circle"
                animation="wave"
                width={16}
                height={16}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
