import { makeStyles, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LIGHT_BLUE } from "../../../themes/components/utils";

const useStyles = makeStyles(() => ({
  border: {
    borderTop: `0.5px solid ${LIGHT_BLUE}`,
  },
}));

export const JobPostsTableHead = () => {
  const styles = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.border} />
        <TableCell className={styles.border} style={{ minWidth: 190 }}>
          Job Title
        </TableCell>
        <TableCell className={styles.border} style={{ maxWidth: 100 }}>
          Number of Applicants
        </TableCell>
        <TableCell className={styles.border} style={{ maxWidth: 120 }}>
          Method of Application
        </TableCell>
        <TableCell className={styles.border}>Resumes</TableCell>
        <TableCell className={styles.border} style={{ width: 160 }}>
          Days Remaining (from 90)
        </TableCell>
        <TableCell align="right" className={styles.border}>
          Job Post Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
