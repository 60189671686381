import { useQuery } from "react-query";
import { fetchProjects } from "../../../../api-services/projects";
import { Project } from "../../../../api-types/projects";

export const PROJECTS_QUERY_KEY = "projects-key";
export const useProjectsQuery = (professionalId?: string) => {
  return useQuery<Project[], unknown, Project[]>(
    [PROJECTS_QUERY_KEY, professionalId],
    async () => {
      const { data } = await fetchProjects(professionalId);

      return data;
    }
  );
};
