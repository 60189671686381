import { useMutation, UseMutationOptions } from "react-query";
import {
  subscribe,
  SubscribeData,
} from "../../../../api-services/subscribe/subscribe";
import { useToast } from "../../../utils/useToast";

export const useSubscribe = (
  mutationOptions?: UseMutationOptions<unknown, unknown, unknown>
) => {
  const { notifySuccess, notifyError } = useToast();

  return useMutation(
    (subscribeData: SubscribeData) => subscribe(subscribeData),
    {
      ...mutationOptions,
      onError(err: any) {
        notifyError(
          err?.response.data.message ?? "Unexpected error. Please try again."
        );
      },
    }
  );
};
