import { Box, useTheme } from "@material-ui/core";
import React from "react";
import { Step } from "../../../elements/Step";

type ProgressBarProps = { currentStep: number };

export const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep }) => {
  const theme = useTheme();

  return (
    <Box pt={8}>
      <Box display="flex" gridColumnGap={60} pb={8}>
        <Step label="Account Information" isSelected={currentStep === 1} />
        <Step label="subscription Information" isSelected={currentStep === 2} />
      </Box>
      <Box width="100%" height="12px" bgcolor="white">
        <Box
          width={`${(currentStep / 2) * 100}%`}
          height="inherit"
          bgcolor={theme.palette.primary.main}
        />
      </Box>
    </Box>
  );
};
