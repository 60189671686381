import { Box, useMediaQuery } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  BLUE,
  MAX_VIEWPORT_WIDTH,
  YELLOW,
} from "../../../../../themes/components/utils";
import { Step } from "../../../../common/elements/Step";
import { TheTalentedLogo } from "../../../../common/logo";

type EditAccountProgressBarProps = {
  currentStep: number;
  noProgress?: boolean;
};

const steps = [
  "Project Information",
  "Skills",
  "Add Projects",
  "Pricing your work",
];

export const EditAccountProgressBar: React.FC<EditAccountProgressBarProps> = ({
  currentStep,
  noProgress,
}) => {
  const smallWidth = useMediaQuery("(max-width: 1440px)");

  const STEPS = useMemo(() => {
    return steps.map((step, index) => {
      return (
        <Step
          key={index}
          label={step}
          isSelected={currentStep === 1 + index}
          color={BLUE}
        />
      );
    });
  }, [currentStep]);

  if (noProgress) {
    return (
      <Box
        height={100}
        px={5}
        bgcolor={YELLOW}
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <TheTalentedLogo inverse />
        <Box width={180} />
      </Box>
    );
  }

  return (
    <Box
      height={100}
      px={5}
      bgcolor={YELLOW}
      display="flex"
      alignItems="center"
      justifyContent={smallWidth ? "center" : "space-between"}
    >
      {!smallWidth && <TheTalentedLogo inverse />}
      <Box
        display="flex"
        flexDirection="column"
        alignSelf="center"
        width={MAX_VIEWPORT_WIDTH - 450}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          {STEPS}
        </Box>
        <Box width="100%" height="12px" bgcolor="white">
          <Box
            width={`${(currentStep / 4) * 100}%`}
            height="inherit"
            bgcolor={BLUE}
          />
        </Box>
      </Box>
      {!smallWidth && <Box width={180} />}
    </Box>
  );
};
