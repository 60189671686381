/* eslint-disable no-undef */
import { Box, makeStyles, Typography } from "@material-ui/core";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BLUE, YELLOW } from "../../../themes/components/utils";
import { useUser } from "../../state/hooks";
import { useScopedDowngradedStateValue } from "../hooks/utils/useScopedDowngradedStateValue";
import { defaultFeatureFlags, FeatureFlagsDialog } from "./FeatureFlagsDialog";

type TheTalentedLogoProps = {
  inverse?: boolean;
};

const useStyles = makeStyles({
  text: {
    fontSize: 28,
    ["@media screen and (max-width: 1280px)"]: {
      fontSize: 19,
    },
    ["@media screen and (max-width: 1024px), screen and (max-height: 750px)"]: {
      fontSize: 19,
    },
    /*["@media screen and (max-width: 850px), screen and (max-height: 400px)"]: {
      fontSize: 13,
    },*/
  },
  ffEnabled: {
    fontWeight: 600,
    fontStyle: "italic",
    fontSize: 16,
    ["@media screen and (max-width: 1380px)"]: {
      fontSize: 14,
    },
    ["@media screen and (max-width: 1280px)"]: {
      fontSize: 12,
    },
  },
  beta: {
    fontWeight: 600,
    fontStyle: "italic",
    lineHeight: "21px",
    fontSize: 18,
    ["@media screen and (max-width: 1380px)"]: {
      fontSize: 16,
    },
    ["@media screen and (max-width: 1280px)"]: {
      fontSize: 14,
    },
    /*["@media screen and (max-width: 850px)"]: {
      fontSize: 12,
    },*/
  },
  wrapper: {
    cursor: "pointer",
    width: 180,
    height: 52,
    padding: `5px 5px 5px 0px`,
    ["@media screen and (max-width: 1280px)"]: {
      width: 126,
      height: 38,
      padding: `4px 4px 4px 0px`,
    },
    ["@media screen and (max-width: 1024px), screen and (max-height: 750px)"]: {
      width: 126,
      height: 38,
      padding: `4px 4px 4px 0px`,

    },
    /*["@media screen and (max-width: 850px), screen and (max-height: 400px)"]: {
      width: 87,
      height: 26,
      padding: `2px 2px 2px 0px`,
    },*/
  },
});

export const TheTalentedLogo: FC<TheTalentedLogoProps> = ({ inverse }) => {
  const styles = useStyles();

  const user = useScopedDowngradedStateValue(useUser());

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const navigateToHomeHandler = useCallback(() => {
    const accountType =
      user.accountType ??
      window.sessionStorage.getItem("accountType") ??
      "professional";

    if (accountType === "client") {
      location.replace("/client");
      return;
    }

    location.replace("/");
  }, [user.accountType]);

  const [optionIsPressed, setOptionIsPressed] = useState(false);

  const handleLogoClick = () => {
    if (process.env.REACT_APP_ENV === "prod") {
      navigateToHomeHandler();
      return;
    }

    if (optionIsPressed) {
      setOpen(true);
    } else {
      navigateToHomeHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", function () {
      setOptionIsPressed(false);
    });

    return () =>
      document.removeEventListener("keyup", function () {
        setOptionIsPressed(false);
      });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 18) {
        setOptionIsPressed(true);
      } else {
        setOptionIsPressed(false);
      }
    });

    return () =>
      document.removeEventListener("keydown", function () {
        setOptionIsPressed(false);
      });
  }, []);

  const areFeatureFlagsEnabled = useMemo(() => {
    const platform = window.sessionStorage.getItem("platform") ?? "dev";
    const showProfessionals =
      window.sessionStorage.getItem("showProfessionals") ?? "0";
    const showJobRequests =
      window.sessionStorage.getItem("showJobRequests") ?? "0";

    if (process.env.REACT_APP_ENV === "prod") {
      return false;
    }

    return (
      defaultFeatureFlags.platform != platform ||
      defaultFeatureFlags.showJobRequests != showJobRequests ||
      defaultFeatureFlags.showProfessionals != showProfessionals
    );
  }, []);

  return (
    <Box>
      <Box display="flex"
           gridColumnGap={8}
           justifyContent="center"
           alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          className={styles.wrapper}
          border={`1px solid ${inverse ? BLUE : YELLOW}`}
          onClick={handleLogoClick}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            height="100%"
          >
            <Typography
              className={styles.text}
              color={inverse ? "secondary" : "primary"}
            >
              The
            </Typography>
          </Box>
          <Box
            bgcolor={inverse ? BLUE : YELLOW}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            height="100%"
          >
            <Typography
              className={styles.text}
              color={inverse ? "primary" : "secondary"}
            >
              Talented
            </Typography>
          </Box>
        </Box>
        <Box alignSelf="flex-end" pr={1}>
          <Typography
            variant="subtitle2"
            className={styles.beta}
            color={inverse ? "secondary" : "primary"}
          >
            Beta
          </Typography>
        </Box>
      </Box>
      {areFeatureFlagsEnabled && (
        <Box
          display="flex"
          alignItems="center"
          gridColumnGap={8}
          pt={1}
          pl={3}
          width={180}
        >
          <WarningAmberIcon color="error" />
          <Typography className={styles.ffEnabled} color={"error"}>
            F-Flags Enabled
          </Typography>
        </Box>
      )}
      <FeatureFlagsDialog open={open} handleClose={handleClose} />
    </Box>
  );
};
