import { Box } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { JobPost } from "../../../../api-types/job-posts";
import { BLUE } from "../../../../themes/components/utils";
import { Section } from "../../../common/components";
import { PaginationFooter } from "../../../common/professionals-search/PaginationFooter";
import { JobPostItem } from "../../job-requests/search-job-requests-list/job-post-item";
import { useSavedJobPosts } from "../hooks";

const defaultRowsPerPage = 4;
const itemsPerRow = 1;

export const SavedJobRequestsList: React.FC = () => {
  const { data: savedJobPosts, isLoading } = useSavedJobPosts();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () =>
      savedJobPosts
        ? Math.round(savedJobPosts.length / (defaultRowsPerPage * itemsPerRow))
        : 0,
    [savedJobPosts]
  );

  const currentPageFilteredItems = useMemo(() => {
    const itemsCount = defaultRowsPerPage * itemsPerRow;
    const endIndex = currentPage * itemsCount;

    return savedJobPosts
      ? savedJobPosts.slice(endIndex - itemsCount, endIndex)
      : [];
  }, [currentPage, savedJobPosts]);

  const handleChangePage = useCallback((event: Object, page: number) => {
    setCurrentPage(page);
  }, []);

  const errorMessage = useMemo(() => {
    return !isLoading && savedJobPosts?.length
      ? ""
      : "There are no saved job posts";
  }, [savedJobPosts?.length, isLoading]);

  return (
    <Section isLoading={isLoading} errorMessage={errorMessage} color="primary">
      <Box height="calc(100% - 100px)">
        <Box display="flex" flexDirection="column" height="100%">
          {currentPageFilteredItems.map((jobPost: JobPost) => {
            return (
              <Box key={jobPost._id} py={10}>
                <Box bgcolor={BLUE}>
                  <JobPostItem jobPost={jobPost} />
                </Box>
              </Box>
            );
          })}
        </Box>
        <PaginationFooter
          boxProps={{ pr: 4, py: 10, alignSelf: "flex-end" }}
          rowsPerPage={defaultRowsPerPage}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handleChangePage}
          totalCount={savedJobPosts?.length}
          objectNamePlural="saved job posts"
          objectNameSingular="saved job post"
        />
      </Box>
    </Section>
  );
};
