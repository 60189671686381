import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const SubscriptionInformationSkeleton: React.FC = () => {
  return (
    <Box
      display="flex"
      gridColumnGap={24}
      gridRowGap={36}
      justifyContent="flex-end"
      flexDirection="column"
    >
      <Skeleton variant="rect" animation="wave" width="100%" height={40} />
      <Skeleton variant="rect" animation="wave" width="25%" height={40} />
      <Box display="flex" flexDirection="column" gridRowGap={12}>
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
      </Box>
      <Skeleton variant="rect" animation="wave" width="25%" height={40} />
      <Box display="flex" flexDirection="column" gridRowGap={12}>
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
        <Skeleton variant="rect" animation="wave" width="100%" height={25} />
      </Box>
    </Box>
  );
};
