import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import { useBoldStyles } from "../../../../../themes/style-hooks";
import { useMetadataQuery } from "../../../../common/hooks/metadata/useMetadataQuery";

export const ProfessionalReferralHeaderSummary: React.FC = () => {
  const boldStyles = useBoldStyles();
  const { data: metadata } = useMetadataQuery();
  const { clientInvitationsCount = 0, accountInquiriesCount = 0 } =
    metadata ?? {};

  return (
    <Box display="flex" flexDirection="column" gridRowGap={2} width="35%">
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" className={boldStyles.root}>
          Client Invitations
        </Typography>
        <Typography variant="subtitle2">
          You were invited to consider {clientInvitationsCount} projects
        </Typography>
        {clientInvitationsCount > 0 && (
          <Typography variant="subtitle2">
            <Link href="/invitations/">View Client Invitations Page</Link>
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" className={boldStyles.root}>
          Job Request Inquiries
        </Typography>
        <Typography variant="subtitle2">
          You placed {accountInquiriesCount} job request inquiries
        </Typography>
        {accountInquiriesCount > 0 && (
          <Typography variant="subtitle2">
            <Link href="/inquiries/">View Job Inquiries Page</Link>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
