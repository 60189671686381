import { useQuery } from "react-query";
import { fetchReceivedProfessionalReferrals } from "../../../../../../api-services/projects/collaborations";
import { ProfessionalReferrals } from "../../../../../../api-types/projects/collaborations";
import { PROJECTS_QUERY_KEY } from "../../useProjectsQuery";

export const RECEIVED_PROFESSIONAL_REFERRALS_QUERY_KEY = `${PROJECTS_QUERY_KEY}-received-professional-referrals`;

export const useReceivedProfessionalReferralsProposals = () => {
  return useQuery<ProfessionalReferrals[], unknown, ProfessionalReferrals[]>(
    RECEIVED_PROFESSIONAL_REFERRALS_QUERY_KEY,
    async () => {
      const { data } = await fetchReceivedProfessionalReferrals();

      return data;
    }
  );
};
