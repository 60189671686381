import { Button } from "@material-ui/core";
import { FC, Fragment } from "react";
import { BLUE } from "../../../../themes/components/utils";

type MilestoneChangesActionsProps = {
  onAcceptChanges?: () => void;
  onDenyChanges?: () => void;
};

export const MilestoneChangesActions: FC<MilestoneChangesActionsProps> = ({
  onAcceptChanges,
  onDenyChanges,
}) => {
  return (
    <Fragment>
      <Button
        onClick={onAcceptChanges}
        size="small"
        style={{
          backgroundColor: BLUE,
        }}
      >
        Approve
      </Button>
      <Button
        onClick={onDenyChanges}
        size="small"
        style={{
          backgroundColor: BLUE,
        }}
      >
        Deny
      </Button>
    </Fragment>
  );
};
