import { ButtonBase, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { YELLOW } from "../../../themes/components/utils";

type BackButtonProps = {
  onClick?: () => void;
  backLabel?: string;
  titleClass?: string;
};

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
    color: YELLOW,
  },
}));

export const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  backLabel = "Back",
  titleClass,
}) => {
  const classes = useStyles();

  return (
    <ButtonBase onClick={onClick}>
      <ArrowBackIosIcon className={classes.leftIcon} />
      <Typography className={titleClass} variant="subtitle2" color="primary">
        {backLabel}
      </Typography>
    </ButtonBase>
  );
};
