import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { addProjectFile } from "../../../../api-services/projects/project/addProjectFile";
import { AddFilesRequest } from "../../../../api-types/job-requests/common";
import { projectKey } from "../../../common/hooks/projects/project/useProjectQuery";

export const useAddProjectFileMutation = () => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();

  return useMutation(
    (files: AddFilesRequest) => addProjectFile(projectId ?? "", files),
    {
      onSuccess() {},
      onSettled() {
        queryClient.invalidateQueries(projectKey(projectId));
      },
    }
  );
};
