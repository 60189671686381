import { useMutation, useQueryClient } from "react-query";
import { createJobApplication } from "../../../../api-services/job-applications";
import { JobApplicationData } from "../../../../api-types/job-applications";
import { METADATA_QUERY_KEY } from "../../../common/hooks/metadata/useMetadataQuery";
import { useToast } from "../../../utils/useToast";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../job-requests/hooks/feedbackErrors";

export const useCreateJobApplication = () => {
  const queryClient = useQueryClient();
  const { notifySuccess } = useToast();

  return useMutation(
    async (application: JobApplicationData) => {
      const { data } = await createJobApplication(application);

      return data;
    },
    {
      onSuccess() {
        notifySuccess(feedbackMessage("job application", FEEDBACK_TYPE.SUBMIT));
      },
      onSettled() {
        queryClient.invalidateQueries(METADATA_QUERY_KEY);
      },
    }
  );
};
