import {
  Box,
  Checkbox,
  Input,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { Field } from "formik";
import React, { ChangeEvent, Fragment, useCallback } from "react";
import { YELLOW, RED, BLUE } from "../../../../themes/components/utils";
import { CustomTooltip } from "../../../common/components";

export const useStyles = makeStyles({
  checkbox: {
    "& .MuiSvgIcon-root": {
      width: 40,
      height: 40,
      fill: "white",
    },
  },
  checkboxColor: {
    "& .MuiSvgIcon-root": {
      fill: BLUE,
    },
  },
  input: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderRadius: 2,
    borderColor: BLUE,
    color: BLUE,
    marginRight: 12,
    fontSize: 24,
    width: 96,
    height: 43,

    "& .MuiInputBase-input": {
      paddingRight: 2,
    },

    "& input": {
      padding: "4px 6px",
    },
    /* Chrome, Safari, Edge, Opera */
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    /* Firefox */
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  inputColor: {
    borderColor: BLUE,
    color: BLUE,
  },
  inputWidthSmall: {
    width: 68,
  },
  leftLabel: {
    paddingRight: 12,
  },
  inputError: {
    border: `2px solid ${RED}`,
  },
});

type CheckboxWithInputProps = {
  className?: string;
  disabled?: boolean;
  leftLabel?: string;
  betweenInputsLabel?: string;
  colorCheckbox: boolean;
  colorInput?: boolean;
  width?: string;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (field: string, value: boolean) => void;
  value?: string | number;
  minValue?: string | number;
  maxValue?: string | number;
  error?: string;
  minError?: string;
  maxError?: string;
  name?: string;
  checkboxValue?: string;
  checked?: boolean;
  isMinMaxInput?: boolean;
};

export const CheckboxWithInput: React.FC<CheckboxWithInputProps> = ({
  leftLabel,
  betweenInputsLabel,
  colorCheckbox,
  colorInput,
  disabled,
  width,
  onChange,
  onBlur,
  onFocus,
  value,
  minValue,
  maxValue,
  checkboxValue,
  name,
  checked,
  isMinMaxInput,
  error,
  minError,
  maxError,
  className,
}) => {
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Field
        as={Checkbox}
        className={`${className} ${styles.checkbox} ${
          colorCheckbox && styles.checkboxColor
        }`}
        disabled={disabled}
        name={`${name}.checked`}
        onChange={onChange}
        onBlur={onBlur}
        data-cy={`${name}.checked`}
      />

      {!isMinMaxInput ? (
        <Fragment>
          {leftLabel && (
            <Typography
              color="secondary"
              variant="body1"
              className={styles.leftLabel}
            >
              {leftLabel}
            </Typography>
          )}
          <Field
            as={Input}
            disabled={!checked || disabled}
            disableUnderline
            className={`${styles.input} ${colorInput && styles.inputColor} ${
              width === "small" && styles.inputWidthSmall
            } ${!error ? "" : styles.inputError}`}
            value={value}
            name={`${name}.${checkboxValue}`}
            onChange={onChange}
            onBlur={onBlur}
            style={{
              paddingRight: 4,
            }}
            type="number"
            inputProps={{ min: 0, max: 999 }}
            data-cy={`${name}.${checkboxValue}`}
            endAdornment={
              error && (
                <CustomTooltip title={error} type="error">
                  <ErrorRoundedIcon
                    color="error"
                    fontSize="small"
                    data-cy-error={`${name}.${checkboxValue}`}
                    style={{ cursor: "initial" }}
                  />
                </CustomTooltip>
              )
            }
          />
        </Fragment>
      ) : (
        <Fragment>
          {leftLabel && (
            <Typography
              color="secondary"
              variant="body1"
              className={styles.leftLabel}
            >
              {leftLabel}
            </Typography>
          )}
          <Field
            disabled={!checked || disabled}
            as={Input}
            disableUnderline
            className={`${styles.input} ${colorInput && styles.inputColor} ${
              width === "small" && styles.inputWidthSmall
            } ${!minError ? "" : styles.inputError}`}
            value={minValue}
            name={`${name}.${checkboxValue}.min`}
            onChange={onChange}
            onBlur={onBlur}
            style={{
              paddingRight: 4,
            }}
            type="number"
            data-cy={`${name}.${checkboxValue}.min`}
            endAdornment={
              minError && (
                <CustomTooltip title={minError} type="error">
                  <ErrorRoundedIcon
                    color="error"
                    fontSize="small"
                    data-cy-error={`${name}.${checkboxValue}.min`}
                    style={{ cursor: "initial" }}
                  />
                </CustomTooltip>
              )
            }
          />

          {betweenInputsLabel && (
            <Typography
              color="secondary"
              variant="body1"
              className={styles.leftLabel}
            >
              {betweenInputsLabel}
            </Typography>
          )}

          <Field
            disabled={!checked || disabled}
            as={Input}
            disableUnderline
            className={`${styles.input} ${colorInput && styles.inputColor} ${
              width === "small" && styles.inputWidthSmall
            }  ${!maxError ? "" : styles.inputError}`}
            value={maxValue}
            name={`${name}.${checkboxValue}.max`}
            onChange={onChange}
            onBlur={onBlur}
            type="number"
            style={{
              paddingRight: 4,
            }}
            data-cy={`${name}.${checkboxValue}.max`}
            endAdornment={
              maxError && (
                <CustomTooltip title={maxError} type="error">
                  <ErrorRoundedIcon
                    color="error"
                    fontSize="small"
                    data-cy-error={`${name}.${checkboxValue}.max`}
                    style={{ cursor: "initial" }}
                  />
                </CustomTooltip>
              )
            }
          />
        </Fragment>
      )}
    </Box>
  );
};
