import {
  Box,
  Divider,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { format } from "date-fns";
import { Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { array, object, string } from "yup";
import { SkillV2 } from "../../../../../api-types/job-requests/common";
import {
  COLOR,
  LIGHT_BLUE,
  RED,
  YELLOW,
} from "../../../../../themes/components/utils";
import { CustomTooltip } from "../../../../common/components";
import { formatNominativeMonth } from "../../../../common/components/LoadingTypography";
import { useJobRequestQuery } from "../../../../common/hooks/job-requests/job-request/useJobRequestQuery";
import { useJobRequestUpdateMutation } from "../../../../common/hooks/job-requests/job-request/useJobRequestUpdateMutation";
import {
  FEEDBACK_TYPE,
  feedbackMessage,
} from "../../../../professional/job-requests/hooks/feedbackErrors";
import { useToast } from "../../../../utils/useToast";
import { parseJobRequestForm } from "../../create-job-request-wizard/utils";
import {
  BudgetSchemaDefinition,
  FixedProjectFundingSchemaDefinition,
  HourlyRateFundingSchemaDefinition,
} from "../../create-job-request-wizard/validation-schema";
import { EditDetailsForm } from "./EditDetailsForm";
import { EditDetailsFormActions } from "./EditDetailsFormActions";

type EditDetailsFormProps = {
  isEditing?: boolean;
  setIsEditing: (value: boolean) => void;
};

const useStyles = makeStyles({
  titleTextField: {
    height: "46px",
    width: "500px",
    "& .MuiOutlinedInput-root": {
      background: "none",
      color: COLOR.WHITE,
      fontSize: "24px",
      fontWeight: "bold",
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "8px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: LIGHT_BLUE,
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: YELLOW,
      borderWidth: "2px",
    },
    "& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
      {
        borderColor: RED,
      },
  },
});

export const EditDetails: React.FC<EditDetailsFormProps> = ({
  setIsEditing,
}) => {
  const styles = useStyles();
  const { data: jobRequest, isLoading: isLoadingJobRequest } =
    useJobRequestQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const { notifyError, notifySuccess } = useToast();

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (isLoadingJobRequest) return;

    setTitle(jobRequest?.title ?? "");
  }, [isLoadingJobRequest, jobRequest]);

  const skills = useMemo(() => {
    if (jobRequest?.skills && !isLoadingJobRequest) {
      return jobRequest.skills.map((skill) => skill.name);
    }

    return [];
  }, [jobRequest, isLoadingJobRequest]);

  const [postedDate] = useState(new Date());

  const initialValues = useMemo(() => {
    return jobRequest ? { ...jobRequest, skills } : {};
  }, [jobRequest, skills]);

  const editDetailsValidationSchema = object({
    title: string().required("Title is required"),
    description: string()
      .required("Description is required")
      .max(2000, "Description must be less than or equal to 2000 characters"),
    skills: array(),
    images: array().of(
      object().shape({
        description: string().max(100, "100 characters maximum"),
      })
    ),
    fixedProjectFunding: object().when("budget.type", {
      is: "fixed",
      then: FixedProjectFundingSchemaDefinition,
    }),
    hourlyRateProjectFunding: object().when("budget.type", {
      is: "hourly",
      then: HourlyRateFundingSchemaDefinition,
    }),
    budget: BudgetSchemaDefinition,
  });

  const { mutate: updateJobRequest } = useJobRequestUpdateMutation();

  return (
    <Box>
      <Box display="flex" gridColumnGap={160} alignItems="flex-end">
        <Box display="flex" flexDirection="column" py={2}>
          <TextField
            className={styles.titleTextField}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            placeholder="New Title"
            value={title}
            error={title.length === 0}
            InputProps={{
              endAdornment: title.length === 0 && (
                <CustomTooltip title="Title is required" type="error">
                  <ErrorRoundedIcon
                    color="error"
                    data-cy-error="title"
                    style={{ cursor: "initial" }}
                    fontSize="small"
                  />
                </CustomTooltip>
              ),
            }}
          />
        </Box>
        <Typography
          style={{
            fontSize: 12,
          }}
        >
          Posted {format(postedDate, formatNominativeMonth)}
        </Typography>
      </Box>
      <Divider />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          searchParams.delete("isEditing");
          setSearchParams(searchParams);

          updateJobRequest(
            { ...parseJobRequestForm(values), title },
            {
              onSuccess() {
                setIsEditing(false);
                notifySuccess(
                  feedbackMessage("job request", FEEDBACK_TYPE.UPDATE)
                );
              },
              onError() {
                notifyError(feedbackMessage("", FEEDBACK_TYPE.GENERAL_ERROR));
              },
            }
          );
        }}
        validationSchema={editDetailsValidationSchema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          values,
          isValid,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <EditDetailsForm
                handleChange={handleChange}
                handleBlur={handleBlur}
                onUpdateSkills={(newSkills: SkillV2[]) => {
                  setFieldValue(
                    "skills",
                    newSkills.map((skillName) => ({
                      name: skillName,
                    }))
                  );
                }}
                onUpdateImages={(images: any[]) => {
                  setFieldValue("images", images);
                }}
                errors={errors}
                values={values}
              />
              <EditDetailsFormActions
                setIsEditing={setIsEditing}
                disabledSubmit={!isValid || !title.length}
              />
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
