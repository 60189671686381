import React from "react";
import { Route } from "react-router-dom";
import { AccountInformation } from "../../modules/common/account/account-information";
import { OurBlog } from "../../modules/common/blog";
import { Contact } from "../../modules/common/contact";
import { ClientLanding } from "../../modules/common/landing-page/client";
import { ProfessionalLanding } from "../../modules/common/landing-page/professional";
import { JobRequestsPageSubscribe } from "../../modules/common/landing-page/subscribe/job-requests";
import { ProfessionalsPageSubscribe } from "../../modules/common/landing-page/subscribe/professionals";
import { NotificationsPage } from "../../modules/common/notifications/NotificationsPage";
import { Browse } from "../../modules/common/professionals-search";
import { SignIn } from "../../modules/common/sign-in";
import { PasswordReset } from "../../modules/common/sign-in/PasswordReset";
import { PasswordResetConfirmation } from "../../modules/common/sign-in/PasswordResetConfirmation";
import { SignUp } from "../../modules/common/sign-up";
import { ProfessionalPublicAccount } from "../../modules/professional/account/public";
import { ProjectDetails } from "../../modules/professional/account/public/sections/on-going-projects/project-details";
import { PortfolioProject } from "../../modules/professional/account/public/sections/portfolio/portfolio-project";
import { JobPreviewPage } from "../../modules/professional/job-posts";
import { NotFound } from "../NotFound";
import { PrivateRoute } from "../PrivateRoute";

export const commonRoutes = [
  <PrivateRoute
    path="/professionals/:professionalId/portfolio/projects/:projectId"
    element={<PortfolioProject />}
  />,
  <PrivateRoute element={<AccountInformation />} path="/account-information" />,
  <PrivateRoute path="/browse" element={<Browse />} />,
  <PrivateRoute
    path="/professionals/:professionalId"
    element={<ProfessionalPublicAccount />}
  />,

  <PrivateRoute
    path="/professionals/:professionalId/projects/:projectId"
    element={<ProjectDetails />}
  />,
  <Route path="/sign-up" element={<SignUp />} />,
  <Route path="/notifications" element={<NotificationsPage />} />,
  <Route
    path="/password-reset/:token"
    element={<PasswordResetConfirmation />}
  />,
  <Route path="/password-reset" element={<PasswordReset />} />,
  <Route path="/sign-in" element={<SignIn />} />,
  <Route path="/browse/subscribe" element={<ProfessionalsPageSubscribe />} />,
  <Route path="/jobs/subscribe" element={<JobRequestsPageSubscribe />} />,
  <PrivateRoute path="/contact" element={<Contact />} />,
  <Route path="/" element={<ProfessionalLanding />} />,
  <Route path="/client" element={<ClientLanding />} />,
  <Route path="/blog" element={<OurBlog />} />,
  // <PrivateRoute
  //   path="/job-requests/search/:jobPostId"
  //   element={<JobPreviewPage />}
  // />,
  <Route path="/job-requests/search/:jobPostId" element={<JobPreviewPage />} />,
  <Route path="*" element={<NotFound />} />,
];
